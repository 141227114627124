import React, { useContext } from 'react';
import { ActionIcon, Flex, Navbar, useMantineTheme } from '@mantine/core';
import {
  IconAlbum,
  IconChartLine,
  IconBuildingCommunity,
  IconLock,
  IconLayoutSidebarLeftExpand,
  IconLayoutSidebarLeftCollapse,
} from '@tabler/icons-react';
import { LinksGroup } from './LeftMenuNestes';
import { AuthContext } from '../../context/AuthContext';
import { ADMIN_ROLES, USER_ROLES } from '../../constants/user';
import { SidebarContext } from '../../context/SidebarContext';
import { useFeatureFlagsService } from '../../services/featureFlagsService';

export function NavbarSimple() {
  const { currentUser } = useContext(AuthContext);
  const { handleCollapsedChange, enableExpanding, expanded } = useContext(SidebarContext);

  const navItems = [];

  const { isFeatureEnabled } = useFeatureFlagsService();

  currentUser?.role === USER_ROLES.PROPERTY_MANAGER &&
    navItems.push(
      { link: '/properties', label: 'Properties', icon: IconBuildingCommunity },
      { link: '/portfolio-overview', label: 'Portfolio Overview', icon: IconAlbum },
      { link: '/forecast-planner', label: 'Forecast Planner', icon: IconChartLine }
    );

  ADMIN_ROLES.includes(currentUser?.role) &&
    navItems.push({
      label: 'Admin Controls',
      icon: IconLock,
      link: '/admin',
    });

  const links = navItems
    .filter((x) => !x.featureFlag || isFeatureEnabled(x.featureFlag))
    .map((item) => <LinksGroup {...item} key={item.label} />);
  const { colors } = useMantineTheme();
  return (
    <Navbar
      width={{ sm: 80 }}
      sx={{
        backgroundColor: colors.secondary_background_color[6],
        borderRight: '1px',
        boxShadow: '#00000029 0px 0px 18px',
      }}
    >
      <Navbar.Section grow>
        <Flex direction={'column'} align={'center'}>
          {links}
        </Flex>
      </Navbar.Section>
      <ActionIcon
        size='xl'
        w={'100%'}
        variant='outline'
        sx={{
          border: 'none',
          color: colors.text_color[4],
          '&:disabled': {
            backgroundColor: 'transparent',
          },
        }}
        disabled={!enableExpanding}
      >
        {expanded ? (
          <IconLayoutSidebarLeftCollapse size='2rem' onClick={handleCollapsedChange} />
        ) : (
          <IconLayoutSidebarLeftExpand size='2rem' onClick={handleCollapsedChange} />
        )}
      </ActionIcon>
    </Navbar>
  );
}
