import React from 'react';
import { Tooltip, useMantineTheme, Text } from '@mantine/core';

const IconInfo = ({ hint, width = 220 }) => {
  const { colors } = useMantineTheme();
  return (
    <Tooltip
      multiline
      w={width}
      withArrow
      transitionProps={{ duration: 200 }}
      label={hint}
      arrowSize={8}
      color='company_theme_primary_color'
      position='bottom'
      zIndex={500}
    >
      <Text fw={6} sx={{ cursor: 'pointer', zIndex: 201 }} color={colors.text_color[4]}>
        &#9432;
      </Text>
    </Tooltip>
  );
};

export default IconInfo;
