export const mapNullToEmptyString = (obj) => {
  let copy = { ...obj };
  for (let key in copy) {
    if (copy[key] === null) {
      copy[key] = '';
    } else if (Array.isArray(copy[key])) {
      copy[key] = copy[key].map((x) => (typeof x === 'object' ? mapNullToEmptyString(x) : x));
    } else if (typeof copy[key] === 'object') {
      copy[key] = mapNullToEmptyString(copy[key]);
    }
  }
  return copy;
};
