import { Input } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React from 'react';

export default function Search({ handleChange, label = 'Search', placeholder = 'Search', defaultValue = '' }) {
  return (
    <>
      <Input.Label>{label}</Input.Label>
      <Input
        defaultValue={defaultValue}
        placeholder={placeholder}
        icon={<IconSearch size='1.3em' />}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
    </>
  );
}
