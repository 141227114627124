import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDebouncedState } from '@mantine/hooks';
import * as dayjs from 'dayjs';
import { AuthContext } from './AuthContext';
import { useSearchParams } from 'react-router-dom';
import { useMantineColorScheme } from '@mantine/core';
import { USER_PROPERTIES_KEY_BY_ROLE, USER_ROLES } from '../constants/user';
import { useTheme } from './ThemeContext';

export const PropertyFiltersContext = createContext(null);

export const PropertyFiltersProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const { setCompanyTheme } = useTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const options =
    currentUser?.[USER_PROPERTIES_KEY_BY_ROLE[currentUser.role]]
      ?.map((x) => ({ ...x, value: x.id, label: x.name }))
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  const [searchParams] = useSearchParams();
  const { property_id, from, to, theme } = Object.fromEntries(searchParams);

  const startDate = from
    ? dayjs(from)
    : dayjs().date() === 1
    ? dayjs().subtract(1, 'month').startOf('month')
    : dayjs().startOf('month');
  const endDate = to ? dayjs(to) : dayjs().subtract(1, 'day');

  const defaultProperty = options.length === 1 ? options[0] : options.find((x) => x.id === +property_id);

  const [property, setProperty] = useDebouncedState(defaultProperty, 400, {
    leading: true,
  });

  if (theme && theme !== colorScheme) {
    toggleColorScheme();
  }

  const [multiTouchAttribution, setMultiTouchAttribution] = useState(false);

  const toggleMultiTouchAttribution = () => {
    setMultiTouchAttribution(!multiTouchAttribution);
  };

  const handleSelectChange = (value) => {
    const option = options.find((o) => o.value === value);
    setProperty(option);
  };

  const [dates, setDates] = useDebouncedState([startDate, endDate], 300, {
    leading: true,
  });

  useEffect(() => {
    if (currentUser.role === USER_ROLES.SDM_USER && property?.company_theme) {
      setCompanyTheme(property?.company_theme);
    }
  }, [property]);

  return (
    <PropertyFiltersContext.Provider
      displayName={'PropertyFiltersProvider'}
      value={{
        dates,
        options,
        property,
        setProperty,
        setDates,
        handleSelectChange,
        multiTouchAttribution,
        toggleMultiTouchAttribution,
      }}
    >
      {children}
    </PropertyFiltersContext.Provider>
  );
};
