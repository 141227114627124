import React from 'react';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import { columns, initialState } from './constants/usersActivityDef';
import SimpleReportTable from '../../../../../../../components/SimpleReportTable/SimpleReportTable';
import dayjs from 'dayjs';
import { useUsersActivityService } from '../../../../../../../services/usersActivityService';

const UsersLastLoginTable = ({ dates }) => {
  const { getUsersByLastLogin } = useUsersActivityService();

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'Users Last Log In',
        columns,
        initialState,
        cellMaxWidth: '',
      }}
      fetchMethod={getUsersByLastLogin}
      defaultValue={[]}
      deps={[dates]}
      params={{ dates }}
      paramsFormatter={(params) => [
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default UsersLastLoginTable;
