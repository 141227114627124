import React from 'react';
import { Flex, Title } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';

export default function SystemHealthHeader({ dates, onChangeDates }) {
  return (
    <Flex justify='space-between' align='center'>
      <Title fw={700} my={25}>
        System Health
      </Title>
      <DatePickerInput
        valueFormat='MM/DD/YYYY'
        type='range'
        placeholder='Pick dates range'
        icon={<IconCalendar size='1.3em' />}
        variant={'default'}
        value={dates}
        onChange={onChangeDates}
        w={250}
      />
    </Flex>
  );
}
