import { Text } from '@mantine/core';
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export const columns = [
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => (
      <Text
        c={
          cell.getValue() === 'active'
            ? 'green'
            : cell.getValue() === 'pending'
            ? 'yellow'
            : cell.getValue() === 'draft'
            ? 'gray'
            : 'red'
        }
        tt='capitalize'
      >
        {cell.getValue()}
      </Text>
    ),
    filterFn: 'notEquals',
    columnFilterModeOptions: ['equals', 'notEquals', 'fuzzy'],
    sortingFn: (a, b) => {
      const statuses = { draft: 0, pending: 1, active: 3, disabled: 4 };
      return statuses[b.original.status] - statuses[a.original.status];
    },
  },
  {
    accessorKey: 'id',
    header: 'ID',
    columnFilterModeOptions: ['fuzzy'],
  },
  {
    accessorKey: 'full_name',
    header: 'Full Name',
    Cell: ({ cell }) => {
      return (
        <Link to={`${cell?.row?.original?.id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      );
    },
    columnFilterModeOptions: ['fuzzy'],
  },
  {
    accessorKey: 'email',
    header: 'Email',
    columnFilterModeOptions: ['fuzzy'],
  },
  {
    accessorKey: 'role',
    header: 'Role',
    columnFilterModeOptions: ['fuzzy'],
  },
  {
    accessorKey: 'company_name',
    header: 'Company',
    Cell: ({ cell }) => {
      return (
        <Link to={`/admin/companies/${cell?.row?.original?.company_id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      );
    },
    columnFilterModeOptions: ['fuzzy'],
  },
  {
    accessorKey: 'created_date',
    header: 'Created On',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false,
    Cell: ({ cell }) => dayjs(cell.getValue()).format('MMM DD YYYY'),
    accessorFn: (row) => {
      const sDay = new Date(row.created_date);
      sDay.setHours(0, 0, 0, 0);
      return sDay;
    },
  },
];

export const initialState = {
  sorting: [{ id: 'status', desc: true }],
  columnFilters: [
    {
      id: 'status',
      // 'status' column's filter function is set to 'notEquals' by default.
      // Therefore, this initial filter will exclude all records with 'disabled' status
      value: 'disabled',
    },
  ],
};
