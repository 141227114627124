import dayjs from 'dayjs';
import React from 'react';
import AudioPlayer from '../../../../../../../../../components/AudioPlayer/AudioPlayer';
import { IconCheck } from '@tabler/icons-react';
import { Flex, Text, useMantineTheme } from '@mantine/core';
import { THEME_MODES } from '../../../../../../../../../constants/theme_modes';
import { formatDuration } from '../../../../../../../../../utils/time.util';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

function formatCustomerName(name) {
  if (!name) return '';

  name = name.trim();

  if (name.includes(',')) {
    let parts = name.split(',').map((part) => part.trim());
    name = `${parts[1]} ${parts[0]}`;
  }

  return name
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

function formatPhoneNumber(phoneNumber) {
  let cleaned = phoneNumber.replace(/\D/g, '').slice(1);

  return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
}

export const columns = [
  {
    accessorKey: 'call_type',
    header: 'Call Type',
    maxSize: 50,
    Cell: ({ cell }) => <Text tt='capitalize'>{cell.getValue()}</Text>,
  },
  {
    accessorKey: 'customer_phone_number',
    header: 'Contact',
    maxSize: 60,
    enableSorting: false,
    Cell: ({ cell, row }) => {
      const contactNumber = formatPhoneNumber(cell.getValue());
      const customerName = formatCustomerName(row.original.customer_name);
      const { colorScheme, colors } = useMantineTheme();
      const customerNameColor = colorScheme === THEME_MODES.darkMode ? 'darkgrey' : 'grey';

      return (
        <Flex justify={'center'} direction={'column'} align={'center'}>
          <Text color={customerNameColor}>{customerName}</Text>
          <Text color={colors.link_color[6]}>{contactNumber}</Text>
        </Flex>
      );
    },
  },
  {
    accessorKey: 'start_time',
    header: 'Start Time',
    maxSize: 75,
    Cell: ({ cell }) => {
      const date = dayjs(cell.getValue()).format('MMM D hh:mma');

      return <div>{date}</div>;
    },
    accessorFn: (row) => {
      return new Date(row.start_time);
    },
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    maxSize: 50,
    Cell: ({ cell }) => {
      const duration = dayjs.duration(cell.getValue(), 'seconds');
      const formattedDuration = formatDuration(duration);

      return <div style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>{formattedDuration}</div>;
    },
  },
  {
    accessorKey: 'first_call',
    header: 'First call',
    maxSize: 50,
    Cell: ({ cell }) => {
      const firstCall = cell.getValue();

      return (
        <div style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>{firstCall ? <IconCheck /> : ''}</div>
      );
    },
  },
  {
    accessorKey: 'source',
    header: 'Source',
    maxSize: 50,
  },
  {
    accessorKey: 'recording_player',
    header: 'Record',
    maxSize: 50,
    enableSorting: false,
    Cell: ({ table, row, cell }) => {
      const recordingUrl = cell.getValue() && cell.getValue().replace('recording', 'recording/redirect');
      const isAbandonedCall = row.original.call_type === 'abandoned';
      const isMissedCall = row.original.call_type === 'missed';
      const { currentPlayingUrl, setCurrentPlayingUrl, playingInstance, setPlayingInstance } = table.getState();

      return recordingUrl && !isAbandonedCall && !isMissedCall ? (
        <AudioPlayer
          recordingUrl={recordingUrl}
          currentPlayingUrl={currentPlayingUrl}
          setCurrentPlayingUrl={setCurrentPlayingUrl}
          playingInstance={playingInstance}
          setPlayingInstance={setPlayingInstance}
        />
      ) : (
        ''
      );
    },
  },
];

export const initialState = {
  sorting: [{ id: 'start_time', desc: true }],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
  currentPlayingUrl: null,
  playingInstance: null,
};
