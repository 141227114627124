import React, { useContext } from 'react';
import { columns, initialState, state } from './constants/unavailableUnitsTableDef';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import SimpleReportTable from '../../../../../../../../components/SimpleReportTable/SimpleReportTable';
import { useBiPropertyBoxScoreService } from '../../../../../../../../services/bi/property/overview/PropertyBoxScoreService';

export default function UnavailableUnitsTable() {
  const { getUnavailableUnits } = useBiPropertyBoxScoreService();

  const { property } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'Unavailable Units',
        columns,
        initialState,
        state,
      }}
      fetchMethod={getUnavailableUnits}
      defaultValue={[]}
      deps={[property]}
      params={{ property }}
      paramsValidator={(params) => params.property}
      paramsFormatter={(params) => [params.property.value]}
    />
  );
}
