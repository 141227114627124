import React, { useState } from 'react';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { Group, Text, useMantineTheme, Image, LoadingOverlay, AspectRatio } from '@mantine/core';
import { IconUpload, IconX } from '@tabler/icons-react';
import { useUploadFileService } from '../../services/uploadFileService';

const MEDIA_BUCKET = process.env.REACT_APP_MEDIA_BUCKET_NAME;

export default function SingleImageUploader({
  value,
  onChange,
  disabled,
  readOnly,
  title,
  path,
  bucketName = MEDIA_BUCKET,
  maxSize = 100,
  ...props
}) {
  const theme = useMantineTheme();
  const { upload } = useUploadFileService();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState('');

  const handleFileUpload = async (file) => {
    try {
      if (file) {
        const imageName = `${path && path + '/'}${file.name}`;

        setUploadedFile(file);
        setIsLoading(true);
        const image = await upload(file, imageName, bucketName);

        return handleImageChange(image?.url);
      }
    } catch (e) {
      console.log('Error occurred', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <Dropzone
        onDrop={(files) => {
          handleFileUpload(files[0]);
          setIsDragging(false);
          setRejectionMessage('');
        }}
        onReject={(files) => {
          const errors = files[0]?.errors || [];
          const invalidTypeError = errors.find((error) => error.code === 'file-invalid-type');
          const largeFileError = errors.find((error) => error.code === 'file-too-large');

          let errorMsg = '';
          if (invalidTypeError) {
            let errorType = 'File type must be PNG';
            errorMsg = `Unable to upload image. ${errorType}. Please make the appropriate changes and try again!`;
          } else if (largeFileError) {
            let errorType = `Maximum size is ${maxSize}kb`;
            errorMsg = `Unable to upload image. ${errorType}. Please make the appropriate changes and try again!`;
          }

          setRejectionMessage(errorMsg);
          setIsDragging(false);
        }}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        maxSize={maxSize * 1024}
        disabled={disabled}
        readOnly={readOnly}
        accept={[MIME_TYPES.png]}
        {...props}
      >
        {isDragging && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              zIndex: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Dropzone.Accept>
              <IconUpload
                size='3.2rem'
                stroke={1.5}
                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
              />
            </Dropzone.Accept>
            <Dropzone.Reject
              style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}
            >
              <IconX size='3.2rem' stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
            </Dropzone.Reject>
          </div>
        )}
        <Group
          position='center'
          style={{
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {!readOnly && (
            <Text style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <LoadingOverlay
                id={`loading_overlay__${title}`}
                zIndex={10}
                visible={isLoading}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ type: 'bars' }}
                sx={{
                  borderRadius: '5%',
                }}
              />
              <Text size='l' inline>
                Drag <b>PNG</b> image here or click to select a file
              </Text>
              <Text size='sm' color='dimmed' inline mt={7}>
                Attached file should not exceed {maxSize}kb
              </Text>
            </Text>
          )}
          <AspectRatio ratio={4 / 3} style={{ width: '100%', maxWidth: '200px', objectFit: 'contain' }}>
            <Image src={value ? value : uploadedFile} />
            {!value && (
              <Text c={'dimmed'} size={'xl'}>
                No Image
              </Text>
            )}
          </AspectRatio>
        </Group>
      </Dropzone>
      {!isDragging && rejectionMessage && (
        <Group
          position='center'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: '1rem',
          }}
        >
          <Text size='md' sx={{ textAlign: 'center' }} color={theme.colors.red[6]}>
            {rejectionMessage}
          </Text>
        </Group>
      )}
    </>
  );
}
