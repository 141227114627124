import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { useWebsitePerformanceService } from '../../../../../../../services/bi/property/marketing/WebsitePerformanceService';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import dayjs from 'dayjs';

export default function PhoneCallsMetric() {
  const { getPhoneCalls } = useWebsitePerformanceService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Phone Calls',
        hint: 'A total count of gross phone calls generated from your property website.',
      }}
      fetchMethod={getPhoneCalls}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
