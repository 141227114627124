import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/marketing/digital-market-survey'

export const useDigitalMarketSurveyService = () => {
  const { axios } = useAxios();

  const getMarketImpressionShare = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/market-impression-share`, { ...config, params });

    return response?.data;
  };

  const getAboveOrganic = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/above-organic`, { ...config, params });

    return response?.data;
  };

  const getTopPlacement = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/impression-absolute-top`, { ...config, params });

    return response?.data;
  };

  const getMarketShareLostDueToBudget = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/market-share-lost`, { ...config, params });

    return response?.data;
  };

  return {
    getMarketImpressionShare,
    getAboveOrganic,
    getTopPlacement,
    getMarketShareLostDueToBudget
  };
};