import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';
import { useBrandAwarenessService } from '../../../../../../../services/bi/property/marketing/BrandAwarenessService';

const AvgCpmMetric = () => {
  const { getAvgCpm } = useBrandAwarenessService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Average CPM',
        valueFormatter: (value) => value?.toFixed(2),
        prefix: '$',
        hint: 'CPM stands for cost per mille, or cost per thousand impressions. Average CPM refers to the average cost of one thousand ad impressions or the average amount you pay every thousand times internet browsers load your ad.',
      }}
      fetchMethod={getAvgCpm}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default AvgCpmMetric;
