import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import { useWebsitePerformanceService } from '../../../../../../../../services/bi/property/marketing/WebsitePerformanceService';
import PieChartComponent from '../../../../../../../../components/PieChart/PieChart';

export default function TopReferralSources() {
  const { getTopReferralSources } = useWebsitePerformanceService();

  const { property, dates } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    if (!data?.length) return [];

    const mainSources = data.filter((x) => x.percentage >= 5);
    const otherSources = data.filter((x) => x.percentage < 5);

    return [
      ...mainSources,
      {
        source: 'Others',
        total: otherSources.reduce((acc, cur) => acc + cur.total, 0),
        percentage: otherSources.reduce((acc, cur) => acc + cur.percentage, 0),
      },
    ];
  };

  return (
    <DataFetcherComponent
      Child={PieChartComponent}
      childProps={{
        title: 'Top Referral Sources',
        index: 'source',
        excludeFields: ['total'],
        variant: 'donut',
        hint: 'What sites your visitors are coming from.',
        dataFormatter,
        customTooltipValueFormatter: (x) => `${x.payload?.total} (${Number(x.value).toFixed(1)}%)`,
        valueFormatter: (x) => `${Number(x).toFixed(1)}%`,
      }}
      fetchMethod={getTopReferralSources}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
