import React, { useContext } from 'react';
import { useWebsiteLeadsService } from '../../../../../../../../services/bi/property/marketing/WebsiteLeadAttributionService';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import dayjs from 'dayjs';
import VerticalBarChartComponent from '../../../../../../../../components/VerticalBarChart/VerticalBarChart';

const WebsiteLeadsBySource = () => {
  const { getWebsiteLeadsBySource } = useWebsiteLeadsService();

  const { property, dates, multiTouchAttribution } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    if (!data?.length) return [];

    return [
      ...data.map((x) => ({
        website_leads: Number(x.total),
        source_and_medium: `${x.source} / ${x.medium}`,
      })),
    ].sort((a, b) => b.website_leads - a.website_leads);
  };

  return (
    <DataFetcherComponent
      Child={VerticalBarChartComponent}
      childProps={{
        title: 'Website Leads By Source',
        index: 'source_and_medium',
        dataFormatter,
        showLegend: false,
      }}
      fetchMethod={getWebsiteLeadsBySource}
      defaultValue={[]}
      deps={[property, dates, multiTouchAttribution]}
      params={{ property, dates, multiTouchAttribution }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
        multiTouchAttribution,
      ]}
    />
  );
};

export default WebsiteLeadsBySource;
