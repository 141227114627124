import React, { useContext, useState } from 'react';
import { Group, Flex, Title, useMantineTheme, Menu, LoadingOverlay, Switch, ThemeIcon } from '@mantine/core';
import { PropertyFiltersContext } from '../../../../context/PropertyFiltersContext';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendarMonth, IconFileDownload, IconFileTypePdf } from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';
import { usePropertyDetails } from '../../../../context/PropertyDetailsContext';
import { loadImage } from '../../../../utils/utils';
import { AuthContext } from '../../../../context/AuthContext';
import { useExportReportService } from '../Services/exportReportService';
import { useFeatureFlagsService } from '../../../../services/featureFlagsService';
import { FEATURE_FLAGS } from '../../../../constants/featureFlags';
import {
  HIDE_DATE_FILTER_LOCATIONS,
  SHOW_MULTI_TOUCH_ATTRIBUTION_LOCATIONS,
} from '../../../../constants/propertyReportPages';
import sDLogoUrlOld from '../../../../assets/imgs/streetdigital-logo.png';

export default function PropertiesHeader({ isExporting, setIsExporting }) {
  const { property, dates, setDates, multiTouchAttribution, toggleMultiTouchAttribution } =
    useContext(PropertyFiltersContext);
  const { currentUser } = useContext(AuthContext);
  const [datePickerValue, setDatePickerValue] = useState(dates);
  const location = useLocation();
  const { exportPDF } = useExportReportService();
  const { isFeatureEnabled } = useFeatureFlagsService();
  const { colors } = useMantineTheme();

  const { details } = usePropertyDetails();

  const hideDatePicker = HIDE_DATE_FILTER_LOCATIONS.includes(location.pathname.split('/').pop());
  const showMultiTouchAttribution = SHOW_MULTI_TOUCH_ATTRIBUTION_LOCATIONS.includes(location.pathname.split('/').pop());

  const locationName = location.pathname
    .split('/')
    .pop()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const onChangeDates = (value) => {
    setDatePickerValue(value);
    if (value.length && value.every((el) => !!el)) {
      setDates(value);
    }
  };

  const toggleExporting = async () => {
    setIsExporting(!isExporting);

    const logo = await loadImage(currentUser?.theme?.logo_url_light ?? currentUser.company_logo);

    const aiLogoUrl = `https://storage.googleapis.com/${process.env.REACT_APP_MEDIA_BUCKET_NAME}/AI-Dark-Icon.png`;
    const aiLogo = await loadImage(aiLogoUrl);
    // TODO: CHANGE ONCE WE HAVE DARK LOGO
    // const sDLogoUrl = `https://storage.googleapis.com/${process.env.REACT_APP_MEDIA_BUCKET_NAME}/streetdigital-ai_logo.png`;
    const sDLogo = await loadImage(sDLogoUrlOld);

    setTimeout(
      async () => {
        await exportPDF({
          reportSelector: 'reporting-page-container',
          logo,
          aiLogo,
          drawColor: colors.border_color[0],
          textColor: [colors.text_subtle[0], colors.text_placeholder_color[0]],
          sDLogo,
          propertyName: property.name,
          pageName: locationName,
          from: dates[0],
          to: dates[1],
          hideDatePicker,
        });

        setIsExporting(!!isExporting);
      },

      700
    );
  };

  return (
    <Group
      p='xl'
      style={{
        position: 'sticky',
        'justify-content': 'space-between',
        alignItems: 'flex-start',
        top: 0,
        zIndex: 85,
        background: colors.gradient_header_color[6],
      }}
    >
      <Flex align='baseline' direction='column' sx={{ flex: '1' }}>
        <Title fw={600} tt='uppercase' sx={{ fontSize: '2rem' }}>
          {property?.label ?? ''}
        </Title>
        {details && (
          <Title color='text_third' fw={400} sx={{ fontSize: '0.75rem' }}>
            {details.city}, {details.state}
          </Title>
        )}
        <Title color='text_third' fw={400} sx={{ fontSize: '0.75rem' }}>
          {locationName}
        </Title>
        {showMultiTouchAttribution && isFeatureEnabled(FEATURE_FLAGS.GA4MultiTouchAttribution) && (
          <Switch
            ml='lg'
            style={{ alignSelf: 'center' }}
            checked={multiTouchAttribution}
            onChange={toggleMultiTouchAttribution}
            label='Multi Touch Attribution'
          />
        )}
      </Flex>

      <Flex gap='1.5rem'>
        {!hideDatePicker && (
          <DatePickerInput
            valueFormat='MM/DD/YYYY'
            type='range'
            placeholder='Pick dates range'
            icon={<IconCalendarMonth size='1.15em' color={colors.icon_secondary[0]} />}
            value={datePickerValue}
            radius='md'
            styles={{
              input: {
                backgroundColor: colors.primary_background[0],
                padding: '.5rem 0 .5rem 3rem!important',
                color: colors.text_secondary[0],
                maxHeight: 40,
                fontSize: '1rem',
                borderColor: colors.border_color[0],
              },
              icon: {
                paddingLeft: '1rem',
              },
            }}
            onChange={onChangeDates}
            w={260}
          />
        )}
        <Group>
          <LoadingOverlay
            id='pdf_export_loading_overlay'
            zIndex={10}
            visible={isExporting}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ type: 'bars' }}
          />
          {/* TODO: confirm with client if we want CSV in future near PDF button or in menu */}
          <Menu width={200} className={'download-file-dropdown'}>
            <Menu.Target>
              <ThemeIcon
                radius='md'
                variant='outline'
                sx={{
                  height: '100%',
                  minWidth: '40px',
                  backgroundColor: colors.primary_background,
                  cursor: 'pointer',
                  borderColor: colors.border_color[0],
                }}
              >
                <IconFileDownload color={colors.simple_icon_colors[0]} />
              </ThemeIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Download file format</Menu.Label>
              <Menu.Item icon={<IconFileTypePdf size={14} />} onClick={toggleExporting} id='pdf-export-button'>
                PDF
              </Menu.Item>
              {/*TODO: CSV Download*/}
              {/*<Menu.Item icon={<IconFileTypeCsv size={14} />} onClick={() => console.log('CSV')}>*/}
              {/*  CSV*/}
              {/*</Menu.Item>*/}
            </Menu.Dropdown>
          </Menu>

          {/*  <ActionIcon>*/}
          {/*    <IconViewportWide color='black' />*/}
          {/*  </ActionIcon>*/}
        </Group>
      </Flex>
    </Group>
  );
}
