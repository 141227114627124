import React from 'react';
import {
  ActionIcon,
  Box,
  Card,
  Container,
  Flex,
  LoadingOverlay,
  Overlay,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconArrowNarrowDown, IconArrowNarrowUp, IconRefresh } from '@tabler/icons-react';
import { GROW, GROW_COLOR, REVERSE_GROW_COLOR } from '../../constants/metric';
import IconInfo from '../IconInfo/IconInfo';

export default function ScoreMetric({
  title,
  showTrend = true,
  data,
  isLoading,
  hint,
  prefix,
  suffix,
  isError,
  onRetry,
  valueFormatter,
}) {
  const retry = () => {
    if (onRetry) {
      onRetry();
    }
  };
  const theme = useMantineTheme();
  return (
    <>
      <Card
        radius='md'
        padding='md'
        sx={{
          border: isError && '2px solid red',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          boxShadow: '#00000021 1px 2px 1px',
          backgroundColor: theme.colors.secondary_background_color[6],
        }}
      >
        <LoadingOverlay
          id={`loading_overlay__${title}`}
          zIndex={10}
          visible={isLoading}
          overlayProps={{ radius: 'sm', blur: 2 }}
          loaderProps={{ type: 'bars' }}
          sx={{
            borderRadius: '5%',
          }}
        />
        {isError && (
          <Overlay color={theme.colors.retry_error_color[6]} opacity={0.6} center sx={{ borderRadius: '5%' }}>
            <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
              <IconRefresh
                id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
                style={{ width: '70%', height: '70%' }}
                stroke={1.5}
                onClick={retry}
              />
            </ActionIcon>
          </Overlay>
        )}
        <Container
          p={0}
          m={0}
          sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
        >
          <Flex p={0}>
            <Title order={5}>{title}</Title>
          </Flex>
          <Title order={2} mt={7} fw={600} ff='Montserrat Semibold, sans-serif'>
            {data.value
              ? `${prefix ?? ''}${valueFormatter ? valueFormatter(data.value) : data.value}${suffix ?? ''}`
              : '-'}
          </Title>
        </Container>
        <Container p={0} m={0} mt={7} sx={{ display: 'flex', justifyContent: showTrend ? 'space-between' : 'end' }}>
          {showTrend && (
            <Flex justify='space-between'>
              {data.grow === GROW.UP && (
                <IconArrowNarrowUp
                  size={28}
                  viewBox='7 0 28 28'
                  color={data.reverse_grow ? REVERSE_GROW_COLOR[data.grow] : GROW_COLOR[data.grow]}
                />
              )}
              {data.grow === GROW.DOWN && (
                <IconArrowNarrowDown
                  size={28}
                  viewBox='7 0 28 28'
                  color={data.reverse_grow ? REVERSE_GROW_COLOR[data.grow] : GROW_COLOR[data.grow]}
                />
              )}
              <Text fw={500} size={17}>
                {data.grow === GROW.STEADY ? 'Steady' : data.percentage_change + '%'}
              </Text>
            </Flex>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {hint && <IconInfo hint={hint} />}
          </Box>
        </Container>
      </Card>
    </>
  );
}
