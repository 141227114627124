import React from 'react';
import { Container, SimpleGrid } from '@mantine/core';
import ATRMetric from './Metrics/ATRMetric';
import OccupancyMetric from './Metrics/OccupancyMetric';
import ThirtyDaysExpirationsMetric from './Metrics/ThirtyDaysExpirationsMetric';
import SixtyDaysExpirationsMetric from './Metrics/SixtyDaysExpirationsMetric';
import AverageRentPerSqftBoxScoreMetric from './Metrics/AverageRentPerSqftBoxScoreMetric';
import AvailableUnitsTable from './Tables/AvailableUnits/AvailableUnits';
import UnavailableUnitsTable from './Tables/UnavailableUnits/UnavailableUnits';
import AvailabilityOverviewTable from './Tables/AvailabilityOverview/AvailabilityOverview';
import FloorplanOccupancyTable from './Tables/FloorplanOccupancy/FloorplanOccupancy';

export default function PropertyOverviewBoxScoreSection() {
  return (
    <Container fluid m={0} pb='lg'>
      <SimpleGrid
        cols={5}
        breakpoints={[
          { maxWidth: 'xl', cols: 3, spacing: 'md' },
          { maxWidth: 'lg', cols: 2, spacing: 'md' },
          { maxWidth: 'md', cols: 1, spacing: 'sm' },
        ]}
      >
        <OccupancyMetric />
        <ATRMetric />
        <ThirtyDaysExpirationsMetric />
        <SixtyDaysExpirationsMetric />
        {/*TODO uncomment one this metric is displaying correct value */}
        {/*<RenewalPercentageMetric />*/}
        <AverageRentPerSqftBoxScoreMetric />
      </SimpleGrid>
      <SimpleGrid mt='md' cols={2} breakpoints={[{ maxWidth: 'lg', cols: 1, spacing: 'sm' }]}>
        <AvailabilityOverviewTable />
        <FloorplanOccupancyTable />
        <AvailableUnitsTable />
        <UnavailableUnitsTable />
      </SimpleGrid>
    </Container>
  );
}
