import { Avatar, Text } from '@mantine/core';
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export const columns = [
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => (
      <Text c={cell.getValue() === 'active' ? 'green' : 'red'} tt='capitalize'>
        {cell.getValue()}
      </Text>
    ),
    size: 110,
  },
  {
    accessorKey: 'id',
    header: 'ID',
    size: 80,
  },

  {
    accessorKey: 'name',
    header: 'Company',
    Cell: ({ cell }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <Avatar src={cell?.row?.original?.logo_url} sx={{ opacity: !cell?.row?.original?.logo_url && '0' }} mr={10} />
        <Link to={`${cell?.row?.original?.id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      </div>
    ),
    mantineTableHeadCellProps: {
      style: { paddingLeft: 63 },
    },
  },
  {
    accessorKey: 'created_date',
    header: 'Created On',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false,
    Cell: ({ cell }) => dayjs(cell.getValue()).format('MMM DD YYYY'),
    accessorFn: (row) => {
      const sDay = new Date(row.created_date);
      sDay.setHours(0, 0, 0, 0);
      return sDay;
    },
  },
];

export const initialState = {
  sorting: [{ id: 'name', desc: false }],
  columnFilters: [{ id: 'status', value: 'active' }],
};
