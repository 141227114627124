import React from 'react';
import { AuthProvider } from '../context/AuthContext';
import ThemeProvider from '../context/ThemeContext';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

function AuthLayout({ children }) {
  return (
    <AuthProvider>
      <ThemeProvider>{children}</ThemeProvider>
    </AuthProvider>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(AuthLayout);
