import React, { useEffect, useState } from 'react';
import { MultiSelect } from '@mantine/core';
import {
  formatReportName,
  PROPERTY_REPORT_PAGES,
  PROPERTY_REPORT_SECTIONS,
  shouldDisplayReportPage,
} from '../../constants/propertyReportPages';

const PagesMultiSelect = ({
  value = [],
  onChange,
  withAsterisk,
  readOnly,
  disabled,
  required,
  error,
  report_pages,
  property_platforms = [],
  manual_report_pages_enabled = false,
}) => {
  const [options, setOptions] = useState([]);

  // This useEffect reduces amount of renders
  useEffect(() => {
    const availableOptions = [];
    for (const section of Object.values(PROPERTY_REPORT_SECTIONS)) {
      for (const page of Object.values(PROPERTY_REPORT_PAGES[section])) {
        availableOptions.push({
          value: page,
          label: `${formatReportName(section)} > ${formatReportName(page)}`,
          disabled: !shouldDisplayReportPage(
            page,
            manual_report_pages_enabled,
            report_pages,
            property_platforms,
            () => true
          ),
        });
      }
    }

    setOptions(availableOptions);
  }, [report_pages]);

  const handleSelectChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <MultiSelect
      withinPortal
      searchable
      label={'Report pages'}
      placeholder='Select report pages'
      nothingFound='Nothing found'
      value={value}
      withAsterisk={withAsterisk}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      error={error}
      onChange={handleSelectChange}
      data={options}
    />
  );
};
export default PagesMultiSelect;
