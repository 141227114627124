import React, { useEffect, useState } from 'react';
import { Divider, Group, Text } from '@mantine/core';
import { DatePicker } from '@mantine/dates';

const ReportOneTimeDateRange = ({ onChange, ...props }) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <Group position='center' {...props}>
      <Divider />
      <DatePicker type='range' value={value} onChange={setValue} />
      <Text size='sm'>*Specify custom date range or leave empty</Text>
    </Group>
  );
};

export default ReportOneTimeDateRange;
