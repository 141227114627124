import React, { useState } from 'react';

import { Title, Container, Button, Text, TextInput, Box, Group, createStyles, Center } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuthService } from '../../services/authService';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import VideoBackground from '../../components/VideoBackgound/VideoBackground';

const useStyles = createStyles(() => {
  return {
    control: {
      padding: '10px',
      color: 'white',
      backgroundColor: 'rgba(154,154,154,0.75)',
      '::placeholder': {
        color: 'white',
      },
      '&:focus': {
        backgroundColor: 'rgba(154,154,154,0.75)',
        outline: 'none',
      },
    },
  };
});

export default function SendResetPasswordEmail() {
  const authService = useAuthService();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid Email Address'),
    },
  });

  const submitForm = async ({ email }) => {
    try {
      setIsLoading(true);
      await authService.sendResetPasswordEmail(email);
      notifications.show({
        title: 'Success!',
        message: `Reset password link was sent to your email`,
        color: 'green',
      });
      navigate('/auth');
    } catch (e) {
      const { data } = e.response;

      notifications.show({
        title: 'An Error Occurred',
        message: data.message,
        color: 'red',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { classes } = useStyles();

  return (
    <Box>
      <Container size={620} my={250}>
        <Center mb={'4.5rem'}>
          <img src='https://storage.googleapis.com/sdm-image-uploads/SDM_LOGO_WHITE.png' alt='SDM logo' width={450} />
        </Center>
        <Title
          align='center'
          sx={(theme) => ({
            fontFamily: `${theme.fontFamily}`,
            fontWeight: 900,
            color: 'white',
            letterSpacing: '3px',
          })}
        >
          Reset password
        </Title>

        <Text
          align={'center'}
          sx={(theme) => ({
            fontFamily: `${theme.fontFamily}`,
            fontWeight: 500,
            color: 'white',
            letterSpacing: '1px',
          })}
        >
          Enter your email address below and we&apos;ll send you a link to reset your password.
        </Text>
        <form onSubmit={form.onSubmit((values) => submitForm(values))}>
          <Group size={620} my='sm' sx={{ display: 'flex', alignItems: 'center', justifyItems: 'space-between' }}>
            <TextInput
              placeholder='Enter Your Email'
              required
              radius='md'
              size='lg'
              w={400}
              mt='md'
              sx={{
                input: classes.control,
                wrapper: { marginBottom: 20 },
                borderRadius: '7px',
              }}
              {...form.getInputProps('email')}
            />

            <Button
              size='lg'
              radius='md'
              color='light'
              variant='light'
              type='submit'
              mt='md'
              w={170}
              sx={{
                input: classes.control,
                wrapper: { marginBottom: 20 },
                color: 'black',
              }}
              loading={isLoading}
            >
              Confirm
            </Button>
          </Group>
        </form>
      </Container>
      <VideoBackground styles={{ position: 'absolute', top: 0 }} />
    </Box>
  );
}
