import { Text } from '@mantine/core';
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { calculateNextReportSendingDate } from '../../../../../../utils/nextReportSendingDate.util';
import { SCHEDULED_REPORTS_STATUS } from './scheduledReports';

export const columns = [
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => (
      <Text c={cell.getValue() === 'active' ? 'green' : 'red'} tt='capitalize'>
        {cell.getValue()}
      </Text>
    ),
    filterFn: 'fuzzy',
    sortingFn: (a, b) => {
      const statuses = { active: 1, disabled: 2 };
      return statuses[b.original.status] - statuses[a.original.status];
    },
    maxSize: 0,
  },
  {
    accessorKey: 'report_name',
    header: 'Report name',
    Cell: ({ cell }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <Link to={`${cell?.row?.original?.id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      </div>
    ),
    maxSize: 0,
  },
  {
    accessorKey: 'property_name',
    header: 'Property name',
    Cell: ({ cell }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <Link to={`/admin/properties/${cell?.row?.original?.property_id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      </div>
    ),
    maxSize: 0,
  },
  {
    accessorKey: 'report_type',
    header: 'Report type',
    Cell: ({ cell }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <Text tt='capitalize'>{cell.getValue()?.replace(/_/g, ' ')}</Text>
      </div>
    ),
    maxSize: 0,
  },
  {
    accessorKey: 'recipients_count',
    header: 'Recipients',
    Cell: ({ cell }) => <Text fw={700}>{cell.getValue()}</Text>,
  },
  {
    accessorKey: 'frequency',
    header: 'Frequency',
    Cell: ({ cell }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <Text tt='capitalize'>{cell.getValue()}</Text>
      </div>
    ),
    maxSize: 0,
  },
  {
    accessorKey: 'created_date',
    header: 'Created On',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false,
    Cell: ({ cell }) => dayjs(cell.getValue()).format('MMM DD YYYY'),
    accessorFn: (row) => {
      const sDay = new Date(row.created_date);
      sDay.setHours(0, 0, 0, 0);
      return sDay;
    },
    maxSize: 0,
  },
  {
    header: 'Next Report Date',
    enableColumnFilterModes: false,
    Cell: ({ row }) => {
      const currentDate = dayjs().tz('America/New_York');
      const nextSendingDate = calculateNextReportSendingDate(
        currentDate,
        parseInt(row.original.on, 10),
        row.original.time,
        row.original.frequency
      );

      return nextSendingDate && row.original.status === SCHEDULED_REPORTS_STATUS.ACTIVE
        ? dayjs(nextSendingDate).format('ddd DD MMM h:mmA ET')
        : 'N/A';
    },
    maxSize: 0,
  },
];

export const initialState = {
  sorting: [{ id: 'created_date', desc: true }],
  columnFilters: [{ id: 'status', value: 'active' }],
};
