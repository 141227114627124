import React, { useContext, useState } from 'react';
import { Container, Flex, SimpleGrid, Title } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { UsersActivityContext } from '../../../../../context/UsersActivityContext';
import UsersCountByActivityName from './Charts/UsersCountByActivityName/UsersCountByActivityName';
import UsersCountByCreatedDate from './Charts/UsersCountByCreatedDate/UsersCountByCreatedDate';
import UsersLastLoginTable from './Tables/UsersLastLoginTable/UsersLastLoginTable';

const UsersActivityReports = () => {
  const { dates, setDates } = useContext(UsersActivityContext);

  const [datePickerValue, setDatePickerValue] = useState(dates);
  const onChangeDates = (value) => {
    setDatePickerValue(value);
    if (value.length && value.every((el) => !!el)) {
      setDates(value);
    }
  };

  return (
    <Container fluid px={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title fw={700} ml='lg' my={25}>
          Users activity
        </Title>
        <Flex justify='right'>
          <DatePickerInput
            valueFormat='MM/DD/YYYY'
            type='range'
            placeholder='Pick dates range'
            icon={<IconCalendar size='1.3em' />}
            variant={'default'}
            value={datePickerValue}
            onChange={onChangeDates}
            w={250}
          />
        </Flex>
      </div>
      <SimpleGrid mb='md' cols={2} breakpoints={[{ maxWidth: 'xl', cols: 1, spacing: 'sm' }]}>
        <UsersCountByActivityName />
        <UsersCountByCreatedDate />
      </SimpleGrid>
      <UsersLastLoginTable dates={dates} />
    </Container>
  );
};

export default UsersActivityReports;
