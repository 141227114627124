import { useAxios } from '../../axiosService';

const API_PATH = '/bi/portfolio-overview';

export const usePortfolioOverviewService = () => {
  const { axios } = useAxios();

  const getImpressions = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/impressions`, { ...config, params });

    return response?.data;
  };

  const getClicks = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/clicks`, { ...config, params });

    return response?.data;
  };

  const getCTR = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/ctr`, { ...config, params });

    return response?.data;
  };

  const getAvgCpc = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/avg-cpc`, { ...config, params });

    return response?.data;
  };

  const getLeads = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/leads`, { ...config, params });

    return response?.data;
  };

  const getTours = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/tours`, { ...config, params });

    return response?.data;
  };

  const getLeases = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/completed-leases`, { ...config, params });

    return response?.data;
  };

  const getAverageRentPerSqft = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/average-rent-per-sqft`, { ...config, params });

    return response?.data;
  };

  const getOccupancy = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/occupancy`, { ...config, params });

    return response?.data;
  };

  const getThirtyDaysExpirations = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/thirty-days-expirations`, { ...config, params });

    return response?.data;
  };

  const getSixtyDaysExpirations = async (property_ids, from, to, config = {}) => {
    const params = { property_ids, from, to };
    const response = await axios.get(`${API_PATH}/sixty-days-expirations`, { ...config, params });

    return response?.data;
  };

  return {
    getImpressions,
    getClicks,
    getCTR,
    getAvgCpc,
    getLeads,
    getTours,
    getLeases,
    getAverageRentPerSqft,
    getOccupancy,
    getThirtyDaysExpirations,
    getSixtyDaysExpirations,
  };
};
