import React, { useEffect, useState } from 'react';
import { Modal, Radio, TextInput, Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';

const SaveFilterModal = ({ opened, onClose, onSave, existingFilter, allFilters }) => {
  const [updateMode, setUpdateMode] = useState();
  const [privacyMode, setPrivacyMode] = useState();

  const form = useForm({
    initialValues: {
      name: existingFilter?.name ?? '',
    },
    validate: {
      name: (value) => {
        if (!value?.trim()) {
          return 'Please enter a unique name';
        }

        if (
          allFilters.some(
            (x) =>
              x.name.trim().toLowerCase() === value?.trim().toLowerCase() &&
              (updateMode === 'create' || existingFilter.id !== x.id)
          )
        ) {
          return 'Oops! Filter already exists. Enter a new name please and try again.';
        }

        return null;
      },
    },
  });

  useEffect(() => {
    setUpdateMode(existingFilter?.id ? 'update' : 'create');
    setPrivacyMode(existingFilter?.is_public ? 'public' : 'private');
  }, []);

  const saveChanges = form.onSubmit((values) => {
    onSave && onSave(updateMode, values.name, privacyMode === 'public');
  });

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title={'Save Filter'}
      centered
      size={'lg'}
      sx={{
        '.mantine-CloseButton-root': {
          '&:focus': {
            outline: 'none',
          },
          '&:focus-visible': {
            outline: 'none',
          },
        },
      }}
    >
      {existingFilter?.id && (
        <Radio.Group value={updateMode} onChange={setUpdateMode}>
          <Group mt='xs'>
            <Radio value='update' label='Override' />
            <Radio value='create' label='Create New' />
          </Group>
        </Radio.Group>
      )}
      <form onSubmit={saveChanges}>
        <TextInput
          mt='sm'
          withAsterisk
          label='Name Filter'
          placeholder='Enter a unique filter name'
          {...form.getInputProps('name')}
        />

        <Group grow align='center' mt={'xl'}>
          <Radio.Group mr={15} value={privacyMode} onChange={setPrivacyMode}>
            <Group>
              <Radio value={'private'} label='Private Filter' />
              <Radio value={'public'} label='Public Filter' />
            </Group>
          </Radio.Group>

          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button mr={15} variant='outline' onClick={onClose}>
              Cancel
            </Button>
            <Button type='submit' disabled={form.getInputProps('name').error}>
              Save
            </Button>
          </div>
        </Group>
      </form>
    </Modal>
  );
};

export default SaveFilterModal;
