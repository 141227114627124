import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import { useWebsitePerformanceService } from '../../../../../../../../services/bi/property/marketing/WebsitePerformanceService';
import PieChartComponent from '../../../../../../../../components/PieChart/PieChart';

export default function TopPageViews() {
  const { getTopPageViews } = useWebsitePerformanceService();

  const { property, dates } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    if (!data?.length) return [];

    const sorted = data.sort((a, b) => b.percentage - a.percentage);

    const mainSources = sorted.slice(0, 4);
    const otherSources = sorted.slice(4);

    return [
      ...mainSources,
      {
        page_name: 'Others',
        total: otherSources.reduce((acc, cur) => acc + cur.total, 0),
        percentage: otherSources.reduce((acc, cur) => acc + cur.percentage, 0),
      },
    ];
  };

  return (
    <DataFetcherComponent
      Child={PieChartComponent}
      childProps={{
        title: 'Top Page Views',
        index: 'page_name',
        excludeFields: ['total'],
        variant: 'donut',
        hint: 'Every time someone starts a session by opening and viewing a page on your website, sorted by most viewed pages.',
        dataFormatter,
        customTooltipValueFormatter: (x) => `${x.payload?.total} (${Number(x.value).toFixed(1)}%)`,
        valueFormatter: (x) => `${Number(x).toFixed(1)}%`,
      }}
      fetchMethod={getTopPageViews}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
