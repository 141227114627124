import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/market-survey/rent-comparison';

export const useRentComparisonService = () => {
  const { axios } = useAxios();

  const getOccupancy = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/occupancy`, { ...config, params });

    return response?.data;
  };

  const getExposure = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/exposure`, { ...config, params });

    return response?.data;
  };

  const getPropertySpecs = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/property-specs`, { ...config, params });

    return response?.data;
  };

  const getAvgRent = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/avg-rent`, { ...config, params });

    return response?.data;
  };

  const getRentalComparisonByProperty = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/rental-comparison-by-property`, { ...config, params });

    return response?.data;
  };

  return {
    getOccupancy,
    getExposure,
    getPropertySpecs,
    getAvgRent,
    getRentalComparisonByProperty,
  };
};
