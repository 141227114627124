import React, { useContext, useEffect } from 'react';
import { Box, Flex, NavLink, Title, useMantineTheme } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import UserPropertiesDropdown from '../../../../components/UserPropertiesDropdown/UserPropertiesDropdown';
import {
  PROPERTY_REPORT_PAGES,
  PROPERTY_REPORT_PAGES_ICONS,
  PROPERTY_REPORT_SECTIONS,
  shouldDisplayReportPage,
  shouldDisplaySection,
} from '../../../../constants/propertyReportPages';
import { PropertyFiltersContext } from '../../../../context/PropertyFiltersContext';
import { useFeatureFlagsService } from '../../../../services/featureFlagsService';
import sDLogo from '../../../../assets/imgs/streetdigital-logo.png';

export default function PropertiesNavigation({ styles }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { colors } = useMantineTheme();
  const { property } = useContext(PropertyFiltersContext);

  const { isFeatureEnabled } = useFeatureFlagsService();

  useEffect(() => {
    handlePropertyChange(property);
  }, [property, location.pathname]);

  const handlePropertyChange = (property) => {
    if (!property) return;

    const currentPage = location.pathname.split('/').pop();

    if (
      !shouldDisplayReportPage(
        currentPage,
        property.enable_manual_report_pages,
        property.report_pages,
        property.platforms,
        isFeatureEnabled
      )
    ) {
      for (const section of Object.values(PROPERTY_REPORT_SECTIONS)) {
        for (const otherPage of Object.values(PROPERTY_REPORT_PAGES[section])) {
          if (
            shouldDisplayReportPage(
              otherPage,
              property.enable_manual_report_pages,
              property.report_pages,
              property.platforms,
              isFeatureEnabled
            )
          ) {
            return navigate(`${section}/${otherPage}`);
          }
        }
      }

      navigate('/');
    }
  };

  const shouldDisplayElement = ({ section, page }) => {
    if (!property) return false;
    return page
      ? shouldDisplayReportPage(
          page,
          property.enable_manual_report_pages,
          property.report_pages,
          property.platforms,
          isFeatureEnabled
        )
      : shouldDisplaySection(
          section,
          property.enable_manual_report_pages,
          property.report_pages,
          property.platforms,
          isFeatureEnabled
        );
  };

  const navigationElement = ({ section, page, icon: Icon }) => {
    const activeLinkPathname = `/properties/${section}${page ? '/' + page : ''}`;
    const isLinkActive = location.pathname === activeLinkPathname;

    return page ? (
      <NavLink
        id={'property-report-page__' + page}
        icon={<Icon size='1.5rem' color={isLinkActive ? colors.dark[0] : colors.simple_icon_third_colors[0]} />}
        sx={{
          borderRadius: '0.5rem',
          marginBottom: '0.5rem',
          backgroundColor: isLinkActive && colors.company_theme_primary_color[6] + '!important',
          color: colors.text_secondary[0],
        }}
        variant='filled'
        active={isLinkActive}
        p='0.75rem 0.5rem'
        onClick={() => navigate(`${section}${'/' + page}`)}
        label={
          <Title order={6} fw={600} tt='capitalize' color={isLinkActive && colors.dark[0]}>
            {page.replaceAll('_', ' ')}
          </Title>
        }
      />
    ) : (
      <Title order={5} size={18} fw={500} tt='capitalize' pb='1rem' mt='1.5rem'>
        {section.replaceAll('_', ' ')}
      </Title>
    );
  };

  return (
    <Box
      h='100%'
      w={270}
      sx={{
        ...styles,
        padding: '1.5rem 1rem',
        backgroundColor: colors.primary_background[6],
        zIndex: 85,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UserPropertiesDropdown />

      <Flex direction='column' gap='1rem' justify='space-between' sx={{ flex: '1' }}>
        <div>
          {Object.values(PROPERTY_REPORT_SECTIONS)
            .filter((section) => shouldDisplayElement({ section }))
            .map((section) => {
              return (
                <Box key={section}>
                  {navigationElement({ section })}
                  {Object.values(PROPERTY_REPORT_PAGES[section])
                    .filter((page) => shouldDisplayElement({ page, section }))
                    .map((page) => navigationElement({ page, section, icon: PROPERTY_REPORT_PAGES_ICONS[page] }))}
                </Box>
              );
            })}
        </div>
        <div>
          {/* TODO: CHANGE ONCE WE HAVE DARK LOGO */}
          <img style={{ width: '100%' }} src={sDLogo} />
        </div>
      </Flex>
    </Box>
  );
}
