import React, { useState, useEffect } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { columns } from '../../constants/userPropertiesTableDef';
import {usePropertyService} from "../../../../../../../services/propertyService";

export default function UserPropertiesSelection({ company_id, selected_values, onChange }) {
  const { get } = usePropertyService();

  const initialProperties = {};
  for (const x of Array.from(selected_values ?? [])) {
    initialProperties[x.id] = true;
  }

  const [options, setOptions] = useState([]);
  const [rowSelection, setRowSelection] = useState(initialProperties);

  const [isLoading, setIsLoading] = useState(false);

  const table = useMantineReactTable({
    columns,
    data: options ?? [],
    enableRowSelection: true,
    state: { isLoading, rowSelection },
    getRowId: (row) => row.id,
    onRowSelectionChange: setRowSelection,
    initialState: {
      pagination: { pageSize: 50 },
    },
  });

  useEffect(() => {
    async function getDropdownOptions() {
      try {
        setIsLoading(true);
        const properties = await get();
        const filtered = properties.filter((x) => x.company_id === company_id);
        const sorted = filtered.sort((a, b) => !!initialProperties[b.id] - !!initialProperties[a.id]);
        setOptions(sorted);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }

    getDropdownOptions();
  }, [company_id]);

  useEffect(() => {
    if (onChange) {
      onChange(Object.keys(rowSelection).map((x) => ({ id: x })));
    }
  }, [rowSelection]);

  return <MantineReactTable table={table} />;
}
