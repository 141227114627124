import React, { useContext } from 'react';
import { Select, useMantineTheme } from '@mantine/core';
import { PropertyFiltersContext } from '../../context/PropertyFiltersContext';

export default function UserPropertiesDropdown({ styles, variant = 'default' }) {
  const { options, property, handleSelectChange } = useContext(PropertyFiltersContext);
  const { colors } = useMantineTheme();

  return (
    <Select
      searchable
      m='sm'
      size='md'
      placeholder='Search Properties'
      defaultValue={property?.value}
      variant={variant}
      onChange={handleSelectChange}
      data={options}
      styles={() => ({
        dropdown: {
          backgroundColor: colors.secondary_background_color[6],
        },
      })}
      sx={{ ...styles }}
    />
  );
}
