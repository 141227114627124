import React from 'react';
import { JsonInput, Modal, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useDisclosure } from '@mantine/hooks';
import { Link } from 'react-router-dom';

export const columns = [
  {
    accessorKey: 'property_name',
    header: 'Property Name',
    Cell: ({ cell, row }) => {
      return (
        <Link to={`/admin/properties/${row?.original?.property_id}`} relative='path' style={{ color: 'blue' }}>
          {cell?.getValue()}
        </Link>
      );
    },
    minSize: 250,
  },
  {
    accessorKey: 'connection_type',
    header: 'Connection type',
    Cell: ({ cell }) => {
      return (
        <Text c='blue' fw={700} tt='capitalize'>
          {cell.getValue()}
        </Text>
      );
    },
    maxSize: 0,
  },
  {
    accessorKey: 'error',
    header: 'Error',
    Cell: ({ cell }) => {
      const [opened, { open, close }] = useDisclosure(false);
      return (
        <>
          <Modal zIndex={404} padding={'lg'} size='55%' opened={opened} onClose={close} title='Error' centered>
            <JsonInput value={JSON.stringify(JSON.parse(cell.getValue()), null, 2)} autosize />
          </Modal>

          <Text sx={{ cursor: 'pointer' }} onClick={open} w={350} truncate='end'>
            {cell.getValue()}
          </Text>
        </>
      );
    },
  },
  {
    accessorKey: 'request_details',
    header: 'Request details',
    Cell: ({ cell }) => {
      const [opened, { open, close }] = useDisclosure(false);
      return (
        <>
          <Modal
            zIndex={404}
            padding={'lg'}
            size='55%'
            opened={opened}
            onClose={close}
            title='Request details'
            centered
          >
            <JsonInput value={JSON.stringify(JSON.parse(cell.getValue()), null, 2)} autosize />
          </Modal>

          <Text sx={{ cursor: 'pointer' }} onClick={open} w={250} truncate='end'>
            {cell.getValue()}
          </Text>
        </>
      );
    },
  },
  {
    accessorKey: 'created_time',
    header: 'Created On',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false,
    Cell: ({ cell }) => dayjs(cell.getValue()).format('MMM DD YYYY HH:mm:ss A'),
    accessorFn: (row) => {
      const sDay = new Date(row.created_time);
      return sDay;
    },
    minSize: 201,
  },
];

export const initialState = {
  sorting: [{ id: 'created_time', desc: true }],
  pagination: { pageSize: 10 },
};
