import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { useBiPropertyOverviewService } from '../../../../../../../../services/bi/property/overview/PropertyOverviewService';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import LineChartComponent from '../../../../../../../../components/LineChart/LineChart';

export default function ToursAndLeases() {
  const { getToursAndLeases } = useBiPropertyOverviewService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{ title: 'Tours & Leases Overview', index: 'date' }}
      fetchMethod={getToursAndLeases}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
