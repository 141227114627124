import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@mantine/core';

export const columns = [
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => (
      <Text c={cell.getValue() === 'active' ? 'green' : 'red'} tt='capitalize'>
        {cell.getValue()}
      </Text>
    ),
  },
  {
    accessorKey: 'full_name',
    header: 'Full  Name',
    Cell: ({ cell }) => {
      return (
        <Link to={`/admin/users/${cell?.row?.original?.id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      );
    },
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'role',
    header: 'Role',
  },
];

export const initialState = {
  columnFilters: [{ id: 'status', value: 'active' }],
  pagination: { pageSize: 5 },
};
