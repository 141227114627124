import React, { useContext, useEffect } from 'react';
import { AppShell } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { NavbarSimple } from '../components/LeftMenu/LeftMenu';
import Header from '../components/Header/Header';
import { AuthContext } from '../context/AuthContext';
import UserThemeProvider from '../components/UserThemeProvider/UserThemeProvider';
import { nprogress } from '@mantine/nprogress';
import SidebarProvider from '../context/SidebarContext';
import { PortfolioOverviewFiltersProvider } from '../context/PortfolioOverviewFiltersContext';
import { InternalPortfolioOverviewProvider } from '../pages/Admin/Components/Sections/PortfolioOverview/Context/PortfolioOverviewContext';

export default function AppLayout() {
  const { currentUser, isUserLoading } = useContext(AuthContext);

  useEffect(() => {
    if (isUserLoading) {
      nprogress.start();
      return;
    }
    nprogress.complete();
  }, [isUserLoading]);

  return (
    <InternalPortfolioOverviewProvider>
      {!isUserLoading && (
        // TODO create separate BI route and set PortfolioOverviewFiltersProvider for it
        <PortfolioOverviewFiltersProvider>
          <UserThemeProvider>
            <SidebarProvider>
              <AppShell
                layout='alt'
                padding={0}
                navbar={<NavbarSimple />}
                header={
                  <Header
                    userName={currentUser.full_name}
                    company={currentUser.company_name}
                    logo={currentUser.company_logo}
                    logo_url_light={currentUser?.theme?.logo_url_light}
                    logo_url_dark={currentUser?.theme?.logo_url_dark}
                  />
                }
              >
                <Outlet />
              </AppShell>
            </SidebarProvider>
          </UserThemeProvider>
        </PortfolioOverviewFiltersProvider>
      )}
    </InternalPortfolioOverviewProvider>
  );
}
