import React, { useContext } from 'react';
import { columns, initialState, state } from './constants/topPerformingKeywordsTableDef';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import SimpleReportTable from '../../../../../../../../components/SimpleReportTable/SimpleReportTable';
import dayjs from 'dayjs';
import { useSearchEngineService } from '../../../../../../../../services/bi/property/marketing/SearchEngineService';

export default function TopPerformingKeywordsTable() {
  const { getTopPerformingKeywords } = useSearchEngineService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'Top Performing Keywords',
        columns,
        hint: 'Top search engine keywords that users are searching to cause your ad to show and direct them to your website.',
        initialState,
        state,
      }}
      fetchMethod={getTopPerformingKeywords}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
