import React from 'react';
import { Container, SimpleGrid } from '@mantine/core';
import ATRMetric from './Metrics/ATRMetric';
import OccupancyMetric from './Metrics/OccupancyMetric';
import ThirtyDaysExpirationsMetric from './Metrics/ThirtyDaysExpirationsMetric';
import SixtyDaysExpirationsMetric from './Metrics/SixtyDaysExpirationsMetric';
import AverageRentPerSqftBoxScoreMetric from './Metrics/AverageRentPerSqftBoxScoreMetric';
import AvailableUnitsTable from './Tables/AvailableUnits/AvailableUnits';
import UnavailableUnitsTable from './Tables/UnavailableUnits/UnavailableUnits';
import AvailabilityOverviewTable from './Tables/AvailabilityOverview/AvailabilityOverview';
import FloorplanOccupancyTable from './Tables/FloorplanOccupancy/FloorplanOccupancy';

export default function PropertyOverviewBoxScoreSection() {
  return (
    <Container fluid m={0} sx={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
      <SimpleGrid
        spacing='xl'
        cols={5}
        breakpoints={[
          { maxWidth: 'xl', cols: 3 },
          { maxWidth: 'lg', cols: 2 },
          { maxWidth: 'md', cols: 1 },
        ]}
      >
        <OccupancyMetric />
        <ATRMetric />
        <ThirtyDaysExpirationsMetric />
        <SixtyDaysExpirationsMetric />
        {/*TODO uncomment one this metric is displaying correct value */}
        {/*<RenewalPercentageMetric />*/}
        <AverageRentPerSqftBoxScoreMetric />
      </SimpleGrid>
      <SimpleGrid mt='md' cols={2} breakpoints={[{ maxWidth: 'lg', cols: 1, spacing: 'sm' }]}>
        <AvailabilityOverviewTable />
        <FloorplanOccupancyTable />
        <AvailableUnitsTable />
        <UnavailableUnitsTable />
      </SimpleGrid>
    </Container>
  );
}
