import React, { useState, useEffect, useContext } from 'react';
import { Select } from '@mantine/core';
import { AuthContext } from '../../context/AuthContext';
import { ADMIN_ROLES, USER_ROLES } from '../../constants/user';

export default function UserRolesDropdown({ value, onChange, withAsterisk, readOnly, disabled, required, error }) {
  const { currentUser } = useContext(AuthContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    switch (currentUser?.role) {
      case USER_ROLES.SDM_ADMIN:
        setOptions(ADMIN_ROLES);
        break;
      case USER_ROLES.SDM_USER:
        setOptions([USER_ROLES.PROPERTY_MANAGER]);
        break;
      default:
        break;
    }
  }, []);

  const handleSelectChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      label='User role'
      placeholder='Select user role'
      defaultValue={value}
      withAsterisk={withAsterisk}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      error={error}
      onChange={handleSelectChange}
      data={options}
    />
  );
}
