import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import LineBarChartComponent from '../../../../../../../../components/LineBarChart/LineBarChart';
import { useSocialMediaAdvertising } from '../../../../../../../../services/bi/property/marketing/SocialMediaAdvertisingService';

export default function CostPerClickAndClicks() {
  const { getCostPerClickAndClicks } = useSocialMediaAdvertising();

  const { property, dates } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    const keyMappings = { cpc_all: 'cpc_(all)', clicks_all: 'clicks_(all)' };
    const renameKeys = (obj, keys) =>
      Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          keys[key] || key,
          key === 'cpc_all' ? Number(value).toFixed(2) : value,
        ])
      );

    return data.map((entry) => renameKeys(entry, keyMappings));
  };

  return (
    <DataFetcherComponent
      Child={LineBarChartComponent}
      childProps={{
        title: 'Cost Per Click & Clicks',
        index: 'date',
        yAxisConfig: [
          {
            key: 'Clicks (all)',
            position: 'left',
            chartType: 'Line',
          },
          {
            key: 'Cpc (all)',
            position: 'right',
            chartType: 'Bar',
            prefix: '$',
            valueFormatter: (x) => `$${Number(x).toFixed(2)}`,
          },
        ],
        dataFormatter,
      }}
      fetchMethod={getCostPerClickAndClicks}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
