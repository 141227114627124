import React from 'react';
import dayjs from 'dayjs';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import { useConnectionErrorsService } from '../../../../../../../../services/connectionErrorsService';
import LineChartComponent from '../../../../../../../../components/LineChart/LineChart';

export default function BrokenConnectionByDate({ dates }) {
  const { getBrokenConnectionsByDate } = useConnectionErrorsService();

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{
        title: 'Broken Property Connections by Date',
        index: 'created_date',
        showLegend: false,
      }}
      fetchMethod={getBrokenConnectionsByDate}
      defaultValue={[]}
      deps={[dates]}
      params={{ dates }}
      paramsFormatter={(params) => [
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
