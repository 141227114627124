import React from 'react';
import { useRouteError } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import ErrorPage from './ErrorPage';

const ErrorBoundary = () => {
  const error = useRouteError();

  if (error?.response?.status === 403 || error?.response?.status === 404) {
    return <PageNotFound />;
  }
  return <ErrorPage />;
};

export default ErrorBoundary;
