import React, { useContext, useEffect, useState } from 'react';
import PropertiesNavigation from './Components/Navigation/PropertiesNavigation';
import { Affix, Text, Group, Stack, useMantineTheme, Flex, Title, Transition, MantineProvider } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import PropertiesHeader from './Components/Header/PropertiesHeader';
import { PropertyFiltersContext } from '../../context/PropertyFiltersContext';
import PropertyDetailsProvider from '../../context/PropertyDetailsContext';
import PropertyKnowledgePanel from './Components/PropertyKnowledgePanel/PropertyKnowledgePanel';
import VideoBackground from '../../components/VideoBackgound/VideoBackground';
import PropertiesDropdown from '../../components/PropertiesDropdown/PropertiesDropdown';
import { AuthContext } from '../../context/AuthContext';
import { SidebarContext } from '../../context/SidebarContext';
import { formStyles } from '../../constants/formStyles';
import { FEATURE_FLAGS } from '../../constants/featureFlags';
import { useFeatureFlagsService } from '../../services/featureFlagsService';

export default function Properties() {
  const { property } = useContext(PropertyFiltersContext);
  const { currentUser } = useContext(AuthContext);
  const { expanded, setEnableExpanding, handleCollapsedChange } = useContext(SidebarContext);
  const theme = useMantineTheme();
  const { isFeatureEnabled } = useFeatureFlagsService();

  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    setEnableExpanding(!!property);

    if (!expanded && !!property) {
      handleCollapsedChange();
    }
  }, [property]);

  const slideRight = {
    in: { opacity: 1, transform: 'scaleX(1)' },
    out: { opacity: 0, transform: 'scaleX(0)' },
    common: {
      transformOrigin: 'left',
      overflow: 'auto',
    },
    transitionProperty: 'transform opacity',
  };

  return (
    <Group
      h='calc(100vh - 70px)'
      style={{ overflowY: 'hidden' }}
      align='start'
      spacing={0}
      sx={{ background: property && theme.colors.gradient_background_color[6] }}
    >
      {property ? (
        <>
          <Transition mounted={expanded} transition={slideRight} duration={100} timingFunction='ease-in-out'>
            {(styles) => <PropertiesNavigation styles={styles} />}
          </Transition>
          <div
            style={{
              flex: 1,
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <PropertyDetailsProvider>
              <Stack spacing='sm'>
                <PropertiesHeader isExporting={isExporting} setIsExporting={setIsExporting} />
                <div style={{ width: isExporting ? '100rem' : '100%', paddingRight: '58px' }}>
                  <MantineProvider
                    inherit
                    theme={{
                      colors: {
                        ...theme.colors,
                        secondary_background_color: theme.colors.third_background_color,
                        retry_error_color: theme.colors.secondary_retry_error_color,
                      },
                      components: {
                        Card: {
                          styles: {
                            root: { border: isExporting ? '1px solid gray' : 'none' },
                          },
                        },
                      },
                      breakpoints: isExporting
                        ? {
                            xs: '1rem',
                            sm: '1rem',
                            md: '1rem',
                            lg: '1rem',
                            xl: '2rem',
                          }
                        : theme.breakpoints,
                    }}
                  >
                    <div id='reporting-page-container'>
                      <Outlet></Outlet>
                    </div>
                  </MantineProvider>
                  {isFeatureEnabled(FEATURE_FLAGS.PropertyKnowledgePanel) && (
                    <Affix zIndex={219} position={{ top: 150, right: '-1rem' }}>
                      <PropertyKnowledgePanel />
                    </Affix>
                  )}
                  <Affix zIndex={199} position={{ bottom: 30, right: '.9rem' }}>
                    <div
                      style={{
                        writingMode: 'tb-rl',
                        transform: 'rotate(-180deg)',
                      }}
                    >
                      <Text c='dimmed' size='md'>
                        Powered By
                      </Text>
                      <Text style={{ letterSpacing: '0.15rem' }} c='dimmed' size='md' tt='uppercase'>
                        Street Digital Media
                      </Text>
                    </div>
                  </Affix>
                </div>
              </Stack>
            </PropertyDetailsProvider>
          </div>
        </>
      ) : (
        <>
          <Flex
            h='100%'
            w='100%'
            direction={'column'}
            align={'center'}
            justify={'center'}
            sx={{
              margin: 0,
            }}
          >
            <Flex align={'start'} justify={'center'} direction={'column'} w={'45%'}>
              <Title order={2} color='white' size={45} sx={{ letterSpacing: '4px' }}>
                Welcome, {currentUser?.first_name}
              </Title>
              <Text color='white' fw={600} size={25} mt={20}>
                Explore deeper insights, for a better understanding.
              </Text>
              <Text color='white' w='100%' size={25} mb={20}>
                Property data, team performance and digital marketing efforts, all in one place
              </Text>
            </Flex>
            <PropertiesDropdown styles={{ ...formStyles, marginLeft: '0 !important' }} variant='unstyled' />
          </Flex>
          <VideoBackground styles={{ position: 'fixed', top: 0 }} />
        </>
      )}
    </Group>
  );
}
