import { useFlags } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAG_CAMPAIGN_VARIATION_TYPES, FEATURE_FLAGS } from '../constants/featureFlags';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export const useFeatureFlagsService = () => {
  const flags = useFlags();
  const { currentUser } = useContext(AuthContext);

  const isFeatureEnabled = (flag) => {
    if (!Object.values(FEATURE_FLAGS).includes(flag)) {
      throw new Error(`FEATURE_FLAGS does not include a definition of '${flag}'`);
    }

    const state = flags[flag];

    if (typeof state === 'boolean') return state;

    switch (state) {
      case FEATURE_FLAG_CAMPAIGN_VARIATION_TYPES.AllEnabled:
        return true;
      case FEATURE_FLAG_CAMPAIGN_VARIATION_TYPES.NoneEnabled:
        return false;
      default:
        return state
          ?.split(',')
          ?.map(Number)
          ?.some((propertyId) => !!currentUser?.properties?.find((p) => p.id == propertyId));
    }
  };

  return {
    isFeatureEnabled,
  };
};
