import React, { useContext } from 'react';
import { useBiPropertyOverviewService } from '../../../../../../../services/bi/property/overview/PropertyOverviewService';
import { GROW } from '../../../../../../../constants/metric';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import dayjs from 'dayjs';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';

export default function AverageRentPerSqftMetric() {
  const { getAverageRentPerSqft } = useBiPropertyOverviewService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Avg. Rent/Sqft',
        valueFormatter: (value) => value?.toFixed(2),
        prefix: '$',
        hint: 'The average rent for each unit within your property divided by the total number of square feet in each unit.',
      }}
      fetchMethod={getAverageRentPerSqft}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
