import React, { useContext } from 'react';
import { Box, NavLink, Title, useMantineTheme } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContext';
import { USER_ROLES } from '../../../../constants/user';
import { useFeatureFlagsService } from '../../../../services/featureFlagsService';
import { FEATURE_FLAGS } from '../../../../constants/featureFlags';

export default function AdminNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const { colors } = useMantineTheme();
  const { isFeatureEnabled } = useFeatureFlagsService();

  const NavigationElement = ({ label, link, isBold = false, withIndentation = true }) => {
    const isLinkActive = `${location.pathname}/`.startsWith(`/admin/${link}/`);
    return (
      <NavLink
        active={isLinkActive}
        onClick={() => navigate(link)}
        style={{
          backgroundColor: isLinkActive ? colors.company_theme_primary_color[4] || '#e7f5ff' : '',
        }}
        label={
          <Title order={6} fw={isBold ? 700 : 400} ml={withIndentation ? 'sm' : 0} color={isLinkActive ? 'white' : ''}>
            {label}
          </Title>
        }
      />
    );
  };

  return (
    <Box h='100%' w={250} sx={{ boxShadow: '#00000012 0px 0px 15px', zIndex: 1 }}>
      <Box mb='sm'>
        {currentUser?.role == USER_ROLES.SDM_USER && (
          <>
            {isFeatureEnabled(FEATURE_FLAGS.InternalPortfolioOverview) && (
              <NavigationElement label='Portfolio overview' link='portfolio_overview' />
            )}
            <NavigationElement label='Companies' link='companies' />
            <NavigationElement label='Properties' link='properties' />
            <NavigationElement label='Budget' link='budget' />
            <NavigationElement label='Users activity' link='users_activity' />
            <NavigationElement label='Reports' link='reports' />
            <NavigationElement label='System health' link='system_health' />
          </>
        )}
        <NavigationElement label='Users' link='users' />
      </Box>
    </Box>
  );
}
