import React from 'react';

import { Modal } from '@mantine/core';

export const OnboardingFormModal = ({ opened, close }) => {
  return (
    <Modal opened={opened} onClose={close} size='55rem' zIndex='1000'>
      <iframe
        src={process.env.REACT_APP_AI_ONBOARDING_LINK}
        width='100%'
        height='850px'
        allowFullScreen
        frameBorder='0'
      ></iframe>
    </Modal>
  );
};
