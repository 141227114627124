import { Container, Title, Text, Group, Button } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  const onClickBack = () => {
    navigate('/');
  };

  return (
    <Container>
      <Container
        sx={(theme) => ({
          textAlign: 'center',
          fontWeight: 900,
          fontSize: 55,
          color: theme.colors.gray[3],
        })}
        my={30}
      >
        404
      </Container>
      <Title sx={{ textAlign: 'center', fontSize: 33, fontWeight: 900 }}>You have found a secret place.</Title>
      <Text c='dimmed' size='lg' ta='center' my={30}>
        Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another
        URL.
      </Text>
      <Group sx={{ justifyContent: 'center' }}>
        <Button variant='subtle' size='md' onClick={onClickBack}>
          Get back to home page
        </Button>
      </Group>
    </Container>
  );
};

export default PageNotFound;
