import React, { useContext } from 'react';
import { IntegrationsContext } from '../../IntegrationsComponent';
import { Button, Group, Modal } from '@mantine/core';

const FormModal = ({ children }) => {
  const {
    connectionName,
    openedIntegrationModal,
    closeIntegrationModal,
    readOnly,
    connection,
    connectionData,
    connectionValue,
  } = useContext(IntegrationsContext);

  const modalTitle =
    connectionValue && !readOnly ? `Edit ${connectionName}` : readOnly ? connectionName : `Add ${connectionName}`;

  const handleSave = () => {
    connection.onChange(connectionData);

    closeIntegrationModal();
  };

  return (
    <Modal opened={openedIntegrationModal} onClose={closeIntegrationModal} title={modalTitle} centered>
      {children}
      {!readOnly && (
        <Group position='center' my='md'>
          <Button w={160} onClick={handleSave}>
            Save
          </Button>
          <Button variant='default' w={160} onClick={closeIntegrationModal}>
            Cancel
          </Button>
        </Group>
      )}
    </Modal>
  );
};

export default FormModal;
