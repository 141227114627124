export const columns = [
  {
    accessorKey: 'unit_number',
    header: 'Unit',
    maxSize: 50,
  },
  {
    accessorKey: 'floor_plan',
    header: 'Floor Plan',
    maxSize: 70,
  },
  {
    accessorKey: 'availability_status',
    header: 'Status',
    maxSize: 100,
  },
  {
    accessorKey: 'available_date',
    header: 'Available Date',
    maxSize: 70,
  },
  {
    accessorKey: 'total_days_vacant_and_ready',
    header: 'Total Days Vacant',
    maxSize: 70,
  },
];

export const initialState = {
  sorting: [
    { id: 'availability_status', desc: true },
    { id: 'total_days_vacant_and_ready', desc: true },
  ],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
};
