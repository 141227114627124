import React, { useContext } from 'react';
import { createStyles, Select, useMantineTheme } from '@mantine/core';
import { PropertyFiltersContext } from '../../context/PropertyFiltersContext';

const useStyles = createStyles(() => {
  return {
    control: {
      padding: '1.75rem',
      color: 'white',
      borderRadius: '5px',
      paddingLeft: '2.25rem',
      backgroundColor: 'rgba(154,154,154,0.75)',
      '::placeholder': {
        color: 'white',
      },
      '::focus': {
        backgroundColor: 'rgba(154,154,154,0.75)',
        border: '1px solid #f3f3f3',
        borderColor: 'none',
      },
    },
  };
});

export default function UserPropertiesDropdown({ styles, variant = 'default' }) {
  const { options, property, handleSelectChange } = useContext(PropertyFiltersContext);
  const { colors } = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Select
      searchable
      m='sm'
      size='lg'
      w='45%'
      placeholder='Search Properties'
      defaultValue={property?.value}
      variant={variant}
      onChange={handleSelectChange}
      data={options}
      styles={() => ({
        dropdown: {
          backgroundColor: colors.secondary_background_color[6],
        },
        input: classes.control,
      })}
      sx={{ ...styles }}
    />
  );
}
