import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/router';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { NavigationProgress } from '@mantine/nprogress';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MantineProvider
    theme={{
      headings: {
        fontFamily: 'Montserrat Light, sans-serif',
      },

      fontFamily: 'Lato Regular, sans-serif',
    }}
    withNormalizeCSS
    withGlobalStyles
  >
    <NavigationProgress />
    <Notifications />
    <RouterProvider router={router} />
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
