import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import PieChartComponent from '../../../../../../../../components/PieChart/PieChart';
import dayjs from 'dayjs';
import { useRemarketingService } from '../../../../../../../../services/bi/property/marketing/RemarketingService';

const ClicksByDevice = () => {
  const { getClicksByDevice } = useRemarketingService();

  const { property, dates } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    if (!data?.length) return [];

    return [
      ...data.map((x) => ({
        clicks: x.clicks,
        percentage: x.percentage,
        segmentsDevice: x.segments_device,
      })),
    ];
  };

  return (
    <DataFetcherComponent
      Child={PieChartComponent}
      childProps={{
        title: 'Clicks By Device',
        index: 'segmentsDevice',
        excludeFields: ['clicks'],
        variant: 'donut',
        hint: 'What sites your visitors are coming from.',
        dataFormatter,
        customTooltipValueFormatter: (x) => `${x.payload?.clicks} (${Number(x.value).toFixed(1)}%)`,
        valueFormatter: (x) => `${Number(x).toFixed(1)}%`,
      }}
      fetchMethod={getClicksByDevice}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default ClicksByDevice;
