import React, { useEffect, useState } from 'react';
import { Select } from '@mantine/core';
import { usePropertyService } from '../../services/propertyService';

const ScheduledReportPropertiesDropdown = ({
  value,
  onChange,
  withAsterisk,
  readOnly,
  disabled,
  required,
  error,
  company_id,
}) => {
  const { get: getProperties } = usePropertyService();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function getDropdownOptions() {
      const properties = await getProperties(company_id);

      if (options?.length) {
        handleSelectChange(null);
      }
      setOptions(readOnly ? properties : properties.filter((value) => value.status === 'active'));
    }

    company_id && getDropdownOptions();
  }, [company_id]);

  const handleSelectChange = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Select
      withinPortal
      label={'Property'}
      placeholder='Select a property'
      searchable
      value={value}
      withAsterisk={withAsterisk}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      error={error}
      onChange={handleSelectChange}
      data={options
        .map((property) => ({ value: property?.id, label: property?.name }))
        .sort((a, b) => a.label?.localeCompare(b.label))}
    />
  );
};

export default ScheduledReportPropertiesDropdown;
