import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { useWebsitePerformanceService } from '../../../../../../../services/bi/property/marketing/WebsitePerformanceService';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import dayjs from 'dayjs';

export default function EngagementRateMetric() {
  const { getEngagementRate } = useWebsitePerformanceService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Engagement Rate',
        hint: 'The total number of engaged sessions divided by the total number of sessions, expressed as a percentage. Engaged sessions refer to sessions where users interacted with your property website beyond just landing on a page. The typical benchmark is 60% or higher. ',
        valueFormatter: (x) => (Number(x) * 100).toFixed(1),
        suffix: '%',
      }}
      fetchMethod={getEngagementRate}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
