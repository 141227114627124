import { Box, Button, Indicator, Switch, Title, useMantineTheme } from '@mantine/core';
import React, { useState } from 'react';
import CompanyForm from './forms/companyForm';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { IconArrowLeft, IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  columns as relatedPropertiesColumns,
  initialState as relatedPropertiesInitialState,
} from './constants/companyPropertiesTableDef';
import {
  columns as verifiedCompanyUsersColumns,
  initialState as verifiedCompanyInitialState,
} from './constants/verifiedCompanyUsersTableDef';
import {
  columns as relatedUsersColumns,
  initialState as relatedUsersInitialState,
} from '../../Shared/relatedUsersTableDef';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import { FORM_MODES } from '../../../../../constants/form_modes';
import { useCompanyService } from '../../../../../services/companyService';
import { PROPERTY_STATUS } from '../../../../../constants/propertyStatus';
import ConfirmationModal from '../../../../../components/ConfirmationModal/ConfirmationModal';
import { COMPANY_STATUS } from '../../../../../constants/companyStatus';

export default function AdminViewCompany() {
  const company = useRouteLoaderData('company-details');
  const navigate = useNavigate();
  const companyService = useCompanyService();
  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const [initialData, setInitialData] = useState(company);
  const [checked, setChecked] = useState(initialData?.status === PROPERTY_STATUS.ACTIVE);

  const relatedPropertiesTable = useMantineReactTable({
    columns: relatedPropertiesColumns,
    initialState: relatedPropertiesInitialState,
    data: initialData.properties ?? [],
    enableTopToolbar: false,
  });

  const verifiedCompanyUsersTable = useMantineReactTable({
    columns: verifiedCompanyUsersColumns,
    initialState: verifiedCompanyInitialState,
    data: initialData.verified_company_users ?? [],
    enableTopToolbar: false,
  });

  const relatedUsersTable = useMantineReactTable({
    columns: relatedUsersColumns,
    initialState: relatedUsersInitialState,
    data: initialData.users ?? [],
    enableTopToolbar: false,
  });

  const onClickEditCompany = () => {
    navigate('edit');
  };

  const onClickBack = () => navigate(-1);

  const onClickModalConfirm = async () => {
    try {
      setIsConfirmLoading(true);
      const company = checked
        ? await companyService.disableCompany(initialData?.id)
        : await companyService.enableCompany(initialData?.id);

      setInitialData(company);

      setChecked(!checked);
      notifications.show({
        title: 'Success!',
        message: `${initialData?.name} company was successfully ${
          company?.status === PROPERTY_STATUS.ACTIVE ? 'enabled' : 'disabled'
        }!`,
        color: 'green',
      });
    } catch (e) {
      notifications.show({
        title: 'An Error Occurred',
        message: e.message,
        color: 'red',
      });
    } finally {
      setIsConfirmLoading(false);
      close();
    }
  };

  const onChangeCompanyStatus = () => {
    open();
  };

  const indicatorColor = initialData?.status === PROPERTY_STATUS.ACTIVE ? 'green.6' : 'red.6';
  const modalLabel = initialData?.status === PROPERTY_STATUS.ACTIVE ? 'Disable' : 'Enable';

  return (
    <Box mx='auto' mt='md' px={30} pb={30}>
      <ConfirmationModal
        opened={opened}
        actionTitle={`${modalLabel} company`}
        title={`Are you sure to ${modalLabel} company ${initialData?.name}?`}
        onClickModalConfirm={onClickModalConfirm}
        isConfirmLoading={isConfirmLoading}
        onClose={close}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Indicator color={indicatorColor}>
            <Title order={2} mb={20} align='left'>
              {company?.name}
            </Title>
          </Indicator>
          {initialData?.status === COMPANY_STATUS.ACTIVE && (
            <Button variant='white' sx={{ backgroundColor: 'transparent' }} onClick={onClickEditCompany} ml={17} px={6}>
              <IconEdit size={30} color='gray' variant='default' style={{ marginRight: 3 }} />
            </Button>
          )}
          <Box
            sx={({ colors }) => ({
              '.mantine-Switch-track': {
                background: colors.red[6],
                borderColor: colors.red[6],
                cursor: 'pointer',
              },
              '.mantine-Switch-thumb': {
                borderColor: 'white',
              },
            })}
          >
            <Switch
              checked={checked}
              onChange={(event) => onChangeCompanyStatus(event.currentTarget.checked)}
              color={'teal'}
              size='md'
              pt={6}
              ml={20}
              thumbIcon={
                checked ? (
                  <IconCheck size='0.8rem' color={theme.colors.teal[theme.fn.primaryShade()]} stroke={3} />
                ) : (
                  <IconX size='0.8rem' color={theme.colors.red[theme.fn.primaryShade()]} stroke={3} />
                )
              }
            />
          </Box>
        </div>
        <Button color='gray' variant='default' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Go Back
        </Button>
      </div>
      <CompanyForm mode={FORM_MODES.View} initialValues={company}></CompanyForm>
      <Title order={3} mt={20} mb={20} align='left'>
        Users Verified By Company
      </Title>
      <MantineReactTable table={verifiedCompanyUsersTable} />
      <Title order={3} mt={20} mb={20} align='left'>
        Related Properties
      </Title>
      <MantineReactTable table={relatedPropertiesTable} />

      <Title order={3} mt={20} mb={20} align='left'>
        Related Users
      </Title>
      <MantineReactTable table={relatedUsersTable} />
    </Box>
  );
}

export const loader = async ({ params }) => {
  const { getCompanyById } = useCompanyService();
  return await getCompanyById(params.id);
};
