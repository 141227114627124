import React from 'react';
import { ActionIcon, Card, Overlay, Title, useMantineTheme, Flex, LoadingOverlay } from '@mantine/core';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { IconInfoCircle, IconRefresh } from '@tabler/icons-react';
import Info from '../Info/Info';

export default function SimpleReportTable({
  title,
  columns,
  hint,
  initialState,
  state,
  data,
  enablePagination,
  isStriped = false,
  enableBottomToolbar,
  isLoading,
  isError,
  onRetry,
  cellMaxWidth = '1.3rem',
  enableFilters = false,
  enableColumnActions = false,
  enableColumnFilters = false,
  enableCustomTitle = false,
  renderCustomTitle = () => {},
}) {
  const { colors } = useMantineTheme();

  const backgroundColor = colors.primary_background[0];

  const table = useMantineReactTable({
    columns,
    initialState,
    state,
    data,
    enableFilters,
    enableColumnActions,
    enablePagination,
    enableBottomToolbar,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters,
    mantineTableBodyProps: {
      sx: {
        'tr:last-of-type td': { borderBottom: `1px solid ${colors.border_color[0]}!important` },
        ...(isStriped
          ? {
              '& tr:nth-of-type(even)': {
                backgroundColor: colors.background_weak[0],
              },
            }
          : {}),
      },
    },
    mantineTableHeadProps: {
      sx: {
        display: 'table-header-group',
      },
    },
    mantineTableHeadRowProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        borderBottom: `1px solid ${colors.border_color[0]}!important`,

        backgroundColor: colors.table_header[0],
        paddingLeft: '1rem',
        color: colors.table_text_header[0] + '!important',
        maxWidth: cellMaxWidth,
        fontSize: '12px!important',
        ['& .mantine-Indicator-indicator']: {
          color: colors.company_theme_primary_color[5],
          fontSize: '8px',
          top:5
        },
      },
    },
    mantineTableBodyCellProps: {
      sx: {
        borderTop: `1px solid ${colors.border_color[0]}!important`,
        backgroundColor: 'transparent',
        lineHeight: '.75rem',
        paddingLeft: '1rem',
        maxWidth: cellMaxWidth,
      },
    },
    mantineBottomToolbarProps: {
      sx: {
        backgroundColor,
        fontSize: '0.8rem',
      },
    },
    mantineTableProps: {
      sx: {
        minHeight: '262px',
      },
    },
    mantineTableBodyRowProps: {
      sx: {
        backgroundColor: 'transparent',
        '&:hover td': {
          'background-color': colors.hover[6],
        },
      },
    },
    mantinePaperProps: {
      sx: {
        height: '100%',
        backgroundColor: 'transparent',
      },
      shadow: 'none',
      withBorder: false,
      radius: '8px',
    },
    renderTopToolbar: () => null,
  });

  return (
    <Card
      radius='lg'
      p='1.5rem'
      style={{
        border: '1px solid ' + colors.border_color[0],
        height: 'fit-content',
        backgroundColor: colors.primary_background[0],
      }}
    >
      {enableCustomTitle ? (
        renderCustomTitle({ title })
      ) : (
        <Flex sx={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
          <Title size={20} fw='600'>
            {title}
          </Title>
          {hint && (
            <Info label={hint} w={200}>
              <IconInfoCircle size={20} color={colors.simple_icon_colors[0]} />
            </Info>
          )}
        </Flex>
      )}
      <MantineReactTable table={table} />
      {isError && (
        <Overlay color={colors.retry_error_color[6]} opacity={0.6} center zIndex={10}>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}

      <LoadingOverlay
        id={`loading_overlay__${title?.replaceAll(/\W/g, '')}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'lg', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
    </Card>
  );
}
