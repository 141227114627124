import { useEffect } from 'react';

export const useAbortController = (fetcher, params, dependencies = []) => {
  let abortController = new AbortController();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetcher(...params, { signal: abortController.signal });
      } catch (e) {
        if (abortController.signal.aborted) {
          console.log('Request aborted', abortController.signal);
          return;
        }

        throw e;
      }
    };
    abortController.abort();
    abortController = new AbortController();

    fetchData();

    return () => abortController.abort();
  }, dependencies);
};
