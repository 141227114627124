import React, { useContext, useEffect, useState } from 'react';

import PortfolioOverviewTable from './Components/Tables/PortfolioOverview/PortfolioOverviewTable';
import { Container } from '@mantine/core';
import { useTableColumnData } from './Components/Tables/PortfolioOverview/constants/tableColumnData';
import PortfolioOverviewHeader from './Components/Header/PortfolioOverviewHeader';
import { PortfolioOverviewFiltersContext } from '../../context/PortfolioOverviewFiltersContext';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const PortfolioOverview = () => {
  const {
    propertyIds,
    propertyColumnData,
    tableColumnsData,
    initialColumnsData,
    dates,
    propertySearch,
    prevPropertySearch,
    setPrevPropertySearch,
  } = useContext(PortfolioOverviewFiltersContext);

  const { initialState } = useTableColumnData();

  const initialLoadingState = {};
  initialColumnsData.forEach((column) => (initialLoadingState[column.columnName] = true));
  const initialErrorState = {};
  initialColumnsData.forEach((column) => (initialErrorState[column.columnName] = false));
  const onRetry = {};
  initialColumnsData.forEach(
    (column) =>
      (onRetry[column.columnName] = () => {
        fetchColumnData(column.fetchMethod, column.columnName, column.valueFormatter);
      })
  );
  const initialSignalState = {};

  const [data, setData] = useState(propertyColumnData);
  const [loading, setLoading] = useState(initialLoadingState);
  const [signal, setSignal] = useState(initialSignalState);
  const [error, setError] = useState(initialErrorState);
  const [prevTableColumnsData, setPrevTableColumnsData] = useState([]);
  const navigate = useNavigate();

  let mapData = data;

  useEffect(() => {
    initialColumnsData.forEach((column) => (initialSignalState[column.columnName] = new AbortController()));
    setSignal(initialSignalState);
  }, []);

  useEffect(() => {
    fetch();
  }, [tableColumnsData]);

  useEffect(() => {
    resetData();
    fetch(true);
  }, [dates, propertySearch]);

  const fetchColumnData = async (fetchMethod, columnName, valueFormatter) => {
    try {
      loading[columnName] = true;
      setLoading(loading);

      mapData = mapData.map((obj) => {
        const newObj = { ...obj };
        return newObj;
      });
      setData(mapData);

      const res = await fetchMethod(
        propertyIds,
        dayjs(dates[0]).format('YYYY-MM-DD'),
        dayjs(dates[1]).format('YYYY-MM-DD'),
        { signal: signal[columnName].signal }
      );

      mapData = mapData.map((obj) => {
        const newObj = { ...obj };
        const foundRes = res.find((r) => r.property_id === obj['propertyId']);

        newObj[columnName] = '-';
        newObj[`${columnName}_percentage_change`] = null;

        if (foundRes) {
          newObj[columnName] = valueFormatter ? valueFormatter(foundRes.value.value) : foundRes.value.value;
          newObj[`${columnName}_percentage_change`] = foundRes.value;
        }

        return newObj;
      });

      setData(mapData);

      loading[columnName] = false;
      setLoading(loading);
      if (error[columnName]) {
        error[columnName] = false;
        setError(error);
      }
    } catch (e) {
      if (signal[columnName].signal.aborted) {
        return;
      }
      mapData = mapData.map((obj) => {
        const newObj = { ...obj };
        newObj[columnName] = {};
        return newObj;
      });
      setData(mapData);

      loading[columnName] = false;
      error[columnName] = true;
      setError(error);
      setLoading(loading);
    }
  };

  const fetch = async (isDatesChanged) => {
    mapData = data;
    if (propertySearch === '' && prevPropertySearch !== '') mapData = propertyColumnData;
    if (propertySearch !== '' && isDatesChanged)
      mapData = propertyColumnData.filter((obj) =>
        obj['property']?.toLowerCase().includes(propertySearch.toLowerCase())
      );

    setData(mapData);

    const filteredColumns = isDatesChanged
      ? tableColumnsData.filter((obj1) => obj1.columnName != 'property' && obj1.show)
      : tableColumnsData.filter(
          (obj1) =>
            obj1.columnName != 'property' &&
            obj1.show &&
            !prevTableColumnsData.some((obj2) => obj1.columnName === obj2.columnName && obj1.show === obj2.show)
        );

    setPrevTableColumnsData(tableColumnsData);
    setPrevPropertySearch(propertySearch);

    for (const column of filteredColumns) {
      signal[column.columnName].abort();
      // wait for enter to catch block
      await new Promise((resolve) => setTimeout(resolve, 10));
      signal[column.columnName] = new AbortController();
      setSignal(signal);

      fetchColumnData(column.fetchMethod, column.columnName, column.valueFormatter, column.prefix, column.suffix);
    }
  };

  const resetData = () => {
    setData(propertyColumnData);
  };

  const onPropertyClicked = (id) => {
    navigate(`/properties?property_id=${id}&from=${dates[0]}&to=${dates[1]}`);
  };

  return (
    <Container fluid m={0} p='lg'>
      <PortfolioOverviewHeader />
      <PortfolioOverviewTable
        columns={tableColumnsData?.filter((column) => column.show)?.map((columnData) => columnData.column)}
        initialState={initialState}
        state={{ loading, error, onRetry, onPropertyClicked }}
        data={data}
      />
    </Container>
  );
};

export default PortfolioOverview;
