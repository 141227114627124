import React, { useContext } from 'react';
import { useWebsiteLeadsService } from '../../../../../../../../services/bi/property/marketing/WebsiteLeadAttributionService';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import dayjs from 'dayjs';
import SimpleReportTable from '../../../../../../../../components/SimpleReportTable/SimpleReportTable';
import { columns, initialState, state } from './constants/prospectWebsiteLeadPathTableDef';

const ProspectWebsiteLeadPathTable = () => {
  const { getProspectWebsiteLeadPath } = useWebsiteLeadsService();

  const { property, dates, multiTouchAttribution } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'Prospect Website Lead Path',
        columns,
        initialState,
        state,
      }}
      fetchMethod={getProspectWebsiteLeadPath}
      defaultValue={[]}
      deps={[property, dates, multiTouchAttribution]}
      params={{ property, dates, multiTouchAttribution }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
        multiTouchAttribution,
      ]}
    />
  );
};

export default ProspectWebsiteLeadPathTable;
