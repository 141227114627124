import React, { useState } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';
import { Button, Card, Container, createStyles, Group, SimpleGrid, Text, TextInput, ThemeIcon } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';
import { FORM_MODES } from '../../../../../../constants/form_modes';
import { useNavigate } from 'react-router-dom';
import { FORM_ERRORS } from '../../../../../../constants/form_errors';
import { useVerifiedCompanyUsersService } from '../../../../../../services/verifiedUsersService';
import { notifications } from '@mantine/notifications';

const VerifiedCompanyUsersForm = ({ mode, companyId, initialValues = {} }) => {
  const navigate = useNavigate();

  const { createVerifiedCompanyUser } = useVerifiedCompanyUsersService();
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const form = useForm({
    initialValues: Object.assign(
      {},
      {
        verified_user_first_name: initialValues?.verified_user_name ?? '',
        verified_user_last_name: initialValues?.verified_user_last_name ?? '',
        verified_user_email: initialValues?.verified_user_email ?? '',
      }
    ),
    validate: {
      verified_user_first_name: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      verified_user_last_name: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      verified_user_email: (value) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*\.\w{2,}$/.test(value) ? null : 'Invalid email'),
    },
  });

  // const resetForm = () => form.reset();

  const disabled = mode === FORM_MODES.View;
  const editingDisabled = mode === FORM_MODES.Edit;

  const onSubmitForm = form.onSubmit(async (values) => {
    try {
      setIsSaveLoading(true);
      switch (mode) {
        case FORM_MODES.Create:
          await createVerifiedCompanyUser({ ...values, company_id: companyId });
          break;
      }

      notifications.show({
        title: 'Success!',
        message: `${values.verified_user_first_name} ${values.verified_user_last_name} user was successfully verified!`,
        color: 'green',
      });

      navigate('/admin/companies');
    } catch (error) {
      const { data } = error.response;

      notifications.show({
        title: 'Failed to verify user',
        message: data.message,
        color: 'red',
      });
    } finally {
      setIsSaveLoading(false);
    }
  });

  //TODO refactor: make it reusable
  const useStyles = createStyles((theme) => ({
    form: {
      input: {
        backgroundColor: theme.colors.gray[1],
      },
    },
  }));

  const { classes } = useStyles();

  return (
    <form onSubmit={onSubmitForm} className={disabled ? classes.form : ''}>
      <SimpleGrid mt='md' cols={1} px={0} pr={0}>
        <Container mx={0} pl={0} pr={0} fluid>
          <Card shadow='sm' padding='xl' radius='md' withBorder>
            <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
              <ThemeIcon color='dark.3' size={28}>
                <IconSettings size={17} strokeWidth={3} color={'white'} />
              </ThemeIcon>
              <Text weight={700} size={16} ml={7}>
                Verified users
              </Text>
            </Card.Section>

            <Group grow>
              <TextInput
                readOnly={disabled}
                disabled={editingDisabled}
                mt='sm'
                withAsterisk
                label='First name'
                placeholder='First name'
                {...form.getInputProps('verified_user_first_name')}
              />
              <TextInput
                readOnly={disabled}
                disabled={editingDisabled}
                mt='sm'
                withAsterisk
                label='Last name'
                placeholder='Last name'
                {...form.getInputProps('verified_user_last_name')}
              />
            </Group>
            <Group grow>
              <TextInput
                readOnly={disabled}
                disabled={editingDisabled}
                mt='sm'
                withAsterisk
                label='Email'
                placeholder='Email'
                {...form.getInputProps('verified_user_email')}
              />
              <></>
            </Group>
          </Card>
        </Container>
      </SimpleGrid>

      {mode !== FORM_MODES.View && (
        <Group position='center' my='md'>
          {/* <Button color='red' variant='outline' w={160} onClick={resetForm}>
            Reset
          </Button> */}
          <Button type='submit' w={160} loading={isSaveLoading}>
            Save
          </Button>
        </Group>
      )}
    </form>
  );
};

export default VerifiedCompanyUsersForm;
