import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { useBiPropertyBoxScoreService } from '../../../../../../../services/bi/property/overview/PropertyBoxScoreService';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';

export default function AverageRentPerSqftBoxScoreMetric() {
  const { getAverageRentPerSqft } = useBiPropertyBoxScoreService();

  const { property } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Avg. Rent/Sqft',
        valueFormatter: (value) => value?.toFixed(2),
        prefix: '$',
        hint: 'The average rent for each unit within your property divided by the total number of square feet in each unit.',
        showTrend: false,
      }}
      fetchMethod={getAverageRentPerSqft}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property]}
      params={{ property }}
      paramsValidator={(params) => params.property}
      paramsFormatter={(params) => [params.property.value]}
    />
  );
}
