import {
  IconSpacingHorizontal,
  IconMathLower,
  IconMathGreater,
  IconMathEqualGreater,
  IconMathEqualLower,
  IconEqual,
} from '@tabler/icons-react';

export const FilterOptionSections = {
  Value: 'value',
  Comparison: 'comparison',
};

export const FilterFunctions = {
  [FilterOptionSections.Value]: {
    Equals: { title: 'Equals', functionName: 'equals', icon: IconEqual },
    Between: { title: 'Between', functionName: 'between', icon: IconSpacingHorizontal },
    GreaterThan: { title: 'Greater Than', functionName: 'greaterThan', icon: IconMathGreater },
    GreaterThanOrEquals: {
      title: 'Greater Than Or Equals',
      functionName: 'greaterThanOrEqualTo',
      icon: IconMathEqualGreater,
    },
    LessThan: { title: 'Less Than', functionName: 'lessThan', icon: IconMathLower },
    LessThanOrEquals: { title: 'Less Than Or Equals', functionName: 'lessThanOrEqualTo', icon: IconMathEqualLower },
  },
  [FilterOptionSections.Comparison]: {
    Equals: {
      title: 'Equals',
      functionName: 'comparisonEquals',
      icon: IconEqual,
      localization: 'filterComparisonEquals',
    },
    GreaterThan: {
      title: 'Greater Than',
      functionName: 'comparisonGreaterThan',
      icon: IconMathGreater,
      localization: 'filterComparisonGreaterThan',
    },
    GreaterThanOrEquals: {
      title: 'Greater Than Or Equals',
      functionName: 'comparisonGreaterThanOrEqualTo',
      icon: IconMathEqualGreater,
      localization: 'filterComparisonGreaterThanOrEqualTo',
    },
    LessThan: {
      title: 'Less Than',
      functionName: 'comparisonLessThan',
      icon: IconMathLower,
      localization: 'filterComparisonLessThan',
    },
    LessThanOrEquals: {
      title: 'Less Than Or Equals',
      functionName: 'comparisonLessThanOrEqualTo',
      icon: IconMathEqualLower,
      localization: 'filterComparisonLessThanOrEqualTo',
    },
  },
};

export const getFilterFunctions = () => {
  const functions = [];
  Object.values(FilterOptionSections).forEach((section) => functions.push(...Object.values(FilterFunctions[section])));

  return functions;
};
