import React from 'react';
import { ActionIcon, Card, Container, LoadingOverlay, Overlay, Title, useMantineTheme } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { transformKeysToWordsAndUppercase } from '../../utils/charts.util';
import IconInfo from '../IconInfo/IconInfo';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const BAR_COLORS = ['darkcyan', 'rebeccapurple'];

export default function BarChartComponent({
  title,
  data,
  index,
  hint,
  isLoading,
  isError,
  onRetry,
  valueFormatter,
  dataFormatter = (x) => x,
  excludeFields = [],
  colors = BAR_COLORS,
  showLegend = true,
  yAxisConfig = [],
}) {
  const theme = useMantineTheme();

  const { transformed, transformedKeys } = transformKeysToWordsAndUppercase(dataFormatter(data), [
    ...excludeFields,
    index,
  ]);

  const yAxis = yAxisConfig?.length ? (
    yAxisConfig.map((config) => {
      return (
        <YAxis
          key={config.key}
          tickFormatter={config.valueFormatter ?? valueFormatter}
          type='number'
          fontSize={12}
          strokeWidth={0}
          yAxisId={config.position}
          orientation={config.position}
        />
      );
    })
  ) : (
    <YAxis tickFormatter={valueFormatter} type='number' fontSize={12} strokeWidth={0} />
  );

  return (
    <Card
      p={0}
      style={{
        border: isError && '2px solid red',
        height: 'fit-content',
        boxShadow: '#00000021 1px 2px 1px',
        backgroundColor: theme.colors.secondary_background_color[6],
      }}
    >
      <Container
        fluid
        sx={{
          padding: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `2px ${theme.colors.company_theme_primary_color[7]} solid`,
        }}
      >
        <Title order={5}>{title}</Title>
        {hint && <IconInfo hint={hint} />}
      </Container>
      <ResponsiveContainer width='100%' height={320} style={{ padding: '0 1rem' }}>
        <BarChart
          data={transformed}
          margin={
            transformedKeys?.length === 1
              ? {
                  top: 50,
                  right: 20,
                  left: -10,
                  bottom: 30,
                }
              : {
                  top: 70,
                  right: 20,
                  left: -10,
                  bottom: 10,
                }
          }
          padding={0}
          width={100}
        >
          {showLegend && <Legend type='circle' align={'right'} wrapperStyle={{ top: 10, right: 0 }} />}
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey={index} fontSize={12} />
          {yAxis}
          <Tooltip />
          {transformedKeys.map((key, index) => (
            <Bar
              key={key}
              yAxisId={yAxisConfig ? yAxisConfig.find((x) => x.key === key)?.position : null}
              dataKey={key}
              animationDuration={650}
              strokeWidth={2}
              fill={theme.colors?.chart_colors[index] ?? colors[index] ?? 'grey'}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <LoadingOverlay
        id={`loading_overlay__${title}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'sm', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
      {isError && (
        <Overlay color={theme.colors.retry_error_color[6]} opacity={0.6} center>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
