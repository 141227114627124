export const SCHEDULED_REPORTS_STATUS = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
};

export const SCHEDULED_REPORTS_MODAL_MAP = {
  [SCHEDULED_REPORTS_STATUS.ACTIVE]: 'Disable',
  [SCHEDULED_REPORTS_STATUS.DISABLED]: 'Enable',
};

export const SCHEDULED_REPORTS_STATUS_INDICATOR_MAP = {
  [SCHEDULED_REPORTS_STATUS.ACTIVE]: 'green.6',
  [SCHEDULED_REPORTS_STATUS.DISABLED]: 'red.6',
};
