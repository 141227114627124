import React, { createContext, useContext, useEffect, useState } from 'react';
import { Box, Modal } from '@mantine/core';
import Search from '../../../../../../../../../components/Search/Search';
import { SelectedFields } from './components/SelectedFields';
import { AvailableFields } from './components/AvailableFields';

export const PortfolioOverviewFilterModalContext = createContext(null);

const FilterModal = ({ selectedMetrics, opened, onClose, children, size = '45em' }) => {
  const [searchMetric, setSearchMetric] = useState('');
  const [selectedMetricIds, setSelectedMetricIds] = useState(selectedMetrics);

  useEffect(() => {
    setSelectedMetricIds(selectedMetrics);
  }, [selectedMetrics]);

  return (
    <PortfolioOverviewFilterModalContext.Provider
      value={{
        searchMetric,
        setSearchMetric,
        selectedMetricIds,
        setSelectedMetricIds,
      }}
    >
      <Modal
        size={size}
        opened={opened}
        onClose={onClose}
        centered
        sx={{
          '.mantine-CloseButton-root': {
            '&:focus': {
              outline: 'none',
            },
            '&:focus-visible': {
              outline: 'none',
            },
          },
        }}
      >
        {children}
      </Modal>
    </PortfolioOverviewFilterModalContext.Provider>
  );
};

function SearchInput() {
  const { setSearchMetric } = useContext(PortfolioOverviewFilterModalContext);

  const handleChange = (value) => {
    setSearchMetric(value);
  };
  return (
    <Box sx={{ marginTop: '1em' }}>
      <Search handleChange={handleChange} />
    </Box>
  );
}

FilterModal.SelectedFields = SelectedFields;
FilterModal.Search = SearchInput;
FilterModal.AvailableFields = AvailableFields;

export default FilterModal;
