import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/overview/overview';

export const useBiPropertyOverviewService = () => {
  const { axios } = useAxios();

  const getLeads = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/leads`, { ...config, params });

    return response?.data;
  };

  const getTours = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/tours`, { ...config, params });

    return response?.data;
  };

  const getApprovedLeases = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/approved-leases`, { ...config, params });

    return response?.data;
  };

  const getCompletedLeases = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/completed-leases`, { ...config, params });

    return response?.data;
  };

  const getClosingRatio = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/closing-ratio`, { ...config, params });

    return response?.data;
  };

  const getAverageRentPerSqft = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/average-rent-per-sqft`, { ...config, params });

    return response?.data;
  };

  const getUpcomingExpirations = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/upcoming-expirations`, { ...config, params });

    return response?.data;
  };

  const getToursAndLeases = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/tours-and-leases`, { ...config, params });

    return response?.data;
  };

  const getLeasesByFloorPlan = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/leases-by-floorplan`, { ...config, params });

    return response?.data;
  };

  const getPriceAndOccupancy = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/price-and-occupancy`, { ...config, params });

    return response?.data;
  };

  const getConversionPerformance = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/conversion-performance`, { ...config, params });

    return response?.data;
  };

  return {
    getLeads,
    getTours,
    getApprovedLeases,
    getCompletedLeases,
    getClosingRatio,
    getAverageRentPerSqft,
    getUpcomingExpirations,
    getToursAndLeases,
    getLeasesByFloorPlan,
    getPriceAndOccupancy,
    getConversionPerformance,
  };
};
