import { Box, Button, Indicator, Switch, Text, Title, useMantineTheme } from '@mantine/core';
import React, { useState } from 'react';
import UserForm from './forms/userForm';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { IconArrowLeft, IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  columns as relatedPropertiesColumns,
  initialState as relatedPropertiesInitialState,
} from './constants/userPropertiesTableDef';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useUserService } from '../../../../../services/userService';
import {
  ADMIN_ROLES,
  USER_ROLES,
  USER_STATUS,
  USER_STATUS_INDICATOR_MAP,
  USER_STATUS_MODAL_MAP,
} from '../../../../../constants/user';
import { COMPANY_STATUS } from '../../../../../constants/companyStatus';
import { FORM_MODES } from '../../../../../constants/form_modes';
import ConfirmationModal from '../../../../../components/ConfirmationModal/ConfirmationModal';
import { useAuthService } from '../../../../../services/authService';

export default function AdminViewUser() {
  const user = useRouteLoaderData('user-details');
  const [opened, { open, close }] = useDisclosure(false);
  const [previewModeConfirmationOpened, { open: openPreviewModeConfirmation, close: closePreviewModeConfirmation }] =
    useDisclosure(false);
  const [sendInvitationOpened, { open: openSendModalConfirmation, close: closeSendInvitationClicked }] =
    useDisclosure(false);
  const [resendInvitationOpened, { open: openResendModalConfirmation, close: closeResendInvitationClicked }] =
    useDisclosure(false);

  const navigate = useNavigate();
  const theme = useMantineTheme();
  const userService = useUserService();
  const authService = useAuthService();
  const [initialData, setInitialData] = useState(user);

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [checked, setChecked] = useState(initialData?.status !== USER_STATUS.DISABLED);

  const relatedPropertiesTable = useMantineReactTable({
    columns: relatedPropertiesColumns,
    initialState: relatedPropertiesInitialState,
    data: initialData.properties ?? [],
    enableTopToolbar: false,
  });
  const onClickEditUser = () => {
    navigate('edit');
  };

  const onClickBack = () => navigate(-1);

  const onPreviewClicked = () => openPreviewModeConfirmation();
  const onSendInvitationClicked = () => openSendModalConfirmation();
  const onResendInvitationClicked = () => openResendModalConfirmation();

  // TODO: Refactor to generic confirmation modal component
  const onClickModalConfirm = async () => {
    try {
      setIsConfirmLoading(true);
      const savedUser = checked
        ? await userService.disableUser(initialData?.id, initialData?.role)
        : await userService.enableUser(initialData?.id, initialData?.role);

      checked && initialData?.status === USER_STATUS.DRAFT && onClickBack();
      checked && initialData?.status === USER_STATUS.PENDING && onClickBack();

      setInitialData(savedUser);

      setChecked(!checked);
      notifications.show({
        title: 'Success!',
        message: `${initialData?.full_name} user was successfully ${
          savedUser?.status === USER_STATUS.ACTIVE ? 'enabled' : 'disabled'
        }!`,
        color: 'green',
      });
    } catch (e) {
      notifications.show({
        title: 'An Error Occurred',
        message: e.message,
        color: 'red',
      });
    } finally {
      setIsConfirmLoading(false);
      close();
    }
  };

  const onPreviewModeConfirmed = async () => {
    const accessData = await userService.getUserPreviewModeAccess(user.id, user.role);

    await authService.setUserPreviewMode(accessData);
  };

  const onSendInvitationConfirmed = async () => {
    try {
      initialData.isEmailTokenExpired
        ? await userService.resendConfirmationEmail(initialData.email, initialData.role)
        : await userService.confirmEmail(initialData.email, initialData.role);

      notifications.show({
        title: 'Success!',
        message: `Invitation was sent to ${initialData?.full_name} successfully`,
        color: 'green',
      });
    } catch (error) {
      notifications.show({
        title: 'An Error Occurred',
        message: error.message,
        color: 'red',
      });
    } finally {
      navigate('/admin/users');
    }
  };

  const onChangeUserStatus = () => {
    open();
  };

  const indicatorColor = USER_STATUS_INDICATOR_MAP[initialData?.status];
  const modalLabel = USER_STATUS_MODAL_MAP[initialData?.status];

  return (
    <Box mx='auto' mt='md' px={30}>
      <ConfirmationModal
        opened={opened}
        actionTitle={`${modalLabel} user`}
        title={`Are you sure to ${modalLabel} user ${initialData?.full_name}?`}
        subtitle={
          (initialData?.status === USER_STATUS.PENDING || initialData?.status === USER_STATUS.DRAFT) && (
            <Text fz={'sm'} mt={7}>{`This action will delete user from the system`}</Text>
          )
        }
        onClickModalConfirm={onClickModalConfirm}
        isConfirmLoading={isConfirmLoading}
        onClose={close}
      />

      <ConfirmationModal
        opened={previewModeConfirmationOpened}
        actionTitle={`Preview Mode`}
        title={`Entering Preview Mode for ${initialData?.full_name} Property Manager. Continue?`}
        onClickModalConfirm={onPreviewModeConfirmed}
        isConfirmLoading={isConfirmLoading}
        onClose={closePreviewModeConfirmation}
      />
      <ConfirmationModal
        opened={sendInvitationOpened}
        actionTitle='Send invitation'
        subtitle='Are you sure that you want to send an invitation?'
        onClickModalConfirm={onSendInvitationConfirmed}
        isConfirmLoading={isConfirmLoading}
        onClose={closeSendInvitationClicked}
      />
      <ConfirmationModal
        opened={resendInvitationOpened}
        actionTitle='Resend invitation'
        subtitle='Are you sure that you want to resend an invitation?'
        onClickModalConfirm={onSendInvitationConfirmed}
        isConfirmLoading={isConfirmLoading}
        onClose={closeResendInvitationClicked}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Indicator color={indicatorColor || 'green.6'}>
            <Title order={2} mb={20} pr={7} align='left'>
              {initialData?.full_name}
            </Title>
          </Indicator>
          {initialData?.status !== USER_STATUS.DISABLED && (
            <Button variant='white' sx={{ backgroundColor: 'transparent' }} onClick={onClickEditUser} ml={17} px={6}>
              <IconEdit size={30} color='gray' variant='default' />
            </Button>
          )}
          {initialData?.company?.status === COMPANY_STATUS.ACTIVE && (
            <Box
              sx={({ colors }) => ({
                '.mantine-Switch-track': {
                  background: colors.red[6],
                  borderColor: colors.red[6],
                  cursor: 'pointer',
                },
                '.mantine-Switch-thumb': {
                  borderColor: 'white',
                },
              })}
            >
              <Switch
                checked={checked}
                onChange={(event) => onChangeUserStatus(event.currentTarget.checked)}
                color={'teal'}
                size='md'
                pt={6}
                ml={20}
                thumbIcon={
                  checked ? (
                    <IconCheck size='0.8rem' color={theme.colors.teal[theme.fn.primaryShade()]} stroke={3} />
                  ) : (
                    <IconX size='0.8rem' color={theme.colors.red[theme.fn.primaryShade()]} stroke={3} />
                  )
                }
              />
            </Box>
          )}
          {initialData?.status === USER_STATUS.DRAFT && (
            <Button color={'yellow'} variant={'outline'} onClick={onSendInvitationClicked} ml={20}>
              Send invitation
            </Button>
          )}
          {initialData?.status === USER_STATUS.PENDING && initialData.isEmailTokenExpired && (
            <Button color={'yellow'} variant={'outline'} onClick={onResendInvitationClicked} ml={20}>
              Resend invitation
            </Button>
          )}
          {user.role === USER_ROLES.PROPERTY_MANAGER && (
            <Button variant='default' color='gray' onClick={onPreviewClicked} ml={25} px={6}>
              Preview
            </Button>
          )}
        </div>
        <Button color='gray' variant='default' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Go Back
        </Button>
      </div>
      <UserForm mode={FORM_MODES.View} initialValues={initialData}></UserForm>

      {!ADMIN_ROLES.includes(user.role) && (
        <div>
          <Title order={3} mt={20} mb={20} align='left'>
            Related Properties
          </Title>
          <MantineReactTable table={relatedPropertiesTable} />
        </div>
      )}
    </Box>
  );
}

export const loader = async ({ params }) => {
  const { getUserById } = useUserService();
  return await getUserById(params.id);
};
