import React, { useContext, useEffect, useState } from 'react';
import {
  Header as MantineHeader,
  Group,
  ActionIcon,
  Menu,
  Avatar,
  Title,
  useMantineColorScheme,
  useMantineTheme,
  Button,
} from '@mantine/core';
import { useAuthService } from '../../services/authService';
import { IconLogout, IconMoon, IconSun } from '@tabler/icons-react';
import { THEME_MODES } from '../../constants/theme_modes';
import { AuthContext } from '../../context/AuthContext';

export default function Header({ userName, company, logo, logo_url_light, logo_url_dark }) {
  const authService = useAuthService();
  const { currentUser, isPreviewMode } = useContext(AuthContext);
  const { colors } = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const [currentLogo, setCurrentLogo] = useState(null);

  useEffect(() => {
    if (colorScheme === THEME_MODES.darkMode && logo_url_dark) {
      setCurrentLogo(logo_url_dark);
    } else if (colorScheme === THEME_MODES.lightMode && logo_url_light) {
      setCurrentLogo(logo_url_light);
    } else {
      setCurrentLogo(logo);
    }
  }, [colorScheme]);

  return (
    <MantineHeader
      height={70}
      px={25}
      zIndex={221}
      sx={{
        boxShadow: '#00000021 83px 0px 13px',
        borderBottom: `4px solid ${colors.company_theme_primary_color[6]}`,
        paddingLeft: '1rem !important',
        backgroundColor: colors.primary_background_color[4],
      }}
    >
      <Group position='apart' sx={{ height: '100%' }}>
        <Group sx={{ height: '100%', width: '70%' }}>
          <img style={{ maxHeight: '50px', maxWidth: '200px', objectFit: 'contain' }} src={currentLogo} alt />
          <Title fw={700} ml='lg' size='lg'>
            {userName}
          </Title>
          {isPreviewMode && (
            <Title fw={700} size='lg' italic color='yellow'>
              (Preview Mode)
            </Title>
          )}
          <Title color='dimmed' ml='lg' size='lg'>
            {company}
          </Title>
        </Group>

        <Group>
          {isPreviewMode && (
            <Button color='yellow' onClick={() => authService.exitUserPreviewMode()}>
              Exit Preview Mode
            </Button>
          )}
          {currentUser.role === 'Property Manager' && (
            <ActionIcon radius='xl' onClick={() => toggleColorScheme()} title='Toggle color scheme'>
              {colorScheme === THEME_MODES.darkMode ? <IconSun size={25} /> : <IconMoon size={25} />}
            </ActionIcon>
          )}
          <ActionIcon>
            <Menu shadow='md' width={200}>
              <Menu.Target>
                <Avatar radius='xl' />
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Profile</Menu.Label>

                <Menu.Item icon={<IconLogout size={14} />} onClick={() => authService.logout()}>
                  Log Out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </ActionIcon>
        </Group>
      </Group>
    </MantineHeader>
  );
}
