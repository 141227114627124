import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { useWebsitePerformanceService } from '../../../../../../../services/bi/property/marketing/WebsitePerformanceService';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import dayjs from 'dayjs';

export default function ActiveUsersMetric() {
  const { getActiveUsers } = useWebsitePerformanceService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Active Users',
        hint: 'The count of unique users who have engaged with your website within a specified time. If a user engages multiple times within the same session, they are still counted as a single active user. Active Users are calculated through BigQuery to deliver an exact calculation of Active Users.',
      }}
      fetchMethod={getActiveUsers}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
