import React, { useContext, useEffect, useState } from 'react';
import {
  Header as MantineHeader,
  Group,
  ActionIcon,
  Menu,
  Title,
  useMantineColorScheme,
  useMantineTheme,
  Button,
  ThemeIcon,
  UnstyledButton,
  Text,
} from '@mantine/core';
import { useAuthService } from '../../services/authService';
import { IconChevronDown, IconLogout, IconMoonFilled, IconSunFilled, IconUserFilled } from '@tabler/icons-react';
import { THEME_MODES } from '../../constants/theme_modes';
import { AuthContext } from '../../context/AuthContext';

export default function Header({ userName, company, logo, logo_url_light, logo_url_dark }) {
  const authService = useAuthService();
  const { currentUser, isPreviewMode } = useContext(AuthContext);
  const { colors } = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const [currentLogo, setCurrentLogo] = useState(null);
  const [showCompanyNameInsteadOfLogo, setShowCompanyNameInsteadOfLogo] = useState(false);

  useEffect(() => {
    if (colorScheme === THEME_MODES.darkMode && logo_url_dark) {
      setCurrentLogo(logo_url_dark);
    } else if (colorScheme === THEME_MODES.lightMode && logo_url_light) {
      setCurrentLogo(logo_url_light);
    } else {
      setCurrentLogo(logo);
    }
  }, [colorScheme]);

  return (
    <MantineHeader
      height={64}
      p='0 1.5rem 0 1rem'
      zIndex={100}
      sx={{
        borderBottom: `1px solid ${colors.border_color[0]}`,
        backgroundColor: colors.primary_background[4],
      }}
    >
      <Group position='apart' sx={{ height: '100%' }}>
        <Group sx={{ height: '100%' }}>
          {showCompanyNameInsteadOfLogo ? (
            <Title color='dimmed' ml='lg' size='lg'>
              {company}
            </Title>
          ) : (
            <img
              style={{ maxHeight: '60px', maxWidth: '200px', objectFit: 'contain' }}
              src={currentLogo}
              alt
              onError={() => setShowCompanyNameInsteadOfLogo(true)}
            />
          )}

          {isPreviewMode && (
            <Title fw={700} size='lg' italic color='yellow'>
              (Preview Mode)
            </Title>
          )}
        </Group>

        <Group sx={{ height: '100%' }} py={12} spacing='xl'>
          {isPreviewMode && (
            <Button color='yellow' onClick={() => authService.exitUserPreviewMode()}>
              Exit Preview Mode
            </Button>
          )}
          {currentUser.role === 'Property Manager' && (
            <ActionIcon
              color='icon_primary'
              sx={{ height: '40px', width: '40px' }}
              variant='light'
              radius='xl'
              onClick={() => toggleColorScheme()}
              title='Toggle color scheme'
            >
              {colorScheme === THEME_MODES.darkMode ? <IconSunFilled size={24} /> : <IconMoonFilled size={24} />}
            </ActionIcon>
          )}

          <Menu shadow='md' width={200}>
            <Menu.Target>
              <UnstyledButton
                sx={{
                  '&:hover .personIcon': {
                    backgroundColor:
                      colorScheme === THEME_MODES.darkMode ? 'rgba(137, 152, 176, 0.25)' : 'rgba(218, 222, 225, 0.65)',
                  },
                  '&:hover .personRole': {
                    filter: colorScheme === THEME_MODES.darkMode ? 'brightness(120%)' : 'brightness(80%)',
                  },
                  '.personName': {
                    textWrap: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: '180px',
                    textOverflow: 'ellipsis',
                  },
                  '&:hover .personName': {
                    'text-shadow': `-.1px -.1px 0 ${colors.dark[1]}, .1px -.1px 0 ${colors.dark[1]}, -.1px .1px 0 ${colors.dark[1]}, .1px .1px 0 ${colors.dark[1]}`,
                  },
                }}
              >
                <Group>
                  <ThemeIcon
                    className='personIcon'
                    color='icon_primary'
                    radius='xl'
                    variant='light'
                    sx={{ height: '40px', minWidth: '40px' }}
                  >
                    <IconUserFilled size={24} />
                  </ThemeIcon>
                  <div style={{ flex: 1 }}>
                    <Group spacing='8px'>
                      <Text className='personName' size='sm' weight={500}>
                        {userName}
                      </Text>
                      <IconChevronDown
                        size='1.1rem'
                        color={colorScheme === THEME_MODES.darkMode ? colors.icon_primary[2] : colors.icon_primary[6]}
                      />
                    </Group>
                    <Text className='personRole' color='text_placeholder_color' size='xs'>
                      {currentUser.role}
                    </Text>
                  </div>
                </Group>
              </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Profile</Menu.Label>

              <Menu.Item icon={<IconLogout size={14} />} onClick={() => authService.logout()}>
                Log Out
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </MantineHeader>
  );
}
