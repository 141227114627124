import React, { createContext, useContext, useState } from 'react';
import * as dayjs from 'dayjs';
import { usePropertyService } from '../../../../../../services/propertyService';
import { AuthContext } from '../../../../../../context/AuthContext';
import { useAbortController } from '../../../../../../hooks/abortControllerHook';

export const InternalPortfolioOverviewContext = createContext(null);

export const InternalPortfolioOverviewProvider = ({ children }) => {
  const { get: getProperties } = usePropertyService();

  const { setManagedProperties } = useContext(AuthContext);

  const [filterPresets, setFilterPresets] = useState([]);
  const [selectedFilterPreset, setSelectedFilterPreset] = useState(null);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedMetricsOrder, setSelectedMetricsOrder] = useState([]);
  const [tableFilters, setTableFilters] = useState([]);
  const [tableSorting, setTableSorting] = useState([]);

  const [accountManager, setAccountManager] = useState({});
  const [company, setCompany] = useState({});

  const [properties, setProperties] = useState([]);

  useAbortController(
    async (...params) => {
      if (!company?.value || !accountManager?.value) return;

      const properties = await getProperties(...params);
      setProperties(properties);
      setManagedProperties(properties);
    },
    [company?.value, accountManager?.value, true],
    [company?.value, accountManager?.value]
  );

  const startDate = dayjs().date() === 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month');
  const endDate = dayjs().subtract(1, 'day');

  const [dates, setDates] = useState([startDate, endDate]);

  const [csvData, setCsvData] = useState([]);

  const [globalSearch, setGlobalSearch] = useState('');

  return (
    <InternalPortfolioOverviewContext.Provider
      displayName={'InternalPortfolioOverviewContext'}
      value={{
        filterPresets,
        setFilterPresets,
        selectedFilterPreset,
        setSelectedFilterPreset,
        selectedMetrics,
        setSelectedMetrics,
        selectedMetricsOrder,
        setSelectedMetricsOrder,
        tableFilters,
        setTableFilters,
        tableSorting,
        setTableSorting,
        dates,
        setDates,
        globalSearch,
        setGlobalSearch,
        company,
        setCompany,
        accountManager,
        setAccountManager,
        properties,
        setProperties,
        csvData,
        setCsvData,
      }}
    >
      {children}
    </InternalPortfolioOverviewContext.Provider>
  );
};
