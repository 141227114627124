import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Card } from '@mantine/core';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { tableOptions } from './Config/portfolioOverviewTableDef';
import { InternalPortfolioOverviewContext } from '../../Context/PortfolioOverviewContext';
import { generateColumn } from './Builder/ColumnsBuilder';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useInternalPortfolioOverviewService } from '../../Services/portfolioOverviewService';
import { getPortfolioOverviewMetricById } from '../../../../../../../constants/portfolioOverviewMetrics';
import { useAbortController } from '../../../../../../../hooks/abortControllerHook';

export default function AdminPortfolioOverviewTable() {
  const { getTableData } = useInternalPortfolioOverviewService();
  const {
    dates,
    properties,
    selectedMetrics,
    setSelectedMetrics,
    setSelectedMetricsOrder,
    setTableFilters,
    setTableSorting,
    selectedFilterPreset,
    setCsvData,
  } = useContext(InternalPortfolioOverviewContext);

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const [loading, setLoading] = useState(false);

  const onPropertyClicked = async (id) => {
    const params = {
      property_id: id,
      from: dayjs(dates[0]).format('YYYY-MM-DD'),
      to: dayjs(dates[1]).format('YYYY-MM-DD'),
    };
    navigate(`/properties?${createSearchParams(params)}`);
  };

  const table = useMantineReactTable({
    ...tableOptions,
    state: { ...tableOptions.state, loading, onPropertyClicked, properties },
    columns: [...tableOptions.columns, ...columns],
    data,
  });

  useEffect(() => {
    const newData = properties?.map((x) => ({ property: x.id, name: x.name }));
    setData(newData);
  }, [properties]);

  useAbortController(
    async (...params) => {
      if (!dates?.length || !properties?.length || !selectedMetrics.length) return;

      setLoading(true);
      let newData = await getTableData(...params);
      newData = newData.map((x) => ({ ...x, name: properties.find((p) => p.id == x.property)?.name }));
      setData(newData);
      setLoading(false);
    },
    [
      {
        from: dayjs(dates[0]).format('YYYY-MM-DD'),
        to: dayjs(dates[1]).format('YYYY-MM-DD'),
        property_ids: properties.map((x) => x.id),
        metrics: selectedMetrics,
      },
    ],
    [dates, properties, selectedMetrics]
  );

  useEffect(() => {
    const newColumns = selectedMetrics.map((metric) => ({
      ...generateColumn(getPortfolioOverviewMetricById(metric)),
    }));

    setColumns(newColumns);
    const existingOrder = table.getState().columnOrder.filter((x) => newColumns.map((x) => x.accessorKey).includes(x));
    table.setColumnOrder(Array.from(new Set([...existingOrder, ...newColumns.map((x) => x.accessorKey)])));
  }, [selectedMetrics]);

  useEffect(() => {
    setSelectedMetricsOrder(table.getState().columnOrder);
  }, [table.getState().columnOrder]);

  useEffect(() => {
    setTableFilters(table.getState().columnFilters);
  }, [table.getState().columnFilters]);

  useEffect(() => {
    setTableSorting(table.getState().sorting);
  }, [table.getState().sorting]);

  useEffect(() => {
    if (!selectedFilterPreset || !columns.length) return;

    const { filters, sorting } = selectedFilterPreset.data;

    const existingFilter = table
      .getState()
      .columnFilters.filter((x) => x.id === 'property' || columns.map((x) => x.accessorKey).includes(x.id));
    table.setColumnFilters(Array.from(new Set([...existingFilter, ...filters])));

    const existingSorting = table
      .getState()
      .sorting.filter((x) => x.id === 'property' || columns.map((x) => x.accessorKey).includes(x.id));
    table.setSorting(Array.from(new Set([...existingSorting, ...sorting])));
  }, [columns]);

  useEffect(() => {
    if (!selectedFilterPreset) return;

    setColumns([]);
    setSelectedMetrics([]);
    table.setColumnFilters([]);
    table.setColumnOrder([]);
    table.setSorting([]);

    // This ensures that the table columns are rendered in the correct order from the very beginning,
    // without having to reorder them
    setSelectedMetrics([...selectedFilterPreset.data.order]);
  }, [selectedFilterPreset]);

  useEffect(() => {
    setCsvData({ rows: table.getPrePaginationRowModel().rows, headers: table.getFlatHeaders() });
  }, [table.getPrePaginationRowModel().rows, table.getFlatHeaders()]);

  return (
    <Card
      p={0}
      sx={{
        height: 'fit-content',
      }}
    >
      <MantineReactTable table={table} />
    </Card>
  );
}
