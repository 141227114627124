import { Text } from '@mantine/core';
import React from 'react';

export const columns = [
  {
    accessorKey: 'source',
    header: 'Source',
    maxSize: 0,
  },
  {
    accessorKey: 'medium',
    header: 'Medium',
    maxSize: 0,
  },
  {
    accessorKey: 'total',
    header: 'Website Leads',
    maxSize: 0,
    Cell: ({ cell }) => <Text>{Number(cell.getValue()).toFixed(2)}</Text>,
  },
];

export const initialState = {
  sorting: [{ id: 'total', desc: true }],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
};
