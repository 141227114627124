import React from 'react';
import { Text, Modal, Button } from '@mantine/core';

export default function ConfirmationModal({
  opened,
  onClose,
  actionTitle,
  title,
  subtitle,
  onClickModalConfirm,
  isConfirmLoading,
  Child,
  childProps = {},
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<div style={{ fontWeight: 600, fontSize: 20 }}> {actionTitle}</div>}
      centered
    >
      {title && <Text fw={700}>{title}</Text>}
      {subtitle && (
        <Text fz={'sm'} mt={7}>
          {subtitle}
        </Text>
      )}
      {Child && <Child {...childProps} />}
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
        <Button mr={15} variant='outline' onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onClickModalConfirm} loading={isConfirmLoading}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
}
