import React, { useEffect, useState } from 'react';
import { MultiSelect } from '@mantine/core';
import { USER_STATUS } from '../../constants/user';

const UsersMultiSelect = ({
  value = [],
  onChange,
  withAsterisk,
  readOnly,
  disabled,
  required,
  error,
  users,
  internalUsers,
  verifiedCompanyUsers,
  filter = (x) => x.status === USER_STATUS.ACTIVE,
}) => {
  const [options, setOptions] = useState([]);

  // This useEffect reduces amount of renders
  useEffect(() => {
    const filteredUsers = users?.filter(filter);

    const propertyUsers =
      filteredUsers
        ?.map((user) => ({
          value: `${user?.email}`,
          label: `${user?.full_name ?? ''} (${user?.email})`,
          group: 'Property managers',
        }))
        .sort((a, b) => a.label?.localeCompare(b?.label)) || [];

    const accountManagers =
      internalUsers
        ?.map((user) => ({
          value: `${user?.email}`,
          label: `${user?.full_name ?? ''} (${user?.email})`,
          group: 'Account managers',
        }))
        .sort((a, b) => a.label?.localeCompare(b?.label)) || [];

    const verifiedUsers =
      verifiedCompanyUsers
        ?.map((user) => ({
          value: `${user?.verified_user_email}`,
          label: `${user?.verified_user_first_name ?? ''} ${user?.verified_user_last_name ?? ''} (${
            user?.verified_user_email
          })`,
          group: 'Users verified by company',
        }))
        .sort((a, b) => a.label?.localeCompare(b?.label)) || [];

    setOptions([...propertyUsers, ...accountManagers, ...verifiedUsers]);
  }, [users, verifiedCompanyUsers, internalUsers]);

  const handleSelectChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <MultiSelect
      withinPortal
      searchable
      label={'Recipients'}
      placeholder='Select recipients'
      nothingFound='Nothing found'
      value={value?.map(String)}
      withAsterisk={withAsterisk}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      error={error}
      onChange={handleSelectChange}
      data={options}
    />
  );
};

export default UsersMultiSelect;
