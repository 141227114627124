import React, { useContext } from 'react';
import { useDigitalMarketSurveyService } from '../../../../../../../services/bi/property/marketing/DigitalMarketSurveyService';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';

const TopPlacement = () => {
  const { getTopPlacement } = useDigitalMarketSurveyService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Top Placement %',
        hint: 'The percent of your ad impressions that are shown as the very first ad above the organic search results. Our benchmark is at least 20%.',
        valueFormatter: (x) => (Number(x) * 100).toFixed(2),
        suffix: '%',
      }}
      fetchMethod={getTopPlacement}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default TopPlacement;
