import React from 'react';
import { columns, initialState, state } from './constants/connectionErrorPatternsTableDef';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import SimpleReportTable from '../../../../../../../../components/SimpleReportTable/SimpleReportTable';
import dayjs from 'dayjs';
import { useConnectionErrorsService } from '../../../../../../../../services/connectionErrorsService';

export default function ConnectionErrorPatterns({ dates }) {
  const { getConnectionErrorPatterns } = useConnectionErrorsService();

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'Connection Error Patterns',
        columns,
        initialState,
        state,
      }}
      fetchMethod={getConnectionErrorPatterns}
      defaultValue={[]}
      deps={[dates]}
      params={{ dates }}
      paramsFormatter={(params) => [
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
