import React from 'react';
import { UnstyledButton, useMantineTheme } from '@mantine/core';
import Info from '../Info/Info';

export const LeftMenuItemButton = ({ icon: Icon, label, isLinkActive, onClick }) => {
  const { colors } = useMantineTheme();

  return (
    <Info label={label} position='right' nowrap openDelay={200}>
      <UnstyledButton
        onClick={onClick}
        sx={{
          borderRadius: '.5rem',
          width: '3.5rem',
          height: '3.5rem',
          display: 'flex',
          alignItems: 'center',
          'justify-content': 'center',
          '&:hover': {
            backgroundColor: colors.hover_color[6],
          },
          backgroundColor: isLinkActive && colors.company_theme_primary_color[6] + '!important',
        }}
      >
        <Icon size='1.5rem' color={isLinkActive ? colors.dark[0] : colors.simple_icon_colors[0]} />
      </UnstyledButton>
    </Info>
  );
};
