import React from 'react';
import dayjs from 'dayjs';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import { useConnectionErrorsService } from '../../../../../../../../services/connectionErrorsService';
import LineChartComponent from '../../../../../../../../components/LineChart/LineChart';

export default function ConnectionErrorsByDate({ dates }) {
  const { getConnectionErrorsCountByDate } = useConnectionErrorsService();

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{
        title: 'Errors by Date',
        index: 'created_date',
        showLegend: false,
      }}
      fetchMethod={getConnectionErrorsCountByDate}
      defaultValue={[]}
      deps={[dates]}
      params={{ dates }}
      paramsFormatter={(params) => [
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
