import {
  Button,
  Card,
  Container,
  Group,
  NumberInput,
  SimpleGrid,
  Text,
  TextInput,
  ThemeIcon,
  createStyles,
  Switch,
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { DateInput } from '@mantine/dates';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconBrandGoogle,
  IconCalendar,
  IconCloudDataConnection,
  IconDots,
  IconPageBreak,
  IconReload,
  IconSettings,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import * as dayjs from 'dayjs';
import { usePropertyService } from '../../../../../../services/propertyService';
import { mapNullToEmptyString } from '../../../../../../utils/form.util';
import { FORM_ERRORS } from '../../../../../../constants/form_errors';
import { validateUrl } from '../../../../../../utils/url.util';
import { FORM_MODES } from '../../../../../../constants/form_modes';
import CompaniesDropdown from '../../../../../../components/CompaniesDropdown/CompaniesDropdown';
import { dateOrNull } from '../../../../../../utils/time.util';
import ReportPagesControl from './controls/ReportPagesControl/ReportPagesControl';
import IconInfo from '../../../../../../components/IconInfo/IconInfo';
import { FEATURE_FLAGS } from '../../../../../../constants/featureFlags';
import { useFeatureFlagsService } from '../../../../../../services/featureFlagsService';
import SDMUsersSelect from '../../../../../../components/SDMUsersSelect/SDMUsersSelect';
import { USER_STATUS } from '../../../../../../constants/user';
import { CONNECTION_TYPE } from '../../../../../../constants/connectionType';

export default function AdminPropertyForm({ mode, initialValues = {}, handleRetryConnection }) {
  const navigate = useNavigate();
  const propertyService = usePropertyService();

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const { isFeatureEnabled } = useFeatureFlagsService();

  // TODO: Should be refactored
  const {
    id,
    name,
    company_id,
    start_date,
    end_date,
    account_manager_id,
    details,
    enable_manual_report_pages,
    report_pages,
  } = initialValues;
  const mappedInitialValues = !Object.keys(initialValues).length
    ? {}
    : mapNullToEmptyString({
        id,
        name,
        company_id,
        start_date,
        end_date,
        details,
        enable_manual_report_pages,
        report_pages,
      });

  const form = useForm({
    initialValues: Object.assign({}, { details: {} }, { ...mappedInitialValues, account_manager_id }),

    validate: {
      name: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      company_id: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      account_manager_id: (value) => {
        if (
          (value &&
            value === initialValues.account_manager_id &&
            initialValues.account_manager.status === USER_STATUS.DISABLED) ||
          !value
        ) {
          return FORM_ERRORS.REQUIRED_FIELD_ERROR;
        }
      },
      end_date: (value, values) => {
        if (value && values?.start_date && dayjs(value).isBefore(values?.start_date)) {
          return FORM_ERRORS.END_DATE_LATER_START_DATE_ERROR;
        }
      },
      details: {
        gds_url: (value) => {
          if (value?.length && !validateUrl(value)) return FORM_ERRORS.URL_ERROR;
        },
      },
      report_pages: (value, values) => {
        if (!value?.length && values?.enable_manual_report_pages) {
          notifications.show({
            title: 'Action required!',
            message: 'Please choose at least one report page!',
            color: 'yellow',
          });
          return FORM_ERRORS.REQUIRED_FIELD_ERROR;
        }
      },
    },
  });

  const disabled = mode === FORM_MODES.View;
  const editingDisabled = mode === FORM_MODES.Edit;

  const onSubmitForm = form.onSubmit(async (values) => {
    try {
      setIsSaveLoading(true);
      switch (mode) {
        case FORM_MODES.Create:
          await propertyService.createProperty(values);
          break;
        case FORM_MODES.Edit:
          await propertyService.updateProperty(initialValues.id, values);
          break;
      }

      notifications.show({
        title: 'Success!',
        message: `${values.name} property was successfully ${mode === FORM_MODES.Create ? 'added' : 'updated'}!`,
        color: 'green',
      });
      navigate('/admin/properties');
    } catch (e) {
      notifications.show({
        title: 'An Error Occurred',
        message: e.message,
        color: 'red',
      });
    } finally {
      setIsSaveLoading(false);
    }
  });

  const useStyles = createStyles((theme) => ({
    form: {
      input: {
        backgroundColor: theme.colors.gray[1],
      },
    },
  }));

  const { classes } = useStyles();

  const renderRetryConnectionSection = (connection_type) => {
    if (mode !== FORM_MODES.View) return;

    const hasConnectionErrors =
      initialValues.connection_errors &&
      initialValues.connection_errors?.some((x) => x.connection_type === connection_type && !x.retried_from_bi);

    return (
      hasConnectionErrors && (
        <IconReload
          size='md'
          color='red'
          style={{ paddingRight: '4px' }}
          stroke={1.5}
          cursor='pointer'
          onClick={() => handleRetryConnection(connection_type)}
        />
      )
    );
  };

  return (
    <form onSubmit={onSubmitForm} className={disabled ? classes.form : ''}>
      <SimpleGrid cols={2} px={0}>
        <Container mx={0} pl={0}>
          <Card shadow='sm' padding='xl' radius='md' withBorder>
            <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
              <ThemeIcon color='dark.3' size={28}>
                <IconSettings size={17} strokeWidth={3} color={'white'} />
              </ThemeIcon>
              <Text weight={700} size={16} ml={7}>
                Main info
              </Text>
            </Card.Section>

            <TextInput
              mt='sm'
              withAsterisk
              label='Property name'
              placeholder='Name'
              {...form.getInputProps('name')}
              readOnly={disabled}
            />
            <Container mt='sm' p={0}>
              <CompaniesDropdown
                withAsterisk
                {...form.getInputProps('company_id')}
                readOnly={disabled}
                disabled={editingDisabled}
              />
            </Container>

            <Container mt='sm' p={0}>
              <SDMUsersSelect readOnly={disabled} withAsterisk {...form.getInputProps('account_manager_id')} />
            </Container>

            <NumberInput
              hideControls
              mt='sm'
              label='Total units'
              placeholder='Count'
              min={0}
              {...form.getInputProps('details.total_units')}
              readOnly={disabled}
            />
            <DateInput
              clearable
              mt='sm'
              icon={<IconCalendar size='1.1rem' stroke={1.5} />}
              label='Start date'
              placeholder='Start date'
              value={dateOrNull({ ...form.getInputProps('start_date') }.value)}
              onChange={(value) => {
                form.setFieldValue('start_date', value);
              }}
              {...form.getInputProps('start_date')}
              readOnly={disabled}
            />
            <DateInput
              clearable
              mt='sm'
              icon={<IconCalendar size='1.1rem' stroke={1.5} />}
              label='End date'
              placeholder='End date'
              value={dateOrNull({ ...form.getInputProps('end_date') }.value)}
              onChange={(value) => {
                form.setFieldValue('end_date', value);
              }}
              {...form.getInputProps('end_date')}
              readOnly={disabled}
            />
          </Card>

          <Card shadow='sm' padding='xl' radius='md' withBorder mt={20}>
            <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
              <ThemeIcon color='blue' size={28}>
                <IconBrandGoogle size={17} strokeWidth={4} color={'white'} />
              </ThemeIcon>
              <Text weight={700} size={16} ml={7}>
                Google settings
              </Text>
            </Card.Section>
            <TextInput
              mt='sm'
              label='GDS url'
              placeholder='GDS url'
              {...form.getInputProps('details.gds_url')}
              readOnly={disabled}
            />
            <NumberInput
              hideControls
              mt='sm'
              label='Google ADS account ID'
              placeholder='ID'
              min={0}
              {...form.getInputProps('details.google_ads_account_id')}
              readOnly={disabled}
            />
            <NumberInput
              hideControls
              mt='sm'
              label='Google Analytics account ID'
              placeholder='ID'
              min={0}
              {...form.getInputProps('details.google_analytics_account_id')}
              readOnly={disabled}
            />
            <NumberInput
              hideControls
              mt='sm'
              label='Google PPC invoice amount'
              placeholder='Amount'
              min={0}
              {...form.getInputProps('details.google_ppc_invoice_amount')}
              readOnly={disabled}
            />
          </Card>
        </Container>
        <Container mx={0} pr={0}>
          <Card shadow='sm' padding='xl' radius='md' withBorder>
            <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
              <ThemeIcon color='green' size={28}>
                <IconCloudDataConnection size={17} strokeWidth={4} color={'white'} />
              </ThemeIcon>
              <Text weight={700} ml={7}>
                Integrations
              </Text>
            </Card.Section>
            <NumberInput
              hideControls
              mt='sm'
              label='Entrata account ID'
              placeholder='ID'
              min={0}
              rightSection={renderRetryConnectionSection(CONNECTION_TYPE.Entrata)}
              {...form.getInputProps('details.entrata_account_id')}
              readOnly={disabled}
            />
            <NumberInput
              hideControls
              mt='sm'
              label='Callrail account ID'
              placeholder='ID'
              min={0}
              rightSection={renderRetryConnectionSection(CONNECTION_TYPE.Callrail)}
              {...form.getInputProps('details.callrail_account_id')}
              readOnly={disabled}
            />
            <NumberInput
              hideControls
              mt='sm'
              label='Knock account ID'
              placeholder='ID'
              min={0}
              rightSection={renderRetryConnectionSection(CONNECTION_TYPE.Knock)}
              {...form.getInputProps('details.knock_source_id')}
              readOnly={disabled}
            />
            {isFeatureEnabled(FEATURE_FLAGS.FacebookIntegration) && (
              <NumberInput
                hideControls
                mt='sm'
                label='Facebook ADS account ID'
                placeholder='ID'
                min={0}
                rightSection={renderRetryConnectionSection(CONNECTION_TYPE.FacebookAds)}
                {...form.getInputProps('details.facebook_ads_account_id')}
                readOnly={disabled}
              />
            )}
            {isFeatureEnabled(FEATURE_FLAGS.YardiIntegration) && (
              <TextInput
                hideControls
                mt='sm'
                label='Yardi account ID'
                placeholder='ID'
                min={0}
                rightSection={renderRetryConnectionSection(CONNECTION_TYPE.Yardi)}
                {...form.getInputProps('details.yardi_account_id')}
                readOnly={disabled}
              />
            )}
          </Card>
          <Card shadow='sm' padding='xl' radius='md' withBorder mt={20}>
            <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
              <ThemeIcon color='dark.5' size={28}>
                <IconDots size={17} strokeWidth={4} color={'white'} />
              </ThemeIcon>
              <Text weight={700} ml={7}>
                Other settings
              </Text>
            </Card.Section>

            <NumberInput
              hideControls
              mt='sm'
              label='Facebook invoice amount'
              placeholder='Amount'
              min={0}
              {...form.getInputProps('details.facebook_invoice_amount')}
              readOnly={disabled}
            />
            <NumberInput
              hideControls
              mt='sm'
              label='Youtube invoice amount'
              placeholder='Amount'
              min={0}
              {...form.getInputProps('details.youtube_invoice_amount')}
              readOnly={disabled}
            />
            <NumberInput
              hideControls
              mt='sm'
              label='Geofencing invoice amount'
              placeholder='Amount'
              min={0}
              {...form.getInputProps('details.geofencing_invoice_amount')}
              readOnly={disabled}
            />
            <NumberInput
              hideControls
              mt='sm'
              label='Display invoice amount'
              placeholder='Amount'
              min={0}
              {...form.getInputProps('details.display_invoice_amount')}
              readOnly={disabled}
            />
            <DateInput
              clearable
              mt='sm'
              icon={<IconCalendar size='1.1rem' stroke={1.5} />}
              label='Date property cancelled'
              placeholder='Date'
              value={dateOrNull({ ...form.getInputProps('details.date_property_cancelled') }.value)}
              onChange={(value) => {
                form.setFieldValue('details.date_property_cancelled', value);
              }}
              readOnly={disabled}
            />
          </Card>
        </Container>
      </SimpleGrid>
      <Container mx={0} px={0} py='xl' fluid>
        <Card shadow='sm' padding='xl' radius='md' withBorder>
          <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
            <ThemeIcon color='green' size={28}>
              <IconPageBreak size={17} strokeWidth={4} color={'white'} />
            </ThemeIcon>
            <Text weight={700} mx={7}>
              Report Pages
            </Text>
            <IconInfo
              ml='md'
              width={800}
              hint="Customize which pages to display on the Property report. When customization is off, pages are displayed based on property connections. For example, Property Overview will be shown if there's a PMS connection set up, and Website Lead Attribution will be displayed if the Google Analytics identifier is in place."
            />
          </Card.Section>
          <Switch
            py='md'
            label='Customize which report pages to show'
            style={{ opacity: disabled ? 0.4 : 1 }}
            checked={form.getInputProps('enable_manual_report_pages')?.value}
            onChange={disabled ? () => {} : form.getInputProps('enable_manual_report_pages')?.onChange}
          ></Switch>
          {!!form.getInputProps('enable_manual_report_pages').value && (
            <ReportPagesControl readOnly={disabled} {...form.getInputProps('report_pages')} />
          )}
        </Card>
      </Container>

      {!disabled && (
        <Group position='center' my='md'>
          <Button type='submit' w={160} loading={isSaveLoading}>
            Save
          </Button>
        </Group>
      )}
    </form>
  );
}
