import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ActionIcon, Card, Container, LoadingOverlay, Overlay, Title, useMantineTheme } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import IconInfo from '../IconInfo/IconInfo';
import { transformKeysToWordsAndUppercase } from '../../utils/charts.util';

const LINE_BAR_COLORS = ['darkcyan', 'rebeccapurple'];

const createLineBarChart = (transformedKeys, yAxisConfig, colors) => {
  const theme = useMantineTheme();
  return transformedKeys.reverse().map((key, index) => {
    const config = yAxisConfig && yAxisConfig.find((x) => x.key === key);
    if (key === config.key && config.chartType === 'Bar') {
      return (
        <Bar
          key={key}
          yAxisId={config?.position ?? null}
          dataKey={key}
          strokeWidth={2}
          fill={theme.colors?.chart_colors[index] ?? colors[index] ?? 'grey'}
        />
      );
    } else if (key === config.key && config.chartType === 'Line') {
      return (
        <Line
          key={key}
          yAxisId={config?.position ?? null}
          dataKey={key}
          animationDuration={650}
          strokeWidth={2}
          dot={true}
          stroke={theme.colors?.chart_colors[index] ?? LINE_BAR_COLORS[index] ?? 'grey'}
          activeDot={{ r: 5 }}
        />
      );
    }
  });
};

export default function LineBarChartComponent({
  title,
  data,
  index,
  hint,
  isLoading,
  isError,
  onRetry,
  valueFormatter,
  dataFormatter = (x) => x,
  excludeFields = [],
  showLegend = true,
  yAxisConfig = [],
}) {
  const theme = useMantineTheme();
  const { transformed, transformedKeys } = transformKeysToWordsAndUppercase(dataFormatter(data), [
    ...excludeFields,
    index,
  ]);

  const yAxis = yAxisConfig?.length ? (
    yAxisConfig.map((config) => {
      return (
        <YAxis
          key={config.key}
          tickFormatter={config.valueFormatter ?? valueFormatter}
          type='number'
          fontSize={12}
          strokeWidth={0}
          yAxisId={config.position}
          orientation={config.position}
        />
      );
    })
  ) : (
    <YAxis tickFormatter={valueFormatter} type='number' fontSize={12} strokeWidth={0} />
  );

  const LineBarChart = createLineBarChart(transformedKeys, yAxisConfig, LINE_BAR_COLORS) || null;

  return (
    <Card
      p={0}
      style={{
        border: isError && '2px solid red',
        height: 'fit-content',
        boxShadow: '#00000021 1px 2px 1px',
        backgroundColor: theme.colors.secondary_background_color[6],
      }}
    >
      <Container
        fluid
        sx={{
          padding: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `2px ${theme.colors.company_theme_primary_color[7]} solid`,
        }}
      >
        <Title order={5}>{title}</Title>
        {hint && <IconInfo hint={hint} />}
      </Container>
      <ResponsiveContainer width='100%' height={320} style={{ padding: '0 .6rem' }}>
        <ComposedChart
          width={500}
          height={400}
          data={transformed}
          margin={{
            top: 50,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          {showLegend && (
            <Legend type='circle' layout='horizontal' verticalAlign={'bottom'} wrapperStyle={{ bottom: 10 }} />
          )}
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey={index} fontSize={12} />
          {yAxis}
          <Tooltip />
          {LineBarChart}
        </ComposedChart>
      </ResponsiveContainer>
      <LoadingOverlay
        id={`loading_overlay__${title}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'sm', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
      {isError && (
        <Overlay color={theme.colors.retry_error_color[6]} opacity={0.6} center>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
