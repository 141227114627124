import * as React from 'react';
import { InternalPortfolioOverviewContext } from '../../../../Context/PortfolioOverviewContext';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { ActionIcon, Tooltip, Flex, Select, Text, Group } from '@mantine/core';
import {
  IconArrowBackUp,
  IconAsterisk,
  IconDeviceFloppy,
  IconFilterEdit,
  IconLock,
  IconUsersGroup,
} from '@tabler/icons-react';
import FilterModal from '../FilterModal/FilterModal';
import SaveFilterModal from '../SaveFilterModal/SaveFilterModal';
import { useFilterPresetsService } from '../../../../Services/filterPresetsService';
import { notifications } from '@mantine/notifications';
import { updateElByKey } from '../../../../../../../../../utils/array.utils';
import { useDebouncedState } from '@mantine/hooks';

export default function PortfolioOverviewFilters() {
  const {
    filterPresets,
    setFilterPresets,
    selectedMetrics,
    selectedMetricsOrder,
    tableFilters,
    tableSorting,
    selectedFilterPreset,
    setSelectedFilterPreset,
  } = useContext(InternalPortfolioOverviewContext);

  const [filtersOpened, setFiltersOpened] = useState(false);
  const [saveModalOpened, setSaveModalOpened] = useState(false);

  const [tableHasUnsavedChanges, setTableHasUnsavedChanges] = useDebouncedState(false, 200);

  const { get: getFilerPresets, create: createFilterPreset, update: updateFilterPreset } = useFilterPresetsService();

  const formatFilterOptions = (options) =>
    options
      .map((x) => ({
        ...x,
        label: x.name,
        value: x.id,
        group: x.is_public ? 'Public Filters' : 'My Own Filters',
      }))
      .sort((a, b) => a.label?.localeCompare(b.label));

  useEffect(() => {
    const get = async () => {
      const options = await getFilerPresets();
      const formatted = formatFilterOptions(options);
      setFilterPresets(formatted);
      !selectedMetrics?.length && setSelectedFilterPreset(formatted[0]);
    };

    get();
  }, []);

  useEffect(() => {
    let tableChanged = false;

    const { metrics, order, filters, sorting } = selectedFilterPreset?.data ?? {};

    if (selectedMetrics.length !== metrics?.length || selectedMetrics.some((x) => !metrics?.includes(x))) {
      tableChanged = true;
    }

    if (selectedMetricsOrder.join() !== order?.join()) {
      tableChanged = true;
    }

    if (JSON.stringify(tableFilters) !== JSON.stringify(filters)) {
      tableChanged = true;
    }

    if (JSON.stringify(tableSorting) !== JSON.stringify(sorting)) {
      tableChanged = true;
    }

    setTableHasUnsavedChanges(tableChanged);
  }, [selectedMetrics, selectedMetricsOrder, tableFilters, tableSorting, selectedFilterPreset]);

  const toggleFiltersModal = () => {
    setFiltersOpened(!filtersOpened);
  };

  const toggleSaveFilterModal = () => {
    setSaveModalOpened(!saveModalOpened);
  };

  const undoChanges = () => {
    setSelectedFilterPreset({ ...selectedFilterPreset });
  };

  const setFilterPreset = (id) => {
    setSelectedFilterPreset(filterPresets.find((x) => x.value === id));
  };

  const saveFiler = async (mode, name, is_public) => {
    const filter = {
      name,
      is_public,
      data: {
        metrics: selectedMetrics,
        order: selectedMetricsOrder,
        filters: tableFilters,
        sorting: tableSorting,
      },
    };

    let updated;
    try {
      toggleSaveFilterModal();

      let newFilterPresets;
      switch (mode) {
        case 'create':
          updated = await createFilterPreset(filter);
          newFilterPresets = [
            ...filterPresets,
            {
              ...updated,
              value: updated.id,
              label: updated.name,
            },
          ];
          break;
        case 'update':
          updated = await updateFilterPreset(selectedFilterPreset.id, filter);
          newFilterPresets = updateElByKey(
            'id',
            {
              ...updated,
              value: updated.id,
              label: updated.name,
            },
            filterPresets
          );

          break;
      }

      setFilterPresets(formatFilterOptions(newFilterPresets));

      notifications.show({ title: 'Success!', color: 'green', message: 'Changes saved' });
    } catch (e) {
      notifications.show({ title: 'Error!', color: 'red', message: 'Failed to apply changes' });
    }

    setSelectedFilterPreset(updated);
  };

  const getFilterIcon = (is_public) =>
    is_public ? <IconUsersGroup size={'1rem'} color={'grey'} /> : <IconLock size={'1rem'} color={'grey'} />;

  const SelectFilterItem = ({ label, is_public, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {getFilterIcon(is_public)}
        <div>
          <Text size='sm'>{label}</Text>
        </div>
      </Group>
    </div>
  );

  return (
    <Flex gap='xs' align={'center'}>
      <Select
        placeholder={'Filters'}
        itemComponent={forwardRef(SelectFilterItem)}
        label={''}
        value={selectedFilterPreset?.id}
        onChange={setFilterPreset}
        data={filterPresets}
        w={250}
        rightSection={tableHasUnsavedChanges && <IconAsterisk size={'0.6rem'} color={'red'} />}
        icon={getFilterIcon(selectedFilterPreset?.is_public)}
      />
      <Tooltip label='Filters'>
        <ActionIcon>
          <IconFilterEdit onClick={toggleFiltersModal} />
        </ActionIcon>
      </Tooltip>
      <Tooltip label='Save Current Changes'>
        <ActionIcon disabled={!tableHasUnsavedChanges}>
          <IconDeviceFloppy onClick={toggleSaveFilterModal} />
        </ActionIcon>
      </Tooltip>
      <Tooltip label='Undo Changes to Selected Filer'>
        <ActionIcon disabled={!tableHasUnsavedChanges}>
          <IconArrowBackUp onClick={undoChanges} />
        </ActionIcon>
      </Tooltip>

      <FilterModal selectedMetrics={selectedMetrics} opened={filtersOpened} onClose={toggleFiltersModal}>
        <FilterModal.SelectedFields onClose={toggleFiltersModal}></FilterModal.SelectedFields>
        <FilterModal.Search></FilterModal.Search>
        <FilterModal.AvailableFields></FilterModal.AvailableFields>
      </FilterModal>

      {saveModalOpened && (
        <SaveFilterModal
          existingFilter={selectedFilterPreset}
          allFilters={filterPresets}
          opened={saveModalOpened}
          onClose={toggleSaveFilterModal}
          onSave={saveFiler}
        ></SaveFilterModal>
      )}
    </Flex>
  );
}
