import React from 'react';
import dayjs from 'dayjs';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import { useConnectionErrorsService } from '../../../../../../../../services/connectionErrorsService';
import VerticalBarChartComponent from '../../../../../../../../components/VerticalBarChart/VerticalBarChart';

export default function ConnectionTypeErrors({ dates }) {
  const { getConnectionErrorsCountByType } = useConnectionErrorsService();

  return (
    <DataFetcherComponent
      Child={VerticalBarChartComponent}
      childProps={{
        title: 'Errors by Connection Type',
        index: 'connection_type',
        showLegend: false,
      }}
      fetchMethod={getConnectionErrorsCountByType}
      defaultValue={[]}
      deps={[dates]}
      params={{ dates }}
      paramsFormatter={(params) => [
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
