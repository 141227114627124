import { Button, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useVerifiedCompanyUsersService } from '../../../../../../services/verifiedUsersService';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import ConfirmationModal from '../../../../../../components/ConfirmationModal/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
const { deleteVerifiedCompanyUser } = useVerifiedCompanyUsersService();

const onDeleteVerifiedCompanyUser = async (id, verifiedCompanyUser, actions) => {
  try {
    actions.setIsConfirmLoading(true);
    await deleteVerifiedCompanyUser(id);

    notifications.show({
      title: 'Success!',
      message: `${verifiedCompanyUser.verified_user_first_name} ${verifiedCompanyUser.verified_user_last_name} was successfully removed!`,
      color: 'green',
    });

    actions.navigate(`/admin/companies`);
  } catch (error) {
    const { data } = error.response;

    notifications.show({
      title: 'Failed to remove user.',
      message: data.message,
      color: 'red',
    });
  } finally {
    actions.setIsConfirmLoading(false);
    actions.close();
  }
};

export const columns = [
  {
    accessorKey: 'id',
    header: 'ID',
    size: 80,
  },
  {
    accessorKey: 'verified_user_first_name',
    header: 'First name',
    Cell: ({ cell }) => <Text tt='capitalize'>{cell.getValue()}</Text>,
    size: 110,
  },
  {
    accessorKey: 'verified_user_last_name',
    header: 'Last name',
    Cell: ({ cell }) => <Text tt='capitalize'>{cell.getValue()}</Text>,
    size: 110,
  },
  {
    accessorKey: 'verified_user_email',
    header: 'Email',
    Cell: ({ cell }) => <Text>{cell.getValue()}</Text>,
    size: 110,
  },
  {
    accessorKey: 'id',
    header: 'Actions',
    Cell: ({ cell, row }) => {
      const navigate = useNavigate();
      const [opened, { open, close }] = useDisclosure(false);
      const [isConfirmLoading, setIsConfirmLoading] = useState(false);
      const verifiedCompanyUser = row.original;

      return (
        <>
          <ConfirmationModal
            opened={opened}
            actionTitle={`Delete user`}
            title={`Are you sure that you want to delete ${verifiedCompanyUser.verified_user_first_name} ${verifiedCompanyUser.verified_user_last_name}?`}
            onClickModalConfirm={() =>
              onDeleteVerifiedCompanyUser(cell.getValue(), verifiedCompanyUser, {
                setIsConfirmLoading,
                close,
                navigate,
              })
            }
            isConfirmLoading={isConfirmLoading}
            onClose={close}
          />
          <Button color='red' variant='outline' w={160} onClick={() => open()}>
            Delete
          </Button>
        </>
      );
    },
    size: 110,
  },
];

export const initialState = {
  sorting: [{ id: 'name', desc: false }],
};
