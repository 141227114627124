import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { useBiPropertyBoxScoreService } from '../../../../../../../services/bi/property/overview/PropertyBoxScoreService';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';

export default function ThirtyDaysExpirationsMetric() {
  const { getThirtyDaysExpirations } = useBiPropertyBoxScoreService();

  const { property } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: '30-Day Expirations',
        hint: 'The number of leases currently set to expire (not renew) within the next 30-day period.',
        showTrend: false,
      }}
      fetchMethod={getThirtyDaysExpirations}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property]}
      params={{ property }}
      paramsValidator={(params) => params.property}
      paramsFormatter={(params) => [params.property.value]}
    />
  );
}
