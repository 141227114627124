import { axiosInstance } from '../../../../../../services/axiosService';

const API_PATH = '/admin/portfolio-overview/filter-presets';

export const useFilterPresetsService = () => {
  const get = async () => {
    const response = await axiosInstance.get(API_PATH);

    return response?.data;
  };

  const create = async (filter) => {
    const response = await axiosInstance.post(API_PATH, filter);

    return response?.data;
  };

  const update = async (id, filter) => {
    const response = await axiosInstance.patch(`${API_PATH}/${id}`, filter);

    return response?.data;
  };

  return {
    get,
    create,
    update,
  };
};
