import React, { useContext, useEffect, useState } from 'react';
import { Group, Flex, useMantineTheme, MultiSelect, Input } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { PortfolioOverviewFiltersContext } from '../../../../context/PortfolioOverviewFiltersContext';
import { IconCalendar, IconSearch } from '@tabler/icons-react';

export default function PortfolioOverviewHeader() {
  const {
    tableColumnsData,
    setTableColumnsData,
    dates,
    setDates,
    onChangePropertySearch,
    portfolioOverviewColumns,
    setPortfolioOverviewColumns,
    prevPropertySearch,
  } = useContext(PortfolioOverviewFiltersContext);
  const { colors } = useMantineTheme();
  const [datePickerValue, setDatePickerValue] = useState(dates);
  const mapColumns = tableColumnsData?.filter((c) => c.columnName != 'property').map((c) => c.column.header);

  useEffect(() => {
    const filtered = tableColumnsData.filter((c) => c.show);
    const headers = filtered.map((c) => c.column.header);
    const order = filtered.map((c) => c.columnName);
    const selectedProperty = prevPropertySearch;
    const updatedPortfolioOverviewColumns = {
      ...portfolioOverviewColumns,
      order,
      headers,
      selectedProperty,
    };

    setPortfolioOverviewColumns(updatedPortfolioOverviewColumns);
  }, [tableColumnsData, prevPropertySearch]);

  const onChangeDates = (value) => {
    setDatePickerValue(value);
    if (value.length && value.every((el) => !!el)) {
      setDates(value);
    }
  };

  const onChangeSearch = (e) => {
    onChangePropertySearch(e.target.value);
  };

  return (
    <Group
      m='md'
      grow
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 85,
        margin: 0,
        paddingTop: '1.25rem',
        paddingBottom: '1.25rem',
      }}
    >
      <Flex justify='space-between'>
        <Input
          defaultValue={portfolioOverviewColumns?.selectedProperty}
          placeholder='Search'
          w={250}
          icon={<IconSearch size='1.3em' />}
          onChange={onChangeSearch}
        />
        <Group>
          <MultiSelect
            multiple
            searchable
            clearable
            mr={10}
            value={portfolioOverviewColumns?.headers}
            placeholder='Data fields'
            variant={'default'}
            onChange={(values) => {
              if (!values.length) return setTableColumnsData(tableColumnsData.map((c) => ({ ...c, show: true })));
              setTableColumnsData(
                tableColumnsData.map((c) =>
                  !values.includes(c.column.header) && c.columnName != 'property'
                    ? { ...c, show: false }
                    : { ...c, show: true }
                )
              );
            }}
            data={mapColumns}
            styles={() => ({
              input: { maxHeight: 30, overflowY: 'auto', width: 350 },
              dropdown: {
                backgroundColor: colors.secondary_background_color[6],
              },
            })}
            comboboxProps={{ transitionProps: { transition: 'pop', duration: 200 } }}
          />
          <DatePickerInput
            valueFormat='MM/DD/YYYY'
            type='range'
            placeholder='Pick dates range'
            icon={<IconCalendar size='1.3em' />}
            variant={'default'}
            value={datePickerValue}
            onChange={onChangeDates}
            w={250}
          />
        </Group>
      </Flex>
    </Group>
  );
}
