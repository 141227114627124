import { USER_ROLES_MAP } from '../constants/user';
import { axiosInstance } from './axiosService';

const API_PATH = '/admin/users';

export const useUserService = () => {
  const createUser = async (user, role) => {
    const response = await axiosInstance.post(`${API_PATH}/${USER_ROLES_MAP[role]}`, user);

    return response?.data;
  };

  const confirmEmail = async (email, role) => {
    const response = await axiosInstance.post(`${API_PATH}/${USER_ROLES_MAP[role]}/confirm-email`, {
      email,
    });

    return response?.data;
  };

  const resendConfirmationEmail = async (email, role) => {
    const response = await axiosInstance.post(`${API_PATH}/${USER_ROLES_MAP[role]}/resend-confirmation-email`, {
      email,
    });

    return response?.data;
  };

  const updateUser = async (id, user, role) => {
    const response = await axiosInstance.patch(`${API_PATH}/${USER_ROLES_MAP[role]}/${id}`, user);

    return response?.data;
  };

  const get = async () => {
    const response = await axiosInstance.get(API_PATH);

    return response?.data;
  };

  const getUserById = async (id) => {
    const response = await axiosInstance.get(`${API_PATH}/${id}`);

    return response?.data;
  };

  const getInternalActiveUsers = async () => {
    const response = await axiosInstance.get(`${API_PATH}/internal-active`);

    return response?.data;
  };

  const enableUser = async (id, role) => {
    const response = await axiosInstance.patch(`${API_PATH}/${USER_ROLES_MAP[role]}/enable/${id}`);

    return response?.data;
  };

  const disableUser = async (id, role) => {
    const response = await axiosInstance.patch(`${API_PATH}/${USER_ROLES_MAP[role]}/disable/${id}`);

    return response?.data;
  };

  const getUserPreviewModeAccess = async (id, role) => {
    const response = await axiosInstance.get(`${API_PATH}/${USER_ROLES_MAP[role]}/user-preview-mode-access/${id}`);

    return response?.data;
  };

  return {
    createUser,
    confirmEmail,
    resendConfirmationEmail,
    updateUser,
    get,
    getUserById,
    enableUser,
    disableUser,
    getUserPreviewModeAccess,
    getInternalActiveUsers,
  };
};
