import React, { useState } from 'react';
import { Container, SimpleGrid } from '@mantine/core';
import * as dayjs from 'dayjs';
import SystemHealthHeader from './Components/Header/SystemHealthHeader';
import ConnectionTypeErrors from './Components/Charts/ConnectionTypeErrors/ConnectionTypeErrors';
import ConnectionErrorsByDate from './Components/Charts/ConnectionTypeErrors/ConnectionErrorsByDate';
import ConnectionErrorsTable from './Components/Tables/ConnectionErrorsTable';
import ConnectionErrorPatterns from './Components/Tables/ConnectionErrorPatterns/ConnectionErrorPatterns';
import BrokenConnectionByDate from './Components/Charts/ConnectionTypeErrors/BrokenConnectionsByDate';

export default function AdminSystemHealth() {
  const startDate = dayjs().subtract(7, 'day');
  const endDate = dayjs();

  const [datePickerValue, setDatePickerValue] = useState([startDate, endDate]);
  const [dates, setDates] = useState([startDate, endDate]);

  const onChangeDates = (value) => {
    setDatePickerValue(value);
    if (value.length && value.every((el) => !!el)) {
      setDates(value);
    }
  };

  return (
    <Container fluid px={30} pb={30}>
      <SystemHealthHeader dates={datePickerValue} onChangeDates={onChangeDates} />
      <SimpleGrid mb='md' cols={2} breakpoints={[{ maxWidth: 'lg', cols: 1, spacing: 'sm' }]}>
        <ConnectionTypeErrors dates={dates} />
        <ConnectionErrorsByDate dates={dates} />
        <ConnectionErrorPatterns dates={dates} />
        <BrokenConnectionByDate dates={dates} />
      </SimpleGrid>
      <ConnectionErrorsTable dates={dates} />
    </Container>
  );
}
