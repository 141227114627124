import React, { useState } from 'react';

import { PasswordInput, Title, Container, Button, Text, Group, Box, createStyles, Center } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuthService } from '../../services/authService';
import { notifications } from '@mantine/notifications';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import VideoBackground from '../../components/VideoBackgound/VideoBackground';

const useStyles = createStyles(() => {
  return {
    control: {
      padding: '10px',
      color: 'white',
      backgroundColor: 'rgba(154,154,154,0.75)',
      '::placeholder': {
        color: 'white',
      },
      '&:focus': {
        backgroundColor: 'rgba(154,154,154,0.75)',
        outline: 'none',
        border: 'none',
      },
    },
  };
});

export default function EmailConfirmation() {
  const authService = useAuthService();
  const { token } = useParams();

  const { data } = useLoaderData();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    initialValues: {
      password: '',
      repeatPassword: '',
    },

    validate: {
      password: (value) => (value.length < 8 ? 'Has at least 8 characters' : null),
      repeatPassword: (value, values) => (value !== values.password ? 'Passwords did not match' : null),
    },
  });

  const submitForm = async ({ password }) => {
    try {
      setIsLoading(true);
      await authService.setUserPassword({ token, password });
      notifications.show({
        title: 'Success!',
        message: `Password was successfully updated!`,
        color: 'green',
      });
      navigate('/auth');
    } catch (e) {
      notifications.show({
        title: 'An Error Occurred',
        message: e.message,
        color: 'red',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { classes } = useStyles();

  const styles = {
    ['.form-input']: {
      background: 'none',
    },
    ['&: .mantine-PasswordInput-innerInput']: {
      background: 'none',
    },
    ['& .mantine-PasswordInput-rightSection']: {
      padding: '10px',
      border: '1px solid #ccc',
      borderLeft: 'none',
      color: 'white',
      borderRadius: '0 7px 7px 0',
      backgroundColor: 'rgba(154,154,154,0.75)',
    },
    ['& .mantine-PasswordInput-visibilityToggle']: {
      color: 'white',
      '&:hover': {
        background: 'none',
      },
    },
  };

  return (
    <Box>
      <Container size={620} my={250}>
        <Center mb={'4.5rem'}>
          <img src='https://storage.googleapis.com/sdm-image-uploads/SDM_LOGO_WHITE.png' alt='SDM logo' width={450} />
        </Center>
        <Title
          align='center'
          sx={(theme) => ({
            fontFamily: `${theme.fontFamily}`,
            fontWeight: 900,
            color: 'white',
            letterSpacing: '3px',
          })}
        >
          Welcome!
        </Title>

        <Text
          sx={(theme) => ({
            fontFamily: `${theme.fontFamily}`,
            color: 'white',
            fontWeight: 500,
            letterSpacing: '1px',
          })}
          align={'center'}
        >
          Please set password for{' '}
          <Text span inline fw={800}>
            {data.userEmail}{' '}
          </Text>
          to activate your account.
        </Text>
        <form onSubmit={form.onSubmit((values) => submitForm(values))}>
          <PasswordInput
            radius='md'
            size='lg'
            placeholder='Enter Your password'
            required
            classNames={{ input: 'form-input' }}
            mt='md'
            sx={{
              input: classes.control,
              wrapper: { marginBottom: 20 },
              borderRadius: '7px',
              ...styles,
            }}
            {...form.getInputProps('password')}
          />
          <Group size={620} my='sm' sx={{ display: 'flex', alignItems: 'center', justifyItems: 'space-between' }}>
            <PasswordInput
              radius='md'
              size='lg'
              placeholder='Repeat Your password'
              required
              classNames={{ input: 'form-input' }}
              mt='md'
              w={400}
              sx={{
                input: classes.control,
                wrapper: { marginBottom: 20 },
                borderRadius: '7px',
                ...styles,
              }}
              {...form.getInputProps('repeatPassword')}
            />
            <Button
              size='lg'
              radius='md'
              color='light'
              variant='light'
              type='submit'
              mt='md'
              w={170}
              sx={{
                input: classes.control,
                wrapper: { marginBottom: 20 },
                color: 'black',
              }}
              loading={isLoading}
            >
              Confirm
            </Button>
          </Group>
        </form>
      </Container>
      <VideoBackground styles={{ position: 'absolute', top: 0 }} />
    </Box>
  );
}

export const loader = async ({ params }) => {
  const { getTokenInfo } = useAuthService();
  return await getTokenInfo(params?.token);
};
