import React, { useRef } from 'react';
import { ActionIcon, Card, Container, LoadingOverlay, Overlay, Title, useMantineTheme } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { transformKeysToWordsAndUppercase } from '../../utils/charts.util';
import IconInfo from '../IconInfo/IconInfo';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const BAR_COLORS = ['darkcyan', 'rebeccapurple'];

const CustomYAxisTick = ({ x, y, payload }) => {
  const MAX_WIDTH = 130;

  return (
    <foreignObject x={x - MAX_WIDTH} y={y - 10} width={MAX_WIDTH} height={20}>
      <div
        style={{
          width: MAX_WIDTH,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontSize: '12px',
          textAlign: 'right',
        }}
      >
        {payload.value}
      </div>
    </foreignObject>
  );
};

export default function VerticalBarChartComponent({
  title,
  data,
  index,
  hint,
  isLoading,
  isError,
  onRetry,
  dataFormatter = (x) => x,
  excludeFields = [],
  colors = BAR_COLORS,
  showLegend = true,
  interval = 0,
  maxAmountOfRows = 10,
}) {
  const containerRef = useRef(null);

  const theme = useMantineTheme();

  const { transformed, transformedKeys } = transformKeysToWordsAndUppercase(
    dataFormatter(data.slice(0, maxAmountOfRows)),
    [...excludeFields, index]
  );

  return (
    <Card
      p={0}
      style={{
        border: isError && '2px solid red',
        height: 'fit-content',
        boxShadow: '#00000021 1px 2px 1px',
        backgroundColor: theme.colors.secondary_background_color[6],
      }}
    >
      <Container
        fluid
        sx={{
          padding: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `2px ${theme.colors.company_theme_primary_color[7]} solid`,
        }}
      >
        <Title order={5}>{title}</Title>
        {hint && <IconInfo hint={hint} />}
      </Container>
      <ResponsiveContainer ref={containerRef} width='100%' height={320} style={{ padding: '0' }}>
        <ComposedChart
          data={transformed}
          layout={'vertical'}
          margin={{
            top: 30,
            right: 50,
            left: 10,
            bottom: 15,
          }}
          padding={0}
        >
          {showLegend && <Legend type='circle' align={'right'} wrapperStyle={{ top: 10, right: 20 }} />}
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis type='number' fontSize={12} strokeWidth={0} />
          <YAxis
            interval={interval}
            dataKey={index}
            type='category'
            fontSize={12}
            tick={<CustomYAxisTick />}
            width={150}
          />
          <Tooltip />
          {transformedKeys.map((key, index) => (
            <Bar
              key={key}
              dataKey={key}
              animationDuration={650}
              strokeWidth={2}
              fill={theme.colors?.chart_colors[index] ?? colors[index] ?? 'grey'}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
      <LoadingOverlay
        id={`loading_overlay__${title}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'sm', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
      {isError && (
        <Overlay color={theme.colors.retry_error_color[6]} opacity={0.6} center>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
