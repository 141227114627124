import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/marketing/social-media-advertising';

export const useSocialMediaAdvertising = () => {
  const { axios } = useAxios();

  const getImpressions = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/impressions`, { ...config, params });

    return response?.data;
  };

  const getLinkClicks = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/link-clicks`, { ...config, params });

    return response?.data;
  };

  const getPageViews = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/page-views`, { ...config, params });

    return response?.data;
  };

  const getCpcAll = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/cpc-all`, { ...config, params });

    return response?.data;
  };

  const getCtrAll = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/ctr-all`, { ...config, params });

    return response?.data;
  };

  const getFrequency = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/frequency`, { ...config, params });

    return response?.data;
  };

  const getCostPerClickAndClicks = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/cost-per-click-and-clicks`, { ...config, params });

    return response?.data;
  };

  const getLandingPageViews = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/landing-page-views`, { ...config, params });

    return response?.data;
  };
  const getReachByDevice = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/reach-by-device`, { ...config, params });

    return response?.data;
  };
  const getReachByPlatform = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/reach-by-platform`, { ...config, params });

    return response?.data;
  };
  const getReachByDate = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/reach-by-date`, { ...config, params });

    return response?.data;
  };

  return {
    getImpressions,
    getLinkClicks,
    getPageViews,
    getCpcAll,
    getCtrAll,
    getFrequency,
    getCostPerClickAndClicks,
    getLandingPageViews,
    getReachByDevice,
    getReachByPlatform,
    getReachByDate,
  };
};
