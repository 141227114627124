import { renderTableHeader } from '../Builder/HeaderBuilder';
import { useMantineTheme } from '@mantine/core';
import { Link } from 'react-router-dom';
import React from 'react';
import { FilterFunctions, FilterOptionSections } from './filterOptionsModel';

const compareFilterValues = (value, filterValue, operator) => {
  const valueNumber = parseFloat(value);
  const filterValueNumber = parseFloat(filterValue);

  if (isNaN(valueNumber) || isNaN(filterValueNumber)) return false;

  const operators = {
    greater: (a, b) => a > b,
    less: (a, b) => a < b,
    greaterEquals: (a, b) => a >= b,
    lessEquals: (a, b) => a <= b,
    equals: (a, b) => a === b,
  };

  if (!(operator in operators)) {
    console.error(`There is no such filter as: ${operator}`);
    return false;
  }

  return operators[operator](Number(valueNumber.toFixed(1)), Number(filterValueNumber.toFixed(1)));
};

export const tableOptions = {
  columns: [
    {
      accessorKey: 'property',
      header: 'Property',
      minSize: 250,
      enableColumnOrdering: false,
      accessorFn: (row) => row.name,
      enableColumnFilterModes: false,
      filterVariant: 'multi-select',
      mantineFilterMultiSelectProps: ({ table }) => {
        const { properties, columnFilters } = table.getState();

        const data = properties
          .map((property) => ({ value: property.name, label: property.name }))
          .sort((a, b) => a.label.localeCompare(b.label));

        // Resets the filter when property options change
        const propertyFilter = columnFilters.find((x) => x.id === 'property');
        if (propertyFilter?.value?.length && propertyFilter.value.some((pf) => !data.find((d) => d.value === pf))) {
          table.setColumnFilters(columnFilters.filter((x) => x.id !== 'property'));
        }

        return {
          data,
          dropdownComponent: 'div',
          disableSelectedItemFiltering: true,
          styles: {
            input: {
              maxHeight: 30,
              overflowY: 'auto',
            },
          },
        };
      },
      Cell: ({ row, table }) => {
        const { colors } = useMantineTheme();

        return (
          <Link
            onClick={() => {
              table?.getState()?.onPropertyClicked(row.original?.property);
            }}
            sx={{ lineHeight: 1 }}
            fw={600}
            mr={3}
            style={{ color: colors.link_color[6] }}
          >
            {row.original?.name}
          </Link>
        );
      },
    },
  ],
  renderTopToolbar: renderTableHeader,
  initialState: {
    showGlobalFilter: true,
    sorting: [{ id: 'property', asc: true }],
    columnPinning: { left: ['property'] },
    pagination: { pageSize: 15 },
  },
  state: {
    loading: false,
  },
  filterFns: {
    [FilterFunctions[FilterOptionSections.Comparison].Equals.functionName]: (row, id, filterValue) =>
      compareFilterValues(row.original[id]?.percentage_change, filterValue, 'equals'),
    [FilterFunctions[FilterOptionSections.Comparison].LessThan.functionName]: (row, id, filterValue) =>
      compareFilterValues(row.original[id]?.percentage_change, filterValue, 'less'),
    [FilterFunctions[FilterOptionSections.Comparison].LessThanOrEquals.functionName]: (row, id, filterValue) =>
      compareFilterValues(row.original[id]?.percentage_change, filterValue, 'lessEquals'),
    [FilterFunctions[FilterOptionSections.Comparison].GreaterThan.functionName]: (row, id, filterValue) =>
      compareFilterValues(row.original[id]?.percentage_change, filterValue, 'greater'),
    [FilterFunctions[FilterOptionSections.Comparison].GreaterThanOrEquals.functionName]: (row, id, filterValue) =>
      compareFilterValues(row.original[id]?.percentage_change, filterValue, 'greaterEquals'),
  },
  localization: Object.fromEntries(
    Object.keys(FilterFunctions[FilterOptionSections.Comparison]).map((key) => {
      const { localization, title } = FilterFunctions[FilterOptionSections.Comparison][key];
      return [localization, [`(Comparison) ${title}`]];
    })
  ),
  enableStickyHeader: true,
  enableFullScreenToggle: true,
  enableStickyFooter: true,
  enableHiding: false,
  enableColumnOrdering: true,
  enableColumnFilterModes: true,
  enableColumnFilters: true,
  enableColumnActions: true,
  mantineTableProps: {
    withColumnBorders: true,
  },
  mantineTableHeadCellProps: ({ column }) => {
    return {
      style: {
        lineHeight: '.85rem',
        paddingLeft: '1rem',
      },
      align: column.id === 'property' ? 'left' : 'center',
    };
  },
  mantinePaperProps: ({ table }) => {
    return { style: { zIndex: table.getState()?.isFullScreen ? 300 : 1, height: '100%' } };
  },
  mantineTableContainerProps: { sx: { height: 'calc(100vh - 300px)' } },
  mantineSearchTextInputProps: { style: { width: 250 } },
};
