import { axiosInstance } from './axiosService';

const API_PATH = '/admin/companies';

export const useCompanyService = () => {
  const get = async () => {
    const response = await axiosInstance.get(API_PATH);

    return response?.data;
  };

  const getNames = async (account_manager_id, config = {}) => {
    const response = await axiosInstance.get(`${API_PATH}/names`, { ...config, params: { account_manager_id } });

    return response?.data;
  };

  const getCompanyById = async (id) => {
    const response = await axiosInstance.get(`${API_PATH}/${id}`);

    return response?.data;
  };

  const createCompany = async (company) => {
    const response = await axiosInstance.post(API_PATH, company);

    return response?.data;
  };

  const updateCompany = async (id, company) => {
    const response = await axiosInstance.patch(`${API_PATH}/${id}`, company);

    return response?.data;
  };

  const enableCompany = async (id) => {
    const response = await axiosInstance.patch(`${API_PATH}/enable/${id}`);

    return response?.data;
  };

  const disableCompany = async (id) => {
    const response = await axiosInstance.patch(`${API_PATH}/disable/${id}`);

    return response?.data;
  };

  return {
    get,
    getNames,
    getCompanyById,
    createCompany,
    updateCompany,
    enableCompany,
    disableCompany,
  };
};
