import dayjs from 'dayjs';
import React, { useState } from 'react';

import { useScheduledReportsService } from '../../../../../services/scheduledReportsService';
import { Box, Button, Indicator, Switch, Text, Title, useMantineTheme } from '@mantine/core';
import { FORM_MODES } from '../../../../../constants/form_modes';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import AdminScheduledReportForm from './forms/AdminScheduledReportForm';
import { IconArrowLeft, IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import {
  SCHEDULED_REPORTS_MODAL_MAP,
  SCHEDULED_REPORTS_STATUS,
  SCHEDULED_REPORTS_STATUS_INDICATOR_MAP,
} from './constants/scheduledReports';
import { PROPERTY_STATUS } from '../../../../../constants/propertyStatus';
import { useDisclosure } from '@mantine/hooks';
import ConfirmationModal from '../../../../../components/ConfirmationModal/ConfirmationModal';
import { notifications } from '@mantine/notifications';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  columns as relatedUsersColumns,
  initialState as relatedUsersInitialState,
} from '../../Shared/relatedUsersTableDef';
import {
  columns as logsColumns,
  initialState as logsInitialState,
  detailPanel as logDetailPanel,
} from './constants/scheduledReportLogsTableDef';
import ReportOneTimeDateRange from './components/ReportOneTimeDateRange';
import ReportInfo from '../../../../../components/ReportInfo/ReportInfo';

export default function AdminViewScheduledReport() {
  const scheduledReport = useRouteLoaderData('scheduled-report-details');
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [testReportConfirmationOpened, { open: openTestReportConfirmation, close: closeTestReportConfirmation }] =
    useDisclosure(false);
  const [sendReportConfirmationOpened, { open: openSendReportConfirmation, close: closeSendReportConfirmation }] =
    useDisclosure(false);

  const [customDateRange, setCustomDateRange] = useState([]);

  const scheduledReportsService = useScheduledReportsService();
  const [initialData, setInitialData] = useState(scheduledReport);

  const [checked, setChecked] = useState(initialData?.status !== SCHEDULED_REPORTS_STATUS.DISABLED);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const relatedUsersTable = useMantineReactTable({
    columns: relatedUsersColumns,
    initialState: relatedUsersInitialState,
    data: initialData.users ?? [],
    enableTopToolbar: false,
  });

  const logsTable = useMantineReactTable({
    columns: logsColumns,
    initialState: logsInitialState,
    renderDetailPanel: logDetailPanel,
    data: initialData.logs ?? [],
    enableTopToolbar: true,
    enableExpanding: true,
  });

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickEditScheduledReport = () => {
    navigate('edit');
  };

  const onChangeScheduledReportStatus = () => {
    open();
  };

  const modalLabel = SCHEDULED_REPORTS_MODAL_MAP[initialData?.status];
  const indicatorColor = SCHEDULED_REPORTS_STATUS_INDICATOR_MAP[initialData?.status];

  const onClickModalConfirm = async () => {
    try {
      setIsConfirmLoading(true);
      const savedReport = checked
        ? await scheduledReportsService.disableScheduledReport(initialData?.id)
        : await scheduledReportsService.enableScheduledReport(initialData?.id);

      checked && initialData?.status === SCHEDULED_REPORTS_STATUS.DISABLED && onClickBack();

      setInitialData(savedReport);

      setChecked(!checked);
      notifications.show({
        title: 'Success!',
        message: `${initialData?.report_name} report was successfully ${
          savedReport?.status === SCHEDULED_REPORTS_STATUS.ACTIVE ? 'enabled' : 'disabled'
        }!`,
        color: 'green',
      });
    } catch (e) {
      notifications.show({
        title: 'An Error Occurred',
        message: e.message,
        color: 'red',
      });
    } finally {
      setIsConfirmLoading(false);
      close();
    }
  };

  const onReportSendingActionConfirmed = async (action, onPerformed) => {
    try {
      setIsConfirmLoading(true);

      const dateFrom = customDateRange.length ? dayjs(customDateRange[0]).format('YYYY-MM-DD') : null;
      const dateTo = customDateRange.length ? dayjs(customDateRange[1]).format('YYYY-MM-DD') : null;

      await scheduledReportsService.performScheduledReportSendingAction(initialData.id, action, dateFrom, dateTo);

      notifications.show({
        title: 'Success!',
        message: `Scheduled report was successfully enqueued`,
        color: 'green',
      });
    } catch (error) {
      notifications.show({
        title: 'An Error Occurred',
        message: error.message,
        color: 'red',
      });
    } finally {
      setIsConfirmLoading(false);
      onPerformed();
    }
  };

  const customDatesComponentProps = { mt: 'sm', mb: 'sm', onChange: setCustomDateRange };

  return (
    <Box mx='auto' mt='md' px={30}>
      <ConfirmationModal
        opened={opened}
        actionTitle={`${modalLabel} scheduled report`}
        title={`Are you sure to ${modalLabel} report ${initialData?.report_name}?`}
        subtitle={
          initialData?.status === SCHEDULED_REPORTS_STATUS.ACTIVE && (
            <Text fz={'sm'} mt={7}>{`This action will disable report`}</Text>
          )
        }
        onClickModalConfirm={onClickModalConfirm}
        isConfirmLoading={isConfirmLoading}
        onClose={close}
      />

      <ConfirmationModal
        opened={testReportConfirmationOpened}
        actionTitle={`Test Report`}
        title={`Once confirmed, this report will be prepared and sent on your email address.`}
        onClickModalConfirm={() => onReportSendingActionConfirmed('test', closeTestReportConfirmation)}
        isConfirmLoading={isConfirmLoading}
        onClose={closeTestReportConfirmation}
        Child={ReportOneTimeDateRange}
        childProps={customDatesComponentProps}
      />
      <ConfirmationModal
        opened={sendReportConfirmationOpened}
        actionTitle={`Send Report`}
        title={`Once confirmed, this report will be prepared and sent to all active recipients.`}
        onClickModalConfirm={() => onReportSendingActionConfirmed('send', closeSendReportConfirmation)}
        isConfirmLoading={isConfirmLoading}
        onClose={closeSendReportConfirmation}
        Child={ReportOneTimeDateRange}
        childProps={customDatesComponentProps}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Indicator color={indicatorColor || 'green.6'}>
            <Title order={2} mb={20} pr={7} align='left'>
              {initialData?.report_name}
            </Title>
          </Indicator>
          {initialData?.status !== SCHEDULED_REPORTS_STATUS.DISABLED && (
            <Button
              variant='white'
              sx={{ backgroundColor: 'transparent' }}
              onClick={onClickEditScheduledReport}
              ml={17}
              px={6}
            >
              <IconEdit size={30} color='gray' variant='default' />
            </Button>
          )}
          {initialData?.property_status === PROPERTY_STATUS.ACTIVE && (
            <Box
              sx={({ colors }) => ({
                '.mantine-Switch-track': {
                  background: colors.red[6],
                  borderColor: colors.red[6],
                  cursor: 'pointer',
                },
                '.mantine-Switch-thumb': {
                  borderColor: 'white',
                },
              })}
            >
              <Switch
                checked={checked}
                onChange={(event) => onChangeScheduledReportStatus(event.currentTarget.checked)}
                color={'teal'}
                size='md'
                pt={6}
                ml={20}
                thumbIcon={
                  checked ? (
                    <IconCheck size='0.8rem' color={theme.colors.teal[theme.fn.primaryShade()]} stroke={3} />
                  ) : (
                    <IconX size='0.8rem' color={theme.colors.red[theme.fn.primaryShade()]} stroke={3} />
                  )
                }
              />
            </Box>
          )}
          {initialData?.status === SCHEDULED_REPORTS_STATUS.ACTIVE && (
            <>
              <Button color={'yellow'} variant={'outline'} onClick={openTestReportConfirmation} ml={20}>
                Test Report
              </Button>

              <Button color={'red'} variant={'outline'} onClick={openSendReportConfirmation} ml={20}>
                Send Now
              </Button>
            </>
          )}
        </div>
        <Button color='gray' variant='default' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Go Back
        </Button>
      </div>

      <ReportInfo report={scheduledReport} />

      <AdminScheduledReportForm mode={FORM_MODES.View} initialValues={initialData}></AdminScheduledReportForm>

      <Title order={3} mt={20} mb={20} align='left'>
        Report History
      </Title>
      <MantineReactTable table={logsTable} />

      <Title order={3} mt={20} mb={20} align='left'>
        Related Users
      </Title>
      <MantineReactTable table={relatedUsersTable} />
    </Box>
  );
}

export const loader = async ({ params }) => {
  const { getScheduledReportById } = useScheduledReportsService();
  return await getScheduledReportById(Number(params.id));
};
