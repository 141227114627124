import React from 'react';
import {
  ActionIcon,
  LoadingOverlay,
  Overlay,
  Title,
  Text,
  Card,
  Flex,
  useMantineTheme,
  createStyles,
} from '@mantine/core';
import { IconInfoCircle, IconRefresh } from '@tabler/icons-react';
import { transformKeysToWordsAndUppercase } from '../../utils/charts.util';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import Info from '../Info/Info';

const formatIndexName = (index) =>
  index
    .split('_')
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');

const PIE_COLORS = ['#fd7f6f', '#7eb0d5', '#b2e061', '#bd7ebe', '#ffb55a', '#ffee65', '#beb9db', '#fdcce5', '#8bd3c7'];

const useStyles = createStyles({
  recharts: {
    '.recharts-legend-wrapper .recharts-legend-item': {
      'margin-right': '0!important',
    },
    '.recharts-surface': {
      overflow: 'visible',
    },
  },
});

export default function PieChartComponent({
  title,
  data,
  index,
  hint,
  isLoading,
  isError,
  onRetry,
  customTooltipValueFormatter,
  dataFormatter = (x) => x,
  excludeFields = [],
}) {
  const { colors } = useMantineTheme();
  const { classes } = useStyles();

  const chartColors = colors?.chart_colors.concat(PIE_COLORS);

  const sortedDataByPercentage = dataFormatter(data).sort((a, b) => b.percentage - a.percentage);

  const { transformed, transformedKeys } = transformKeysToWordsAndUppercase(sortedDataByPercentage, [
    ...excludeFields,
    index,
  ]);

  const CustomTooltip = ({ payload, active }) => {
    if (!active || !payload) return null;
    const categoryPayload = payload?.[0];
    if (!categoryPayload) return null;

    return (
      <Card>
        <Flex>
          <Text size='sm'>{categoryPayload.payload[index]}</Text>
          <Text size='sm' ml='lg' fw={700}>
            {customTooltipValueFormatter(categoryPayload)}
          </Text>
        </Flex>
      </Card>
    );
  };

  return (
    <Card
      radius='lg'
      p='1.5rem .5rem 1.5rem 1.5rem'
      sx={{
        border: '1px solid ' + colors.border_color[0],
        height: 'fit-content',
        backgroundColor: colors.primary_background[0],
      }}
    >
      <Flex sx={{ justifyContent: 'space-between' }} mr='md'>
        <Title size={20} fw='600'>
          {title}
        </Title>
        {hint && (
          <Info label={hint} w={200}>
            <IconInfoCircle size={20} color={colors.simple_icon_colors[0]} />
          </Info>
        )}
      </Flex>

      <ResponsiveContainer width='100%' height={320} className={classes.recharts}>
        <PieChart>
          <Legend
            formatter={(value, { payload }) => (
              <span style={{ color: colors.text_chart_legend[0], fontSize: '12px' }}>
                {value} - <span style={{ fontWeight: '600' }}>{Number(payload.percent * 100).toFixed()}%</span>
              </span>
            )}
            wrapperStyle={{ width: '45%', fontSize: '.75rem', padding: '.5rem 0', 'overflow-wrap': 'break-word' }}
            layout='vetical'
            iconType='plainline'
            verticalAlign='middle'
            align={'right'}
          />
          <Tooltip position={{ x: 0 }} content={<CustomTooltip />} />
          <Pie
            data={transformed}
            cx='25%'
            stroke='none'
            cy='50%'
            animationDuration={650}
            innerRadius='35%'
            outerRadius='55%'
            dataKey={transformedKeys[0]}
          >
            <Label value={formatIndexName(index)} fontWeight='500' fill={colors.text_color[4]} fontSize='.7rem' position='center' />

            {transformed.map((entry, i) => (
              <Cell style={{ outline: 'none' }} name={entry[index]} key={`cell-${i}`} fill={chartColors[i]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      <LoadingOverlay
        id={`loading_overlay__${title}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'lg', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
      {isError && (
        <Overlay color={colors.retry_error_color[6]} opacity={0.6} center zIndex={10}>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
