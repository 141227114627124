import * as React from 'react';
import SDMUsersSelect from '../../../../../../../../../components/SDMUsersSelect/SDMUsersSelect';
import { InternalPortfolioOverviewContext } from '../../../../Context/PortfolioOverviewContext';
import { useContext } from 'react';

export default function AccountManagerSelection() {
  const { accountManager, setAccountManager } = useContext(InternalPortfolioOverviewContext);

  return (
    <SDMUsersSelect
      placeholder={'Account Manager'}
      label={''}
      value={accountManager?.value}
      onChange={setAccountManager}
      preSelectByDefault={true}
      returnObjectOnChange={true}
      w={250}
    />
  );
}
