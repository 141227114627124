import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';
import { useDigitalMarketSurveyService } from '../../../../../../../services/bi/property/marketing/DigitalMarketSurveyService';

const MarketImpressionShare = () => {
  const { getMarketImpressionShare } = useDigitalMarketSurveyService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Market Impression Share',
        hint: "The number of impressions you've received divided by the estimated number of impressions you were eligible to receive. Eligibility is based on your current ads' targeting settings, approval statuses, bids, and Quality Scores. Benchmark of 20% and above for stabilized communities. If your property requires additional traffic and your Market Impression Share is below 20% there is likely room to increase your budget to show more frequently online. ",
        valueFormatter: (x) => (Number(x) * 100).toFixed(2),
        suffix: '%',
      }}
      fetchMethod={getMarketImpressionShare}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default MarketImpressionShare;
