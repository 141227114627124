export const SCHEDULED_REPORT_LOG_STATUS = {
  EnqueueingFailed: 'enqueueing_failed',
  Enqueued: 'enqueued',
  ReportPreparationFailed: 'report_preparation_failed',
  ReportSendingFailed: 'report_sending_failed',
  Sent: 'sent',
};

export const SCHEDULED_REPORTS_LOG_STATUS_COLOR = {
  [SCHEDULED_REPORT_LOG_STATUS.EnqueueingFailed]: 'red',
  [SCHEDULED_REPORT_LOG_STATUS.Enqueued]: 'grey',
  [SCHEDULED_REPORT_LOG_STATUS.ReportPreparationFailed]: 'red',
  [SCHEDULED_REPORT_LOG_STATUS.ReportSendingFailed]: 'red',
  [SCHEDULED_REPORT_LOG_STATUS.Sent]: 'green',
};
