import React, { useContext, useState } from 'react';
import { createStyles, Select } from '@mantine/core';
import { PropertyFiltersContext } from '../../context/PropertyFiltersContext';
import { IconChevronDown } from '@tabler/icons-react';

const useStyles = createStyles(({ colors }) => {
  return {
    rightSection: {
      'margin-top': '1.5rem',
      stroke: colors.simple_icon_colors[0],
      pointerEvents: 'none',
    },
    label: {
      position: 'absolute',
      'z-index': 1,
      'pointer-events': 'none',
      'margin-left': '0.8rem',
      color: colors.text_third[0],
      'margin-top': '0.8rem',
    },
    input: {
      height: 'auto',
      'text-overflow': 'ellipsis',
      padding: '2rem 2rem 0.5rem 0.8rem',
      'font-size': '18px',
      'border-color': colors.border_color[0] + '!important',
      'font-weight': '500',
      backgroundColor: colors.page_background[0],
    },
    dropdown: {
      top: '93px!important',
      borderColor: colors.border_color[0],
      borderRadius: '0.5rem',
      backgroundColor: colors.page_background[0],
    },
    itemsWrapper: {
      gap: 4,
      padding: '0.75rem 1rem',
    },
    item: {
      'font-weight': '500',
    },
  };
});

export default function UserPropertiesDropdown() {
  const { options, property, handleSelectChange } = useContext(PropertyFiltersContext);
  const [isOpened, setIsOpened] = useState(false);

  const { classes } = useStyles();
  const styles = {};
  if (isOpened) {
    styles['input'] = {
      borderRadius: '0.5rem 0.5rem 0 0',
    };
  }

  return (
    <Select
      radius='md'
      dropdownOpened={true}
      searchable
      sx={styles}
      label='Property'
      classNames={classes}
      onDropdownClose={() => setIsOpened(false)}
      onDropdownOpen={() => setIsOpened(true)}
      placeholder='Search Properties'
      defaultValue={property?.value}
      onChange={handleSelectChange}
      data={options}
      rightSection={<IconChevronDown size='1.1rem' color='simple_icon_colors' />}
    />
  );
}
