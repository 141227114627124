import React, { useContext } from 'react';
import {
  useWebsiteLeadsService
} from '../../../../../../../../services/bi/property/marketing/WebsiteLeadAttributionService';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import LineChartComponent from '../../../../../../../../components/LineChart/LineChart';
import dayjs from 'dayjs';

const WebsiteLeadsByDay = () => {
  const { getWebsiteLeadsByDay } = useWebsiteLeadsService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{
        title: 'Website Leads By Day',
        index: 'event_date',
      }}
      fetchMethod={getWebsiteLeadsByDay}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default WebsiteLeadsByDay;
