import React from 'react';
import { Container, Grid, SimpleGrid } from '@mantine/core';
import ImpressionsMetric from './Metrics/ImpressionsMetric';
import ClicksMetric from './Metrics/ClicksMetric';
import CTRMetric from './Metrics/CTRMetric';
import AvgCpcMetric from './Metrics/AvgCpcMetric';
import AvgCpmMetric from './Metrics/AvgCpmMetric';
import PerformanceOverview from './Charts/PerformanceOverview/PerformanceOverview';
import ClickThroughRate from './Charts/ClickThroughRate/ClickThroughRate';
import ClicksByDevice from './Charts/ClicksByDevice/ClicksByDevice';
import PerformanceByHourOfDay from './Charts/PerformanceByHourOfDay/PerformanceByHourOfDay';

export default function PropertyMarketingBrandAwarenessSection() {
  return (
    <Container fluid m={0} sx={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
      <SimpleGrid
        cols={5}
        spacing='xl'
        breakpoints={[
          { maxWidth: 'xl', cols: 3 },
          { maxWidth: 'lg', cols: 2 },
          { maxWidth: 'md', cols: 1 },
        ]}
      >
        <ImpressionsMetric />
        <ClicksMetric />
        <CTRMetric />
        <AvgCpcMetric />
        <AvgCpmMetric />
      </SimpleGrid>
      <Grid mt='md'>
        <Grid.Col span={8} xl={8} lg={12} md={12} sm={12} xs={12}>
          <PerformanceOverview />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={12} md={12} sm={12} xs={12}>
          <ClickThroughRate />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={6} md={12} sm={12} xs={12}>
          <ClicksByDevice />
        </Grid.Col>
        <Grid.Col span={8} xl={8} lg={12} md={12} sm={12} xs={12}>
          <PerformanceByHourOfDay />
        </Grid.Col>
        {/*TODO Uncomment once we have the Age data*/}
        {/*<Grid.Col span={4} xl={4} lg={6} md={12}>*/}
        {/*  <ClicksByAgeGender />*/}
        {/*</Grid.Col>*/}
        {/*<Grid.Col span={4} xl={4} lg={6} md={12}>*/}
        {/*  <ImpressionsByAgeGender />*/}
        {/*</Grid.Col>*/}
      </Grid>
    </Container>
  );
}
