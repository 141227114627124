import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import { useSearchEngineService } from '../../../../../../../../services/bi/property/marketing/SearchEngineService';
import VerticalBarChartComponent from '../../../../../../../../components/VerticalBarChart/VerticalBarChart';

export default function ClicksByAdGroup() {
  const { getClicksByAdGroup } = useSearchEngineService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={VerticalBarChartComponent}
      childProps={{
        title: 'Clicks By Ad Group',
        index: 'ad_group_name',
        hint: 'Number of clicks by ad group (An ad group in Google Ads is a set of ad options that run against a specific set of keywords.).',
      }}
      fetchMethod={getClicksByAdGroup}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
