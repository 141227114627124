import dayjs from 'dayjs';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { getPortfolioOverviewMetricById } from '../../../../../../constants/portfolioOverviewMetrics';

const getMetricValues = (rows, headers) => {
  return rows.map(({ original }) => {
    const formattedData = { Property: original.name };
    headers.forEach(({ id, name }) => {
      let value = original[id]?.value !== undefined ? original[id].value : '-';
      const metricConfig = getPortfolioOverviewMetricById(id);
      if (metricConfig) {
        value = formatValue(metricConfig, value);
        formattedData[name] = value;
      } else {
        formattedData[id] = value;
      }
    });
    return formattedData;
  });
};

const formatValue = (obj, value) => {
  if (value === '-') return value;

  if (obj.valueFormatter) value = obj.valueFormatter(value);
  if (obj.prefix) value = obj.prefix + value;
  if (obj.suffix) value = value + obj.suffix;

  return value;
};

export const useExportReportService = () => {
  const exportCSV = async ({ csvData, companyName, accountManagerName, from, to }) => {
    const formattedCompanyName = companyName?.split(' ').join('_').toLowerCase();
    const formattedAccountManagerName = accountManagerName?.split(' ').join('_').toLowerCase();
    const formattedFrom = dayjs(from).format('YYYY-MM-DD');
    const formattedTo = dayjs(to).format('YYYY-MM-DD');
    const filename = `${formattedFrom}_${formattedTo}_${formattedCompanyName}_${formattedAccountManagerName}`;

    const headers = csvData?.headers
      ?.filter((item) => item.id !== 'property')
      .map((item) => ({
        id: item.id,
        name: item?.column?.columnDef?.header,
      }));

    const result = getMetricValues(csvData.rows, headers);

    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename });
    const csv = await generateCsv(csvConfig)(result);

    return download(csvConfig)(csv);
  };

  return {
    exportCSV,
  };
};
