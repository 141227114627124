export const columns = [
  {
    accessorKey: 'floor_plan',
    header: 'Floor Plan',
    maxSize: 50,
  },
  {
    accessorKey: 'thirty_days_expirations_count',
    header: '30 Days Expirations',
    maxSize: 50,
  },
  {
    accessorKey: 'sixty_days_expirations_count',
    header: '60 Days Expirations',
    maxSize: 50,
  },
];

export const initialState = {
  sorting: [{ id: 'thirty_days_expirations_count', desc: true }],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
};
