import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/marketing/brand-awareness';

export const useBrandAwarenessService = () => {
  const { axios } = useAxios();

  const getImpressions = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/impressions`, { ...config, params });

    return response?.data;
  };

  const getClicks = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/clicks`, { ...config, params });

    return response?.data;
  };

  const getCTR = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/ctr`, { ...config, params });

    return response?.data;
  };

  const getAvgCpc = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/avg-cpc`, { ...config, params });

    return response?.data;
  };

  const getAvgCpm = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/avg-cpm`, { ...config, params });

    return response?.data;
  };

  const getPerformanceOverview = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/performance-overview`, { ...config, params });

    return response?.data;
  };
  const getClickThroughRate = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/click-through-rate`, { ...config, params });

    return response?.data;
  };
  const getClicksByDevice = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/clicks-by-device`, { ...config, params });

    return response?.data;
  };

  const getClicksByAgeGender = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/clicks-by-age-gender`, { ...config, params });

    return response?.data;
  };

  const getImpressionsByAgeGender = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/impressions-by-age-gender`, { ...config, params });

    return response?.data;
  };

  const getPerformanceByHourOfDay = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/performance-by-hour-of-day`, { ...config, params });

    return response?.data;
  };

  return {
    getImpressions,
    getClicks,
    getCTR,
    getAvgCpc,
    getAvgCpm,
    getPerformanceOverview,
    getClickThroughRate,
    getClicksByDevice,
    getClicksByAgeGender,
    getImpressionsByAgeGender,
    getPerformanceByHourOfDay,
  };
};
