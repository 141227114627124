import React from 'react';
import { Container } from '@mantine/core';
import AdminPortfolioOverviewHeader from './Components/Header/PortfolioOverviewHeader';
import AdminPortfolioOverviewTable from './Components/Table/PortolioOverviewTable';

export default function AdminPortfolioOverview() {
  return (
    <Container fluid px={30}>
      <AdminPortfolioOverviewHeader />
      <AdminPortfolioOverviewTable />
    </Container>
  );
}
