import React from 'react';
import { Center, Container, Skeleton, Text, Tooltip, Menu } from '@mantine/core';
import { IconAlertTriangle, IconArrowNarrowDown, IconArrowNarrowUp } from '@tabler/icons-react';
import { GROW } from '../../../../../../../../constants/metric';
import { FilterFunctions, FilterOptionSections, getFilterFunctions } from '../Config/filterOptionsModel';

export const generateColumn = ({ id, name, ...params }) => ({
  accessorKey: id,
  header: name,
  id,
  Cell: ({ row, table }) => {
    const { value, grow, percentage_change, error } = row.original?.[id] ?? {};

    return (
      <div>
        {table.getState()?.loading ? (
          <Skeleton visible={true}>1</Skeleton>
        ) : error ? (
          <Center ml={'sm'}>
            <Container sx={{ height: 14 }}>
              <IconAlertTriangle color='red' size={17} />
            </Container>
          </Center>
        ) : (
          <Center ml={'sm'}>
            <Text sx={{ lineHeight: 1 }} fw={600} mr={3}>
              {value && value != '-' ? params.prefix : ''}
              {value && params.valueFormatter ? params.valueFormatter(value) : value}
              {value && value != '-' ? params.suffix : ''}
            </Text>
            {grow === GROW.UP && (
              <Tooltip
                withArrow
                arrowSize={8}
                label={`+${percentage_change}% in comparison to previous period of time`}
              >
                <Container sx={{ cursor: 'pointer' }} m={0} p={0} h={14} w={'xs'}>
                  <IconArrowNarrowUp size={12} stroke={1.5} />
                </Container>
              </Tooltip>
            )}
            {grow === GROW.DOWN && (
              <Tooltip withArrow arrowSize={8} label={`${percentage_change}% in comparison to previous period of time`}>
                <Container sx={{ cursor: 'pointer' }} m={0} p={0} h={14} w={'xs'}>
                  <IconArrowNarrowDown size={12} stroke={1.5} />
                </Container>
              </Tooltip>
            )}

            {grow === GROW.STEADY && <Container m={0} p={0} h={14} w={'xs'}></Container>}
          </Center>
        )}
      </div>
    );
  },
  maxSize: 0,
  enableColumnOrdering: true,
  enableColumnFilter: true,
  enableColumnFilterModes: true,
  accessorFn: (row) => row[id]?.value,
  columnFilterModeOptions: getFilterFunctions().map((x) => x.functionName),
  renderColumnFilterModeMenuItems: (table) => {
    const items = [];

    Object.values(FilterOptionSections).forEach((section) => {
      items.push(
        <Menu.Label key={section}>
          <Text tt={'capitalize'}>{section}</Text>
        </Menu.Label>
      );

      items.push(
        ...Object.values(FilterFunctions[section]).map((filterFunction) => (
          <Menu.Item
            icon={<filterFunction.icon size={20} />}
            key={filterFunction.functionName}
            onClick={() => table.onSelectFilterMode(filterFunction.functionName)}
          >
            <div>{filterFunction.title}</div>
          </Menu.Item>
        ))
      );
    });

    return items;
  },
  sortingFn: (rowA, rowB) => {
    if (!rowA.original?.[id]?.value) return -1;
    if (!rowB.original?.[id]?.value) return -1;

    return +rowA.original?.[id]?.value - +rowB.original?.[id]?.value;
  },
  ...params,
});
