import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';
import { useRentComparisonService } from '../../../../../../../services/bi/property/MarketSurvey/RentComperisonService';
import { Grid, Title, Text } from '@mantine/core';

const PropertySpecsMetric = () => {
  const { getPropertySpecs } = useRentComparisonService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Property Specs',
        hint: 'Built: The year the property was constructed.',
        showTrend: false,
        useCustomContent: true,
        renderCustomContent: (value, params) => {
          if (params.isLoading || !value) {
            return (
              <Title order={2} mt={7} fw={600} ff='Montserrat Semibold, sans-serif'>
                -
              </Title>
            );
          }

          const formatLabel = (key) => {
            return key.replace(/_/g, ' ').replace(/\b\w/g, (letter) => letter.toUpperCase());
          };

          return (
            <Grid>
              {Object.keys(value)
                .filter((label) => label !== 'service_id')
                .map((label) => (
                  <Grid.Col span={4} align={'flex-start'} key={label}>
                    <Title order={4} mt={7} fw={600} ff='Montserrat Semibold, sans-serif'>
                      {value[label] ?? '-'}
                    </Title>
                    <Text size={12} color='dimmed' fw={600} ff='Montserrat Semibold, sans-serif'>
                      {formatLabel(label)}
                    </Text>
                  </Grid.Col>
                ))}
            </Grid>
          );
        },
      }}
      fetchMethod={getPropertySpecs}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default PropertySpecsMetric;
