import {
  IconBrandMeta,
  IconBuildings,
  IconChartBarPopular,
  IconDeviceLaptop,
  IconHeartRateMonitor,
  IconLayersDifference,
  IconLayoutGrid,
  IconPhoneCall,
  IconPresentation,
  IconScreenShare,
  IconWorldSearch,
} from '@tabler/icons-react';
import { CONNECTION_TYPE } from './connectionType';
import { FEATURE_FLAGS } from './featureFlags';

export const PROPERTY_REPORT_SECTIONS = {
  PropertyOverview: 'property_overview',
  Marketing: 'marketing',
  MarketSurvey: 'market_survey',
  TeamPerformance: 'team_performance',
};

export const PROPERTY_REPORT_PAGES = {
  [PROPERTY_REPORT_SECTIONS.PropertyOverview]: {
    Overview: 'overview',
    BoxScore: 'box_score',
  },
  [PROPERTY_REPORT_SECTIONS.Marketing]: {
    WebsitePerformance: 'website_performance',
    SearchEngineMarketing: 'search_engine_marketing',
    DigitalMarketSurvey: 'digital_market_survey',
    BrandAwareness: 'brand_awareness',
    Remarketing: 'remarketing',
    SocialMediaAdvertising: 'social_media_advertising',
    WebsiteLeadAttribution: 'website_lead_attribution',
  },
  [PROPERTY_REPORT_SECTIONS.MarketSurvey]: {
    RentComparison: 'rent_comparison',
  },
  [PROPERTY_REPORT_SECTIONS.TeamPerformance]: {
    Calls: 'call_summary',
  },
};

export const PROPERTY_REPORT_PAGES_ICONS = {
  overview: IconPresentation,
  box_score: IconLayoutGrid,
  website_performance: IconDeviceLaptop,
  search_engine_marketing: IconWorldSearch,
  remarketing: IconHeartRateMonitor,
  social_media_advertising: IconBrandMeta,
  brand_awareness: IconScreenShare,
  website_lead_attribution: IconChartBarPopular,
  digital_market_survey: IconBuildings,
  call_summary: IconPhoneCall,
  rent_comparison: IconLayersDifference,
};

export const PROPERTY_REPORT_ABBREVIATIONS = {
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].WebsitePerformance]: 'SITE',
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].SearchEngineMarketing]: 'SEM',
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].BrandAwareness]: 'BA',
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].Remarketing]: 'RM',
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].SocialMediaAdvertising]: 'SM',
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].WebsiteLeadAttribution]: 'SITE ATT',
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.TeamPerformance].Calls]: 'CALL',
};

export const ORDERED_PROPERTY_REPORT_PAGES = [
  ...Object.values(PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.PropertyOverview]),
  ...Object.values(PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing]),
  ...Object.values(PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.MarketSurvey]),
  ...Object.values(PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.TeamPerformance]),
];

export const PROPERTY_REPORT_PAGES_REQUIRED_CONNECTIONS = {
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.PropertyOverview].Overview]: [
    CONNECTION_TYPE.Entrata,
    CONNECTION_TYPE.Knock,
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.PropertyOverview].BoxScore]: [
    CONNECTION_TYPE.Entrata,
    CONNECTION_TYPE.Knock,
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].WebsitePerformance]: [CONNECTION_TYPE.GoogleAnalytics],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].SearchEngineMarketing]: [CONNECTION_TYPE.GoogleAds],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].DigitalMarketSurvey]: [CONNECTION_TYPE.GoogleAds],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].BrandAwareness]: [CONNECTION_TYPE.GoogleAds],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].Remarketing]: [CONNECTION_TYPE.GoogleAds],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].SocialMediaAdvertising]: [CONNECTION_TYPE.FacebookAds],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].WebsiteLeadAttribution]: [CONNECTION_TYPE.GoogleAnalytics],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.MarketSurvey].RentComparison]: [CONNECTION_TYPE.HelloData],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.TeamPerformance].Calls]: [CONNECTION_TYPE.Callrail],
};

export const PROPERTY_REPORT_PAGES_REQUIRED_FEATURE_FLAG = {
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.MarketSurvey].RentComparison]: FEATURE_FLAGS.HelloDataIntegration,
};

export const HIDE_DATE_FILTER_LOCATIONS = [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.PropertyOverview].BoxScore];
export const SHOW_MULTI_TOUCH_ATTRIBUTION_LOCATIONS = [
  PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].WebsiteLeadAttribution,
];

export const shouldDisplayReportPage = (
  page,
  custom_config_enabled,
  report_pages,
  connected_platforms,
  isFeatureEnabled
) => {
  if (
    PROPERTY_REPORT_PAGES_REQUIRED_FEATURE_FLAG[page] &&
    !isFeatureEnabled(PROPERTY_REPORT_PAGES_REQUIRED_FEATURE_FLAG[page])
  ) {
    return false;
  }

  if (custom_config_enabled) {
    return report_pages.includes(page);
  }

  return (
    !PROPERTY_REPORT_PAGES_REQUIRED_CONNECTIONS[page] ||
    PROPERTY_REPORT_PAGES_REQUIRED_CONNECTIONS[page].some((x) => connected_platforms.includes(x))
  );
};

export const shouldDisplaySection = (
  section,
  custom_config_enabled,
  report_pages,
  connected_platforms,
  isFeatureEnabled
) => {
  return Object.values(PROPERTY_REPORT_PAGES[section]).some((x) =>
    shouldDisplayReportPage(x, custom_config_enabled, report_pages, connected_platforms, isFeatureEnabled)
  );
};

export const formatReportName = (page, withSectionName = false) => {
  const prefix = withSectionName ? Object.keys(PROPERTY_REPORT_SECTIONS).find((section) => !!section[page]) : '';
  return `${prefix ? prefix + ' > ' : ''}${page}`
    .split('_')
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');
};
