import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import dayjs from 'dayjs';
import { useSearchEngineService } from '../../../../../../../services/bi/property/marketing/SearchEngineService';

export default function CTRMetric() {
  const { getCTR } = useSearchEngineService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'CTR',
        hint: 'The number of clicks on your search ads, divided by the number of times your ad was shown. Internal benchmark of 8% for Google PPC ad campaigns. Industry benchmark of 6.5%.',
        valueFormatter: (x) => Number(x).toFixed(2),
        suffix: '%',
      }}
      fetchMethod={getCTR}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
