import React, { useContext, useEffect } from 'react';
import { Box, createStyles, Flex, NavLink, Title, useMantineTheme } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import UserPropertiesDropdown from '../../../../components/UserPropertiesDropdown/UserPropertiesDropdown';
import {
  PROPERTY_REPORT_PAGES,
  PROPERTY_REPORT_SECTIONS,
  shouldDisplayReportPage,
  shouldDisplaySection,
} from '../../../../constants/propertyReportPages';
import { PropertyFiltersContext } from '../../../../context/PropertyFiltersContext';
import { useFeatureFlagsService } from '../../../../services/featureFlagsService';

const useStyles = createStyles(() => {
  const { colors } = useMantineTheme();

  return {
    control: {
      '&:hover': {
        backgroundColor: colors.hover_color[5],
        color: colors.text_color[4],
      },
    },
  };
});

export default function PropertiesNavigation({ styles }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { colors } = useMantineTheme();
  const { classes } = useStyles();
  const { property } = useContext(PropertyFiltersContext);

  const { isFeatureEnabled } = useFeatureFlagsService();

  useEffect(() => {
    handlePropertyChange(property);
  }, [property, location.pathname]);

  const handlePropertyChange = (property) => {
    if (!property) return;

    const currentPage = location.pathname.split('/').pop();

    if (
      !shouldDisplayReportPage(
        currentPage,
        property.enable_manual_report_pages,
        property.report_pages,
        property.platforms,
        isFeatureEnabled
      )
    ) {
      for (const section of Object.values(PROPERTY_REPORT_SECTIONS)) {
        for (const otherPage of Object.values(PROPERTY_REPORT_PAGES[section])) {
          if (
            shouldDisplayReportPage(
              otherPage,
              property.enable_manual_report_pages,
              property.report_pages,
              property.platforms,
              isFeatureEnabled
            )
          ) {
            return navigate(`${section}/${otherPage}`);
          }
        }
      }

      navigate('/');
    }
  };

  const NavigationElement = ({ section, page }) => {
    if (property) {
      const shouldDisplay = page
        ? shouldDisplayReportPage(
            page,
            property.enable_manual_report_pages,
            property.report_pages,
            property.platforms,
            isFeatureEnabled
          )
        : shouldDisplaySection(
            section,
            property.enable_manual_report_pages,
            property.report_pages,
            property.platforms,
            isFeatureEnabled
          );

      if (!shouldDisplay) {
        return null;
      }
    }

    const activeLinkPathname = `/properties/${section}${page ? '/' + page : ''}`;

    return page ? (
      <NavLink
        id={'property-report-page__' + page}
        className={classes.control}
        active={location.pathname === activeLinkPathname}
        onClick={() => navigate(`${section}${'/' + page}`)}
        style={{
          backgroundColor: location.pathname === activeLinkPathname && colors.company_theme_primary_color[8],
        }}
        label={
          <Title
            order={6}
            fw={400}
            tt='capitalize'
            ml={'sm'}
            color={location.pathname === activeLinkPathname ? colors.text_color[6] : ''}
          >
            {page.replaceAll('_', ' ')}
          </Title>
        }
      />
    ) : (
      <Title order={5} fw={700} tt='capitalize' p={'0.5rem 0.75rem'}>
        {section.replaceAll('_', ' ')}
      </Title>
    );
  };

  return (
    <Box
      h='100%'
      w={250}
      sx={{
        ...styles,
        backgroundColor: colors.nested_navigation_color[6],
        boxShadow: '#00000012 0px 0px 15px',
        zIndex: 230,
      }}
    >
      <Flex
        h={80}
        align={'center'}
        sx={{
          backgroundColor: colors.secondary_background_color[6],
        }}
      >
        <UserPropertiesDropdown styles={{ margin: '0 !important', padding: 10, color: 'red' }} variant='unstyled' />
      </Flex>

      {Object.values(PROPERTY_REPORT_SECTIONS)
        .map((section) => (
          <Box mb='sm' key={section}>
            <NavigationElement section={section} />
            {Object.values(PROPERTY_REPORT_PAGES[section])
              .map((page) => <NavigationElement key={page} section={section} page={page} />)
              .filter(Boolean)}
          </Box>
        ))
        .filter(Boolean)}
    </Box>
  );
}
