import React from 'react';
import { Container, Grid, SimpleGrid } from '@mantine/core';
import SessionsMetric from './Metrics/SessionsMetric';
import ActiveUsersMetric from './Metrics/ActiveUsersMetric';
import NewUsersMetric from './Metrics/NewUsersMetric';
import PhoneCallsMetric from './Metrics/PhoneCallsMetric';
import FormSubmissionsMetric from './Metrics/FormSubmissionsMetric';
import EngagementRateMetric from './Metrics/EngagementRateMetric';
import PerformanceOverview from './Charts/PerformanceOverview/PerformanceOverview';
import TopReferralSources from './Charts/TopReferralSources/TopReferralSources';
import TopPageViews from './Charts/TopPageViews/TopPageViews';
import UsersByDevice from './Charts/UsersByDevice/UsersByDevice';
import NewUsersBySourceTable from './Tables/NewUsersBySource/NewUsersBySource';

export default function PropertyMarketingWebsitePerformanceSection() {
  return (
    <Container fluid m={0} pb='lg'>
      <SimpleGrid
        cols={6}
        breakpoints={[
          { maxWidth: 'xl', cols: 3, spacing: 'md' },
          { maxWidth: 'lg', cols: 2, spacing: 'md' },
          { maxWidth: 'md', cols: 1, spacing: 'sm' },
        ]}
      >
        <SessionsMetric />
        <ActiveUsersMetric />
        <NewUsersMetric />
        <EngagementRateMetric />
        <PhoneCallsMetric />
        <FormSubmissionsMetric />
      </SimpleGrid>
      <Grid mt='md'>
        <Grid.Col span={8} xl={8} lg={8} md={12} sm={12} xs={12}>
          <PerformanceOverview />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={6} md={12} sm={12} xs={12}>
          <TopReferralSources />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={6} md={12} sm={12} xs={12}>
          <TopPageViews />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={6} md={12} sm={12} xs={12}>
          <NewUsersBySourceTable />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={6} md={12} sm={12} xs={12}>
          <UsersByDevice />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
