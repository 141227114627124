import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useFeatureFlagsService } from '../services/featureFlagsService';

const ProtectedRoute = ({ roles, flag, children, redirectPath = '/', featureRedirectPath = '/' }) => {
  const { currentUser } = useContext(AuthContext);

  if (roles && !roles?.includes(currentUser?.role)) {
    return <Navigate to={redirectPath} replace />;
  }

  const { isFeatureEnabled } = useFeatureFlagsService();

  if (flag && !isFeatureEnabled(flag)) {
    return <Navigate to={featureRedirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
