import React, { useContext, useState } from 'react';
import { ColorSchemeProvider } from '@mantine/core';
import { useUserProfileService } from '../../services/userProfileService';
import { AuthContext } from '../../context/AuthContext';
import useDebounceHook from '../../hooks/debounceHook';
import { THEME_MODES } from '../../constants/theme_modes';
import MantineThemeProvider from '../MantineThemeProvider/MantineThemeProvider';

export default function UserThemeProvider({ children }) {
  const { userThemeMode } = useContext(AuthContext);
  const prefersColorScheme =
    userThemeMode ??
    (window.matchMedia('(prefers-color-scheme: dark)').matches ? THEME_MODES.darkMode : THEME_MODES.lightMode);
  const [colorScheme, setColorScheme] = useState(prefersColorScheme);

  const { updateUserPreferences } = useUserProfileService();

  let abortController = new AbortController();

  const updateThemeMode = useDebounceHook(async (updatedColorScheme) => {
    try {
      abortController.abort();
      abortController = new AbortController();

      await updateUserPreferences({ user_theme_mode: updatedColorScheme }, { signal: abortController.signal });
    } catch (error) {
      console.error('Error updating user preferences:', error);
    }
  }, 400);

  if (!userThemeMode) {
    updateThemeMode();
  }

  const toggleColorScheme = async () => {
    const updatedColorScheme = colorScheme === THEME_MODES.lightMode ? THEME_MODES.darkMode : THEME_MODES.lightMode;

    setColorScheme(updatedColorScheme);
    await updateThemeMode(updatedColorScheme);
  };

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineThemeProvider colorScheme={colorScheme}> {children}</MantineThemeProvider>
    </ColorSchemeProvider>
  );
}
