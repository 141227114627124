import React, { createContext, useState } from 'react';
import ViewMode from './ViewMode';
import FormModal from './components/FormModal/FormModal';
import { Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

export const IntegrationsContext = createContext(null);

const IntegrationsComponent = ({
  mode,
  connectionName,
  connectionErrors,
  connectionType,
  handleRetryConnection,
  connection,
  hasConnectionErrors,
  readOnly,
  children,
}) => {
  const [openedIntegrationModal, { open: openIntegrationModal, close: closeIntegrationModal }] = useDisclosure(false);
  const [connectionData, setConnectionData] = useState();

  const connectionValue =
    typeof connection?.value !== 'object' && connection?.value ? connection?.value : connection?.value?.id;

  return (
    <IntegrationsContext.Provider
      value={{
        mode,
        connectionName,
        connectionType,
        connectionErrors,
        connectionValue,
        connection,
        readOnly,
        handleRetryConnection,
        openedIntegrationModal,
        openIntegrationModal,
        closeIntegrationModal,
        hasConnectionErrors,
        connectionData,
        setConnectionData,
      }}
    >
      <Title order={5} mt='sm'>
        {connectionName}
      </Title>
      {children}
    </IntegrationsContext.Provider>
  );
};

IntegrationsComponent.ViewMode = ViewMode;
IntegrationsComponent.FormModal = FormModal;

export default IntegrationsComponent;
