import React, { useState } from 'react';
import { TextInput, PasswordInput, Container, Group, Button, Flex, createStyles, Center } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuthService } from '../../services/authService';
import { notifications } from '@mantine/notifications';
import { Link, useNavigate } from 'react-router-dom';
import VideoBackground from '../../components/VideoBackgound/VideoBackground';
import { formStyles } from '../../constants/formStyles';

const useStyles = createStyles(() => {
  return {
    control: {
      padding: '10px',
      color: 'white',
      backgroundColor: 'rgba(154,154,154,0.75)',
      '::placeholder': {
        color: 'white',
      },
      '::focus': {
        backgroundColor: 'rgba(154,154,154,0.75)',
        border: '1px solid #f3f3f3',
        borderColor: 'none',
      },
    },
  };
});

export default function Login() {
  const authService = useAuthService();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid Email Address'),
    },
  });

  const submitForm = async (data) => {
    try {
      setIsLoading(true);
      await authService.login(data);
      navigate('/');
    } catch (e) {
      const { data } = e.response;
      notifications.show({
        title: data.title ?? 'An Error Occurred',
        message: data.message,
        color: 'red',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { classes } = useStyles();

  return (
    <Flex justify={'center'} align={'center'} sx={{ padding: 0, margin: 0 }}>
      <Container size={620} my={250}>
        <Center mb={'4.5rem'}>
          <img
            src={`https://storage.googleapis.com/${process.env.REACT_APP_MEDIA_BUCKET_NAME}/streetdigital-ai_logo.svg`}
            alt='STREETDIGITAL AI'
            width={450}
          />
        </Center>
        <form onSubmit={form.onSubmit((values) => submitForm(values))}>
          <TextInput
            placeholder='Email Address'
            radius='md'
            size='lg'
            required
            classNames={{ input: 'form-input' }}
            mt='xl'
            mb='sm'
            sx={{
              input: classes.control,
              wrapper: { marginBottom: 20 },
              borderRadius: '7px',
            }}
            {...form.getInputProps('email')}
          />
          <Group size={620} my='sm' sx={{ display: 'flex', alignItems: 'center', justifyItems: 'space-between' }}>
            <PasswordInput
              placeholder='Password'
              radius='md'
              size='lg'
              required
              classNames={{ input: 'form-input' }}
              w={400}
              sx={{
                input: classes.control,
                wrapper: { marginBottom: 20 },
                borderRadius: '7px',
                ...formStyles,
              }}
              mt='md'
              {...form.getInputProps('password')}
            />
            <Button
              size='lg'
              radius='md'
              color='light'
              variant='light'
              type='submit'
              mt='md'
              w={170}
              sx={{
                input: classes.control,
                wrapper: { marginBottom: 20 },
                color: 'black',
              }}
              loading={isLoading}
            >
              Login
            </Button>
          </Group>
          <Group position='center' mt='xl'>
            <Link
              to={'/auth/reset-password'}
              style={{ color: 'white', fontSize: 18, fontWeight: 700, textDecoration: 'none' }}
            >
              Reset password
            </Link>
          </Group>
        </form>
      </Container>
      <VideoBackground styles={{ position: 'absolute', top: 0 }} />
    </Flex>
  );
}
