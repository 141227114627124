import React, { useLayoutEffect } from 'react';
import { Box, Button, Title } from '@mantine/core';
import AdminPropertyForm from './forms/propertyForm';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import { FORM_MODES } from '../../../../../constants/form_modes';
import { PROPERTY_STATUS } from '../../../../../constants/propertyStatus';

export default function AdminEditProperty() {
  const data = useRouteLoaderData('property-details');
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (data?.status === PROPERTY_STATUS.DISABLED) {
      navigate(-1);
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <Box mx='auto' mt='sm' px={30} pb={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title order={2} mb={20} align='left'>
          Edit Property
        </Title>
        <Button color='gray' variant='default' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Go Back
        </Button>
      </div>

      <AdminPropertyForm mode={FORM_MODES.Edit} initialValues={data}></AdminPropertyForm>
    </Box>
  );
}
