import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';
import { useCallsService } from '../../../../../../../services/bi/property/teamPerformance/callsService';

const FormSubmissions = () => {
  const { getFormSubmissions } = useCallsService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Form Submissions',
        hint: 'Track the number of submitted forms to measure engagement and capture lead information from your website.',
      }}
      fetchMethod={getFormSubmissions}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default FormSubmissions;
