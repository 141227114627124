import React, { useState, useEffect } from 'react';
import { Select } from '@mantine/core';
import { useCompanyService } from '../../services/companyService';
import { useAbortController } from '../../hooks/abortControllerHook';

export default function CompaniesDropdown({
  value,
  onChange,
  withAsterisk,
  readOnly,
  disabled,
  required,
  error,
  accountManagerId,
  preSelectByDefault = false,
  returnObjectOnChange = false,
  label = 'Company',
  placeholder = 'Select a company',
  ...props
}) {
  const { getNames } = useCompanyService();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useAbortController(
    async (...params) => {
      setLoading(true);

      try {
        setOptions([]);

        const names = await getNames(...params);

        const filteredNames = readOnly ? names : names.filter((name) => name.status !== 'disabled');

        if (value && !filteredNames.some((x) => x.id === value)) {
          handleSelectChange(null);
        }
        setOptions(
          filteredNames.map((x) => ({ value: x.id, label: x.name })).sort((a, b) => a.label?.localeCompare(b.label))
        );
      } catch (error) {
        setOptions([]);
      } finally {
        setLoading(false);
      }
    },
    [accountManagerId],
    [accountManagerId]
  );

  useEffect(() => {
    if (preSelectByDefault && options.length > 0 && !value) {
      handleSelectChange(options[0].value);
    }
  }, [accountManagerId, options]);

  const handleSelectChange = (value) => {
    const selectedCompany = options.find((option) => option.value === value);
    if (onChange) {
      onChange(returnObjectOnChange ? { ...selectedCompany } : value);
    }
  };

  return (
    <Select
      withinPortal
      label={label}
      placeholder={loading ? 'Loading...' : placeholder}
      searchable
      value={value}
      withAsterisk={withAsterisk}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      error={error}
      onChange={handleSelectChange}
      data={options}
      {...props}
    />
  );
}
