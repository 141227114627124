import React from 'react';
import { Box, Button, Title } from '@mantine/core';
import AdminUserForm from './forms/userForm';
import { FORM_MODES } from '../../../../../constants/form_modes';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

export default function AdminAddUser() {
  const navigate = useNavigate();
  const onClickBack = () => navigate(-1);

  return (
    <Box mx='auto' mt='sm' px={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title order={2} mb={20} align='left'>
          Add User
        </Title>
        <Button color='gray' variant='default' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Go Back
        </Button>
      </div>
      <AdminUserForm mode={FORM_MODES.Create}></AdminUserForm>
    </Box>
  );
}
