import React, { useContext } from 'react';

import { SidebarContext } from '../../context/SidebarContext';
import { useFeatureFlagsService } from '../../services/featureFlagsService';
import { FEATURE_FLAGS } from '../../constants/featureFlags';
import AlertInfo from '../../components/AlertInfo/AlertInfo';
import PortfolioOverview from './PortfolioOverview';

const PortfolioOverviewPage = () => {
  const { setEnableExpanding } = useContext(SidebarContext);
  setEnableExpanding(false);

  const { isFeatureEnabled } = useFeatureFlagsService();

  return isFeatureEnabled(FEATURE_FLAGS.PortfolioOverview) ? (
    <PortfolioOverview />
  ) : (
    <AlertInfo title='Coming Soon!' subtitle='Our team is actively working on the Portfolio Overview implementation.' />
  );
};

export default PortfolioOverviewPage;
