import React, { useContext } from 'react';
import { useBiPropertyOverviewService } from '../../../../../../../services/bi/property/overview/PropertyOverviewService';
import { GROW } from '../../../../../../../constants/metric';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import dayjs from 'dayjs';

export default function ClosingRatioMetric() {
  const { getClosingRatio } = useBiPropertyOverviewService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Closing Ratio',
        valueFormatter: (value) => Math.round(value),
        suffix: '%',
        hint: 'The tour to lease conversion ratio.',
      }}
      fetchMethod={getClosingRatio}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
