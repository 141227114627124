export const FEATURE_FLAGS = {
  FacebookIntegration: 'facebook-integration',
  PdfExport: 'pdf-export',
  ConversionPerformance: 'conversion-performance',
  PropertyKnowledgePanel: 'property-knowledge-panel',
  PortfolioOverview: 'portfolio-overview',
  ScheduledReports: 'scheduled-reports',
  GA4MultiTouchAttribution: 'ga-4-multi-touch-attribution',
  VerifiedCompanyUsers: 'verified-company-users',
  YardiIntegration: 'yardi-integration',
  TeamPerformance: 'team-performance',
  InternalPortfolioOverview: 'internal-portfolio-overview',
};

export const FEATURE_FLAG_CAMPAIGN_VARIATION_TYPES = {
  AllEnabled: 'all',
  NoneEnabled: 'none',
};
