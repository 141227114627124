import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LeftMenuItemButton } from './LeftMenuItemButton';

LinksGroup.propTypes = {
  label: PropTypes.string,
  initiallyOpened: PropTypes.bool,
  links: PropTypes.array,
  link: PropTypes.string,
  externalLink: PropTypes.bool,
};

export function LinksGroup({ icon, label, link, externalLink = false }) {
  const navigate = useNavigate();
  const location = useLocation();

  const isLinkActive = location.pathname.startsWith(link);

  const onClick = () => {
    if (externalLink) {
      window.open(link, '_blank');
      return;
    }

    link && navigate(link);
  };

  return <LeftMenuItemButton icon={icon} label={label} isLinkActive={isLinkActive} onClick={onClick} />;
}
