import React, { useContext } from 'react';
import { useRemarketingService } from '../../../../../../../../services/bi/property/marketing/RemarketingService';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import LineChartComponent from '../../../../../../../../components/LineChart/LineChart';
import dayjs from 'dayjs';

const ClickThroughRate = () => {
  const { getClickThroughRate } = useRemarketingService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{
        title: 'Click Through Rate',
        index: 'date',
        valueFormatter: (x) => `${Number(x * 100).toFixed(2)}%`,
      }}
      fetchMethod={getClickThroughRate}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default ClickThroughRate;
