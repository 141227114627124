export const FEATURE_FLAGS = {
  PropertyKnowledgePanel: 'property-knowledge-panel',
  PortfolioOverview: 'portfolio-overview',
  GA4MultiTouchAttribution: 'ga-4-multi-touch-attribution',
  YardiIntegration: 'yardi-integration',
  InternalPortfolioOverview: 'internal-portfolio-overview',
  HelloDataIntegration: 'hello-data-integration',
};

export const FEATURE_FLAG_CAMPAIGN_VARIATION_TYPES = {
  AllEnabled: 'all',
  NoneEnabled: 'none',
};
