import React, { useEffect, useState } from 'react';

export default function DataFetcherComponent({
  fetchMethod,
  defaultValue,
  deps,
  params,
  paramsValidator,
  paramsFormatter,
  Child,
  childProps = {},
}) {
  const [data, setData] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  let abortController = new AbortController();

  const fetchData = async () => {
    setData(defaultValue);
    setIsError(false);

    if (!paramsValidator || !!paramsValidator(params)) {
      setIsLoading(true);
      try {
        const response = await fetchMethod(...paramsFormatter(params), { signal: abortController.signal });
        setData(response);
      } catch (e) {
        if (abortController.signal.aborted) {
          return;
        }
        setIsError(true);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    abortController.abort();
    abortController = new AbortController();

    fetchData();

    return () => {
      abortController.abort();
    };
  }, deps);

  return <Child {...childProps} data={data} isLoading={isLoading} isError={isError} onRetry={fetchData} />;
}
