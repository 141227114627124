import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/details';

export const usePropertyDetailsService = () => {
  const { axios } = useAxios();

  const get = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(API_PATH, { ...config, params });

    return response?.data;
  };

  return {
    get,
  };
};
