import React, { useContext } from 'react';
import { columns, initialState, state } from './constants/newUsersBySourceTableDef';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import SimpleReportTable from '../../../../../../../../components/SimpleReportTable/SimpleReportTable';
import { useWebsitePerformanceService } from '../../../../../../../../services/bi/property/marketing/WebsitePerformanceService';
import dayjs from 'dayjs';

export default function NewUsersBySourceTable() {
  const { getNewUsersBySource } = useWebsitePerformanceService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'New Users By Source',
        columns,
        initialState,
        hint: 'Total new users by source (source is the actual website that sends traffic to your website).',
        state,
      }}
      fetchMethod={getNewUsersBySource}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
