import React, { useContext, useEffect } from 'react';
import { IntegrationsContext } from '../IntegrationsComponent';
import { TextInput } from '@mantine/core';

const YardiConnection = () => {
  const { readOnly, connection, connectionData, setConnectionData } = useContext(IntegrationsContext);

  useEffect(() => {
    setConnectionData(connection?.value ?? '');
  }, [connection?.value]);

  return (
    <TextInput
      hideControls
      mt='sm'
      label='Account ID'
      placeholder='ID'
      min={0}
      value={connectionData}
      onChange={(event) => setConnectionData(event.target.value)}
      readOnly={readOnly}
    />
  );
};

export default YardiConnection;
