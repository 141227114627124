import { Button, Chip, CloseButton, Flex, Group, Text, useMantineTheme } from '@mantine/core';
import React, { useContext } from 'react';
import { InternalPortfolioOverviewContext } from '../../../../../Context/PortfolioOverviewContext';
import { removeElById } from '../../../../../../../../../../utils/array.utils';
import { formatFilterModalChipText } from '../../../../../../../../../../constants/portfolioOverviewMetrics';
import { IconX } from '@tabler/icons-react';
import { PortfolioOverviewFilterModalContext } from '../FilterModal';

export const SelectedFields = ({ onClose }) => {
  const { colors } = useMantineTheme();
  const { setSelectedMetrics } = useContext(InternalPortfolioOverviewContext);
  const { selectedMetricIds, setSelectedMetricIds } = useContext(PortfolioOverviewFilterModalContext);

  const onSelectedMetricRemove = (id) => {
    setSelectedMetricIds(removeElById(id, selectedMetricIds));
  };

  const onApplyPreset = () => {
    setSelectedMetrics([...selectedMetricIds]);
    onClose();
  };

  return (
    <>
      <Flex justify='space-between' align='flex-end' mb='xs'>
        <Text sx={{ fontSize: '.9rem' }}>Selected Fields</Text>
        <Button
          color={colors.text_color[4]}
          variant='subtle'
          compact
          sx={{ '&:hover': { backgroundColor: colors.hover_color[5] } }}
          onClick={onApplyPreset}
        >
          Apply
        </Button>
      </Flex>
      <Group
        spacing='xs'
        grow={false}
        noWrap={false}
        sx={{
          border: '1px solid #ced4da',
          borderRadius: '.25em',
          padding: '.5em',
          minHeight: '3em',
          rowGap: '.2em',
        }}
      >
        {selectedMetricIds.map((id) => {
          return (
            <Chip
              key={id}
              variant='outline'
              checked={false}
              sx={{ label: { padding: '0 10px', fontSize: '.6rem', height: '1.3rem' } }}
            >
              <span>{formatFilterModalChipText(id)}</span>
              <CloseButton size='xs' variant='transparent' ml='3px' onClick={() => onSelectedMetricRemove(id)}>
                <IconX size={14} />
              </CloseButton>
            </Chip>
          );
        })}
      </Group>
    </>
  );
};
