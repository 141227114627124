import React, { useContext, useEffect, useRef, useState } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { Button, Card, createStyles, Flex, Group, SimpleGrid, Text, TextInput, ThemeIcon } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';
import { FORM_MODES } from '../../../../../../constants/form_modes';
import UsersMultiSelect from '../../../../../../components/UsersMultiSelect/UsersMultiSelect';
import ScheduledReportPropertiesDropdown from '../../../../../../components/ScheduledReportPropertiesDropdown/ScheduledReportPropertiesDropdown';
import { mapNullToEmptyString } from '../../../../../../utils/form.util';
import PagesMultiSelect from '../../../../../../components/PagesMultiSelect/PagesMultiSelect';
import { notifications } from '@mantine/notifications';
import { usePropertyService } from '../../../../../../services/propertyService';
import { useScheduledReportsService } from '../../../../../../services/scheduledReportsService';
import { FORM_ERRORS } from '../../../../../../constants/form_errors';
import { USER_ROLES, USER_STATUS } from '../../../../../../constants/user';
import { AuthContext } from '../../../../../../context/AuthContext';
import FrequencyDropdown, { FREQUENCIES } from '../../../../../../components/FrequencyDropdown/FrequencyDropdown';
import WeekdaySelector from '../../../../../../components/WeekdaySelector/WeekdaySelector';
import DayOfMonthSelector from '../../../../../../components/DayOfMonthSelector/DayOfMonthSelector';
import TimeSelector from '../../../../../../components/TimeSelector/TimeSelector';
import ReportDateRangeOptionsSelector from '../../../../../../components/ReportDateRangeOptionsSelector/ReportDateRangeOptionsSelector';
import { ORDERED_PROPERTY_REPORT_PAGES } from '../../../../../../constants/propertyReportPages';
import CompaniesDropdown from '../../../../../../components/CompaniesDropdown/CompaniesDropdown';
import { useVerifiedCompanyUsersService } from '../../../../../../services/verifiedUsersService';
import { useUserService } from '../../../../../../services/userService';

const AdminScheduledReportForm = ({ mode, initialValues = {} }) => {
  const {
    frequency,
    on,
    report_name,
    property_id,
    report_pages,
    users,
    verified_company_users,
    internal_users,
    time,
    date_range_option,
    company_id,
  } = mapNullToEmptyString(initialValues);
  const { currentUser } = useContext(AuthContext);

  const rendered = useRef(false);

  const { createScheduledReport, updateScheduledReport } = useScheduledReportsService();

  const form = useForm({
    initialValues: {
      frequency,
      on,
      report_name,
      property_id,
      report_pages,
      user_emails: (users?.concat(verified_company_users, internal_users) ?? []).map(
        (x) => x.email || x.verified_user_email
      ),
      time,
      date_range_option,
      company_id,
    },

    validate: {
      frequency: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      report_name: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      property_id: currentUser.role !== USER_ROLES.SDM_USER ? null : isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      user_emails: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      report_pages: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      time: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      date_range_option: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
    },
  });

  const [property, setProperty] = useState();

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const navigate = useNavigate();

  const { getPropertyDetails } = usePropertyService();
  useEffect(() => {
    async function getProperty() {
      try {
        const propertyDetails = await getPropertyDetails(form.values.property_id);

        setProperty(propertyDetails);
      } catch (error) {
        console.error(error);
      }
    }

    form.values.property_id && getProperty();
  }, [form.values.property_id]);

  const [allCompanyVerifiedUsers, setAllCompanyVerifiedUsers] = useState([]);
  const { get: getVerifiedUsers } = useVerifiedCompanyUsersService();

  useEffect(() => {
    async function fetch() {
      const result = await getVerifiedUsers(form.values.company_id);
      setAllCompanyVerifiedUsers(result);
    }

    form.values.company_id && fetch();
  }, [form.values.company_id]);

  const [internalUsers, setInternalUsers] = useState([]);
  const { getInternalActiveUsers } = useUserService();

  useEffect(() => {
    if (internalUsers.length > 0) return;

    async function fetch() {
      const result = await getInternalActiveUsers();
      setInternalUsers(result);
    }

    fetch();
  }, []);

  useEffect(() => {
    if (rendered.current) {
      form.setFieldValue('on', '');
    }

    rendered.current = true;
  }, [form.values.frequency]);

  const onSubmitForm = form.onSubmit(async (values) => {
    try {
      const user_ids = property.users
        ?.filter((user) => values.user_emails.includes(user.email))
        .map((user) => `${user.id}`);

      const verified_company_users_ids = allCompanyVerifiedUsers
        ?.filter((user) => values.user_emails.includes(user.verified_user_email))
        .map((user) => `${user.id}`);

      const internal_users_ids = internalUsers
        ?.filter((user) => values.user_emails.includes(user.email))
        .map((user) => `${user.id}`);

      const orderedReportPageStructure = values.report_pages.sort(
        (a, b) => ORDERED_PROPERTY_REPORT_PAGES.indexOf(a) - ORDERED_PROPERTY_REPORT_PAGES.indexOf(b)
      );

      setIsSaveLoading(true);
      switch (mode) {
        case FORM_MODES.Create:
          await createScheduledReport({
            ...values,
            report_pages: orderedReportPageStructure,
            user_ids,
            verified_company_users_ids,
            internal_users_ids,
          });
          break;
        case FORM_MODES.Edit:
          await updateScheduledReport(initialValues.id, {
            ...values,
            report_pages: orderedReportPageStructure,
            user_ids,
            verified_company_users_ids,
            internal_users_ids,
          });
          break;
      }

      notifications.show({
        title: 'Success!',
        message: `${values.report_name} report was successfully ${mode === FORM_MODES.Create ? 'added' : 'updated'}!`,
        color: 'green',
      });
    } catch (error) {
      const { data } = error.response;

      notifications.show({
        title: 'Failed to save report name',
        message: data.message,
        color: 'red',
      });
    }
    navigate('/admin/reports');
  });

  const disabled = mode === FORM_MODES.View;
  const editingDisabled = mode === FORM_MODES.Edit;

  const useStyles = createStyles((theme) => ({
    form: {
      input: {
        backgroundColor: theme.colors.gray[1],
      },
    },
  }));

  const { classes } = useStyles();

  return (
    <form onSubmit={onSubmitForm} className={disabled ? classes.form : ''}>
      <Card shadow='sm' padding='xl' radius='md' withBorder>
        <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
          <ThemeIcon size={28}>
            <IconUser size={17} strokeWidth={3} color={'white'} />
          </ThemeIcon>
          <Text weight={700} size={16} ml={7}>
            Email delivery
          </Text>
        </Card.Section>

        <SimpleGrid cols={2} mt={'sm'}>
          <TextInput
            readOnly={disabled}
            withAsterisk
            label='Report name'
            placeholder='Report name'
            {...form.getInputProps('report_name')}
          />
          <ReportDateRangeOptionsSelector
            required
            readOnly={disabled}
            withAsterisk
            {...form.getInputProps('date_range_option')}
          />
          <CompaniesDropdown
            withAsterisk
            readOnly={disabled}
            disabled={editingDisabled}
            {...form.getInputProps('company_id')}
          />
          <ScheduledReportPropertiesDropdown
            readOnly={disabled}
            disabled={editingDisabled || !form.getInputProps('company_id').value}
            withAsterisk
            company_id={form.getInputProps('company_id').value}
            {...form.getInputProps('property_id')}
          />
          {property?.account_manager?.status === USER_STATUS.ACTIVE && (
            <TextInput readOnly disabled value={property?.account_manager?.email} label='From' placeholder='From' />
          )}

          <UsersMultiSelect
            users={property?.users}
            verifiedCompanyUsers={allCompanyVerifiedUsers}
            internalUsers={internalUsers}
            readOnly={disabled}
            filter={(user) => [USER_STATUS.ACTIVE, USER_STATUS.DRAFT, USER_STATUS.PENDING].includes(user.status)}
            withAsterisk
            {...form.getInputProps('user_emails')}
          />
          <PagesMultiSelect
            report_pages={property?.report_pages}
            manual_report_pages_enabled={property?.enable_manual_report_pages}
            property_platforms={property?.platforms}
            readOnly={disabled}
            withAsterisk
            {...form.getInputProps('report_pages')}
          />
          <Flex direction='column' gap='sm'>
            <Group gap='sm'>
              <FrequencyDropdown
                style={{ flex: 4 }}
                readOnly={disabled}
                withAsterisk
                {...form.getInputProps('frequency')}
              />
              <TimeSelector required readOnly={disabled} withAsterisk {...form.getInputProps('time')} />
            </Group>
            {form.getInputProps('frequency').value === FREQUENCIES.Weekly && (
              <WeekdaySelector disabled={disabled} {...form.getInputProps('on')} />
            )}
            {form.getInputProps('frequency').value === FREQUENCIES.Monthly && (
              <DayOfMonthSelector disabled={disabled} {...form.getInputProps('on')} />
            )}
          </Flex>
        </SimpleGrid>
      </Card>

      {!disabled && (
        <Group position='center' my='md'>
          <Button type='submit' mt={15} w={160} loading={isSaveLoading}>
            Save
          </Button>
        </Group>
      )}
    </form>
  );
};

export default AdminScheduledReportForm;
