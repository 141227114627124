import React, { useContext } from 'react';
import { useDigitalMarketSurveyService } from '../../../../../../../services/bi/property/marketing/DigitalMarketSurveyService';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';

const MarketShareLostDueToBudget = () => {
  const { getMarketShareLostDueToBudget } = useDigitalMarketSurveyService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Market Share Lost Due To Budget',
        hint: "The percentage of time that your ads weren't shown on the Search Network due to insufficient budget. Benchmark of 10% or below. If your property has a Market Share Lost Due To Budget above 10% there is room to increase your budget, so your ads show more frequently.",
        valueFormatter: (x) => (Number(x) * 100).toFixed(2),
        suffix: '%',
      }}
      fetchMethod={getMarketShareLostDueToBudget}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default MarketShareLostDueToBudget;
