import React from 'react';
import { Container, SimpleGrid } from '@mantine/core';
import MarketImpressionShare from './Metrics/MarketImpressionShare';
import AboveOrganic from './Metrics/AboveOrganic';
import TopPlacement from './Metrics/TopPlacement';
import MarketShareLostDueToBudget from './Metrics/MarketShareLostDueToBudget';

export default function PropertyMarketingDigitalMarketSurveySection() {
  return (
    <Container fluid m={0} sx={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
      <SimpleGrid
        cols={4}
        spacing="xl"
        breakpoints={[
          { maxWidth: 'xl', cols: 2 },
          { maxWidth: 'lg', cols: 1 },
        ]}
      >
        <MarketImpressionShare />
        <AboveOrganic />
        <TopPlacement />
        <MarketShareLostDueToBudget />
      </SimpleGrid>
    </Container>
  );
}
