import React, { useContext, useEffect } from 'react';
import { Center, Container, Group, Loader } from '@mantine/core';
import { Outlet, useNavigation } from 'react-router-dom';
import AdminNavigation from './Components/Navigation/AdminNavigation';
import { AuthContext } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';

export default function AdminPage() {
  const { state } = useNavigation();
  const { currentUser } = useContext(AuthContext);
  const { setCompanyTheme } = useTheme();

  useEffect(() => {
    setCompanyTheme(currentUser?.theme);
  }, [currentUser]);

  return (
    <Group h='calc(100vh - 70px)' style={{ overflowY: 'hidden' }} align='start' spacing={0}>
      <AdminNavigation />
      <div style={{ flex: 1, height: '100%', backgroundColor: '#fafafa', overflowY: 'auto' }}>
        {state === 'loading' ? (
          <Container fluid pt={50}>
            <Center>
              <Loader />
            </Center>
          </Container>
        ) : (
          <Outlet />
        )}
      </div>
    </Group>
  );
}
