import React from 'react';
import { Button, Container, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { MantineReactTable } from 'mantine-react-table';
import { columns, initialState } from './constants/scheduledReportDef';
import { useScheduledReportsService } from '../../../../../services/scheduledReportsService';

const AdminScheduledReports = () => {
  const data = useLoaderData();
  const navigate = useNavigate();

  const onClickAddScheduledReport = () => {
    navigate('/admin/reports/add');
  };

  return (
    <Container fluid px={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title fw={700} ml='lg' my={25}>
          Reports
        </Title>
        <Button color='gray' variant='default' onClick={onClickAddScheduledReport}>
          <IconPlus style={{ marginRight: 5 }} />
          Create
        </Button>
      </div>
      <MantineReactTable
        columns={columns}
        data={data}
        initialState={initialState}
        mantineTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f9fdff',
            },
            td: {
              backgroundColor: 'transparent',
            },
          },
        }}
      />
    </Container>
  );
};

export const loader = async () => {
  const { get } = useScheduledReportsService();
  return await get();
};

export default AdminScheduledReports;
