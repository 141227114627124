import React, { useContext, useState } from 'react';
import { columns, initialState, state } from './constants/newCallLogTableDef';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import SimpleReportTable from '../../../../../../../../components/SimpleReportTable/SimpleReportTable';
import { useCallsService } from '../../../../../../../../services/bi/property/teamPerformance/callsService';
import dayjs from 'dayjs';

export default function CallLogTable() {
  const { getCallLog } = useCallsService();

  const { property, dates } = useContext(PropertyFiltersContext);

  const [currentPlayingUrl, setCurrentPlayingUrl] = useState(null);
  const [playingInstance, setPlayingInstance] = useState(null);

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'Call Log',
        columns,
        initialState,
        state: { ...state, currentPlayingUrl, setCurrentPlayingUrl, playingInstance, setPlayingInstance },
        hint: 'View detailed records of all inbound calls, including durations, outcomes, and call recordings for comprehensive tracking and analysis.',
      }}
      fetchMethod={getCallLog}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
