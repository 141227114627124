import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import { useBrandAwarenessService } from '../../../../../../../../services/bi/property/marketing/BrandAwarenessService';
import BarChartComponent from '../../../../../../../../components/BarChart/BarChart';

export default function PerformanceByHourOfDay() {
  const { getPerformanceByHourOfDay } = useBrandAwarenessService();

  const { property, dates } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    if (!data?.length) return [];

    return data.map((x) => ({
      ...x,
      hour_of_day: dayjs().hour(x.hour_of_day).format('ha'),
    }));
  };

  return (
    <DataFetcherComponent
      Child={BarChartComponent}
      childProps={{
        title: 'Performance By Time of Day',
        index: 'hour_of_day',
        dataFormatter,
        yAxisConfig: [
          { key: 'Clicks', position: 'left' },
          { key: 'Impressions', position: 'right' },
        ],
      }}
      fetchMethod={getPerformanceByHourOfDay}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
