import React from 'react';
import { Container, Grid, SimpleGrid } from '@mantine/core';
import PerformanceOverview from './Charts/PerformanceOverview/PerformanceOverview';
import ImpressionsMetric from './Metrics/ImpressionsMetric';
import ClicksMetric from './Metrics/ClicksMetric';
import CTRMetric from './Metrics/CTRMetric';
import AvgCpcMetric from './Metrics/AvgCpcMetric';
import PhoneCallsMetric from './Metrics/PhoneCallsMetric';
import FormSubmitsMetric from './Metrics/FormSubmitsMetric';
import TopPerformingKeywordsTable from './Tables/TopPerformingKeywords/TopPerformingKeywords';
import ClicksByAdGroup from './Charts/ClicksByAdGroup/ClicksByAdGroup';
import ClicksByLocations from './Charts/ClicksByLocations/ClicksByLocations';

export default function PropertyMarketingSearchEngineMarketingSection() {
  return (
    <Container fluid m={0} pb='lg' style={{ width: '100%' }} >
      <SimpleGrid
        cols={6}
        breakpoints={[
          { maxWidth: 'xl', cols: 3, spacing: 'md' },
          { maxWidth: 'lg', cols: 2, spacing: 'md' },
          { maxWidth: 'md', cols: 1, spacing: 'sm' },
        ]}
      >
        <ImpressionsMetric />
        <ClicksMetric />
        <CTRMetric />
        <AvgCpcMetric />
        <PhoneCallsMetric />
        <FormSubmitsMetric />
      </SimpleGrid>
      <Grid mt='md'>
        <Grid.Col span={8} xl={8} lg={12} md={12} sm={12} xs={12}>
          <Grid>
            <Grid.Col span={12}>
              <PerformanceOverview />
            </Grid.Col>
            <Grid.Col span={6} xl={6} md={6} sm={12} xs={12}>
              <ClicksByAdGroup />
            </Grid.Col>
            <Grid.Col span={6} xl={6} md={6} sm={12}  xs={12}>
              <ClicksByLocations />
            </Grid.Col>
            {/*TODO Uncomment once we have the Age data*/}
            {/*<Grid.Col span={6}>*/}
            {/*  <ClicksByAge />*/}
            {/*</Grid.Col>*/}
          </Grid>
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={12} md={12} sm={12} xs={12}>
          <TopPerformingKeywordsTable />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
