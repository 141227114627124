// eslint-disable-next-line import/no-unresolved
import mem from 'mem';

import { getToken, removeToken, updateToken } from './tokenService';
import { axiosInstance } from './axiosService';
import { ORIGINAL_REFRESH_TOKEN, ORIGINAL_TOKEN, REFRESH_TOKEN, TOKEN } from '../constants/localStorage';

const refreshTokenFn = async () => {
  try {
    const refreshToken = getToken(REFRESH_TOKEN) ?? getToken(ORIGINAL_REFRESH_TOKEN);

    const response = await axiosInstance.post('/auth/refresh-token', {
      refreshToken,
    });

    if (!response.data) {
      removeToken(TOKEN);
      removeToken(REFRESH_TOKEN);
      removeToken(ORIGINAL_TOKEN);
      removeToken(ORIGINAL_REFRESH_TOKEN);
    } else if (getToken(ORIGINAL_REFRESH_TOKEN)) {
      removeToken(ORIGINAL_TOKEN);
      updateToken(REFRESH_TOKEN, refreshToken);
      removeToken(ORIGINAL_REFRESH_TOKEN);
      updateToken(TOKEN, response.data);

      return window.location.replace('/');
    }

    updateToken(TOKEN, response.data);

    return response.data;
  } catch (error) {
    removeToken(TOKEN);
    removeToken(REFRESH_TOKEN);
    removeToken(ORIGINAL_TOKEN);
    removeToken(ORIGINAL_REFRESH_TOKEN);
    window.location.replace('/auth');
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
