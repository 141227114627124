import React from 'react';
import { Box, JsonInput, Text } from '@mantine/core';
import { SCHEDULED_REPORTS_LOG_STATUS_COLOR } from './scheduledReportLogs';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { formatReportName } from '../../../../../../constants/propertyReportPages';

dayjs.extend(utc);

export const columns = [
  {
    accessorKey: 'created_date',
    header: 'Last Run',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false,
    Cell: ({ cell }) => dayjs(cell.getValue()).format('ddd DD MMM h:mm A'),
    accessorFn: (row) => {
      const sDay = dayjs(row.created_date).utc().toDate();
      return sDay;
    },
    maxSize: 0,
  },
  {
    accessorKey: 'report_trigger_type',
    header: 'Trigger Type',
    Cell: ({ cell }) => <Text tt='capitalize'>{cell.getValue().replaceAll('_', ' ')}</Text>,
    maxSize: 0,
  },
  {
    accessorKey: 'status',
    header: 'Delivery Status',
    Cell: ({ cell }) => (
      <Text c={SCHEDULED_REPORTS_LOG_STATUS_COLOR[cell.getValue()]} tt='capitalize'>
        {cell.getValue().replaceAll('_', ' ')}
      </Text>
    ),
    maxSize: 0,
  },
  {
    accessorKey: 'updated_date',
    header: 'Status Updated',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false,
    Cell: ({ cell }) => dayjs(cell.getValue()).format('ddd DD MMM h:mm A'),
    accessorFn: (row) => {
      const sDay = dayjs(row.updated_date).utc().toDate();
      return sDay;
    },
    maxSize: 0,
  },
  {
    accessorKey: 'id',
    header: 'Report ID',
    maxSize: 0,
  },
  {
    accessorKey: 'sender',
    header: 'Sender',
    Cell: ({ cell }) => <Text>{cell.getValue() ?? 'N/A'}</Text>,
    maxSize: 0,
  },
];

export const detailPanel = ({ row }) => (
  <>
    <Box
      sx={{
        display: 'grid',
        margin: 'auto',
        gridTemplateColumns: '1fr 1fr 1fr',
        width: '100%',
      }}
    >
      <div>
        <Text fw={700}>Report Date Range:</Text>
        <Text>{`${row.original.report_date_from} — ${row.original.report_date_to}`}</Text>
      </div>
      <div>
        <Text fw={700}>Report Pages:</Text>
        <Text>{row.original.report_pages.map((page) => formatReportName(page, true)).join(', ')}</Text>
      </div>
      <div>
        <Text fw={700}>Recipients:</Text>
        <Text>{row.original.recipients.join(', ')}</Text>
      </div>
    </Box>
    {row.original.status_details && !!Object.keys(row.original.status_details).length && (
      <div>
        <Text fw={700}>Status Details:</Text>
        <JsonInput value={JSON.stringify(row.original.status_details, null, 2)} autosize />
      </div>
    )}
  </>
);

export const initialState = {
  sorting: [{ id: 'updated_date', desc: true }],
  pagination: { pageSize: 5 },
};
