import { axiosInstance } from '../../../../../../services/axiosService';

const API_PATH = '/admin/portfolio-overview';

export const useInternalPortfolioOverviewService = () => {
  const getTableData = async (params, config = {}) => {
    const response = await axiosInstance.get(API_PATH, { ...config, params });

    return response?.data;
  };

  return {
    getTableData,
  };
};
