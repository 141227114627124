import { axiosInstance } from './axiosService';

const API_PATH = '/admin/upload-logo';

export const useUploadFileService = () => {
  const upload = async (file, name, bucketName) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);

    const response = await axiosInstance.post(API_PATH, formData, {
      params: { bucketName },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response?.data;
  };

  return {
    upload,
  };
};
