import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';
import { useRentComparisonService } from '../../../../../../../services/bi/property/MarketSurvey/RentComperisonService';

const OccupancyMetric = () => {
  const { getOccupancy } = useRentComparisonService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Occupancy',
        showTrend: false,
        valueFormatter: (value) => Math.round(value),
        suffix: '%',
        hint: 'The percent of all available units within a property that are currently occupied.',
      }}
      fetchMethod={getOccupancy}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default OccupancyMetric;
