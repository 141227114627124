import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Container, Flex, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { columns, initialState } from './constants/scheduledReportDef';
import { useScheduledReportsService } from '../../../../../services/scheduledReportsService';
import { AuthContext } from '../../../../../context/AuthContext';

const AdminScheduledReports = () => {
  const data = useLoaderData();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const managedReports = data.filter(({ account_manager_id }) => account_manager_id === currentUser.id);
    setFilteredData(checked ? managedReports : data);
  }, [checked]);

  const onClickAddScheduledReport = () => {
    navigate('/admin/reports/add');
  };

  const table = useMantineReactTable({
    columns,
    initialState,
    data: filteredData,
    mantineTableBodyProps: {
      sx: {
        '& tr:nth-of-type(odd)': {
          backgroundColor: '#f9fdff',
        },
        td: {
          backgroundColor: 'transparent',
        },
      },
    },
    renderTopToolbarCustomActions: () => {
      return (
        <Flex p='8px'>
          <Checkbox
            sx={{ input: { cursor: 'pointer' } }}
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
            label={<Title order={6}>My Managed Reports</Title>}
          />
        </Flex>
      );
    },
  });

  return (
    <Container fluid px={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title fw={700} ml='lg' my={25}>
          Reports
        </Title>
        <Button color='gray' variant='default' onClick={onClickAddScheduledReport}>
          <IconPlus style={{ marginRight: 5 }} />
          Create
        </Button>
      </div>
      <MantineReactTable table={table} />
    </Container>
  );
};

export const loader = async () => {
  const { get } = useScheduledReportsService();
  return await get();
};

export default AdminScheduledReports;
