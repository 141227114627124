import { Button, Center, Container, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

export default function TokenError() {
  const error = useRouteError();
  const navigate = useNavigate();
  console.log(error);
  const onClickBack = () => navigate('/auth');

  return (
    <Container mt={100}>
      <Text align={'center'} fw={800} sx={{ fontSize: '33px' }}>
        {error?.response?.data?.message}
      </Text>
      <Text align={'center'} fw={500} mt={10} sx={({ colors }) => ({ fontSize: '19px', color: colors.gray[7] })}>
        Please contact support if you need assistance
      </Text>
      <Center>
        <Button mt={30} variant='light' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Back to login
        </Button>
      </Center>
    </Container>
  );
}
