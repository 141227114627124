export const transformKeysToWordsAndUppercase = (inputArray, keysToExclude = []) => {
  const transformedKeys = new Set();
  const transformed = inputArray?.map((obj) => {
    const transformedObj = {};
    for (const key of Object.keys(obj)) {
      if (keysToExclude.includes(key)) {
        transformedObj[key] = obj[key];
      } else {
        const transformedKey = key
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        transformedObj[transformedKey] = obj[key];
        transformedKeys.add(transformedKey);
      }
    }
    return transformedObj;
  });

  return { transformed, transformedKeys: Array.from(transformedKeys) };
};
