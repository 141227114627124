import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { useBiPropertyOverviewService } from '../../../../../../../../services/bi/property/overview/PropertyOverviewService';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import LineChartComponent from '../../../../../../../../components/LineChart/LineChart';

export default function PriceAndOccupancy() {
  const { getPriceAndOccupancy } = useBiPropertyOverviewService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{
        title: 'Price & Occupancy Overview',
        index: 'date',
        valueFormatter: (x) => `${Number(x).toFixed(2)}`,
        yAxisConfig: [
          {
            key: 'Avg Rent Per Sqft',
            position: 'left',
            prefix: '$',
            valueFormatter: (x) => `$${Number(x).toFixed(2)}`,
          },
          {
            key: 'Occupancy',
            position: 'right',
            suffix: '%',
            valueFormatter: (x) => `${Number(x).toFixed(2)}%`,
          },
        ],
      }}
      fetchMethod={getPriceAndOccupancy}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
