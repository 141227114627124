import React, { useContext } from 'react';
import { useUsersActivityService } from '../../../../../../../services/usersActivityService';
import { UsersActivityContext } from '../../../../../../../context/UsersActivityContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import dayjs from 'dayjs';
import LineChartComponent from '../../../../../../../components/LineChart/LineChart';

const UsersCountByCreatedDate = () => {
  const { getUsersByCreatedDate } = useUsersActivityService();
  const { dates } = useContext(UsersActivityContext);

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{
        title: 'Users By Date',
        index: 'date',
      }}
      fetchMethod={getUsersByCreatedDate}
      defaultValue={[]}
      deps={[dates]}
      params={{ dates }}
      paramsValidator={(params) => params.dates?.length}
      paramsFormatter={(params) => {
        return [dayjs(params.dates[0]).format('YYYY-MM-DD'), dayjs(params.dates[1]).format('YYYY-MM-DD')];
      }}
    />
  );
};

export default UsersCountByCreatedDate;
