import React from 'react';
import { Container, Grid, SimpleGrid } from '@mantine/core';
import ImpressionsMetric from './Metrics/ImpressionsMetric';
import CpcAllMetric from './Metrics/CpcAllMetric';
import CtrAllMetric from './Metrics/CtrAllMetric';
import LinkClicksMetric from './Metrics/LinkClicksMetric';
import PageViewsMetric from './Metrics/PageViewsMetric';
import CostPerClickAndClicks from './Charts/CostPerClickAndClicks/CostPerClickAndClicks';
import LandingPageViews from './Charts/LandingPageViews/LandingPageViews';
import ReachByDate from './Charts/ReachByDate/ReachByDate';
import ReachByDevice from './Charts/ReachByDevice/ReachByDevice';
import ReachByPlatform from './Charts/ReachByPlatform/ReachByPlatform';
import FrequencyMetric from './Metrics/FrequencyMetric';

export default function PropertyMarketingSocialMediaAdvertisingSection() {
  return (
    <Container fluid m={0} pb='lg'>
      <SimpleGrid
        cols={6}
        breakpoints={[
          { maxWidth: 'xl', cols: 3, spacing: 'md' },
          { maxWidth: 'lg', cols: 2, spacing: 'md' },
          { maxWidth: 'md', cols: 1, spacing: 'sm' },
        ]}
      >
        <ImpressionsMetric />
        <LinkClicksMetric />
        <PageViewsMetric />
        <CpcAllMetric />
        <CtrAllMetric />
        <FrequencyMetric />
      </SimpleGrid>
      <Grid mt='md'>
        <Grid.Col span={6} xl={6} lg={12} md={12} sm={12} xs={12}>
          <CostPerClickAndClicks />
        </Grid.Col>
        <Grid.Col span={6} xl={6} lg={12} md={12} sm={12} xs={12}>
          <LandingPageViews />
        </Grid.Col>
        <Grid.Col span={6} xl={4} lg={6} md={12} sm={12} xs={12}>
          <ReachByPlatform />
        </Grid.Col>
        <Grid.Col span={6} xl={4} lg={6} md={12} sm={12} xs={12}>
          <ReachByDate />
        </Grid.Col>
        <Grid.Col span={6} xl={4} lg={6} md={12} sm={12} xs={12}>
          <ReachByDevice />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
