import React, { useEffect, useState } from 'react';
import { Select, Text } from '@mantine/core';
import { generateTimeStrings } from '../../utils/time.util';
import dayjs from 'dayjs';

//This allows parsing from one custom format to another
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const TimeSelector = ({ value, onChange, readOnly, required, timezone = 'ET' }) => {
  const options = generateTimeStrings(7, 0);

  const [selectedTime, setSelectedTime] = useState(value);

  const handleClick = (value) => {
    const newValue = options.find((x) => x === value) ?? options[0];
    setSelectedTime(newValue);

    if (onChange) {
      onChange(dayjs(newValue, 'hh:mm A').format('HH:mm'));
    }
  };

  useEffect(() => {
    handleClick(value && dayjs(value, 'HH:mm').format('hh:mm A'));
  }, [value]);

  return (
    <Select
      required={required}
      readOnly={readOnly}
      value={selectedTime}
      label='Send at'
      data={options}
      onChange={handleClick}
      searchable
      rightSection={<Text size='sm'>{timezone}</Text>}
    />
  );
};

export default TimeSelector;
