import { axiosInstance } from './axiosService';

const API_PATH = '/admin/verified-company-users';

export const useVerifiedCompanyUsersService = () => {
  const get = async (company_id) => {
    const response = await axiosInstance.get(API_PATH, { params: { company_id } });

    return response?.data;
  };

  const createVerifiedCompanyUser = async (user) => {
    const response = await axiosInstance.post(`${API_PATH}/create`, user);

    return response?.data;
  };

  const deleteVerifiedCompanyUser = async (id) => {
    const response = await axiosInstance.delete(`${API_PATH}/${id}`);

    return response?.data;
  };

  return {
    get,
    createVerifiedCompanyUser,
    deleteVerifiedCompanyUser,
  };
};
