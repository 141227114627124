import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import dayjs from 'dayjs';
import { useCallsService } from '../../../../../../../../services/bi/property/teamPerformance/callsService';
import VerticalBarChartComponent from '../../../../../../../../components/VerticalBarChart/VerticalBarChart';

const CallType = () => {
  const { getCallType } = useCallsService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={VerticalBarChartComponent}
      childProps={{
        title: 'Call Type',
        index: 'call_type',
        showLegend: false,
        hint: 'Classify and track the nature of each call to gain insights into call handling and customer interactions.',
      }}
      fetchMethod={getCallType}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => {
        return [
          params.property.value,
          dayjs(params.dates[0]).format('YYYY-MM-DD'),
          dayjs(params.dates[1]).format('YYYY-MM-DD'),
        ];
      }}
    />
  );
};

export default CallType;
