import { React } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import PageNotFound from '../pages/PageNotFound';
import Properties from '../pages/Properties/Properties';
import Login from '../pages/Auth/Login';
import App from '../App';
import AuthLayout from '../layouts/AuthLayout';
import EmailConfirmation, { loader as emailConfirmationLoader } from '../pages/Auth/EmailConfirmation';
import ProtectedRoute from './ProtectedRoute';
import { ADMIN_ROLES, USER_ROLES } from '../constants/user';
import PropertyOverviewBoxScoreSection from '../pages/Properties/Components/Sections/PropertyOverview/BoxScore/BoxScore';
import PropertyOverviewSection from '../pages/Properties/Components/Sections/PropertyOverview/Overview/Overview';
import PropertyMarketingWebsitePerformanceSection from '../pages/Properties/Components/Sections/Marketing/WebsitePerformance/WebsitePerformance';
import PropertyMarketingSearchEngineMarketingSection from '../pages/Properties/Components/Sections/Marketing/SearchEngineMarketing/SearchEngineMarketing';
import PropertyMarketingDigitalMarketSurveySection from '../pages/Properties/Components/Sections/Marketing/DigitalMarketSurvey/DigitalMarketSurvey';
import PropertyMarketingBrandAwarenessSection from '../pages/Properties/Components/Sections/Marketing/BrandAwareness/BrandAwareness';
import PropertyMarketingRemarketingSection from '../pages/Properties/Components/Sections/Marketing/Remarketing/Remarketing';
import PropertyMarketingWebsiteLeadAttributionSection from '../pages/Properties/Components/Sections/Marketing/WebsiteLeadAttribution/WebsiteLeadAttribution';
import { PropertyFiltersProvider } from '../context/PropertyFiltersContext';
import AdminPage from '../pages/Admin/AdminPage';
import AdminAddCompany from '../pages/Admin/Components/Sections/Companies/AddCompany';
import AdminViewProperty, {
  loader as adminPropertyDetailsLoader,
} from '../pages/Admin/Components/Sections/Properties/AdminViewProperty';
import AdminAddProperty from '../pages/Admin/Components/Sections/Properties/AddProperty';
import AdminEditProperty from '../pages/Admin/Components/Sections/Properties/AdminEditProperty';
import AdminProperties, {
  loader as adminPropertiesLoader,
} from '../pages/Admin/Components/Sections/Properties/Properties';
import AdminCompanies, { loader as adminCompaniesLoader } from '../pages/Admin/Components/Sections/Companies/Companies';
import AdminViewCompany, {
  loader as adminCompanyDetailsLoader,
} from '../pages/Admin/Components/Sections/Companies/ViewCompany';
import AdminEditCompany from '../pages/Admin/Components/Sections/Companies/EditCompany';
import AdminUsers, { loader as adminUsersLoader } from '../pages/Admin/Components/Sections/Users/Users';
import AdminScheduledReports, {
  loader as adminScheduledDetailsLoader,
} from '../pages/Admin/Components/Sections/AdminScheduledReports/AdminScheduledReports';
import AdminAddUser from '../pages/Admin/Components/Sections/Users/AddUser';
import AdminEditUser from '../pages/Admin/Components/Sections/Users/EditUser';
import AdminViewUser, { loader as adminUserDetailsLoader } from '../pages/Admin/Components/Sections/Users/ViewUser';
import SendResetPasswordEmail from '../pages/Auth/SendResetPasswordEmail';
import ResetPassword, { loader as resetPasswordLoader } from '../pages/Auth/ResetPassword';
import TokenError from '../pages/Auth/TokenError';
import AdminBudget from '../pages/Admin/Components/Sections/AdminBudget/AdminBudget';
import PortfolioOverviewPage from '../pages/PortfolioOverview/PortfolioOverviewPage';
import ProtectedTokenRoute from './ProtectedTokenRoute';
import ErrorBoundary from '../pages/ErrorBoundary';
import { FEATURE_FLAGS } from '../constants/featureFlags';
import PropertyMarketingSocialMediaAdvertisingSection from '../pages/Properties/Components/Sections/Marketing/SocialMediaAdvertising/SocialMediaAdvertising';
import AdminAddScheduledReport from '../pages/Admin/Components/Sections/AdminScheduledReports/AddScheduledReport';
import AdminViewScheduledReport, {
  loader as adminScheduledReportDetailsLoader,
} from '../pages/Admin/Components/Sections/AdminScheduledReports/AdminViewScheduledReport';
import AdminSystemHealth from '../pages/Admin/Components/Sections/SystemHealth/SystemHealth';
import UsersActivity from '../pages/Admin/Components/Sections/UsersActivity/UsersActivity';
import AdminEditScheduledReport from '../pages/Admin/Components/Sections/AdminScheduledReports/AdminEditScheduledReport';
import { UsersActivityProvider } from '../context/UsersActivityContext';
import PropertyTeamPerformanceCallsSection from '../pages/Properties/Components/Sections/TeamPerformance/Calls/Calls';
import AdminPortfolioOverview from '../pages/Admin/Components/Sections/PortfolioOverview/PortfolioOverview';
import ProtectedPropertiesRoute from './ProtectedPropertiesRoute';
import PropertyMarketSurveyRentComparisonSection from '../pages/Properties/Components/Sections/MarketSurvey/RentComparison/RentComparison';

//TODO [Refactor] split into several files
export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthLayout>
        <App />
      </AuthLayout>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      { element: <Navigate to='properties' />, index: true },
      {
        path: 'properties',
        element: (
          <ProtectedRoute roles={[USER_ROLES.PROPERTY_MANAGER, USER_ROLES.SDM_USER]} redirectPath='/admin'>
            <PropertyFiltersProvider>
              <ProtectedPropertiesRoute>
                <Properties />
              </ProtectedPropertiesRoute>
            </PropertyFiltersProvider>
          </ProtectedRoute>
        ),
        children: [
          { element: <Navigate to='property_overview' />, index: true },
          {
            path: 'property_overview',
            children: [
              { element: <Navigate to='overview' />, index: true },
              {
                path: 'overview',
                element: <PropertyOverviewSection />,
              },
              {
                path: 'box_score',
                element: <PropertyOverviewBoxScoreSection />,
              },
            ],
          },
          {
            path: 'marketing',
            children: [
              { element: <Navigate to='website_performance' />, index: true },
              {
                path: 'website_performance',
                element: <PropertyMarketingWebsitePerformanceSection />,
              },
              {
                path: 'search_engine_marketing',
                element: <PropertyMarketingSearchEngineMarketingSection />,
              },
              {
                path: 'digital_market_survey',
                element: <PropertyMarketingDigitalMarketSurveySection />,
              },
              {
                path: 'brand_awareness',
                element: <PropertyMarketingBrandAwarenessSection />,
              },
              {
                path: 'remarketing',
                element: <PropertyMarketingRemarketingSection />,
              },
              {
                path: 'social_media_advertising',
                element: <PropertyMarketingSocialMediaAdvertisingSection />,
              },
              {
                path: 'website_lead_attribution',
                element: <PropertyMarketingWebsiteLeadAttributionSection />,
              },
            ],
          },
          {
            path: 'market_survey',
            children: [
              { element: <Navigate to='rent_comparison' />, index: true },
              {
                path: 'rent_comparison',
                element: (
                  <ProtectedRoute flag={FEATURE_FLAGS.HelloDataIntegration} featureRedirectPath='/properties'>
                    <PropertyMarketSurveyRentComparisonSection />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'team_performance',
            children: [
              { element: <Navigate to='call_summary' />, index: true },
              {
                path: 'call_summary',
                element: <PropertyTeamPerformanceCallsSection />,
              },
            ],
          },
        ],
      },
      {
        path: 'portfolio-overview',
        element: (
          <ProtectedRoute roles={USER_ROLES.PROPERTY_MANAGER} redirectPath='/admin'>
            <PortfolioOverviewPage />
          </ProtectedRoute>
        ),
      },
      // TODO Forecast planner will be returned when it will have something to show
      // {
      //   path: 'forecast-planner',
      //   element: (
      //     <ProtectedRoute roles={USER_ROLES.PROPERTY_MANAGER} redirectPath='/admin'>
      //       <ForecastPlanner />
      //     </ProtectedRoute>
      //   ),
      // },
      {
        path: '/admin',
        element: (
          <ProtectedRoute roles={ADMIN_ROLES}>
            <AdminPage />
          </ProtectedRoute>
        ),
        children: [
          {
            element: <Navigate to='portfolio_overview' />,
            index: true,
          },
          {
            path: 'companies',
            element: (
              <ProtectedRoute roles={[USER_ROLES.SDM_USER]}>
                <AdminCompanies />
              </ProtectedRoute>
            ),
            loader: adminCompaniesLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'companies/add',
            element: (
              <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                <AdminAddCompany />
              </ProtectedRoute>
            ),
          },
          {
            path: 'companies/:id',
            id: 'company-details',
            loader: adminCompanyDetailsLoader,
            errorElement: <ErrorBoundary />,
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                    <AdminViewCompany />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'edit',
                element: (
                  <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                    <AdminEditCompany />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'properties',
            element: (
              <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                <AdminProperties />
              </ProtectedRoute>
            ),
            loader: adminPropertiesLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'properties/add',
            element: (
              <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                <AdminAddProperty />
              </ProtectedRoute>
            ),
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'properties/:id',
            id: 'property-details',
            loader: adminPropertyDetailsLoader,
            errorElement: <ErrorBoundary />,
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                    <AdminViewProperty />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'edit',
                element: (
                  <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                    <AdminEditProperty />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'budget',
            element: (
              <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                <AdminBudget />
              </ProtectedRoute>
            ),
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'users',
            element: (
              <ProtectedRoute roles={ADMIN_ROLES}>
                <AdminUsers />
              </ProtectedRoute>
            ),
            loader: adminUsersLoader,
            errorElement: <ErrorBoundary />,
          },
          { path: 'users/add', element: <AdminAddUser /> },
          {
            path: 'users/:id',
            id: 'user-details',
            loader: adminUserDetailsLoader,
            errorElement: <ErrorBoundary />,
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute roles={ADMIN_ROLES}>
                    <AdminViewUser />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'edit',
                element: (
                  <ProtectedRoute roles={ADMIN_ROLES}>
                    <AdminEditUser />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'scheduled_reports/*',
            element: <Navigate to='/admin/reports' replace />,
          },
          {
            path: 'reports',
            element: (
              <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                <AdminScheduledReports />
              </ProtectedRoute>
            ),
            loader: adminScheduledDetailsLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'reports/add',
            element: <AdminAddScheduledReport />,
          },
          {
            path: 'reports/:id',
            id: 'scheduled-report-details',
            loader: adminScheduledReportDetailsLoader,
            errorElement: <ErrorBoundary />,
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                    <AdminViewScheduledReport />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'edit',
                element: (
                  <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                    <AdminEditScheduledReport />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'system_health',
            element: (
              <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                <AdminSystemHealth />
              </ProtectedRoute>
            ),
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'users_activity',
            element: (
              <ProtectedRoute roles={USER_ROLES.SDM_USER}>
                <UsersActivityProvider>
                  <UsersActivity />
                </UsersActivityProvider>
              </ProtectedRoute>
            ),
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'portfolio_overview',
            element: (
              <ProtectedRoute
                roles={USER_ROLES.SDM_USER}
                redirectPath='/admin/users'
                flag={FEATURE_FLAGS.InternalPortfolioOverview}
                featureRedirectPath='/admin/users'
              >
                <AdminPortfolioOverview />
              </ProtectedRoute>
            ),
            errorElement: <ErrorBoundary />,
          },
        ],
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: '/auth',
    element: <ProtectedTokenRoute />,
    children: [
      {
        element: (
          <AuthLayout>
            <Login />
          </AuthLayout>
        ),
        index: true,
      },
      {
        path: 'confirm-email/:token',
        element: <EmailConfirmation />,
        loader: emailConfirmationLoader,
        errorElement: <TokenError />,
      },
      {
        path: 'reset-password',
        children: [
          {
            index: true,
            element: <SendResetPasswordEmail />,
            errorElement: <TokenError />,
          },
          {
            path: ':token',
            element: <ResetPassword />,
            loader: resetPasswordLoader,
            errorElement: <TokenError />,
          },
        ],
      },
    ],
  },
]);
