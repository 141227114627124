import { Text } from '@mantine/core';
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export const columns = [
  {
    accessorKey: 'id',
    header: 'ID',
    size: 80,
  },

  {
    accessorKey: 'full_name',
    header: 'Full name',
    Cell: ({ cell, row }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <Link to={`/admin/users/${row.original.id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      </div>
    ),
  },
  {
    accessorKey: 'email',
    header: 'Email',
    Cell: ({ cell }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <Text>{cell.getValue()}</Text>
      </div>
    ),
  },
  {
    accessorKey: 'last_login',
    header: 'Last login',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false,
    Cell: ({ cell }) => dayjs(cell.getValue()).format('MMM DD YYYY hh:mm:ss A'),
    accessorFn: (row) => {
      return new Date(row.last_login);
    },
    minSize: 200,
  },
];

export const initialState = {
  sorting: [{ id: 'last_login', desc: true }],
  pagination: { pageSize: 10 },
};
