import React, { useEffect, useState } from 'react';
import { Chip, Flex, Group, Text } from '@mantine/core';

const DayOfMonthSelector = ({ value, onChange, disabled, cutoff = 7 }) => {
  const daysOfMonth = Array.from({ length: cutoff }, (v, k) => k + 1).map(String);

  const [selectedDay, setSelectedDay] = useState(value);

  const handleClick = (value) => {
    const newValue = daysOfMonth.find((x) => x === value) ?? daysOfMonth[0];
    setSelectedDay(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    handleClick(value);
  }, [value]);

  return (
    <Flex align={'baseline'} gap='sm'>
      <Text size={'sm'}>On: </Text>
      <Chip.Group multiple={false} value={selectedDay} onChange={handleClick}>
        <Group justify='center'>
          {daysOfMonth.map((day) => (
            <Chip key={day} value={day} disabled={disabled}>
              {day}
            </Chip>
          ))}
        </Group>
      </Chip.Group>
    </Flex>
  );
};

export default DayOfMonthSelector;
