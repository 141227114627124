import { NumberInput } from '@mantine/core';
import React, { useContext, useEffect } from 'react';
import { IntegrationsContext } from '../IntegrationsComponent';

export default function EntrataConnection() {
  const { readOnly, connection, connectionData, setConnectionData } = useContext(IntegrationsContext);

  useEffect(() => {
    setConnectionData(connection?.value ?? '');
  }, [connection?.value]);

  return (
    <NumberInput
      hideControls
      mt='sm'
      label='Account ID'
      placeholder='ID'
      min={0}
      value={connectionData}
      onChange={setConnectionData}
      readOnly={readOnly}
    />
  );
}
