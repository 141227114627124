import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Drawer, Flex, Group, Spoiler, Text, UnstyledButton, Container, useMantineTheme, Box } from '@mantine/core';
import { IconLayoutSidebarRightExpandFilled, IconX } from '@tabler/icons-react';
import { usePropertyDetails } from '../../../../context/PropertyDetailsContext';
import { Link } from 'react-router-dom';

export default function PropertyKnowledgePanel() {
  const { details } = usePropertyDetails();
  const { colors } = useMantineTheme();

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Drawer.Root position='right' opened={opened} onClose={close} zIndex={223}>
        <Drawer.Overlay opacity={0.2} />
        <Drawer.Content
          mt='120px'
          style={{
            backgroundColor: colors.secondary_background_color[6],
            border: `1px solid ${colors.company_theme_primary_color[6]}`,
            height: 'calc(100vh - 120px)',
          }}
        >
          <Drawer.Body p={0}>
            <Box sx={{ position: 'absolute', cursor: 'pointer' }} m={10} onClick={close}>
              <IconX color={colors.text_color[5]} />
            </Box>

            <img
              width='100%'
              height={200}
              style={{ objectFit: 'cover' }}
              src={`https://placehold.co/600x400/${
                colors.company_theme_primary_color[6]?.replace('#', '') ?? 'grey'
              }/white?text=No Property Image`}
            />

            <Container pt='md'>
              <Text size='lg' fw={700}>
                {details?.marketing_name}
              </Text>
              <Text color='dimmed'>{details?.type}</Text>
            </Container>
            <Group mt='lg' spacing='sm' p='lg'>
              <Flex gap='sm' justify='baseline'>
                <Text size='sm' color='dimmed'>
                  Address:
                </Text>
                <Text>{`${details?.address}, ${details?.city}, ${details?.state}, ${details?.zip_code}`}</Text>
              </Flex>
              <Flex gap='sm'>
                <Text size='sm' color='dimmed'>
                  Website:
                </Text>
                <Link to={details?.website}>{details?.website}</Link>
              </Flex>
              <Flex gap='sm'>
                <Text size='sm' color='dimmed'>
                  Email:
                </Text>
                <Link to={`mailto:${details?.email}`}>{details?.email}</Link>
              </Flex>
              <Spoiler maxHeight={200} showLabel='Show more' hideLabel='Hide'>
                {details?.description}
              </Spoiler>
            </Group>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>

      {details && (
        <UnstyledButton
          onClick={open}
          style={{
            border: `1px solid ${colors.company_theme_primary_color[6]}`,
            padding: '1rem 2rem 1rem 1rem',
            borderRadius: '1rem',
            backgroundColor: colors.secondary_background_color[6],
          }}
        >
          <>
            <IconLayoutSidebarRightExpandFilled />
            <Text mt='md' style={{ writingMode: 'tb-rl', transform: 'rotate(-180deg)' }}>
              Expand Property Knowledge Panel
            </Text>
          </>
        </UnstyledButton>
      )}
    </>
  );
}
