import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/marketing/website-lead-attribution';

export const useWebsiteLeadsService = () => {
  const { axios } = useAxios();

  const getWebsiteLeads = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/website-leads`, { ...config, params });

    return response?.data;
  };

  const getPhoneCalls = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/phone-calls`, { ...config, params });

    return response?.data;
  };

  const getFormSubmits = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/form-submits`, { ...config, params });

    return response?.data;
  };

  const getWebsiteLeadsByDay = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/website-leads-by-day`, { ...config, params });

    return response?.data;
  };

  const getWebsiteLeadsBySource = async (property_id, from, to, multiTouchAttribution, config = {}) => {
    const params = { property_id, from, to, multiTouchAttribution };
    const response = await axios.get(`${API_PATH}/website-leads-by-source`, { ...config, params });

    return response?.data;
  };

  const getProspectWebsiteLeadPath = async (property_id, from, to, multiTouchAttribution, config = {}) => {
    const params = { property_id, from, to, multiTouchAttribution };
    const response = await axios.get(`${API_PATH}/website-leads-by-source`, { ...config, params });

    return response?.data;
  };

  const getPercentageOfTotalWebsiteLeads = async (property_id, from, to, multiTouchAttribution, config = {}) => {
    const params = { property_id, from, to, multiTouchAttribution };
    const response = await axios.get(`${API_PATH}/website-leads-by-source`, { ...config, params });

    return response?.data;
  };

  return {
    getWebsiteLeads,
    getPhoneCalls,
    getFormSubmits,
    getWebsiteLeadsByDay,
    getWebsiteLeadsBySource,
    getProspectWebsiteLeadPath,
    getPercentageOfTotalWebsiteLeads,
  };
};
