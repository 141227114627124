import React from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Button, Container, Title } from '@mantine/core';
import { MantineReactTable } from 'mantine-react-table';
import { columns, initialState } from './constants/companyTableDef';
import { useCompanyService } from '../../../../../services/companyService';
import { IconPlus } from '@tabler/icons-react';

export default function AdminCompanies() {
  const data = useLoaderData();
  const navigate = useNavigate();

  const onClickAddCompany = () => {
    navigate('/admin/companies/add');
  };

  return (
    <Container fluid px={30} pb={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title fw={700} ml='lg' my={25}>
          Companies
        </Title>
        <Button color='gray' variant='default' onClick={onClickAddCompany}>
          <IconPlus style={{ marginRight: 5 }} />
          Add company
        </Button>
      </div>
      <MantineReactTable
        columns={columns}
        data={data}
        initialState={initialState}
        mantineTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f9fdff',
            },
            td: {
              backgroundColor: 'transparent',
            },
          },
        }}
      />
    </Container>
  );
}

export const loader = async () => {
  const { get } = useCompanyService();
  return await get();
};
