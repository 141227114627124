import React, { useContext, useState } from 'react';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import SimpleReportTable from '../../../../../../../../components/SimpleReportTable/SimpleReportTable';
import { columns, initialState, state } from './constants/rentalComparisonByPropertyTableDef';
import dayjs from 'dayjs';
import { useRentComparisonService } from '../../../../../../../../services/bi/property/MarketSurvey/RentComperisonService';
import { Box, Flex, Switch, Title, useMantineTheme, Text, Group } from '@mantine/core';

const RentalComparisonByProperty = () => {
  const { getRentalComparisonByProperty } = useRentComparisonService();

  const { property, dates } = useContext(PropertyFiltersContext);

  const { colors } = useMantineTheme();
  const [isEffectiveRent, setIsEffectiveRent] = useState(true);

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'Rental Comparison: By Property',
        columns,
        initialState,
        state: { ...state, isEffectiveRent, setIsEffectiveRent },
        enablePagination: false,
        enableBottomToolbar: false,
        enableCustomTitle: true,
        enableColumnFilters: true,
        enableFilters: true,
        isStriped: true,
        enableColumnActions: true,
        renderCustomTitle: (params) => {
          return (
            <Flex justify='space-between' align='center' sx={{ marginBottom: '1rem' }}>
              <Group sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Title size={20} fw='600'>
                  {params.title}
                </Title>
                <Title order={6} fw='600' color='dimmed' sx={{ flexGrow: 1, textAlign: 'center' }}>
                  Information displayed is based on publicly available date
                </Title>
              </Group>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text mr={7}>MKT</Text>
                <Switch
                  checked={isEffectiveRent}
                  onChange={(event) => setIsEffectiveRent(event.currentTarget.checked)}
                  sx={() => ({
                    '& .mantine-Switch-track': {
                      backgroundColor: '#CCCFD5',
                    },
                    '& .mantine-Switch-thumb': {
                      backgroundColor: colors.primary_background[6],
                      border: 'none',
                    },
                  })}
                />
                <Text ml={7} color='dimmed'>
                  EFF
                </Text>
              </Box>
            </Flex>
          );
        },
      }}
      fetchMethod={getRentalComparisonByProperty}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default RentalComparisonByProperty;
