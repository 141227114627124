import React, { useEffect, useRef } from 'react';

const VideoBackground = ({ styles }) => {
  const videoRef = useRef(null);

  //TODO Move to envs
  const poster = 'https://storage.googleapis.com/sdm-image-uploads/SDM_login_background_video_poster.jpg';
  const videoSource = 'https://storage.googleapis.com/sdm-image-uploads/SDM_login_background_video.mp4';

  useEffect(() => {
    const preloadVideo = async () => {
      try {
        await videoRef.current.load();
        await videoRef.current.play();
      } catch (error) {
        console.error('Error preloading video:', error);
      }
    };

    preloadVideo();
  }, []);

  return (
    <>
      <video
        loop
        muted
        poster={poster}
        ref={videoRef}
        controls={false}
        style={{
          ...styles,
          height: '100vh',
          width: '100vw',
          margin: 0,
          padding: 0,
          zIndex: -1,
          objectFit: 'cover',
        }}
      >
        <source src={videoSource} type='video/mp4' />
      </video>
    </>
  );
};

export default VideoBackground;
