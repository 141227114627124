import React from 'react';
import { Container, SimpleGrid } from '@mantine/core';
import MarketImpressionShare from './Metrics/MarketImpressionShare';
import AboveOrganic from './Metrics/AboveOrganic';
import TopPlacement from './Metrics/TopPlacement';
import MarketShareLostDueToBudget from './Metrics/MarketShareLostDueToBudget';

export default function PropertyMarketingDigitalMarketSurveySection() {
  return (
    <Container fluid m={0} pb='lg'>
      <SimpleGrid
        cols={4}
        breakpoints={[
          { maxWidth: 'xl', cols: 2, spacing: 'md' },
          { maxWidth: 'lg', cols: 1, spacing: 'md' },
        ]}
      >
        <MarketImpressionShare />
        <AboveOrganic />
        <TopPlacement />
        <MarketShareLostDueToBudget />
      </SimpleGrid>
    </Container>
  );
}
