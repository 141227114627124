import React, { useContext } from 'react';
import { ActionIcon, Divider, Flex, Navbar, useMantineTheme } from '@mantine/core';
import {
  IconLock,
  IconLayoutSidebarLeftCollapseFilled,
  IconLayoutSidebarLeftExpandFilled,
  IconHomeFilled,
  IconBriefcaseFilled,
  IconHours24,
  IconFileDescription,
} from '@tabler/icons-react';
import { LinksGroup } from './LeftMenuNestes';
import { AuthContext } from '../../context/AuthContext';
import { ADMIN_ROLES, USER_ROLES } from '../../constants/user';
import { SidebarContext } from '../../context/SidebarContext';
import { useFeatureFlagsService } from '../../services/featureFlagsService';
import Info from '../Info/Info';
import { LeftMenuItemButton } from './LeftMenuItemButton';
import { useDisclosure } from '@mantine/hooks';
import { OnboardingFormModal } from '../OnboardingFormModal/OnboardingFormModal';

export function NavbarSimple() {
  const { currentUser } = useContext(AuthContext);
  const { handleCollapsedChange, enableExpanding, expanded } = useContext(SidebarContext);
  const [opened, { open: setOpenOnboardingFormModal, close: setCloseOnboardingFormModal }] = useDisclosure(false);

  const navItems = [];

  const { isFeatureEnabled } = useFeatureFlagsService();

  currentUser?.role === USER_ROLES.PROPERTY_MANAGER &&
    navItems.push(
      { link: '/properties', label: 'Properties', icon: IconHomeFilled },
      { link: '/portfolio-overview', label: 'Portfolio Overview', icon: IconBriefcaseFilled }
      // TODO Forecast planner will be returned when it will have something to show
      // { link: '/forecast-planner', label: 'Forecast Planner', icon: IconChartLine }
    );

  ADMIN_ROLES.includes(currentUser?.role) && navItems.push({ icon: IconLock, label: 'Admin Controls', link: '/admin' });

  const links = navItems
    .filter((x) => !x.featureFlag || isFeatureEnabled(x.featureFlag))
    .map((item) => <LinksGroup {...item} key={item.label} />);
  const { colors } = useMantineTheme();
  return (
    <>
      <Navbar
        width={{ sm: 90 }}
        sx={{
          zIndex: 90,
          padding: '1rem 1rem',
          backgroundColor: colors.primary_background[6],
          borderRight: `1px solid ${colors.border_color[0]}`,
        }}
      >
        <Navbar.Section grow>
          <Flex direction={'column'} align={'center'} gap='md'>
            <div
              style={{
                height: '30px',
                width: '100%',
                objectFit: 'contain',
                mask: `url(https://storage.googleapis.com/${process.env.REACT_APP_MEDIA_BUCKET_NAME}/AI-Dark-Icon.svg) no-repeat 100% 100%`,
                backgroundColor: colors.ai_logo[0],
              }}
            ></div>
            <Divider color='border_color' w='100%' />
            {links}
          </Flex>
        </Navbar.Section>
        <Navbar.Section>
          {currentUser?.role === USER_ROLES.PROPERTY_MANAGER && (
            <>
              <Flex direction='column' align='center' gap='md' pb='md'>
                <LeftMenuItemButton
                  icon={IconFileDescription}
                  label='Onboarding Form'
                  onClick={setOpenOnboardingFormModal}
                />
              </Flex>
              <Flex direction='column' align='center' gap='md' pb='md'>
                <LinksGroup
                  link={process.env.REACT_APP_AI_KNOWLEDGE_BASE_LINK}
                  icon={IconHours24}
                  label='Street Digital AI - Knowledge Base'
                  externalLink={true}
                />
              </Flex>
            </>
          )}
        </Navbar.Section>
        <Flex direction='column' align='center' gap='md'>
          <Divider color='border_color' w={'100%'} />

          <Info label={expanded ? 'Collapse side menu' : 'Expand side menu'} position='right' nowrap openDelay={200}>
            <ActionIcon
              onClick={handleCollapsedChange}
              w='3.5rem'
              h='3.5rem'
              color='icon_primary'
              variant='subtle'
              disabled={!enableExpanding}
            >
              {expanded ? (
                <IconLayoutSidebarLeftCollapseFilled size='1.5rem' />
              ) : (
                <IconLayoutSidebarLeftExpandFilled size='1.5rem' />
              )}
            </ActionIcon>
          </Info>
        </Flex>
      </Navbar>
      <OnboardingFormModal opened={opened} close={setCloseOnboardingFormModal} />
    </>
  );
}
