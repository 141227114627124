import React from 'react';
import { Box, Container, SimpleGrid } from '@mantine/core';
import OccupancyMetric from './Metrics/OccupancyMetric';
import ExposureMetric from './Metrics/ExposureMetric';
import PropertySpecsMetric from './Metrics/PropertySpecsMetric';
import AvgRentMetric from './Metrics/AvgRentMetric';
import RentalComparisonByProperty from './Tables/RentalComparisonByProperty/RentalComparisonByProperty';

const PropertyMarketSurveyRentComparisonSection = () => {
  return (
    <Container fluid m={0} pb='lg'>
      <SimpleGrid
        cols={4}
        breakpoints={[
          { maxWidth: 'xl', cols: 3, spacing: 'md' },
          { maxWidth: 'lg', cols: 2, spacing: 'md' },
          { maxWidth: 'md', cols: 1, spacing: 'sm' },
        ]}
      >
        <OccupancyMetric />
        <ExposureMetric />
        <PropertySpecsMetric />
        <AvgRentMetric />
      </SimpleGrid>
      <Box mt='md'>
        <RentalComparisonByProperty />
      </Box>
    </Container>
  );
};

export default PropertyMarketSurveyRentComparisonSection;
