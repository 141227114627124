import React, { useContext } from 'react';
import { useWebsiteLeadsService } from '../../../../../../../../services/bi/property/marketing/WebsiteLeadAttributionService';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import PieChartComponent from '../../../../../../../../components/PieChart/PieChart';
import dayjs from 'dayjs';

const PercentageOfTotalWebsiteLeads = () => {
  const { getPercentageOfTotalWebsiteLeads } = useWebsiteLeadsService();

  const { property, dates, multiTouchAttribution } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    if (!data?.length) return [];

    const totalWebsiteLeads = data.reduce((acc, cur) => acc + cur.total, 0);

    const mapped = data.map((x) => ({ ...x, percentage: (x.total / totalWebsiteLeads) * 100 }));

    const mainSources = mapped.filter((x) => x.percentage >= 1);
    const otherSources = mapped.filter((x) => x.percentage < 1);

    return [
      ...mainSources.map((x) => ({
        total: x.total,
        percentage: x.percentage,
        source_and_medium: `${x.source} / ${x.medium}`,
      })),
      {
        source_and_medium: 'Others',
        total: otherSources.reduce((acc, cur) => acc + cur.total, 0),
        percentage: otherSources.reduce((acc, cur) => acc + cur.percentage, 0),
      },
    ];
  };

  return (
    <DataFetcherComponent
      Child={PieChartComponent}
      childProps={{
        title: 'Percentage Of Total Website Leads',
        index: 'source_and_medium',
        excludeFields: ['total'],
        variant: 'donut',
        dataFormatter,
        customTooltipValueFormatter: (x) => `${Number(x.payload?.total).toFixed(2)} (${Number(x.value).toFixed(1)}%)`,
        valueFormatter: (x) => `${Number(x).toFixed(2)}%`,
      }}
      fetchMethod={getPercentageOfTotalWebsiteLeads}
      defaultValue={[]}
      deps={[property, dates, multiTouchAttribution]}
      params={{ property, dates, multiTouchAttribution }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
        multiTouchAttribution,
      ]}
    />
  );
};

export default PercentageOfTotalWebsiteLeads;
