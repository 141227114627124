import React, { useContext } from 'react';
import AlertInfo from '../../components/AlertInfo/AlertInfo';
import { SidebarContext } from '../../context/SidebarContext';

const ForecastPlanner = () => {
  const { setEnableExpanding } = useContext(SidebarContext);

  setEnableExpanding(false);

  return (
    <AlertInfo title='Coming Soon!' subtitle='Our team is actively working on the Forecast Planner implementation.' />
  );
};

export default ForecastPlanner;
