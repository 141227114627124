import React, { createContext, useContext, useState } from 'react';
import { AuthContext } from './AuthContext';

export const ThemeContext = createContext(null);

const ThemeProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [companyTheme, setCompanyTheme] = useState(currentUser?.theme);

  return (
    <ThemeContext.Provider value={{ companyTheme: !companyTheme ? currentUser?.theme : companyTheme, setCompanyTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

export const useTheme = () => {
  return useContext(ThemeContext);
};
