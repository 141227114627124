import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { USER_ROLES } from '../constants/user';

const ProtectedPropertiesRoute = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  if (currentUser.role === USER_ROLES.PROPERTY_MANAGER && !currentUser.properties?.length) {
    return <Navigate to={'/'} replace />;
  }

  if (currentUser.role === USER_ROLES.SDM_USER && !currentUser.manager_properties?.length) {
    return <Navigate to={'/admin'} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedPropertiesRoute;
