import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import LineChartComponent from '../../../../../../../../components/LineChart/LineChart';
import { useSearchEngineService } from '../../../../../../../../services/bi/property/marketing/SearchEngineService';

export default function PerformanceOverview() {
  const { getPerformanceOverview } = useSearchEngineService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{
        title: 'Performance Overview',
        index: 'date',
        yAxisConfig: [
          { key: 'Clicks', position: 'left' },
          { key: 'Impressions', position: 'right' },
        ],
      }}
      fetchMethod={getPerformanceOverview}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
