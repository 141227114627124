import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import PieChartComponent from '../../../../../../../../components/PieChart/PieChart';
import { useCallsService } from '../../../../../../../../services/bi/property/teamPerformance/callsService';

export default function CallSource() {
  const { getCallSource } = useCallsService();

  const { property, dates } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    if (!data?.length) return [];

    const mainSources = data.slice(0, 4);

    if (data.length > 4) {
      const otherSources = data.slice(4);
      return [
        ...mainSources,
        {
          source: 'Others',
          total: otherSources.reduce((acc, cur) => acc + cur.total, 0),
          percentage: otherSources.reduce((acc, cur) => acc + cur.percentage, 0),
        },
      ];
    }

    return mainSources;
  };

  return (
    <DataFetcherComponent
      Child={PieChartComponent}
      childProps={{
        title: 'Call Source',
        variant: 'donut',
        index: 'source',
        excludeFields: ['total'],
        hint: 'Identify which marketing channels are driving your inbound calls.',
        dataFormatter,
        customTooltipValueFormatter: (x) => `${x.payload?.total} (${Number(x.value).toFixed(1)}%)`,
      }}
      fetchMethod={getCallSource}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
