import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import BarChartComponent from '../../../../../../../../components/BarChart/BarChart';
import { useBiPropertyOverviewService } from '../../../../../../../../services/bi/property/overview/PropertyOverviewService';

export default function ConversionPerformance() {
  const { getConversionPerformance } = useBiPropertyOverviewService();

  const { property, dates } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    return Object.keys(data).map((key) => {
      return {
        conversionName: key
          .split('_')
          .map((k) => k.charAt(0).toUpperCase() + k.slice(1))
          .join(' > '),
        percentage: data[key],
      };
    });
  };

  return (
    <DataFetcherComponent
      Child={BarChartComponent}
      childProps={{
        title: 'Conversion Performance',
        index: 'conversionName',
        dataFormatter,
        showLegend: false,
      }}
      fetchMethod={getConversionPerformance}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
