import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import dayjs from 'dayjs';
import { useRemarketingService } from '../../../../../../../services/bi/property/marketing/RemarketingService';

export default function RemarketingClicksMetric() {
  const { getClicks } = useRemarketingService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Clicks',
        hint: "The number of people who have clicked on your ad. Minimum monthly benchmark of 1,000 clicks per month for stabilized communities' Google Ads Search campaigns. New developments should look to achieve 2,000 clicks per month. ",
      }}
      fetchMethod={getClicks}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
