import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import LineChartComponent from '../../../../../../../../components/LineChart/LineChart';
import dayjs from 'dayjs';
import { useSocialMediaAdvertising } from '../../../../../../../../services/bi/property/marketing/SocialMediaAdvertisingService';

const ReachByDate = () => {
  const { getReachByDate } = useSocialMediaAdvertising();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{
        title: 'Reach By Date',
        index: 'date',
      }}
      fetchMethod={getReachByDate}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default ReachByDate;
