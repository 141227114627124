import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const calculateNextReportSendingDate = (currentDate, day, time, frequency) => {
  const [hour, minute] = time && time.split(':').map(Number);
  let nextDate;

  if (frequency === 'weekly') {
    nextDate = currentDate.day(day).set('hour', hour).set('minute', minute);
    if (nextDate.isBefore(currentDate)) {
      nextDate = nextDate.add(1, 'week');
    }
  } else if (frequency === 'monthly') {
    nextDate = currentDate.date(day).set('hour', hour).set('minute', minute);
    if (nextDate.isBefore(currentDate)) {
      nextDate = nextDate.add(1, 'month');
    }
  }

  return nextDate;
};
