import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';
import { useRentComparisonService } from '../../../../../../../services/bi/property/MarketSurvey/RentComperisonService';
import { Grid, Text, Title } from '@mantine/core';

const AvgRentMetric = () => {
  const { getAvgRent } = useRentComparisonService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Avg Rent',
        showTrend: false,
        prefix: '$',
        hint: 'The average secured rent amount for each unit type (e.g., studio, one-bedroom, two-bedroom).',
        useCustomContent: true,
        renderCustomContent: (value, params) => {
          if (params.isLoading || (value && !value.length)) {
            return (
              <Title order={2} mt={7} fw={600} ff='Montserrat Semibold, sans-serif'>
                -
              </Title>
            );
          }
          const formatRentValue = (rent) => new Intl.NumberFormat('en-US').format(Math.round(rent));

          return (
            <Grid>
              {value?.map(({ beds, rent, avg_rent_per_sqft }, index) => (
                <Grid.Col key={index} mt={7} span={value.length > 3 ? 4 : 3}>
                  <Text size={10} color='dimmed' fw={600} ff='Montserrat Semibold, sans-serif'>
                    {beds > 0 ? `${Number(beds).toFixed()}-Bed` : 'Studio'}
                  </Text>
                  <Title order={4} fw={600} ff='Montserrat Semibold, sans-serif'>
                    {params.prefix}
                    {formatRentValue(rent)}
                  </Title>
                  <Text size={10} color='dimmed' fw={600} ff='Montserrat Semibold, sans-serif'>
                    {params.prefix}
                    {avg_rent_per_sqft?.toFixed(2)} psf
                  </Text>
                </Grid.Col>
              ))}
            </Grid>
          );
        },
      }}
      fetchMethod={getAvgRent}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default AvgRentMetric;
