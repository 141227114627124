import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import { useCallsService } from '../../../../../../../services/bi/property/teamPerformance/callsService';
import { formatDuration } from '../../../../../../../utils/time.util';

const AvgDuration = () => {
  const { getAvgDuration } = useCallsService();

  const { property, dates } = useContext(PropertyFiltersContext);

  const valueFormatter = (value) => {
    const duration = dayjs.duration(value, 'seconds');
    return formatDuration(duration);
  };

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Avg. Duration',
        hint: 'View the average duration of answered calls to evaluate engagement and efficiency.',
        valueFormatter,
      }}
      fetchMethod={getAvgDuration}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default AvgDuration;
