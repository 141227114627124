import React, { useContext } from 'react';
import { MantineProvider, useMantineTheme } from '@mantine/core';
import { THEME_MODES, DEFAULT_THEME_COLOR } from '../../constants/theme_modes';
import { ThemeContext } from '../../context/ThemeContext';

const generateShades = (baseColor) => {
  const theme = useMantineTheme();
  const shades = [];

  for (let i = 1; i <= 6; i++) {
    const shade = theme.fn.lighten(baseColor, i / 10);
    shades.unshift(shade);
  }
  shades.push(baseColor);
  for (let i = 1; i <= 3; i++) {
    const shade = theme.fn.darken(baseColor, i / 10);
    shades.push(shade);
  }

  return shades;
};

const MantineThemeProvider = ({ colorScheme, children }) => {
  const { companyTheme } = useContext(ThemeContext);

  const primaryBackground = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313843' : '#FFFFFF'
  );
  const borderColor = Array.from(
    { length: 10 },
    () => (colorScheme === THEME_MODES.darkMode && '#515C6E') || '#CCCFD5'
  );
  const backgroundWeak = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#373e4a' : '#f7f9fa'
  );
  const hover = Array.from({ length: 10 }, () => (colorScheme === THEME_MODES.darkMode ? '#252a33' : '#eef0f2'));
  const borderWeak = Array.from({ length: 10 }, () => (colorScheme === THEME_MODES.darkMode ? '#515C6E' : '#E9EBED'));
  const aiLogo = Array.from({ length: 10 }, () => (colorScheme === THEME_MODES.darkMode ? '#fff' : '#070708'));

  const pageBackground = Array.from({ length: 10 }, () => {
    return (colorScheme === THEME_MODES.darkMode && '#3F4856') || '#F5F6FA';
  });

  const tableHeader = Array.from({ length: 10 }, () => {
    return (colorScheme === THEME_MODES.darkMode && '#3F4856') || '#EEF0F2';
  });

  const tableTextHeader = Array.from({ length: 10 }, () => {
    return (colorScheme === THEME_MODES.darkMode && '#7A8494') || '#9098A5';
  });

  const gradientHeaderColor = Array.from({ length: 10 }, () => {
    return `linear-gradient(180deg, ${pageBackground[0]} 85%, transparent)`;
  });

  const gradientBackgroundColor = Array.from({ length: 10 }, () => {
    return (
      (colorScheme === THEME_MODES.darkMode &&
        'linear-gradient(180deg, rgba(18,18,18,1) 60%, rgba(32,32,32,1) 100%)') ||
      'linear-gradient(180deg, rgba(239,239,239,1) 30%, rgba(239,239,239,1) 75%)'
    );
  });

  const secondaryBackgroundColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313131' : '#efefef'
  );

  const thirdBackgroundColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313131' : '#ffffff'
  );

  const retryErrorColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313131' : '#d9d9d9'
  );

  const secondaryRetryErrorColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313131' : '#ffffff'
  );

  const hoverColor = Array.from({ length: 10 }, (_, index) => {
    const lightMode = '#d9d9d9' ?? DEFAULT_THEME_COLOR;
    const darkMode = index > 5 ? companyTheme?.secondary_dark ?? DEFAULT_THEME_COLOR : '#121212';

    return (colorScheme === THEME_MODES.darkMode && darkMode) || lightMode;
  });

  const navigationTextColor = Array.from({ length: 10 }, (_, index) => {
    const lightMode = index < 6 ? '#121212' : '#ffffff';
    const darkMode = index < 6 ? '#ffffff' : '#121212';

    return (colorScheme === THEME_MODES.darkMode && darkMode) || lightMode;
  });

  const textColor = Array.from({ length: 10 }, (_, index) => {
    const lightMode = index < 5 ? '#252A33' : '#ffffff';
    const darkMode = '#ffffff';

    return (colorScheme === THEME_MODES.darkMode && darkMode) || lightMode;
  });

  const textSecondaryColor = Array.from({ length: 10 }, () => {
    return (colorScheme === THEME_MODES.darkMode && '#EEF0F2') || '#3F4856';
  });

  const textThirdColor = Array.from({ length: 10 }, () => {
    return (colorScheme === THEME_MODES.darkMode && '#B3B8C1') || '#596579';
  });

  const textSubtle = Array.from({ length: 10 }, () => {
    return (colorScheme === THEME_MODES.darkMode && '#7A8494') || '#7A8494';
  });

  const textChartLegend = Array.from({ length: 10 }, () => {
    return (colorScheme === THEME_MODES.darkMode && '#EEF0F2') || '#596579';
  });

  const iconSecondary = Array.from({ length: 10 }, () => '#B3B8C1');

  const companyThemePrimaryColor = generateShades(
    colorScheme === THEME_MODES.darkMode
      ? companyTheme?.primary_dark ?? DEFAULT_THEME_COLOR
      : companyTheme?.primary_light ?? DEFAULT_THEME_COLOR
  );

  const linkColor = Array.from({ length: 10 }, () => (colorScheme === THEME_MODES.darkMode ? '#0f82af' : 'blue'));

  const textPlaceholderColor = Array.from({ length: 10 }, () => '#9098A5');
  const iconPrimary = [
    '#EEF0F2', //background light theme
    '#dadee1', //hover light theme
    '#CCCFD5', //color dark theme
    'blue', //no idea
    'blue', //no idea
    'blue', //no idea
    '#7A8494', //color light theme
    '#8998b0', //hover dark mode
    '#687c97', //background dark theme
    'blue', //no idea
  ];
  const simpleIconColors = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#CCCFD5' : '#7A8494'
  );
  const simpleIconThirdColors = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#EEF0F2' : '#596579'
  );

  const thirdColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.third_dark : companyTheme?.third_light
  );

  const fourthColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.fourth_dark : companyTheme?.fourth_light
  );

  const fifthColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.fifth_dark : companyTheme?.fifth_light
  );

  const sixthColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.sixth_dark : companyTheme?.sixth_light
  );

  const seventhColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.seventh_dark : companyTheme?.seventh_light
  );

  const chartColors = [
    companyThemePrimaryColor[6],
    colorScheme === THEME_MODES.darkMode ? companyTheme?.secondary_dark : companyTheme?.secondary_light,
    thirdColor[6],
    fourthColor[6],
    fifthColor[6],
    sixthColor[6],
    seventhColor[6],
  ].filter(Boolean);

  return (
    <MantineProvider
      theme={{
        colorScheme,
        colors: {
          ai_logo: aiLogo,
          primary_background: primaryBackground,
          hover,
          background_weak: backgroundWeak,
          secondary_background_color: secondaryBackgroundColor,
          third_background_color: thirdBackgroundColor,
          gradient_background_color: gradientBackgroundColor,
          retry_error_color: retryErrorColor,
          secondary_retry_error_color: secondaryRetryErrorColor,
          main_navigation_text_color: navigationTextColor,
          text_color: textColor,
          page_background: pageBackground,
          text_secondary: textSecondaryColor,
          text_third: textThirdColor,
          text_subtle: textSubtle,
          text_chart_legend: textChartLegend,
          border_color: borderColor,
          border_weak: borderWeak,
          icon_primary: iconPrimary,
          icon_secondary: iconSecondary,
          text_placeholder_color: textPlaceholderColor,
          link_color: linkColor,
          company_theme_primary_color: companyThemePrimaryColor,
          third_color: thirdColor,
          fourth_color: fourthColor,
          fifth_color: fifthColor,
          sixth_color: sixthColor,
          seventh_color: seventhColor,
          hover_color: hoverColor,
          chart_colors: chartColors,
          gradient_header_color: gradientHeaderColor,
          simple_icon_colors: simpleIconColors,
          simple_icon_third_colors: simpleIconThirdColors,
          table_header: tableHeader,
          table_text_header: tableTextHeader,
          dark: [
            '#fff',
            '#A6A7AB',
            '#9098A5',
            '#5c5f66',
            '#373A40',
            '#2C2E33',
            '#25262b',
            '#1A1B1E',
            '#141517',
            '#101113',
          ],
        },
        black: '#252a33',
        white: '#fff',
        primaryColor: 'company_theme_primary_color',
      }}
      withGlobalStyles
      inherit
    >
      {children}
    </MantineProvider>
  );
};

export default MantineThemeProvider;
