export const columns = [
  {
    accessorKey: 'floor_plan',
    header: 'Floor Plan',
  },
  {
    accessorKey: 'occupancy',
    header: 'Occupancy',
    maxSize: 0,
    Cell: ({ cell }) => `${Number(cell.getValue()).toFixed(2)}%`,
  },
];

export const initialState = {
  sorting: [{ id: 'occupancy', asc: true }],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
};
