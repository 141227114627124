import { Button, Card, Group, Text, TextInput, ThemeIcon, createStyles, Title } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconUser } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import UserPropertiesSelection from './components/userPropertiesSelection';
import { AuthContext } from '../../../../../../context/AuthContext';
import { useUserService } from '../../../../../../services/userService';
import { mapNullToEmptyString } from '../../../../../../utils/form.util';
import { FORM_ERRORS } from '../../../../../../constants/form_errors';
import { USER_ROLES } from '../../../../../../constants/user';
import { FORM_MODES } from '../../../../../../constants/form_modes';
import UserRolesDropdown from '../../../../../../components/UserRolesDropdown/UserRolesDropdown';
import CompaniesDropdown from '../../../../../../components/CompaniesDropdown/CompaniesDropdown';
import { validateUrl } from '../../../../../../utils/url.util';

export default function AdminUserForm({ mode, initialValues = {} }) {
  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const userService = useUserService();

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  // TODO: Should be refactored
  const mappedInitialValues = mapNullToEmptyString(initialValues);

  const form = useForm({
    initialValues: Object.assign({}, { details: {} }, mappedInitialValues),

    validate: {
      first_name: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      last_name: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      email: (value) => (/^\w+([.+-]?\w+)*@\w+([.-]?\w+)*\.\w{2,}$/.test(value) ? null : 'Invalid email'),
      username: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      role: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      company_id: currentUser.role !== USER_ROLES.SDM_USER ? null : isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      calendar_url: (value, values) => {
        if (values.role !== USER_ROLES.SDM_USER) return null;
        return validateUrl(value) ? null : FORM_ERRORS.URL_ERROR;
      },
    },
  });

  const disabled = mode === FORM_MODES.View;
  const editingDisabled = mode === FORM_MODES.Edit;

  const onSubmitForm = form.onSubmit(async (values) => {
    try {
      setIsSaveLoading(true);
      switch (mode) {
        case FORM_MODES.Create:
          await userService.createUser(values, values.role);
          break;
        case FORM_MODES.Edit:
          await userService.updateUser(initialValues.id, values, values.role);
          break;
      }

      notifications.show({
        title: 'Success!',
        message: `${values.username} user was successfully ${mode === FORM_MODES.Create ? 'added' : 'updated'}!`,
        color: 'green',
      });
      navigate('/admin/users');
    } catch (e) {
      const { data } = e.response;

      notifications.show({
        title: 'Failed to save user',
        message: data.message,
        color: 'red',
      });
    } finally {
      setIsSaveLoading(false);
    }
  });

  const useStyles = createStyles((theme) => ({
    form: {
      input: {
        backgroundColor: theme.colors.gray[1],
      },
    },
  }));

  const { classes } = useStyles();

  return (
    <form onSubmit={onSubmitForm} className={disabled ? classes.form : ''}>
      <Card shadow='sm' padding='xl' radius='md' withBorder>
        <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
          <ThemeIcon size={28}>
            <IconUser size={17} strokeWidth={3} color={'white'} />
          </ThemeIcon>
          <Text weight={700} size={16} ml={7}>
            User info
          </Text>
        </Card.Section>
        <Group grow>
          <TextInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='First name'
            placeholder='First name'
            {...form.getInputProps('first_name')}
          />
          <TextInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='Last name'
            placeholder='Last name'
            {...form.getInputProps('last_name')}
          />
        </Group>
        <Group grow>
          <TextInput
            readOnly={disabled}
            disabled={editingDisabled}
            mt='sm'
            withAsterisk
            label='Email'
            placeholder='Email'
            {...form.getInputProps('email')}
          />
          <TextInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='Username'
            placeholder='Username'
            {...form.getInputProps('username')}
          />
        </Group>
        <Group mt={15} grow>
          <UserRolesDropdown
            withAsterisk
            {...form.getInputProps('role')}
            readOnly={disabled}
            disabled={editingDisabled}
          />
          {form.getInputProps('role').value === USER_ROLES.SDM_USER ? (
            <TextInput
              withAsterisk
              readOnly={disabled}
              label='Calendar'
              placeholder='URL'
              {...form.getInputProps('calendar_url')}
            />
          ) : (
            <></>
          )}
        </Group>
        {currentUser.role === USER_ROLES.SDM_USER && (
          <Group mt={15} grow>
            <CompaniesDropdown
              withAsterisk
              {...form.getInputProps('company_id')}
              readOnly={disabled}
              disabled={editingDisabled}
            />
            <></>
          </Group>
        )}
      </Card>

      {currentUser.role === USER_ROLES.SDM_USER && mode !== FORM_MODES.View && !!form.values.company_id && (
        <div>
          <Title order={3} mt={20} mb={20} align='left'>
            Managed Properties
          </Title>
          <UserPropertiesSelection
            company_id={form.values.company_id}
            selected_values={initialValues?.properties}
            {...form.getInputProps('properties')}
          ></UserPropertiesSelection>
        </div>
      )}

      {!disabled && (
        <Group position='center' my='md'>
          <Button type='submit' mt={15} w={160} loading={isSaveLoading}>
            Save
          </Button>
        </Group>
      )}
    </form>
  );
}
