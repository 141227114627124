import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/overview/box-score';

export const useBiPropertyBoxScoreService = () => {
  const { axios } = useAxios();

  const getATR = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/atr`, { ...config, params });

    return response?.data;
  };

  const getThirtyDaysExpirations = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/thirty-days-expirations`, { ...config, params });

    return response?.data;
  };

  const getSixtyDaysExpirations = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/sixty-days-expirations`, { ...config, params });

    return response?.data;
  };

  const getOccupancy = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/occupancy`, { ...config, params });

    return response?.data;
  };

  const getRenewalPercentage = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/renewal-percentage`, { ...config, params });

    return response?.data;
  };

  const getAverageRentPerSqft = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/average-rent-per-sqft`, { ...config, params });

    return response?.data;
  };

  const getAvailableUnits = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/available-units`, { ...config, params });

    return response?.data;
  };

  const getUnavailableUnits = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/unavailable-units`, { ...config, params });

    return response?.data;
  };

  const getAvailabilityOverview = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/availability-overview`, { ...config, params });

    return response?.data;
  };

  const getFloorplanOccupancy = async (property_id, config = {}) => {
    const params = { property_id };
    const response = await axios.get(`${API_PATH}/floorplan-occupancy`, { ...config, params });

    return response?.data;
  };

  return {
    getATR,
    getThirtyDaysExpirations,
    getSixtyDaysExpirations,
    getOccupancy,
    getRenewalPercentage,
    getAverageRentPerSqft,
    getAvailableUnits,
    getUnavailableUnits,
    getAvailabilityOverview,
    getFloorplanOccupancy,
  };
};
