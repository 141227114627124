import { Text } from '@mantine/core';
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export const columns = [
  {
    accessorKey: 'status',
    header: 'Status',
    maxSize: 0,
    Cell: ({ cell }) => (
      <Text c={cell.getValue() === 'active' ? 'green' : 'red'} tt='capitalize'>
        {cell.getValue()}
      </Text>
    ),
  },
  {
    accessorKey: 'id',
    header: 'ID',
    maxSize: 0,
    sortDescFirst: false,
  },
  {
    accessorKey: 'name',
    header: 'Property Name',
    maxSize: 0,
    Cell: ({ cell }) => {
      return (
        <Link to={`${cell?.row?.original?.id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      );
    },
  },
  {
    accessorKey: 'account_manager_name',
    header: 'Account manager',
    maxSize: 0,
    filterFn: (row, id, filterValue) => {
      return row.original?.account_manager_name.toLowerCase().includes(filterValue.toLowerCase());
    },
    Cell: ({ cell }) => {
      return cell.getValue() ? <Text>{cell.getValue()}</Text> : <div />;
    },
  },
  {
    accessorKey: 'company_id',
    header: 'Company ID',
    maxSize: 0,
  },
  {
    accessorKey: 'company_name',
    header: 'Company Name',
    maxSize: 0,
    Cell: ({ cell }) => {
      return (
        <Link to={`/admin/companies/${cell?.row?.original?.company_id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      );
    },
  },
  {
    accessorKey: 'created_date',
    header: 'Created On',
    maxSize: 0,
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false,
    Cell: ({ cell }) => dayjs(cell.getValue()).format('MMM DD YYYY'),
    accessorFn: (row) => {
      const sDay = new Date(row.created_date);
      sDay.setHours(0, 0, 0, 0);
      return sDay;
    },
  },
  {
    accessorKey: 'platforms',
    header: 'Connected Platforms',
    maxSize: 0,
    Cell: ({ cell }) =>
      cell.getValue()?.length ? (
        <Text c='blue' fw={700} tt='capitalize'>
          {cell
            .getValue()
            .map((x) => x.replaceAll('_', ' '))
            .join(', ')}
        </Text>
      ) : (
        ''
      ),
  },
];

export const initialState = {
  sorting: [{ id: 'name', desc: false }],
  columnFilters: [{ id: 'status', value: 'active' }],
};
