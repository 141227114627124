export const GROW = {
  UP: 'up',
  DOWN: 'down',
  STEADY: 'steady',
};

export const GROW_COLOR = {
  [GROW.UP]: '#03B79A',
  [GROW.DOWN]: '#DE4F46',
  [GROW.STEADY]: 'text_third',
};

export const REVERSE_GROW_COLOR = {
  [GROW.DOWN]: '#03B79A',
  [GROW.UP]: '#DE4F46',
  [GROW.STEADY]: 'text_third',
};
