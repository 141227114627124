export const columns = [
  {
    accessorKey: 'floorplan',
    header: 'Floor Plan',
  },
  {
    accessorKey: 'event_date',
    header: 'Date Approved',
    maxSize: 0,
  },
  {
    accessorKey: 'secured_rent',
    header: 'Secured Rent',
    maxSize: 0,
    Cell: ({ cell }) => (cell.getValue() ? `$${cell.getValue()}` : ''),
  },
  {
    accessorKey: 'application_id',
    header: 'Application ID',
    maxSize: 0,
  },
];

export const initialState = {
  sorting: [{ id: 'event_date', desc: true }],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
};
