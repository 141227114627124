import React from 'react';
import { ActionIcon, Card, Overlay, Title, Container, useMantineTheme } from '@mantine/core';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { IconRefresh } from '@tabler/icons-react';
import IconInfo from '../IconInfo/IconInfo';

export default function SimpleReportTable({
  title,
  columns,
  hint,
  initialState,
  state,
  data,
  isLoading,
  isError,
  onRetry,
  cellMaxWidth = '1.3rem',
  enableFilters = false,
  enableColumnActions = false,
  enableColumnFilters = false,
}) {
  const theme = useMantineTheme();

  const backgroundColor = theme.colors.secondary_background_color[6];

  const table = useMantineReactTable({
    columns,
    initialState,
    state: { ...state, isLoading },
    data,
    enableFilters,
    enableColumnActions,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters,
    mantineLoadingOverlayProps: {
      loaderProps: {
        id: `loading_overlay__${title?.replaceAll(/\W/g, '')}`,
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        backgroundColor,
        lineHeight: '.75rem',
        paddingLeft: '1rem',
        maxWidth: cellMaxWidth,
      },
    },
    mantineTableBodyCellProps: {
      sx: {
        backgroundColor,
        lineHeight: '.75rem',
        paddingLeft: '1rem',
        maxWidth: cellMaxWidth,
      },
    },
    mantineBottomToolbarProps: {
      sx: {
        backgroundColor,
        fontSize: '0.8rem',
      },
    },
    mantineTableProps: {
      sx: {
        minHeight: '262px',
      },
    },
    mantinePaperProps: {
      sx: {
        height: '100%',
        backgroundColor,
      },
    },
    renderTopToolbar: () => (
      <Container
        p='md'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `2px ${theme.colors.company_theme_primary_color[7]} solid`,
          backgroundColor: backgroundColor,
          maxWidth: '100%',
        }}
      >
        <Title order={5}>{title}</Title>
        {hint && <IconInfo hint={hint} />}
      </Container>
    ),
  });

  return (
    <Card
      p={0}
      radius={isError ? 'md' : 0}
      sx={{
        border: isError && '2px solid red',
        height: 'fit-content',
        boxShadow: '#00000021 1px 2px 1px',
      }}
    >
      <MantineReactTable table={table} />
      {isError && (
        <Overlay color={theme.colors.retry_error_color[6]} opacity={0.6} center>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
