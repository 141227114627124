export const removeElById = (id, arr) => {
  const index = arr.findIndex((elId) => elId === id);
  return index !== -1 ? arr.toSpliced(index, 1) : arr;
};

export const updateElByKey = (key, element, arr) => {
  const index = arr.findIndex((el) => el[key] === element[key]);

  return [...arr.slice(0, index), { ...element }, ...arr.slice(index + 1)];
};
