import React from 'react';
import { ActionIcon, Card, createStyles, Flex, LoadingOverlay, Overlay, Title, useMantineTheme } from '@mantine/core';
import { IconInfoCircle, IconRefresh } from '@tabler/icons-react';
import { transformKeysToWordsAndUppercase } from '../../utils/charts.util';

import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';
import Info from '../Info/Info';

const LINE_COLORS = ['darkcyan', 'rebeccapurple'];

const useStyles = createStyles({
  recharts: {
    '.recharts-cartesian-grid-horizontal line:first-child': {
      'stroke-opacity': 0,
    },
    '.recharts-yAxis.recharts-cartesian-axis .recharts-cartesian-axis-tick:first-child text': {
      'font-size': 0,
    },
  },
});

export default function LineChartComponent({
  title,
  data,
  index,
  hint,
  isLoading,
  isError,
  onRetry,
  valueFormatter,
  excludeFields = [],
  yAxisConfig = [],
}) {
  const { classes } = useStyles();
  const { colors } = useMantineTheme();

  const { transformed, transformedKeys } = transformKeysToWordsAndUppercase(data, [...excludeFields, index]);

  const yAxis = yAxisConfig?.length ? (
    yAxisConfig.map((config) => {
      return (
        <YAxis
          key={config.key}
          tickFormatter={config.valueFormatter ?? valueFormatter}
          tick={{ fill: colors.text_third[0], fontWeight: '500' }}
          type='number'
          fontSize={12}
          strokeWidth={0}
          yAxisId={config.position}
          orientation={config.position}
        />
      );
    })
  ) : (
    <YAxis
      tick={{ fill: colors.text_third[0], fontWeight: '500' }}
      tickFormatter={valueFormatter}
      type='number'
      fontSize={12}
      strokeWidth={0}
    />
  );
  return (
    <Card
      radius='lg'
      p='1.5rem'
      style={{
        border: '1px solid ' + colors.border_color[0],
        height: 'fit-content',
        backgroundColor: colors.primary_background[0],
      }}
    >
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Title size={20} fw='600'>
          {title}
        </Title>
        {hint && (
          <Info label={hint} w={200}>
            <IconInfoCircle size={20} color={colors.simple_icon_colors[0]} />
          </Info>
        )}
      </Flex>
      <ResponsiveContainer width='100%' height={320} className={classes.recharts}>
        <LineChart
          data={transformed}
          margin={{
            top: 32,
            right: 0,
            left: -15,
            bottom: 16,
          }}
        >
          <Legend
            formatter={(value) => (
              <span style={{ color: colors.text_chart_legend[0], fontSize: '14px', fontWeight: '500' }}>{value}</span>
            )}
            iconType='plainline'
            layout='horizontal'
            verticalAlign={'bottom'}
            wrapperStyle={{ bottom: 10 }}
          />
          <CartesianGrid stroke={colors.border_weak[0]} strokeDasharray='4 4' vertical={false} />
          <XAxis strokeWidth={0} tick={{ fill: colors.text_third[0] }} dataKey={index} fontSize={12} />
          {yAxis}
          <Tooltip formatter={valueFormatter} />
          {transformedKeys.map((key, index) => (
            <Line
              type='monotone'
              key={key}
              yAxisId={yAxisConfig ? yAxisConfig.find((x) => x.key === key)?.position : null}
              dataKey={key}
              animationDuration={650}
              strokeWidth={2}
              dot={false}
              stroke={colors?.chart_colors[index] ?? LINE_COLORS[index] ?? 'grey'}
              activeDot={{ r: 5 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <LoadingOverlay
        id={`loading_overlay__${title}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'lg', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
      {isError && (
        <Overlay color={colors.retry_error_color[6]} opacity={0.6} center zIndex={10}>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
