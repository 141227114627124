import React, { useContext, useState } from 'react';
import { Flex, Group, Menu, Title } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar, IconFileDownload, IconFileTypeCsv } from '@tabler/icons-react';
import { InternalPortfolioOverviewContext } from '../../Context/PortfolioOverviewContext';
import { useExportReportService } from '../../Services/exportReportService';

export default function AdminPortfolioOverviewHeader() {
  const { dates, setDates, csvData, company, accountManager } = useContext(InternalPortfolioOverviewContext);

  const { exportCSV } = useExportReportService();
  const [datePickerValue, setDatePickerValue] = useState(dates);

  const onChangeDates = (value) => {
    setDatePickerValue(value);
    if (value.length && value.every((el) => !!el)) {
      setDates(value);
    }
  };

  const exportToCsv = async () => {
    try {
      await exportCSV({
        csvData,
        companyName: company?.label,
        accountManagerName: accountManager?.label,
        from: dates[0],
        to: dates[1],
      });
    } catch (error) {
      console.log('Error', error);
    }
  };

  return (
    <Flex justify='space-between' align='center'>
      <Title fw={700} my={25}>
        Portfolio Overview
      </Title>
      <Group>
        <DatePickerInput
          valueFormat='MM/DD/YYYY'
          type='range'
          placeholder='Pick dates range'
          icon={<IconCalendar size='1.3em' />}
          variant={'default'}
          value={datePickerValue}
          onChange={onChangeDates}
          w={250}
        />
        <Menu shadow='md' width={200}>
          <Menu.Target>
            <IconFileDownload style={{ cursor: 'pointer' }} />
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Download file format</Menu.Label>
            <Menu.Item icon={<IconFileTypeCsv size={14} />} onClick={exportToCsv}>
              CSV
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Flex>
  );
}
