export const columns = [
  {
    accessorKey: 'search_keyword',
    header: 'Search Keyword',
  },
  {
    accessorKey: 'clicks',
    header: 'Clicks',
    maxSize: 0,
  },
];

export const initialState = {
  sorting: [{ id: 'clicks', desc: true }],
  pagination: { pageSize: 19 },
};

export const state = {
  density: 'xs',
};
