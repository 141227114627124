import { axiosInstance } from './axiosService';

const API_PATH = '/admin/scheduled_reports';

export const useScheduledReportsService = () => {
  const createScheduledReport = async (scheduledReport) => {
    const response = await axiosInstance.post(`${API_PATH}`, scheduledReport);

    return response?.data;
  };

  const updateScheduledReport = async (id, scheduledReport) => {
    const response = await axiosInstance.patch(`${API_PATH}/${id}`, scheduledReport);

    return response?.data;
  };

  const get = async () => {
    const response = await axiosInstance.get(API_PATH);

    return response?.data;
  };

  const getScheduledReportById = async (id) => {
    const response = await axiosInstance.get(`${API_PATH}/${id}`);

    return response?.data;
  };

  const enableScheduledReport = async (id) => {
    const response = await axiosInstance.patch(`${API_PATH}/enable/${id}`);

    return response?.data;
  };

  const disableScheduledReport = async (id) => {
    const response = await axiosInstance.patch(`${API_PATH}/disable/${id}`);

    return response?.data;
  };

  const performScheduledReportSendingAction = async (id, action, from, to) => {
    const response = await axiosInstance.post(`${API_PATH}/${action}/${id}?from=${from}&to=${to}`);

    return response?.data;
  };

  return {
    createScheduledReport,
    updateScheduledReport,
    get,
    getScheduledReportById,
    enableScheduledReport,
    disableScheduledReport,
    performScheduledReportSendingAction,
  };
};
