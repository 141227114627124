import {
  Button,
  Card,
  Container,
  createStyles,
  Group,
  SimpleGrid,
  Text,
  TextInput,
  ThemeIcon,
  ColorInput,
  MantineProvider,
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconSettings } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { FORM_MODES } from '../../../../../../constants/form_modes';
import { FORM_ERRORS } from '../../../../../../constants/form_errors';
import { validateUrl } from '../../../../../../utils/url.util';
import { useCompanyService } from '../../../../../../services/companyService';
import SingleImageUploader from '../../../../../../components/SingleImageUploader/SingleImageUploader';

export default function CompanyForm({ mode, initialValues = {} }) {
  const navigate = useNavigate();
  const companyService = useCompanyService();

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const disabled = mode === FORM_MODES.View;
  const form = useForm({
    initialValues: Object.assign(
      {},
      {
        name: initialValues?.name ?? '',
        logo_url: initialValues?.logo_url ?? '',
        theme: {
          logo_url_light: initialValues?.theme?.logo_url_light ?? '',
          logo_url_dark: initialValues?.theme?.logo_url_dark ?? '',
          primary_light: initialValues?.theme?.primary_light ?? '',
          primary_dark: initialValues?.theme?.primary_dark ?? '',
          secondary_light: initialValues?.theme?.secondary_light ?? '',
          secondary_dark: initialValues?.theme?.secondary_dark ?? '',
          third_light: initialValues?.theme?.third_light ?? '',
          third_dark: initialValues?.theme?.third_dark ?? '',
          fourth_light: initialValues?.theme?.fourth_light ?? '',
          fourth_dark: initialValues?.theme?.fourth_dark ?? '',
          fifth_light: initialValues?.theme?.fifth_light ?? '',
          fifth_dark: initialValues?.theme?.fifth_dark ?? '',
          sixth_light: initialValues?.theme?.sixth_light ?? '',
          sixth_dark: initialValues?.theme?.sixth_dark ?? '',
          seventh_light: initialValues?.theme?.seventh_light ?? '',
          seventh_dark: initialValues?.theme?.seventh_dark ?? '',
        },
      }
    ),
    validate: {
      name: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      logo_url: (value) => (value?.length && !validateUrl(value) ? FORM_ERRORS.URL_ERROR : null),
      theme: {
        logo_url_light: (value) => (value?.length && !validateUrl(value) ? FORM_ERRORS.URL_ERROR : null),
        logo_url_dark: (value) => (value?.length && !validateUrl(value) ? FORM_ERRORS.URL_ERROR : null),
        primary_light: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        primary_dark: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        secondary_light: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        secondary_dark: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        third_light: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        third_dark: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        fourth_light: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        fourth_dark: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        fifth_light: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        fifth_dark: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        sixth_light: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        sixth_dark: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        seventh_light: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
        seventh_dark: isNotEmpty(FORM_ERRORS.REQUIRED_FIELD_ERROR),
      },
    },
  });

  const resetForm = () => form.reset();

  const onSubmitForm = form.onSubmit(async (values) => {
    try {
      setIsSaveLoading(true);
      switch (mode) {
        case FORM_MODES.Create:
          await companyService.createCompany(values);
          break;
        case FORM_MODES.Edit:
          await companyService.updateCompany(initialValues.id, values);
          break;
      }

      notifications.show({
        title: 'Success!',
        message: `${values.name} company was successfully ${mode === FORM_MODES.Create ? 'added' : 'updated'}!`,
        color: 'green',
      });
      navigate('/admin/companies');
    } catch (e) {
      notifications.show({
        title: 'An Error Occurred',
        message: e.message,
        color: 'red',
      });
    } finally {
      setIsSaveLoading(false);
    }
  });

  //TODO refactor: make it reusable
  const useStyles = createStyles((theme) => ({
    form: {
      input: {
        backgroundColor: theme.colors.gray[1],
      },
    },
  }));

  const { classes } = useStyles();

  const darkModeInputColor = (theme) => ({
    input: {
      backgroundColor: theme.colors.dark[6],
    },
  });

  return (
    <form onSubmit={onSubmitForm} className={disabled ? classes.form : ''}>
      <SimpleGrid cols={1} px={0} pr={0}>
        <Container mx={0} pl={0} pr={0} fluid>
          <Card shadow='sm' padding='xl' radius='md' withBorder>
            <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
              <ThemeIcon color='dark.3' size={28}>
                <IconSettings size={17} strokeWidth={3} color={'white'} />
              </ThemeIcon>
              <Text weight={700} size={16} ml={7}>
                Main info
              </Text>
            </Card.Section>

            <Group grow>
              <TextInput
                readOnly={disabled}
                mt='sm'
                withAsterisk
                label='Company name'
                placeholder='Name'
                {...form.getInputProps('name')}
              />
              {form.getInputProps('logo_url').value && (
                <TextInput
                  readOnly={true}
                  mt='sm'
                  label='Logo URL (Deprecated)'
                  placeholder='URL'
                  {...form.getInputProps('logo_url')}
                />
              )}
            </Group>
          </Card>
        </Container>
      </SimpleGrid>
      <SimpleGrid mt='md' cols={2} px={0} pr={0}>
        <Card shadow='sm' padding='xl' radius='md' withBorder>
          <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
            <ThemeIcon color='dark.3' size={28}>
              <IconSettings size={17} strokeWidth={3} color={'white'} />
            </ThemeIcon>
            <Text weight={700} size={16} ml={7}>
              Light Theme
            </Text>
          </Card.Section>

          <Text weight={600} size={15} mt={10} mb={10}>
            Light Mode Logo
          </Text>

          <SingleImageUploader
            path={'client-logos'}
            readOnly={disabled}
            disabled={disabled}
            mb='sm'
            h={200}
            title={'Light Mode Logo'}
            {...form.getInputProps(`theme.logo_url_light`)}
          />

          <Text weight={600} size={15} mt={10}>
            Colors
          </Text>
          <ColorInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='Primary'
            placeholder='Primary color'
            {...form.getInputProps(`theme.primary_light`)}
          />

          <ColorInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='Secondary'
            placeholder='Secondary color'
            {...form.getInputProps(`theme.secondary_light`)}
          />

          <ColorInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='Third'
            placeholder='Third color'
            {...form.getInputProps(`theme.third_light`)}
          />
          <ColorInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='Fourth'
            placeholder='Fourth color'
            {...form.getInputProps(`theme.fourth_light`)}
          />

          <ColorInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='Fifth'
            placeholder='Fifth color'
            {...form.getInputProps(`theme.fifth_light`)}
          />

          <ColorInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='Sixth'
            placeholder='Sixth color'
            {...form.getInputProps(`theme.sixth_light`)}
          />
          <ColorInput
            readOnly={disabled}
            mt='sm'
            withAsterisk
            label='Seventh'
            placeholder='Seventh color'
            {...form.getInputProps(`theme.seventh_light`)}
          />
        </Card>
        <MantineProvider theme={{ colorScheme: 'dark' }} inherit>
          <Card shadow='sm' padding='xl' radius='md' withBorder color={'grey'}>
            <Card.Section withBorder inheritPadding py='xs' display={'flex'}>
              <ThemeIcon color='dark.3' size={28}>
                <IconSettings size={17} strokeWidth={3} color={'white'} />
              </ThemeIcon>
              <Text weight={700} size={16} ml={7}>
                Dark Theme
              </Text>
            </Card.Section>

            <Text weight={600} size={15} mt={10} mb={10}>
              Dark Mode Logo
            </Text>
            <SingleImageUploader
              path={'client-logos'}
              disabled={disabled}
              readOnly={disabled}
              mb='sm'
              h={200}
              title={'Dark Mode Logo'}
              {...form.getInputProps(`theme.logo_url_dark`)}
            />

            <Text weight={600} size={15} mt={10}>
              Colors
            </Text>

            <ColorInput
              readOnly={disabled}
              mt='sm'
              withAsterisk
              label='Primary'
              placeholder='Primary color'
              sx={darkModeInputColor}
              {...form.getInputProps(`theme.primary_dark`)}
            />

            <ColorInput
              readOnly={disabled}
              mt='sm'
              withAsterisk
              label='Secondary'
              placeholder='Secondary color'
              sx={darkModeInputColor}
              {...form.getInputProps(`theme.secondary_dark`)}
            />
            <ColorInput
              readOnly={disabled}
              mt='sm'
              withAsterisk
              label='Third'
              placeholder='Third color'
              sx={darkModeInputColor}
              {...form.getInputProps(`theme.third_dark`)}
            />

            <ColorInput
              readOnly={disabled}
              mt='sm'
              withAsterisk
              label='Fourth'
              placeholder='Fourth color'
              sx={darkModeInputColor}
              {...form.getInputProps(`theme.fourth_dark`)}
            />

            <ColorInput
              readOnly={disabled}
              mt='sm'
              withAsterisk
              label='Fifth'
              placeholder='Fifth color'
              sx={darkModeInputColor}
              {...form.getInputProps(`theme.fifth_dark`)}
            />

            <ColorInput
              readOnly={disabled}
              mt='sm'
              withAsterisk
              label='Sixth'
              placeholder='Sixth color'
              sx={darkModeInputColor}
              {...form.getInputProps(`theme.sixth_dark`)}
            />

            <ColorInput
              readOnly={disabled}
              mt='sm'
              withAsterisk
              label='Seventh'
              placeholder='Seventh color'
              sx={darkModeInputColor}
              {...form.getInputProps(`theme.seventh_dark`)}
            />
          </Card>
        </MantineProvider>
      </SimpleGrid>
      {mode !== FORM_MODES.View && (
        <Group position='center' my='md'>
          <Button color='red' variant='outline' w={160} onClick={resetForm}>
            Reset
          </Button>
          <Button type='submit' w={160} loading={isSaveLoading}>
            Save
          </Button>
        </Group>
      )}
    </form>
  );
}
