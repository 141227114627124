import React from 'react';
import { Group, Box, UnstyledButton, createStyles, Title, useMantineTheme } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = createStyles(() => {
  const { colors } = useMantineTheme();

  return {
    control: {
      '&:hover': {
        backgroundColor: colors.hover_color[6],
        color: 'white',
      },
    },
  };
});

LinksGroup.propTypes = {
  label: PropTypes.string,
  initiallyOpened: PropTypes.bool,
  links: PropTypes.array,
  link: PropTypes.string,
};

export function LinksGroup({ icon: Icon, label, link }) {
  const navigate = useNavigate();
  const location = useLocation();

  const isLinkActive = location.pathname.startsWith(link);

  const { classes } = useStyles();

  const onClick = () => {
    link && navigate(link);
  };

  const { colors } = useMantineTheme();

  return (
    <UnstyledButton
      onClick={onClick}
      className={classes.control}
      style={{
        backgroundColor: isLinkActive && colors.company_theme_primary_color[6],
        margin: 0,
        width: '100%',
      }}
      h={80}
    >
      <Group
        sx={{
          display: 'flex',
          alignContent: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          padding: 10,
        }}
      >
        <Box>
          <Icon
            size='1.4rem'
            color={(isLinkActive && colors.main_navigation_text_color[6]) || colors.main_navigation_text_color[5]}
          />
          <Title
            mt={7}
            fw={700}
            size={8}
            tt='uppercase'
            color={(isLinkActive && colors.main_navigation_text_color[6]) || colors.main_navigation_text_color[5]}
          >
            {label}
          </Title>
        </Box>
      </Group>
    </UnstyledButton>
  );
}
