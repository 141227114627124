import React from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { getToken, updateToken } from '../services/tokenService';
import { TOKEN } from '../constants/localStorage';

const ProtectedTokenRoute = ({ redirectPath = '/' }) => {
  if (getToken(TOKEN)) {
    return <Navigate to={redirectPath} replace />;
  }

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  if (token) {
    updateToken(TOKEN, token);
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedTokenRoute;
