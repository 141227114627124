import React, { useLayoutEffect } from 'react';
import { Box, Button, Title } from '@mantine/core';
import { SCHEDULED_REPORTS_STATUS } from './constants/scheduledReports';
import { IconArrowLeft } from '@tabler/icons-react';
import AdminScheduledReportForm from './forms/AdminScheduledReportForm';
import { FORM_MODES } from '../../../../../constants/form_modes';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';

const AdminEditScheduledReport = () => {
  const scheduledReport = useRouteLoaderData('scheduled-report-details');
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (scheduledReport.status === SCHEDULED_REPORTS_STATUS.DISABLED) navigate(-1);
  }, []);

  const onClickBack = () => navigate(-1);

  return (
    <Box mx='auto' mt='md' px={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Title order={2} mb={20} pr={7} align='left'>
            {scheduledReport?.report_name}
          </Title>
        </div>
        <Button color='gray' variant='default' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Go Back
        </Button>
      </div>
      <AdminScheduledReportForm mode={FORM_MODES.Edit} initialValues={scheduledReport}></AdminScheduledReportForm>
    </Box>
  );
};

export default AdminEditScheduledReport;
