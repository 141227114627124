import React, { useRef } from 'react';
import { ActionIcon, Card, Flex, LoadingOverlay, Overlay, Title, useMantineTheme } from '@mantine/core';
import { IconInfoCircle, IconRefresh } from '@tabler/icons-react';
import { transformKeysToWordsAndUppercase } from '../../utils/charts.util';
import Info from '../Info/Info';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const BAR_COLORS = ['darkcyan', 'rebeccapurple'];

const CustomYAxisTick = ({ x, y, payload, color }) => {
  const MAX_WIDTH = 130;

  return (
    <foreignObject x={x - MAX_WIDTH} y={y - 10} width={MAX_WIDTH} height={20}>
      <div
        style={{
          width: MAX_WIDTH,
          overflow: 'hidden',
          color,
          'font-weight': '500',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontSize: '12px',
          textAlign: 'right',
        }}
      >
        {payload.value}
      </div>
    </foreignObject>
  );
};

export default function VerticalBarChartComponent({
  title,
  data,
  index,
  hint,
  isLoading,
  isError,
  onRetry,
  dataFormatter = (x) => x,
  excludeFields = [],
  colors = BAR_COLORS,
  showLegend = true,
  interval = 0,
  maxAmountOfRows = 10,
}) {
  const containerRef = useRef(null);

  const { colors: themeColors } = useMantineTheme();

  const { transformed, transformedKeys } = transformKeysToWordsAndUppercase(
    dataFormatter(data.slice(0, maxAmountOfRows)),
    [...excludeFields, index]
  );

  return (
    <Card
      radius='lg'
      p='1.5rem'
      style={{
        border: '1px solid ' + themeColors.border_color[0],
        height: 'fit-content',
        backgroundColor: themeColors.primary_background[0],
      }}
    >
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Title size={20} fw='600'>
          {title}
        </Title>
        {hint && (
          <Info label={hint} w={200}>
            <IconInfoCircle size={20} color={themeColors.simple_icon_colors[0]} />
          </Info>
        )}
      </Flex>
      <ResponsiveContainer ref={containerRef} width='100%' height={320}>
        <ComposedChart
          data={transformed}
          layout={'vertical'}
          margin={{
            top: 40,
            right: 50,
            left: 10,
            bottom: 15,
          }}
          padding={0}
        >
          {showLegend && (
            <Legend
              formatter={(value) => (
                <span style={{ color: themeColors.text_chart_legend[0], fontSize: '14px', fontWeight: '500' }}>
                  {value}
                </span>
              )}
              iconType='plainline'
              align={'right'}
              wrapperStyle={{ top: 10, right: 20 }}
            />
          )}
          <CartesianGrid stroke={themeColors.border_weak[0]} strokeDasharray='4 4' horizontal={false} />
          <XAxis type='number' fontSize={14} strokeWidth={0} tick={{ fill: themeColors.text_subtle[0] }} />
          <YAxis
            interval={interval}
            dataKey={index}
            strokeWidth={0}
            type='category'
            tick={<CustomYAxisTick color={themeColors.text_secondary[0]} />}
            width={150}
          />
          <Tooltip />
          {transformedKeys.map((key, index) => (
            <Bar
              radius={4}
              key={key}
              dataKey={key}
              animationDuration={650}
              strokeWidth={2}
              fill={themeColors?.chart_colors[index] ?? colors[index] ?? 'grey'}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
      <LoadingOverlay
        id={`loading_overlay__${title}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'lg', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
      {isError && (
        <Overlay color={themeColors.retry_error_color[6]} opacity={0.6} center zIndex={10}>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
