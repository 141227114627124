import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { useBiPropertyBoxScoreService } from '../../../../../../../services/bi/property/overview/PropertyBoxScoreService';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';

export default function OccupancyMetric() {
  const { getOccupancy } = useBiPropertyBoxScoreService();

  const { property } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Occupancy %',
        valueFormatter: (value) => Math.round(value),
        suffix: '%',
        hint: 'The percent of all available units within a property that are currently occupied',
        showTrend: false,
      }}
      fetchMethod={getOccupancy}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property]}
      params={{ property }}
      paramsValidator={(params) => params.property}
      paramsFormatter={(params) => [params.property.value]}
    />
  );
}
