import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import PieChartComponent from '../../../../../../../../components/PieChart/PieChart';
import dayjs from 'dayjs';
import { useSocialMediaAdvertising } from '../../../../../../../../services/bi/property/marketing/SocialMediaAdvertisingService';

const ReachByDevice = () => {
  const { getReachByDevice } = useSocialMediaAdvertising();

  const { property, dates } = useContext(PropertyFiltersContext);

  const dataFormatter = (data) => {
    if (!data?.length) return [];

    return [
      ...data.map((x) => ({
        device: x.device.split('_').join(' '),
        percentage: x.percentage,
        reach: x.reach,
      })),
    ];
  };

  return (
    <DataFetcherComponent
      Child={PieChartComponent}
      childProps={{
        title: 'Reach By Device',
        index: 'device',
        excludeFields: [],
        variant: 'donut',
        dataFormatter,
        customTooltipValueFormatter: (x) => `${x.payload?.Reach} (${Number(x.value).toFixed(1)}%)`,
        valueFormatter: (x) => `${Number(x).toFixed(2)}%`,
      }}
      fetchMethod={getReachByDevice}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default ReachByDevice;
