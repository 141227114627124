import React from 'react';
import { Container, Grid, SimpleGrid } from '@mantine/core';
import PhoneCalls from './Metrics/PhoneCalls';
import Answered from './Metrics/Answered';
import Missed from './Metrics/Missed';
import AvgDuration from './Metrics/AvgDuration';
import Voicemails from './Metrics/Voicemails';
import CallTimeSeries from './Charts/CallTimeSeries/CallTimeSeries';
import CallType from './Charts/CallType/CallType';
import CallSource from './Charts/CallSource/CallSource';
import CallLogTable from './Tables/CallLog/CallLog';
import FormSubmissions from './Metrics/FormSubmissions';

export default function PropertyTeamPerformanceCallsSection() {
  return (
    <Container fluid m={0} pb='lg'>
      <SimpleGrid
        cols={6}
        breakpoints={[
          { maxWidth: 'lg', cols: 2, spacing: 'sm' },
          { maxWidth: 'md', cols: 1, spacing: 'sm' },
        ]}
      >
        <PhoneCalls />
        <Answered />
        <Missed />
        <AvgDuration />
        <Voicemails />
        <FormSubmissions />
      </SimpleGrid>
      <Grid mt='md'>
        <Grid.Col span={8} xl={8} lg={8} md={12} sm={12} xs={12}>
          <CallTimeSeries />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={6} md={12} sm={12} xs={12}>
          <CallType />
        </Grid.Col>
        <Grid.Col span={8} xl={8} lg={8} md={12} sm={12} xs={12}>
          <CallLogTable />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={6} md={12} sm={12} xs={12}>
          <CallSource />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
