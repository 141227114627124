import React, { useContext } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Button, Container, Title } from '@mantine/core';
import { MantineReactTable } from 'mantine-react-table';
import { columns, initialState } from './constants/userTableDef';
import { IconPlus } from '@tabler/icons-react';
import { AuthContext } from '../../../../../context/AuthContext';
import { USER_ROLES } from '../../../../../constants/user';
import { useUserService } from '../../../../../services/userService';

export default function AdminUsers() {
  const { currentUser } = useContext(AuthContext);

  const data = useLoaderData();
  const navigate = useNavigate();

  const onClickAddUser = () => {
    navigate('/admin/users/add');
  };

  return (
    <Container fluid px={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title fw={700} ml='lg' my={25}>
          {currentUser.role === USER_ROLES.SDM_ADMIN ? 'Internal' : 'External'} Users
        </Title>
        <Button color='gray' variant='default' onClick={onClickAddUser}>
          <IconPlus style={{ marginRight: 5 }} />
          Add user
        </Button>
      </div>
      <MantineReactTable
        columns={columns}
        data={data}
        initialState={initialState}
        enableColumnFilterModes
        mantineTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f9fdff',
            },
            td: {
              backgroundColor: 'transparent',
            },
          },
        }}
      />
    </Container>
  );
}

export const loader = async () => {
  const { get } = useUserService();
  return await get();
};
