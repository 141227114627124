import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

export const dateOrNull = (date) => date && dayjs(date);

export const getDaysOfWeek = () => dayjs.extend(localeData).weekdays();

export const generateTimeStrings = (
  fromHour = 0,
  fromMinute = 0,
  toHour = 23,
  toMinute = 30,
  step = 5,
  format = 'hh:mm A'
) => {
  const timeStrings = [];

  let cursor = dayjs().startOf('day').add(fromHour, 'h').add(fromMinute, 'm');

  const end = dayjs().startOf('day').add(toHour, 'h').add(toMinute, 'm');

  do {
    timeStrings.push(cursor.format(format));
    cursor = cursor.add(step, 'm');
  } while (!cursor.isAfter(end));

  return timeStrings;
};

export const formatDuration = (duration) => {
  const hours = duration.hours();
  const mins = duration.minutes();
  const secs = duration.seconds();

  let formattedDuration = '';

  if (hours > 0) {
    formattedDuration += `${hours}hr `;
  }

  if (mins > 0 || hours > 0) {
    formattedDuration += `${mins}m `;
  }

  if (secs > 0 && hours === 0) {
    formattedDuration += `${secs}s`;
  }

  return formattedDuration.trim();
};
