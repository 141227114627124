import { axiosInstance } from './axiosService';

const API_PATH = '/admin/users-activity';

export const useUsersActivityService = () => {
  const getUsersByLastLogin = async (from, to, config = {}) => {
    const params = { from, to };
    const response = await axiosInstance.get(`${API_PATH}/by-last-login`, { ...config, params });

    return response?.data;
  };
  const getUsersByActivityName = async (from, to, config) => {
    const params = { from, to };
    const response = await axiosInstance.get(`${API_PATH}/by-activity-log`, { ...config, params });

    return response?.data;
  };

  const getUsersByCreatedDate = async (from, to, config) => {
    const params = { from, to };
    const response = await axiosInstance.get(`${API_PATH}/by-created-date`, { ...config, params });

    return response?.data;
  };

  return {
    getUsersByLastLogin,
    getUsersByActivityName,
    getUsersByCreatedDate,
  };
};
