import React, { useState, useEffect } from 'react';
import { Card, Group, SimpleGrid, Text, ThemeIcon, Title } from '@mantine/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IconClockHour3, IconFileInfo } from '@tabler/icons-react';
import { useInterval } from 'react-use';
import { calculateNextReportSendingDate } from '../../utils/nextReportSendingDate.util';
import { SCHEDULED_REPORTS_STATUS } from '../../pages/Admin/Components/Sections/AdminScheduledReports/constants/scheduledReports';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const calculateCountdown = (nextDate) => {
  return dayjs(nextDate).fromNow(true);
};

const ReportInfo = ({ report }) => {
  const [nextReportDate, setNextReportDate] = useState(null);
  const [countdown, setCountdown] = useState('');
  const [currentDate, setCurrentDate] = useState(dayjs().tz('America/New_York'));

  const calculateAndSetCountdown = () => {
    if (nextReportDate) {
      setCountdown(calculateCountdown(nextReportDate));
    }
  };

  useEffect(() => {
    const nextDate = calculateNextReportSendingDate(
      currentDate,
      parseInt(report.on, 10),
      report.time,
      report.frequency
    );
    setNextReportDate(nextDate);

    calculateAndSetCountdown();
  }, [report, currentDate]);

  useInterval(() => {
    const newCurrentDate = dayjs().tz('America/New_York');
    setCurrentDate(newCurrentDate);
  }, 1000);

  return (
    <Card shadow='sm' padding='xl' radius='md' mb={20} withBorder>
      <Card.Section withBorder inheritPadding py='xs' display='flex'>
        <ThemeIcon size={28}>
          <IconFileInfo size={17} strokeWidth={3} color='white' />
        </ThemeIcon>
        <Text weight={700} size={16} ml={7}>
          Info
        </Text>
      </Card.Section>
      <SimpleGrid cols={2} mt='sm'>
        <Group display='flex'>
          <IconClockHour3 />
          <Title order={5} ml={5}>
            Next Report Date:
          </Title>
          <Text ml={5}>
            {nextReportDate && report.status === SCHEDULED_REPORTS_STATUS.ACTIVE
              ? `${nextReportDate.format('ddd DD MMM h:mm A ET')} (in ${countdown})`
              : 'N/A'}
          </Text>
        </Group>
      </SimpleGrid>
    </Card>
  );
};

export default ReportInfo;
