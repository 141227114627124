export const USER_ROLES = {
  PROPERTY_MANAGER: 'Property Manager',
  SDM_USER: 'SDM User',
  SDM_ADMIN: 'SDM Admin',
};

export const ADMIN_ROLES = [USER_ROLES.SDM_ADMIN, USER_ROLES.SDM_USER];

export const USER_ROLES_MAP = {
  [USER_ROLES.PROPERTY_MANAGER]: 'external',
  [USER_ROLES.SDM_USER]: 'internal',
  [USER_ROLES.SDM_ADMIN]: 'admin',
};

export const USER_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
  DISABLED: 'disabled',
  DRAFT: 'draft',
};

export const USER_STATUS_INDICATOR_MAP = {
  [USER_STATUS.ACTIVE]: 'green.6',
  [USER_STATUS.PENDING]: 'yellow.6',
  [USER_STATUS.DISABLED]: 'red.6',
  [USER_STATUS.DRAFT]: 'gray.6',
};

export const USER_STATUS_MODAL_MAP = {
  [USER_STATUS.ACTIVE]: 'Disable',
  [USER_STATUS.PENDING]: 'Delete',
  [USER_STATUS.DRAFT]: 'Delete',
  [USER_STATUS.DISABLED]: 'Enable',
};

export const USER_PROPERTIES_KEY_BY_ROLE = {
  [USER_ROLES.SDM_USER]: 'manager_properties',
  [USER_ROLES.PROPERTY_MANAGER]: 'properties',
};
