import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/team-performance/call_summary';

export const useCallsService = () => {
  const { axios } = useAxios();

  const getPhoneCalls = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/phone_calls`, { ...config, params });

    return response?.data;
  };

  const getAnswered = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/answered`, { ...config, params });

    return response?.data;
  };

  const getMissed = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/missed`, { ...config, params });

    return response?.data;
  };

  const getAvgDuration = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/avg_duration`, { ...config, params });

    return response?.data;
  };

  const getVoicemails = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/voicemails`, { ...config, params });

    return response?.data;
  };

  const getFormSubmissions = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/form-submissions`, { ...config, params });

    return response?.data;
  };

  const getCallTimeSeries = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/call_time_series`, { ...config, params });

    return response?.data;
  };

  const getCallType = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/call_type`, { ...config, params });

    return response?.data;
  };

  const getCallLog = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/call_log`, { ...config, params });

    return response?.data;
  };

  const getCallSource = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/call_source`, { ...config, params });

    return response?.data;
  };

  return {
    getPhoneCalls,
    getAnswered,
    getMissed,
    getAvgDuration,
    getVoicemails,
    getFormSubmissions,
    getCallTimeSeries,
    getCallType,
    getCallLog,
    getCallSource,
  };
};
