import { useEffect } from 'react';
import axios, { CancelToken } from 'axios';
import { getToken } from './tokenService';
import { memoizedRefreshToken } from './refreshTokenService';
import { TOKEN } from '../constants/localStorage';

function authInterceptor(config) {
  const finalConfig = { ...config };

  const token = getToken(TOKEN);

  if (!finalConfig.headers) {
    finalConfig.headers = {};
  }
  if (token) {
    finalConfig.headers.Authorization = `Bearer ${token}`;
  }

  return finalConfig;
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});

instance.interceptors.request.use(authInterceptor);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    // Access Token was expired
    if (err.response?.status !== 401) {
      return Promise.reject(err);
    }

    // Refreshing token only once
    const result = await memoizedRefreshToken();

    if (result) {
      originalConfig.headers = {
        ...originalConfig.headers,
        Authorization: `Bearer ${result}`,
      };
    }

    return instance(originalConfig);
  }
);

export const axiosInstance = instance;

export const CancelSignal = CancelToken.source;

export const useAxios = () => {
  const signal = CancelSignal();

  useEffect(() => {
    return () => {
      signal.cancel('Unmount useAxios');
    };
  }, []);

  return { axios: axiosInstance, cancelToken: signal.token };
};
