import React, { useContext } from 'react';
import { MantineProvider, useMantineTheme } from '@mantine/core';
import { THEME_MODES, DEFAULT_THEME_COLOR } from '../../constants/theme_modes';
import { ThemeContext } from '../../context/ThemeContext';

const generateShades = (baseColor) => {
  const theme = useMantineTheme();
  const shades = [];

  for (let i = 1; i <= 6; i++) {
    const shade = theme.fn.lighten(baseColor, i / 10);
    shades.unshift(shade);
  }
  shades.push(baseColor);
  for (let i = 1; i <= 3; i++) {
    const shade = theme.fn.darken(baseColor, i / 10);
    shades.push(shade);
  }

  return shades;
};

const MantineThemeProvider = ({ colorScheme, children }) => {
  const { companyTheme } = useContext(ThemeContext);

  const nestedNavigationColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313131' : '#ffffff'
  );

  const primaryBackgroundColor = Array.from({ length: 10 }, (_, index) => {
    const lightMode = index > 4 ? '#d9d9d9' : '#ffffff';
    const darkMode = '#121212';

    return (colorScheme === THEME_MODES.darkMode && darkMode) || lightMode;
  });

  const gradientBackgroundColor = Array.from({ length: 10 }, () => {
    return (
      (colorScheme === THEME_MODES.darkMode &&
        'linear-gradient(180deg, rgba(18,18,18,1) 60%, rgba(32,32,32,1) 100%)') ||
      'linear-gradient(180deg, rgba(239,239,239,1) 30%, rgba(239,239,239,1) 75%)'
    );
  });

  const gradientHeaderColor = Array.from({ length: 10 }, () => {
    return (
      (colorScheme === THEME_MODES.darkMode && 'linear-gradient(180deg, rgba(18,18,18, 0.95) 85%, transparent)') ||
      'linear-gradient(180deg, rgba(239,239,239,0.95) 85%, transparent)'
    );
  });

  const secondaryBackgroundColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313131' : '#efefef'
  );

  const thirdBackgroundColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313131' : '#ffffff'
  );

  const retryErrorColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313131' : '#d9d9d9'
  );

  const secondaryRetryErrorColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? '#313131' : '#ffffff'
  );

  const hoverColor = Array.from({ length: 10 }, (_, index) => {
    const lightMode = '#d9d9d9' ?? DEFAULT_THEME_COLOR;
    const darkMode = index > 5 ? companyTheme?.secondary_dark ?? DEFAULT_THEME_COLOR : '#121212';

    return (colorScheme === THEME_MODES.darkMode && darkMode) || lightMode;
  });

  const navigationTextColor = Array.from({ length: 10 }, (_, index) => {
    const lightMode = index < 6 ? '#121212' : '#ffffff';
    const darkMode = index < 6 ? '#ffffff' : '#121212';

    return (colorScheme === THEME_MODES.darkMode && darkMode) || lightMode;
  });

  const textColor = Array.from({ length: 10 }, (_, index) => {
    const lightMode = index < 5 ? '#121212' : '#ffffff';
    const darkMode = '#ffffff';

    return (colorScheme === THEME_MODES.darkMode && darkMode) || lightMode;
  });

  const companyThemePrimaryColor = generateShades(
    colorScheme === THEME_MODES.darkMode
      ? companyTheme?.primary_dark ?? DEFAULT_THEME_COLOR
      : companyTheme?.primary_light ?? DEFAULT_THEME_COLOR
  );

  const linkColor = Array.from({ length: 10 }, () => (colorScheme === THEME_MODES.darkMode ? '#0f82af' : 'blue'));

  const thirdColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.third_dark : companyTheme?.third_light
  );

  const fourthColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.fourth_dark : companyTheme?.fourth_light
  );

  const fifthColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.fifth_dark : companyTheme?.fifth_light
  );

  const sixthColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.sixth_dark : companyTheme?.sixth_light
  );

  const seventhColor = Array.from({ length: 10 }, () =>
    colorScheme === THEME_MODES.darkMode ? companyTheme?.seventh_dark : companyTheme?.seventh_light
  );

  const chartColors = [
    companyThemePrimaryColor[6],
    colorScheme === THEME_MODES.darkMode ? companyTheme?.secondary_dark : companyTheme?.secondary_light,
    thirdColor[6],
    fourthColor[6],
    fifthColor[6],
    sixthColor[6],
    seventhColor[6],
  ].filter(Boolean);

  return (
    <MantineProvider
      theme={{
        colorScheme,
        colors: {
          primary_background_color: primaryBackgroundColor,
          secondary_background_color: secondaryBackgroundColor,
          third_background_color: thirdBackgroundColor,
          gradient_background_color: gradientBackgroundColor,
          retry_error_color: retryErrorColor,
          secondary_retry_error_color: secondaryRetryErrorColor,
          nested_navigation_color: nestedNavigationColor,
          main_navigation_text_color: navigationTextColor,
          text_color: textColor,
          link_color: linkColor,
          company_theme_primary_color: companyThemePrimaryColor,
          third_color: thirdColor,
          fourth_color: fourthColor,
          fifth_color: fifthColor,
          sixth_color: sixthColor,
          seventh_color: seventhColor,
          hover_color: hoverColor,
          chart_colors: chartColors,
          gradient_header_color: gradientHeaderColor,
        },
        primaryColor: 'company_theme_primary_color',
      }}
      withGlobalStyles
      inherit
    >
      {children}
    </MantineProvider>
  );
};

export default MantineThemeProvider;
