import { Box, Button, Title } from '@mantine/core';
import React from 'react';
import CompanyForm from './forms/companyForm';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { FORM_MODES } from '../../../../../constants/form_modes';

export default function AdminAddCompany() {
  const navigate = useNavigate();
  const onClickBack = () => navigate(-1);

  return (
    <Box mx='auto' mt='sm' px={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title order={2} mb={20} align='left'>
          Add Company
        </Title>
        <Button color='gray' variant='default' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Go Back
        </Button>
      </div>
      <CompanyForm mode={FORM_MODES.Create}></CompanyForm>
    </Box>
  );
}
