import React from 'react';
import { ActionIcon, Card, Flex, LoadingOverlay, Overlay, Title, useMantineTheme } from '@mantine/core';
import { IconInfoCircle, IconRefresh } from '@tabler/icons-react';
import { transformKeysToWordsAndUppercase } from '../../utils/charts.util';
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Info from '../Info/Info';

const BAR_COLORS = ['darkcyan', 'rebeccapurple'];

const CustomCursor = (props) => {
  const { x, y, width: tooltipWidth, height, stroke } = props;
  return <Rectangle stroke={stroke} x={x + tooltipWidth / 2} y={y} width={1} height={height} />;
};

export default function BarChartComponent({
  title,
  data,
  index,
  hint,
  isLoading,
  isError,
  onRetry,
  valueFormatter,
  dataFormatter = (x) => x,
  excludeFields = [],
  colors = BAR_COLORS,
  showLegend = true,
  yAxisConfig = [],
}) {
  const { colors: themeColors } = useMantineTheme();

  const { transformed, transformedKeys } = transformKeysToWordsAndUppercase(dataFormatter(data), [
    ...excludeFields,
    index,
  ]);

  const yAxis = yAxisConfig?.length ? (
    yAxisConfig.map((config) => {
      return (
        <YAxis
          key={config.key}
          tickFormatter={config.valueFormatter ?? valueFormatter}
          tick={{ fill: themeColors.text_subtle[0] }}
          type='number'
          fontSize={14}
          strokeWidth={0}
          yAxisId={config.position}
          orientation={config.position}
        />
      );
    })
  ) : (
    <YAxis
      tickFormatter={valueFormatter}
      type='number'
      fontSize={14}
      strokeWidth={0}
      tick={{ fill: themeColors.text_subtle[0] }}
    />
  );

  return (
    <Card
      radius='lg'
      p='1.5rem'
      style={{
        border: '1px solid ' + themeColors.border_color[0],
        height: 'fit-content',
        backgroundColor: themeColors.primary_background[0],
      }}
    >
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Title size={20} fw='600'>
          {title}
        </Title>
        {hint && (
          <Info label={hint} w={200}>
            <IconInfoCircle size={20} color={themeColors.simple_icon_colors[0]} />
          </Info>
        )}
      </Flex>
      <ResponsiveContainer width='100%' height={320}>
        <BarChart
          data={transformed}
          margin={
            transformedKeys?.length === 1
              ? {
                  top: 40,
                  right: 20,
                  left: -20,
                  bottom: 0,
                }
              : {
                  top: 60,
                  right: 20,
                  left: -20,
                  bottom: 0,
                }
          }
          padding={0}
          width={100}
        >
          {showLegend && <Legend type='circle' align={'right'} wrapperStyle={{ top: 10, right: 0 }} />}
          <CartesianGrid stroke={themeColors.border_weak[0]} strokeDasharray='4 4' vertical={false} />
          <XAxis
            strokeWidth={0}
            dataKey={index}
            fontSize={12}
            tick={{ fill: themeColors.text_secondary[0], fontWeight: '500' }}
          />
          {yAxis}
          <Tooltip cursor={<CustomCursor stroke={themeColors.text_secondary[0]} />} />
          {transformedKeys.map((key, index) => (
            <Bar
              key={key}
              radius={4}
              yAxisId={yAxisConfig ? yAxisConfig.find((x) => x.key === key)?.position : null}
              dataKey={key}
              animationDuration={650}
              strokeWidth={2}
              fill={themeColors?.chart_colors[index] ?? colors[index] ?? 'grey'}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <LoadingOverlay
        id={`loading_overlay__${title}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'lg', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
      {isError && (
        <Overlay color={themeColors.retry_error_color[6]} opacity={0.6} center zIndex={10}>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
