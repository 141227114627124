import { Button, Container, Group, Title } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();

  const onClickRetry = () => {
    navigate(0);
  };

  return (
    <Container>
      <Title
        sx={(theme) => ({ color: theme.colors.dark[5], textAlign: 'center', fontSize: 33, fontWeight: 900 })}
        my={50}
      >
        Ooops. Something went wrong...
      </Title>
      <Group sx={{ justifyContent: 'center' }}>
        <Button variant='outline' size='md' onClick={onClickRetry}>
          Retry
        </Button>
      </Group>
    </Container>
  );
};

export default ErrorPage;
