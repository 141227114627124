import { Box, Flex, Group, Text, Title } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

const displayCellValue = (value, previousValue, hasPositiveDynamic) => {
  const trend = Number(value - previousValue);
  const isPositiveTrend = trend > 0;
  const trendColor = trend === 0 ? 'dimmed' : isPositiveTrend && hasPositiveDynamic ? '#03B79A' : '#DE4F46';

  return (
    <Flex justify={'center'} align={'center'} px={0} py={12}>
      <Text mr={3}>{value?.toFixed()}%</Text>
      <Text color={trendColor} size={13} sx={{ position: 'relative', top: '0.35rem' }}>
        {(!isNaN(trend) && isPositiveTrend ? '+' + trend?.toFixed(1) : !isNaN(trend) && trend?.toFixed(1)) + '%'}
      </Text>
    </Flex>
  );
};

export const columns = [
  {
    accessorKey: 'property_name',
    header: 'Property',
    Cell: ({ cell, row }) => {
      let url = row.original?.website_url;
      let formattedUrl = url?.replace(/^https?:\/\/([^/]*)\/?.*$/, '$1');

      return (
        <Flex justify={'center'} direction={'column'} align={'flex-start'} px={0}>
          <Text
            size='16px'
            fw={600}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '165px',
            }}
          >
            {cell?.getValue()}
          </Text>
          <Link
            to={row.original?.website_url}
            style={{
              color: 'gray',
              textDecoration: 'none',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '165px',
            }}
          >
            {formattedUrl}
          </Link>
        </Flex>
      );
    },
  },
  {
    accessorKey: 'street_address',
    header: 'Address',
    maxSize: 150,
    Header: () => (
      <Text px={0} align={'left'}>
        Address
      </Text>
    ),
    Cell: ({ cell }) => {
      return (
        <Text
          px={0}
          p={12}
          align={'left'}
          sx={{
            whiteSpace: 'wrap',
          }}
        >
          {cell?.getValue()}
        </Text>
      );
    },
  },
  {
    accessorKey: 'advertised_occupancy',
    header: 'Adv. Leased %',
    maxSize: 120,
    Cell: ({ row, cell }) =>
      cell.getValue() && displayCellValue(cell.getValue(), row.original?.advertised_occupancy_from, true),
  },
  {
    accessorKey: 'exposure',
    header: 'Exposure',
    maxSize: 115,
    Cell: ({ row, cell }) => cell.getValue() && displayCellValue(cell.getValue(), row.original?.exposure_from, false),
  },
  {
    accessorKey: 'year_built',
    header: 'Built',
    maxSize: 100,
    Cell: ({ cell }) => {
      return <Text px={0}>{cell?.getValue()?.toFixed()}</Text>;
    },
  },
  {
    accessorKey: 'total_units',
    header: 'Units',
    maxSize: 100,
    Cell: ({ cell }) => {
      return (
        <Text px={0} p={12}>
          {cell?.getValue()}
        </Text>
      );
    },
  },
  {
    accessorKey: 'total_stories',
    header: 'Stories',
    maxSize: 95,
    Cell: ({ cell }) => {
      return (
        <Text px={0} p={12}>
          {cell?.getValue()}
        </Text>
      );
    },
  },
  {
    accessorKey: 'rent',
    header: 'Rent',
    Header: ({ table }) => {
      const { isEffectiveRent } = table.getState();
      return <Text px={0}>{isEffectiveRent ? 'Avg. Eff. Rent' : 'Avg. Mkt. Rent'}</Text>;
    },
    enableSorting: false,
    Cell: ({ row, table }) => {
      const { isEffectiveRent } = table.getState();
      const formatRentValue = (rent) => new Intl.NumberFormat('en-US').format(Math.round(rent));
      const unitPricing = row.original?.unit_pricing;

      return (
        <Group spacing={8} px={0} py={12} sx={{ flexWrap: 'nowrap' }}>
          {unitPricing?.map(({ label, avg_effective_rent, avg_asking_rent }, index) => {
            if (!avg_effective_rent && !avg_asking_rent) return null;
            return (
              <Box key={index} mr={8}>
                <Text color='dimmed' size={10}>
                  {label}
                </Text>
                <Title size={12} mt={8}>
                  ${formatRentValue(isEffectiveRent ? avg_effective_rent : avg_asking_rent)}
                </Title>
              </Box>
            );
          })}
        </Group>
      );
    },
  },
  {
    accessorKey: 'rent_psf',
    header: 'Rent Psf',
    Header: ({ table }) => {
      const { isEffectiveRent } = table.getState();
      return <Text px={0}>{isEffectiveRent ? 'Eff. Rent PSF' : 'Mkt. Rent PSF'}</Text>;
    },
    enableSorting: false,
    Cell: ({ row, table }) => {
      const { isEffectiveRent } = table.getState();
      const unitPricing = row.original?.unit_pricing || [];

      return (
        <Group spacing={8} px={0} py={12} sx={{ flexWrap: 'nowrap' }}>
          {unitPricing.map((pricing, index) => {
            const { label, avg_sqft, avg_effective_rent, avg_asking_rent } = pricing;

            if (!avg_sqft) return null;

            const rentPerSqft = isEffectiveRent
              ? avg_effective_rent && (avg_effective_rent / avg_sqft).toFixed(2)
              : avg_asking_rent && (avg_asking_rent / avg_sqft).toFixed(2);

            if (!rentPerSqft) return null;

            return (
              <Box key={index} mr={8}>
                <Text color='dimmed' size={10}>
                  {label}
                </Text>
                <Title size={12} mt={8}>
                  ${rentPerSqft}
                </Title>
              </Box>
            );
          })}
        </Group>
      );
    },
  },
];

export const initialState = {
  sorting: [{ id: 'property', desc: true }],
};

export const state = {
  density: 'xs',
};
