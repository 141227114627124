import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import LineChartComponent from '../../../../../../../../components/LineChart/LineChart';
import dayjs from 'dayjs';
import { useCallsService } from '../../../../../../../../services/bi/property/teamPerformance/callsService';

const CallTimeSeries = () => {
  const { getCallTimeSeries } = useCallsService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={LineChartComponent}
      childProps={{
        title: 'Call Trend',
        index: 'date',
        hint: 'Track the fluctuation in call volume and type over time to identify peak periods, trends, and patterns in communication activity.',
      }}
      fetchMethod={getCallTimeSeries}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default CallTimeSeries;
