export const columns = [
  {
    accessorKey: 'unit_number',
    header: 'Unit',
    maxSize: 50,
  },
  {
    accessorKey: 'floor_plan',
    header: 'Floor Plan',
    maxSize: 70,
  },
  {
    accessorKey: 'availability_status',
    header: 'Status',
    maxSize: 100,
  },
  {
    accessorKey: 'available_date',
    header: 'Vacancy Date',
    maxSize: 70,
  },
  {
    accessorKey: 'days_until_made_ready',
    header: 'Days Vacant',
    maxSize: 70,
  },
];

export const initialState = {
  sorting: [{ id: 'days_until_made_ready', desc: true }],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
};
