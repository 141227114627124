import React from 'react';
import { Container, SimpleGrid } from '@mantine/core';
import WebsiteLeads from './Metrics/WebsiteLeads';
import PhoneCalls from './Metrics/PhoneCalls';
import ContactUsFormSubmits from './Metrics/ContactUsFormSubmits';
import WebsiteLeadsByDay from './Charts/WebsiteLeadsByDay/WebsiteLeadsByDay';
import PercentageOfTotalWebsiteLeads from './Charts/PercentageOfTotalWebsiteLeads/PercentageOfTotalWebsiteLeads';
import WebsiteLeadsBySource from './Charts/WebsiteLeadsBySource/WebsiteLeadsBySource';
import ProspectWebsiteLeadPathTable from './Tables/ProspectWebsiteLeadPath/ProspectWebsiteLeadPathTable';

export default function PropertyMarketingWebsiteLeadAttributionSection() {
  return (
    <Container fluid m={0} sx={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
      <SimpleGrid
        cols={3}
        spacing='xl'
        breakpoints={[
          { maxWidth: 'lg', cols: 2 },
          { maxWidth: 'md', cols: 1 },
        ]}
      >
        <WebsiteLeads />
        <PhoneCalls />
        <ContactUsFormSubmits />
      </SimpleGrid>
      <SimpleGrid mt='md' cols={2} breakpoints={[{ maxWidth: 'lg', cols: 1, spacing: 'sm' }]}>
        <WebsiteLeadsByDay />
        <WebsiteLeadsBySource />
        <ProspectWebsiteLeadPathTable />
        <PercentageOfTotalWebsiteLeads />
      </SimpleGrid>
    </Container>
  );
}
