import React, { useRef, useState } from 'react';
import { Box, Button, Indicator, Switch, Title, useMantineTheme } from '@mantine/core';
import AdminPropertyForm from './forms/propertyForm';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { IconAlertCircle, IconArrowLeft, IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  columns as relatedUsersColumns,
  initialState as relatedUsersInitialState,
} from '../../Shared/relatedUsersTableDef';
import {
  columns as connectionErrorsColumns,
  initialState as connectionErrorsInitialState,
} from './constants//connectionErrorsTableDef';
import { usePropertyService } from '../../../../../services/propertyService';
import { PROPERTY_STATUS } from '../../../../../constants/propertyStatus';
import { COMPANY_STATUS } from '../../../../../constants/companyStatus';
import { FORM_MODES } from '../../../../../constants/form_modes';
import ConfirmationModal from '../../../../../components/ConfirmationModal/ConfirmationModal';

export default function AdminViewProperty() {
  const data = useRouteLoaderData('property-details');
  const [opened, { open, close }] = useDisclosure(false);

  const [retryConnectionOpened, { open: openRetryConnection, close: closeRetryConnection }] = useDisclosure(false);
  const [retryConnectionType, setConnectionTypeToRetry] = useState();

  const scrollRef = useRef(null);

  const navigate = useNavigate();
  const theme = useMantineTheme();
  const propertyService = usePropertyService();

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [initialData, setInitialData] = useState(data);

  const [checked, setChecked] = useState(initialData?.status === PROPERTY_STATUS.ACTIVE);

  const relatedUsersTable = useMantineReactTable({
    columns: relatedUsersColumns,
    initialState: relatedUsersInitialState,
    data: data.users ?? [],
    enableTopToolbar: false,
  });

  const connectionErrorsTable = useMantineReactTable({
    columns: connectionErrorsColumns,
    initialState: connectionErrorsInitialState,
    data: data.connection_errors ?? [],
    enableTopToolbar: false,
  });
  const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickEditProperty = () => {
    navigate('edit');
  };

  // TODO: Refactor to generic confirmation modal component
  const onClickModalConfirm = async () => {
    try {
      setIsConfirmLoading(true);
      const property = checked
        ? await propertyService.disableProperty(initialData?.id)
        : await propertyService.enableProperty(initialData?.id);

      setInitialData(property);

      setChecked(!checked);
      notifications.show({
        title: 'Success!',
        message: `${initialData?.name} property was successfully ${
          property?.status === PROPERTY_STATUS.ACTIVE ? 'enabled' : 'disabled'
        }!`,
        color: 'green',
      });
    } catch (e) {
      notifications.show({
        title: 'An Error Occurred',
        message: e.message,
        color: 'red',
      });
    } finally {
      setIsConfirmLoading(false);
      close();
    }
  };

  const onChangePropertyStatus = (status) => {
    open();
    console.log(status);
  };

  const handleRetryConnection = (connection_type) => {
    setConnectionTypeToRetry(connection_type);
    openRetryConnection();
  };

  const retryPropertyConnection = async (connection_type) => {
    try {
      setIsConfirmLoading(true);
      await propertyService.retryPropertyConnection(initialData.id, connection_type);
      navigate(0);
    } catch (e) {
      notifications.show({
        title: 'An Error Occurred',
        message: e.message,
        color: 'red',
      });
    } finally {
      setIsConfirmLoading(false);
      closeRetryConnection();
    }
  };

  const indicatorColor = initialData?.status === PROPERTY_STATUS.ACTIVE ? 'green.6' : 'red.6';
  const modalLabel = initialData?.status === PROPERTY_STATUS.ACTIVE ? 'Disable' : 'Enable';

  return (
    <Box mx='auto' mt='md' px={30} pb={30}>
      <ConfirmationModal
        opened={opened}
        actionTitle={`${modalLabel} property`}
        title={`Are you sure to ${modalLabel} property ${initialData?.name}?`}
        onClickModalConfirm={onClickModalConfirm}
        isConfirmLoading={isConfirmLoading}
        onClose={close}
      />

      <ConfirmationModal
        opened={retryConnectionOpened}
        actionTitle={`Retry connection`}
        title={`Are you sure you want to retry this connection?`}
        onClickModalConfirm={() => retryPropertyConnection(retryConnectionType)}
        isConfirmLoading={isConfirmLoading}
        onClose={closeRetryConnection}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Indicator color={indicatorColor}>
            <Title order={2} mb={20} pr={7} align='left'>
              {initialData?.name}
            </Title>
          </Indicator>
          {data?.connection_errors?.some((x) => !x.retried_from_bi) ? (
            <Button mr={-17} variant='white' sx={{ backgroundColor: 'transparent' }} onClick={executeScroll}>
              <IconAlertCircle style={{ marginLeft: '10px' }} size={30} color='red' variant='default' />
            </Button>
          ) : null}
          {initialData?.status === PROPERTY_STATUS.ACTIVE && (
            <Button
              variant='white'
              sx={{ backgroundColor: 'transparent' }}
              onClick={onClickEditProperty}
              ml={17}
              px={6}
            >
              <IconEdit size={30} color='gray' variant='default' />
            </Button>
          )}
          {initialData?.company?.status === COMPANY_STATUS.ACTIVE && (
            <Box
              sx={({ colors }) => ({
                '.mantine-Switch-track': {
                  background: colors.red[6],
                  borderColor: colors.red[6],
                  cursor: 'pointer',
                },
                '.mantine-Switch-thumb': {
                  borderColor: 'white',
                },
              })}
            >
              <Switch
                checked={checked}
                onChange={(event) => onChangePropertyStatus(event.currentTarget.checked)}
                color={'teal'}
                size='md'
                pt={6}
                ml={20}
                thumbIcon={
                  checked ? (
                    <IconCheck size='0.8rem' color={theme.colors.teal[theme.fn.primaryShade()]} stroke={3} />
                  ) : (
                    <IconX size='0.8rem' color={theme.colors.red[theme.fn.primaryShade()]} stroke={3} />
                  )
                }
              />
            </Box>
          )}
        </div>
        <Button color='gray' variant='default' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Go Back
        </Button>
      </div>

      <AdminPropertyForm
        mode={FORM_MODES.View}
        initialValues={initialData}
        handleRetryConnection={handleRetryConnection}
      ></AdminPropertyForm>

      <Title order={3} mt={20} mb={20} align='left'>
        Related Users
      </Title>
      <MantineReactTable table={relatedUsersTable} />

      {data.connection_errors?.length ? (
        <>
          <Title ref={scrollRef} order={3} mt={20} mb={20} align='left'>
            Connection Errors
          </Title>
          <MantineReactTable table={connectionErrorsTable} />
        </>
      ) : null}
    </Box>
  );
}

export const loader = async ({ params }) => {
  const { getPropertyDetails } = usePropertyService();
  return await getPropertyDetails(params?.id);
};
