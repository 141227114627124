export const GROW = {
  UP: 'up',
  DOWN: 'down',
  STEADY: 'steady',
};

export const GROW_COLOR = {
  [GROW.UP]: 'limeGreen',
  [GROW.DOWN]: 'red',
  [GROW.STEADY]: 'silver',
};

export const REVERSE_GROW_COLOR = {
  [GROW.DOWN]: 'limeGreen',
  [GROW.UP]: 'red',
  [GROW.STEADY]: 'silver',
};
