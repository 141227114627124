import React from 'react';
import { Container, SimpleGrid } from '@mantine/core';
import LeadsMetric from './Metrics/LeadsMetric';
import ToursMetric from './Metrics/ToursMetric';
import ApprovedLeasesMetric from './Metrics/ApprovedLeasesMetric';
import CompletedLeasesMetric from './Metrics/CompletedLeasesMetric';
import ClosingRatioMetric from './Metrics/ClosingRatioMetric';
import AverageRentPerSqftMetric from './Metrics/AverageRentPerSqftMetric';
import UpcomingExpirationsTable from './Tables/UpcomingExpirations/UpcomingExpirations';
import ToursAndLeases from './Charts/ToursAndLeases/ToursAndLeases';
import PriceAndOccupancy from './Charts/PriceAndOccupancy/PriceAndOccupancy';
import ConversionPerformance from './Charts/ConversionPerformance/ConversionPerformance';

export default function PropertyOverviewSection() {
  return (
    <Container fluid m={0} pb='lg'>
      <SimpleGrid
        cols={6}
        breakpoints={[
          { maxWidth: 'xl', cols: 3, spacing: 'md' },
          { maxWidth: 'lg', cols: 2, spacing: 'md' },
          { maxWidth: 'md', cols: 1, spacing: 'sm' },
        ]}
      >
        <LeadsMetric />
        <ToursMetric />
        <CompletedLeasesMetric />
        <ApprovedLeasesMetric />
        <ClosingRatioMetric />
        <AverageRentPerSqftMetric />
      </SimpleGrid>
      <SimpleGrid mt='md' cols={2} breakpoints={[{ maxWidth: 'lg', cols: 1, spacing: 'sm' }]}>
        <PriceAndOccupancy />
        <ToursAndLeases />
        <ConversionPerformance />
        <UpcomingExpirationsTable />
      </SimpleGrid>
    </Container>
  );
}
