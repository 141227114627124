import { GROW, GROW_COLOR } from '../../../../../../../../../constants/metric';
import { IconArrowNarrowDown, IconArrowNarrowUp } from '@tabler/icons-react';
import React from 'react';
import { Flex } from '@mantine/core';

export const columns = [
  {
    accessorKey: 'source',
    header: 'Source',
    maxSize: 0,
  },
  {
    accessorKey: 'medium',
    header: 'Medium',
    maxSize: 0,
  },
  {
    accessorKey: 'total',
    header: 'New Users',
    maxSize: 0,
  },
  {
    accessorKey: 'diff',
    header: '% Δ',
    maxSize: 0,
    mantineTableHeadCellProps: {
      align: 'right',
    },
    Header: ({ column }) => <div style={{ marginRight: '5px' }}>{column.columnDef.header}</div>,
    Cell: ({ cell }) => {
      const value = Number(cell.getValue());

      return (
        <Flex justify='end'>
          <div>{!value || Number.isNaN(value) ? '' : `${value}%`}</div>
          {value > 0 ? (
            <IconArrowNarrowUp size={20} color={GROW_COLOR[GROW.UP]} />
          ) : value < 0 ? (
            <IconArrowNarrowDown size={20} color={GROW_COLOR[GROW.DOWN]} />
          ) : (
            ''
          )}
        </Flex>
      );
    },
  },
];

export const initialState = {
  sorting: [{ id: 'total', desc: true }],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
};
