import React from 'react';
import { Alert, Text, useMantineTheme } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

export default function AlertInfo({ title, subtitle }) {
  const icon = <IconInfoCircle style={{ color: 'white' }} />;
  const { colors } = useMantineTheme();

  return (
    <Alert
      m='lg'
      p='lg'
      variant='light'
      style={{
        backgroundColor: colors.company_theme_primary_color[8],
        color: 'white',
      }}
      title={(title && title) || ''}
      icon={icon}
    >
      <Text size='md' style={{ color: 'white' }}>
        {subtitle}
      </Text>
    </Alert>
  );
}
