import React, { useState, createContext, useContext } from 'react';

export const SidebarContext = createContext(null);

const SidebarProvider = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const [enableExpanding, setEnableExpanding] = useState(false);

  const handleCollapsedChange = () => {
    setExpanded(!expanded);
  };

  return (
    <SidebarContext.Provider
      value={{
        expanded,
        enableExpanding,
        handleCollapsedChange,
        setEnableExpanding,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;

export const useSidebar = () => {
  return useContext(SidebarContext);
};
