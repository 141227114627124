import { axiosInstance } from './axiosService';

const API_PATH = '/admin/connection-errors';

export const useConnectionErrorsService = () => {
  const get = async (from, to, config = {}) => {
    const params = { from, to };
    const response = await axiosInstance.get(API_PATH, { ...config, params });

    return response?.data;
  };

  const getConnectionErrorsCountByType = async (from, to, config = {}) => {
    const params = { from, to };
    const response = await axiosInstance.get(`${API_PATH}/errors-count-by-type`, { ...config, params });

    return response?.data;
  };

  const getConnectionErrorsCountByDate = async (from, to, config = {}) => {
    const params = { from, to };
    const response = await axiosInstance.get(`${API_PATH}/errors-count-by-date`, { ...config, params });

    return response?.data;
  };

  const getConnectionErrorPatterns = async (from, to, config = {}) => {
    const params = { from, to };
    const response = await axiosInstance.get(`${API_PATH}/error-patterns`, { ...config, params });

    return response?.data;
  };

  const getBrokenConnectionsByDate = async (from, to, config = {}) => {
    const params = { from, to };
    const response = await axiosInstance.get(`${API_PATH}/broken-connections-by-date`, { ...config, params });

    return response?.data;
  };

  return {
    get,
    getConnectionErrorsCountByType,
    getConnectionErrorsCountByDate,
    getConnectionErrorPatterns,
    getBrokenConnectionsByDate,
  };
};
