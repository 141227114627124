import React, { useContext, useEffect } from 'react';
import { Box, SimpleGrid, Text, TextInput } from '@mantine/core';
import { IntegrationsContext } from '../IntegrationsComponent';

const HelloDataConnection = () => {
  const { readOnly, connection, setConnectionData, connectionData } = useContext(IntegrationsContext);

  useEffect(() => {
    setConnectionData(() => ({
      ...(connection?.value ?? {}),
      comparable: connection?.value?.comparable?.length ? connection.value.comparable : Array(7).fill({}),
    }));
  }, [connection?.value]);

  const handleComparableChange = (index, newValue) => {
    const updatedComparable = connectionData?.comparable?.map((item, idx) =>
      idx === index ? { ...item, id: newValue } : item
    );

    setConnectionData({
      ...connectionData,
      comparable: updatedComparable,
    });
  };

  return (
    <Box>
      <TextInput
        hideControls
        mt='sm'
        placeholder='ID'
        label='Client Property ID'
        min={0}
        value={connectionData?.id}
        onChange={(event) => {
          setConnectionData({ ...connectionData, id: event.target.value });
        }}
        readOnly={readOnly}
      />
      <Text size={14} mt={15}>
        Comparable IDs
      </Text>
      <SimpleGrid mb='md' cols={2} breakpoints={[{ maxWidth: 'lg', cols: 1, spacing: 'sm' }]}>
        {connectionData?.comparable?.map((comparable, index) => (
          <TextInput
            key={index}
            hideControls
            mt='sm'
            placeholder='ID'
            min={0}
            value={comparable.id}
            onChange={(event) => handleComparableChange(index, event.target.value)}
            readOnly={readOnly}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default HelloDataConnection;
