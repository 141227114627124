import { ORDERED_PROPERTY_REPORT_PAGES, PROPERTY_REPORT_PAGES, PROPERTY_REPORT_SECTIONS } from './propertyReportPages';
import dayjs from 'dayjs';
import { formatDuration } from '../utils/time.util';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const PORTFOLIO_OVERVIEW_METRICS = {
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.PropertyOverview].Overview]: [
    { id: 'overview__leads', name: 'Leads' },
    { id: 'overview__tours', name: 'Tours' },
    { id: 'overview__applications', name: 'Applications' },
    { id: 'overview__approved_leases', name: 'Approved Leases' },
    {
      id: 'overview__closing_ratio',
      name: 'Closing Ratio',
      suffix: '%',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
    {
      id: 'overview__avg_rent_sqft',
      name: 'Avg. Rent/Sqft',
      prefix: '$',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.PropertyOverview].BoxScore]: [
    { id: 'box_score__occupancy', name: 'Occupancy', suffix: '%', valueFormatter: (x) => Number(x).toFixed(2) },
    { id: 'box_score__atr', name: 'ATR', suffix: '%', valueFormatter: (x) => Number(x).toFixed(2) },
    { id: 'box_score__30_day', name: '30-Day' },
    { id: 'box_score__60_day', name: '60-Day' },
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].WebsitePerformance]: [
    { id: 'website_performance__sessions', name: 'Sessions' },
    { id: 'website_performance__active_users', name: 'Active Users' },
    { id: 'website_performance__new_users', name: 'New Users' },
    {
      id: 'website_performance__engagement_rate',
      name: 'Engagement Rate',
      suffix: '%',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
    { id: 'website_performance__phone_calls', name: 'Phone Calls' },
    { id: 'website_performance__form_submissions', name: 'Form Submissions' },
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].SearchEngineMarketing]: [
    { id: 'search_engine_marketing__impressions', name: 'Impressions' },
    { id: 'search_engine_marketing__clicks', name: 'Clicks' },
    { id: 'search_engine_marketing__ctr', name: 'CTR', suffix: '%', valueFormatter: (x) => Number(x).toFixed(2) },
    {
      id: 'search_engine_marketing__avg_cpc',
      name: 'Average CPC',
      prefix: '$',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
    { id: 'search_engine_marketing__phone_calls', name: 'Phone Calls' },
    { id: 'search_engine_marketing__form_submissions', name: 'Form Submissions' },
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].DigitalMarketSurvey]: [
    {
      id: 'digital_market_survey__market_impression_share',
      name: 'Market Impression Share',
      suffix: '%',
      valueFormatter: (x) => Number(x * 100).toFixed(2),
    },
    {
      id: 'digital_market_survey__above_organic',
      name: 'Above Organic',
      suffix: '%',
      valueFormatter: (x) => Number(x * 100).toFixed(2),
    },
    {
      id: 'digital_market_survey__top_placement',
      name: 'Top Placement',
      suffix: '%',
      valueFormatter: (x) => Number(x * 100).toFixed(2),
    },
    {
      id: 'digital_market_survey__market_share_lost',
      name: 'Market Share Lost Due To Budget',
      suffix: '%',
      valueFormatter: (x) => Number(x * 100).toFixed(2),
    },
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].BrandAwareness]: [
    { id: 'brand_awareness__impressions', name: 'Impressions' },
    { id: 'brand_awareness__clicks', name: 'Clicks' },
    {
      id: 'brand_awareness__ctr',
      name: 'CTR',
      suffix: '%',
      valueFormatter: (x) => (Number(x) * 100).toFixed(2),
    },
    {
      id: 'brand_awareness__avg_cpc',
      name: 'Average CPC',
      prefix: '$',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
    {
      id: 'brand_awareness__avg_cpm',
      name: 'Average CPM',
      prefix: '$',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].Remarketing]: [
    { id: 'remarketing__impressions', name: 'Impressions' },
    { id: 'remarketing__clicks', name: 'Clicks' },
    {
      id: 'remarketing__ctr',
      name: 'CTR',
      suffix: '%',
      valueFormatter: (x) => (Number(x) * 100).toFixed(2),
    },
    {
      id: 'remarketing__avg_cpc',
      name: 'Average CPC',
      prefix: '$',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
    {
      id: 'remarketing__avg_cpm',
      name: 'Average CPM',
      prefix: '$',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].SocialMediaAdvertising]: [
    { id: 'social_media_advertising__impressions', name: 'Impressions' },
    { id: 'social_media_advertising__link_clicks', name: 'Link Clicks' },
    { id: 'social_media_advertising__page_views', name: 'Page Views' },
    {
      id: 'social_media_advertising__all_cpc',
      name: 'CPC (All)',
      prefix: '$',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
    {
      id: 'social_media_advertising__all_ctr',
      name: 'CTR (All)',
      suffix: '%',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
    {
      id: 'social_media_advertising__frequency',
      name: 'Frequency',
      valueFormatter: (x) => Number(x).toFixed(2),
    },
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.Marketing].WebsiteLeadAttribution]: [
    { id: 'website_lead_attribution__website_leads', name: 'Website Leads' },
    { id: 'website_lead_attribution__phone_calls', name: 'Phone Calls' },
    { id: 'website_lead_attribution__form_submissions', name: 'Form Submissions' },
  ],
  [PROPERTY_REPORT_PAGES[PROPERTY_REPORT_SECTIONS.TeamPerformance].Calls]: [
    { id: 'call_summary__phone_calls', name: 'Phone Calls' },
    { id: 'call_summary__answered', name: 'Answered' },
    { id: 'call_summary__missed', name: 'Missed' },
    {
      id: 'call_summary__avg_duration',
      name: 'Avg. Duration',
      valueFormatter: (x) => {
        const duration = dayjs.duration(x, 'seconds');
        return formatDuration(duration);
      },
    },
    { id: 'call_summary__voicemails', name: 'Voicemails' },
    { id: 'call_summary__form_submissions', name: 'Form Submissions' },
  ],
};

export const PORTFOLIO_OVERVIEW_METRICS_VALUES = ORDERED_PROPERTY_REPORT_PAGES.reduce((acc, cur) => {
  return [...acc, ...PORTFOLIO_OVERVIEW_METRICS[cur]];
}, []);

export const getPortfolioOverviewMetricById = (id) => {
  if (!id) return;
  return PORTFOLIO_OVERVIEW_METRICS_VALUES.find((el) => el.id === id);
};

export const formatFilterModalChipText = (id) => {
  const el = getPortfolioOverviewMetricById(id);
  const sectionName = id
    .split('__')[0]
    .split('_')
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');

  return `${sectionName} > ${el.name}`;
};
