import React from 'react';
import {
  ActionIcon,
  Card,
  Container,
  Flex,
  LoadingOverlay,
  Overlay,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconArrowNarrowDown, IconArrowNarrowUp, IconInfoCircle, IconRefresh } from '@tabler/icons-react';
import { GROW, GROW_COLOR, REVERSE_GROW_COLOR } from '../../constants/metric';
import Info from '../Info/Info';

export default function ScoreMetric({
  title,
  showTrend = true,
  data,
  isLoading,
  hint,
  prefix,
  suffix,
  isError,
  onRetry,
  valueFormatter,
  useCustomContent = false,
  renderCustomContent = () => <></>,
}) {
  const retry = () => {
    if (onRetry) {
      onRetry();
    }
  };
  const { colors } = useMantineTheme();
  return (
    <>
      <Card
        radius='lg'
        padding='md'
        pb={20}
        sx={{
          border: '1px solid ' + colors.border_color[0],
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          gap: '1rem',
          backgroundColor: colors.primary_background[0],
          'justify-content': 'space-between',
        }}
      >
        <LoadingOverlay
          id={`loading_overlay__${title}`}
          zIndex={10}
          visible={isLoading}
          overlayProps={{ radius: 'lg', blur: 2 }}
          loaderProps={{ type: 'bars' }}
          sx={{
            borderRadius: '1rem',
          }}
        />
        {isError && (
          <Overlay color={colors.retry_error_color[6]} radius='lg' opacity={0.6} center zIndex={10}>
            <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
              <IconRefresh
                id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
                style={{ width: '70%', height: '70%' }}
                stroke={1.5}
                onClick={retry}
              />
            </ActionIcon>
          </Overlay>
        )}
        <Title fw={600} c='text_secondary' order={5}>
          {title}
        </Title>
        <Flex direction='column' gap='lg'>
          {useCustomContent ? (
            renderCustomContent(data.value, { isLoading, prefix, valueFormatter, suffix })
          ) : (
            <Title size='1.5rem' fw={600}>
              {data.value
                ? `${prefix ?? ''}${valueFormatter ? valueFormatter(data.value) : data.value}${suffix ?? ''}`
                : '-'}
            </Title>
          )}
          <Container p={0} m={0} sx={{ display: 'flex', justifyContent: showTrend ? 'space-between' : 'end' }}>
            {showTrend && (
              <Flex justify='space-between'>
                {data.grow === GROW.UP && (
                  <IconArrowNarrowUp
                    color={data.reverse_grow ? REVERSE_GROW_COLOR[data.grow] : GROW_COLOR[data.grow]}
                  />
                )}
                {data.grow === GROW.DOWN && (
                  <IconArrowNarrowDown
                    color={data.reverse_grow ? REVERSE_GROW_COLOR[data.grow] : GROW_COLOR[data.grow]}
                  />
                )}
                <Text
                  ml={4}
                  fw={500}
                  size={14}
                  lh='1.5rem'
                  color={data.reverse_grow ? REVERSE_GROW_COLOR[data.grow] : GROW_COLOR[data.grow]}
                >
                  {data.grow === GROW.STEADY ? 'Steady' : data.percentage_change + '%'}
                </Text>
              </Flex>
            )}

            {hint && (
              <Info label={hint} w={200}>
                <IconInfoCircle size={20} color={colors.simple_icon_colors[0]} />
              </Info>
            )}
          </Container>
        </Flex>
      </Card>
    </>
  );
}
