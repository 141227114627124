import React from 'react';
import dayjs from 'dayjs';
import { useConnectionErrorsService } from '../../../../../../../services/connectionErrorsService';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import SimpleReportTable from '../../../../../../../components/SimpleReportTable/SimpleReportTable';
import { columns, initialState } from './ConnectionErrorsTable/constants/systemHealthTableDef';

export default function ConnectionErrorsTable({ dates }) {
  const { get } = useConnectionErrorsService();

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'Connection Errors',
        columns,
        initialState,
        cellMaxWidth: '',
        enableFilters: true,
        enableColumnActions: true,
        enableColumnFilters: true,
      }}
      fetchMethod={get}
      defaultValue={[]}
      deps={[dates]}
      params={{ dates }}
      paramsFormatter={(params) => [
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
