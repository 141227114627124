import React, { createContext, useContext, useEffect, useState } from 'react';
import * as dayjs from 'dayjs';
import { AuthContext } from './AuthContext';
import { useTableColumnData } from '../pages/PortfolioOverview/Components/Tables/PortfolioOverview/constants/tableColumnData';
import { useDebouncedState } from '@mantine/hooks';
import { PORTFOLIO_OVERVIEW_COLUMNS } from '../constants/localStorage';

export const PortfolioOverviewFiltersContext = createContext(null);

export const PortfolioOverviewFiltersProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const { initialColumnsData } = useTableColumnData();
  const tableColumnsLocalStorage = JSON.parse(localStorage.getItem(PORTFOLIO_OVERVIEW_COLUMNS));

  const updatedInitialColumnsData = initialColumnsData.map((c) => {
    if (tableColumnsLocalStorage?.order?.length && !tableColumnsLocalStorage?.order?.includes(c.columnName)) {
      c.show = false;
      return c;
    }
    return c;
  });

  const properties = currentUser?.properties;
  const [portfolioOverviewColumns, setPortfolioOverviewColumns] = useState(tableColumnsLocalStorage);
  const [tableColumnsData, setTableColumnsData] = useState(updatedInitialColumnsData);
  const [propertyIds, setPropertyIds] = useState(properties.map((p) => p.id));
  const startDate = dayjs().date() === 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month');
  const endDate = dayjs().subtract(1, 'day');
  const [dates, setDates] = useState([startDate, endDate]);
  const propertyColumnData = properties?.map((p) => ({
    property: p.name,
    propertyId: p.id,
  }));
  const [propertySearch, setPropertySearch] = useDebouncedState('', 700);
  const [prevPropertySearch, setPrevPropertySearch] = useState('');

  const setColumns = (values) => {
    setPortfolioOverviewColumns(values);
    localStorage.setItem(PORTFOLIO_OVERVIEW_COLUMNS, JSON.stringify(values));
  };

  const onChangePropertySearch = (value) => {
    const filterProperties = properties.filter((p) => p.name?.toLowerCase()?.includes(value?.toLowerCase()));

    setPropertySearch(value);
    setPropertyIds(filterProperties.map((p) => p.id));
  };

  useEffect(() => {
    if (!tableColumnsLocalStorage?.order?.length && !tableColumnsLocalStorage?.headers?.length) {
      setColumns({
        order: initialColumnsData.map((c) => c.columnName),
        headers: initialColumnsData.map((c) => c.column.header),
        selectedProperty: prevPropertySearch,
      });
    }

    const searchPropertyLocalStorage = portfolioOverviewColumns?.selectedProperty
      ? portfolioOverviewColumns.selectedProperty
      : '';

    onChangePropertySearch(searchPropertyLocalStorage);
  }, []);

  return (
    <PortfolioOverviewFiltersContext.Provider
      displayName={'PortfolioOverviewFiltersProvider'}
      value={{
        dates,
        setDates,
        properties,
        propertyIds,
        propertyColumnData,
        tableColumnsData,
        setTableColumnsData,
        initialColumnsData: initialColumnsData.filter((col) => col.columnName != 'property'),
        propertySearch,
        setPropertySearch,
        prevPropertySearch,
        setPrevPropertySearch,
        onChangePropertySearch,
        portfolioOverviewColumns,
        setPortfolioOverviewColumns: setColumns,
      }}
    >
      {children}
    </PortfolioOverviewFiltersContext.Provider>
  );
};
