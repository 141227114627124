import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/marketing/search-engine';

export const useSearchEngineService = () => {
  const { axios } = useAxios();

  const getPerformanceOverview = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/performance-overview`, { ...config, params });

    return response?.data;
  };

  const getImpressions = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/impressions`, { ...config, params });

    return response?.data;
  };

  const getClicks = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/clicks`, { ...config, params });

    return response?.data;
  };

  const getCTR = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/ctr`, { ...config, params });

    return response?.data;
  };

  const getAvgCpc = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/avg-cpc`, { ...config, params });

    return response?.data;
  };

  const getPhoneCalls = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/phone-calls`, { ...config, params });

    return response?.data;
  };

  const getFormSubmits = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/form-submits`, { ...config, params });
    return response?.data;
  };

  const getTopPerformingKeywords = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/top-performing-keywords`, { ...config, params });

    return response?.data;
  };

  const getClicksByAdGroup = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/clicks-by-ad-group`, { ...config, params });

    return response?.data;
  };

  const getClicksByLocations = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/clicks-by-locations`, { ...config, params });

    return response?.data;
  };

  const getClicksByAge = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/clicks-by-age`, { ...config, params });

    return response?.data;
  };

  return {
    getPerformanceOverview,
    getImpressions,
    getClicks,
    getCTR,
    getAvgCpc,
    getPhoneCalls,
    getFormSubmits,
    getTopPerformingKeywords,
    getClicksByAdGroup,
    getClicksByLocations,
    getClicksByAge,
  };
};
