import { CONNECTION_TYPE } from '../../../../../../../constants/connectionType';
import EntrataConnection from './connections/entrataConnection';
import CallrailConnection from './connections/callrailConnection';
import FacebookAdsConnection from './connections/facebookAdsConnection';
import KnockConnection from './connections/knockConnection';
import YardiConnection from './connections/yardiConnection';
import HelloDataConnection from './connections/helloDataConnection';
import { FEATURE_FLAGS } from '../../../../../../../constants/featureFlags';

export const PROPERTY_INTEGRATIONS = {
  [CONNECTION_TYPE.Entrata]: {
    name: 'Entrata Connection',
    component: EntrataConnection,
    connectionDataPath: 'details.entrata_account_id',
  },
  [CONNECTION_TYPE.Callrail]: {
    name: 'Callrail Connection',
    component: CallrailConnection,
    connectionDataPath: 'details.callrail_account_id',
  },
  [CONNECTION_TYPE.Knock]: {
    name: 'Knock Connection',
    component: KnockConnection,
    connectionDataPath: 'details.knock_source_id',
  },
  [CONNECTION_TYPE.FacebookAds]: {
    name: 'Facebook Ads Connection',
    component: FacebookAdsConnection,
    connectionDataPath: 'details.facebook_ads_account_id',
  },
  [CONNECTION_TYPE.Yardi]: {
    name: 'Yardi Connection',
    component: YardiConnection,
    connectionDataPath: 'details.yardi_account_id',
    featureFlag: FEATURE_FLAGS.YardiIntegration,
  },
  [CONNECTION_TYPE.HelloData]: {
    name: 'HelloData Connection',
    component: HelloDataConnection,
    connectionDataPath: 'details.hello_data_account',
    featureFlag: FEATURE_FLAGS.HelloDataIntegration,
  },
};
