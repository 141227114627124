import React from 'react';
import { JsonInput, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

export const columns = [
  {
    accessorKey: 'connection_type',
    header: 'Connection type',
    maxSize: 0,
    Cell: ({ cell }) => {
      return (
        <Text c='blue' fw={700} tt='capitalize'>
          {cell.getValue()}
        </Text>
      );
    },
  },
  {
    accessorKey: 'pattern',
    header: 'Error Pattern',
    Cell: ({ cell }) => {
      const [opened, { open, close }] = useDisclosure(false);
      return (
        <>
          <Modal zIndex={404} padding={'lg'} size='55%' opened={opened} onClose={close} title='Error' centered>
            <JsonInput value={JSON.stringify({ pattern: `${cell.getValue()}...` })} autosize />
          </Modal>

          <Text sx={{ cursor: 'pointer', textWrap: 'balance' }} onClick={open}>
            {cell.getValue()}
          </Text>
        </>
      );
    },
  },
  {
    accessorKey: 'total',
    header: 'Total Errors',
    maxSize: 0,
  },
];

export const initialState = {
  sorting: [{ id: 'total', desc: true }],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
};
