import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@mantine/core';

export const columns = [
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => (
      <Text c={cell.getValue() === 'active' ? 'green' : 'red'} tt='capitalize'>
        {cell.getValue()}
      </Text>
    ),
  },
  {
    accessorKey: 'name',
    header: 'Property Name',
    Cell: ({ cell }) => {
      return (
        <Link to={`/admin/properties/${cell?.row?.original?.id}`} style={{ color: 'blue' }}>
          {cell.getValue()}
        </Link>
      );
    },
  },
  {
    accessorKey: 'platforms',
    header: 'Connected Platforms',
    Cell: ({ cell }) =>
      cell.getValue()?.length ? (
        <Text c='blue' fw={700} tt='capitalize'>
          {cell.getValue().map((x) => x.replaceAll('_', ' ')).join(', ')}
        </Text>
      ) : (
        ''
      ),
  },
];

export const initialState = {
  columnFilters: [{ id: 'status', value: 'active' }],
  pagination: { pageSize: 5 },
};
