import { PROPERTY_REPORT_PAGES, PROPERTY_REPORT_SECTIONS } from '../../../../../../../../constants/propertyReportPages';
import { List, SimpleGrid, Text, ThemeIcon } from '@mantine/core';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

export default function ReportPagesControl({ readOnly, onChange, value = [] }) {
  const [enabledPages, setEnabledPages] = useState(value?.length ? new Set(value) : new Set());

  const handleChange = (event, page) => {
    event.preventDefault();
    event.stopPropagation();

    let update = enabledPages.size ? new Set(enabledPages) : new Set();
    if (update.has(page)) {
      update.delete(page);
    } else {
      update = update.add(page);
    }

    setEnabledPages(update);
  };

  useEffect(() => {
    onChange && onChange(Array.from(enabledPages));
  }, [enabledPages]);

  return (
    <SimpleGrid cols={3} mx={0} px={0} pt='md'>
      {Object.values(PROPERTY_REPORT_SECTIONS).map((section) => (
        <List key={section}>
          <Text weight={700} tt='capitalize'>
            {section.replaceAll('_', ' ')}
          </Text>
          {Object.values(PROPERTY_REPORT_PAGES[section]).map((page) => (
            <List.Item
              pt='sm'
              key={page}
              opacity={enabledPages.has(page) ? 1 : 0.4}
              style={{ cursor: readOnly ? 'default' : 'pointer' }}
              onClick={(event) => !readOnly && handleChange(event, page)}
              icon={
                <ThemeIcon color='transparent'>
                  {enabledPages.has(page) ? (
                    <IconEye color='blue' size='1.5rem' />
                  ) : (
                    <IconEyeOff color='grey' size='1.5rem' />
                  )}
                </ThemeIcon>
              }
            >
              <Text tt='capitalize'>{page.replaceAll('_', ' ')}</Text>
            </List.Item>
          ))}
        </List>
      ))}
    </SimpleGrid>
  );
}
