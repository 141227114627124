import React, { createContext, useState } from 'react';
import * as dayjs from 'dayjs';

export const UsersActivityContext = createContext(null);

export const UsersActivityProvider = ({ children }) => {
  const startDate = dayjs().date() === 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month');
  const endDate = dayjs();

  const [dates, setDates] = useState([startDate, endDate]);

  return <UsersActivityContext.Provider value={{ dates, setDates }}>{children}</UsersActivityContext.Provider>;
};
