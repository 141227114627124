import { getToken, removeToken, updateToken } from './tokenService';
import { ORIGINAL_REFRESH_TOKEN, ORIGINAL_TOKEN, REFRESH_TOKEN, TOKEN } from '../constants/localStorage';
import { axiosInstance } from './axiosService';

export const useAuthService = () => {
  const login = async (body) => {
    const res = await axiosInstance.post(process.env.REACT_APP_API_BASE_URL + '/auth/login', body);
    updateToken(TOKEN, res.data.token);
    updateToken(REFRESH_TOKEN, res.data.refresh_token);

    return res;
  };

  const logout = () => {
    removeToken(TOKEN);
    removeToken(REFRESH_TOKEN);
    removeToken(ORIGINAL_TOKEN);
    removeToken(ORIGINAL_REFRESH_TOKEN);
    window.location.replace('/auth');
  };

  const authorize = async () => {
    try {
      return await axiosInstance.get(process.env.REACT_APP_API_BASE_URL + '/auth/me');
    } catch (error) {
      if (error.response.status === 403) {
        logout();
      }
    }
  };

  const getTokenInfo = async (token) => {
    return await axiosInstance.get(process.env.REACT_APP_API_BASE_URL + `/auth/verify-token/${token}`);
  };

  const setUserPassword = async (body) => {
    const res = await axiosInstance.post(process.env.REACT_APP_API_BASE_URL + '/auth/set-password', body);

    return res;
  };

  const sendResetPasswordEmail = async (email) => {
    return await axiosInstance.get(process.env.REACT_APP_API_BASE_URL + `/auth/forgot-password/${email}`);
  };

  const checkResetPasswordToken = async (token) => {
    return await axiosInstance.get(process.env.REACT_APP_API_BASE_URL + `/auth/reset-password/${token}`);
  };

  const resetPassword = async (body) => {
    const res = await axiosInstance.post(process.env.REACT_APP_API_BASE_URL + '/auth/reset-password', body);

    return res;
  };

  const setUserPreviewMode = async (accessData) => {
    if (!accessData?.token) {
      throw new Error('Missing user access token');
    }

    const originalToken = getToken(TOKEN);
    updateToken(ORIGINAL_TOKEN, originalToken);

    const originalRefreshToken = getToken(REFRESH_TOKEN);
    updateToken(ORIGINAL_REFRESH_TOKEN, originalRefreshToken);

    updateToken(TOKEN, accessData.token);
    removeToken(REFRESH_TOKEN);

    window.location.replace('/');
  };

  const exitUserPreviewMode = () => {
    const originalToken = getToken(ORIGINAL_TOKEN);
    updateToken(TOKEN, originalToken);
    removeToken(ORIGINAL_TOKEN);

    const originalRefreshToken = getToken(ORIGINAL_REFRESH_TOKEN);
    updateToken(REFRESH_TOKEN, originalRefreshToken);
    removeToken(ORIGINAL_REFRESH_TOKEN);

    window.location.replace('/');
  };

  return {
    login,
    logout,
    authorize,
    getTokenInfo,
    setUserPassword,
    sendResetPasswordEmail,
    checkResetPasswordToken,
    resetPassword,
    setUserPreviewMode,
    exitUserPreviewMode,
  };
};
