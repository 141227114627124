import React, { useContext } from 'react';
import { IntegrationsContext } from './IntegrationsComponent';
import { Box, Button, Group } from '@mantine/core';
import { IconEdit, IconPlus, IconCheck, IconReload, IconEye } from '@tabler/icons-react';
import { FORM_MODES } from '../../../../../../../constants/form_modes';

const ViewMode = () => {
  const {
    openIntegrationModal,
    mode,
    connectionType,
    hasConnectionErrors,
    handleRetryConnection,
    readOnly,
    connectionValue,
  } = useContext(IntegrationsContext);

  const renderRetryConnectionSection = () => {
    if (mode !== FORM_MODES.View) return;

    return (
      hasConnectionErrors && (
        <IconReload
          size={30}
          color='red'
          style={{ paddingRight: '4px' }}
          stroke={1.5}
          cursor='pointer'
          onClick={() => handleRetryConnection(connectionType)}
        />
      )
    );
  };

  return (
    <Box>
      {connectionValue ? (
        <Group my={7}>
          {renderRetryConnectionSection()}
          {!hasConnectionErrors && <IconCheck size={30} color='green' />}
          <Button variant='white' bg='transparent' px={6} onClick={openIntegrationModal}>
            {readOnly ? (
              <IconEye size={30} color='gray' variant='default' />
            ) : (
              <IconEdit size={30} color='gray' variant='default' />
            )}
          </Button>
        </Group>
      ) : (
        <Group my={7}>
          <Button color='gray' variant='default' my={7} onClick={openIntegrationModal} disabled={readOnly}>
            <IconPlus style={{ marginRight: 5 }} />
            Add connection
          </Button>
        </Group>
      )}
    </Box>
  );
};

export default ViewMode;
