import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import { useWebsitePerformanceService } from '../../../../../../../../services/bi/property/marketing/WebsitePerformanceService';
import BarChartComponent from '../../../../../../../../components/BarChart/BarChart';

export default function UsersByDevice() {
  const { getUsersByDevice } = useWebsitePerformanceService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={BarChartComponent}
      childProps={{
        title: 'Users By Device',
        index: 'device',
        hint: 'Total users by type of device used to interact with the property website.',
        dataFormatter: (data) => data?.sort((a, b) => b.total - a.total) ?? [],
        showLegend: false
      }}
      fetchMethod={getUsersByDevice}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
