import React from 'react';
import { Center, Title } from '@mantine/core';

const PropertyMarketSurveyRentComparisonSection = () => {
  return (
    <Center>
      <Title>Coming soon!</Title>
    </Center>
  );
};

export default PropertyMarketSurveyRentComparisonSection;
