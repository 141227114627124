import React, { useContext } from 'react';
import { useBiPropertyOverviewService } from '../../../../../../../../services/bi/property/overview/PropertyOverviewService';
import { columns, initialState, state } from './constants/upcommingExpirationsTableDef';
import { PropertyFiltersContext } from '../../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../../components/DataFetcher/DataFetcher';
import dayjs from 'dayjs';
import SimpleReportTable from '../../../../../../../../components/SimpleReportTable/SimpleReportTable';

export default function UpcomingExpirationsTable() {
  const { getUpcomingExpirations } = useBiPropertyOverviewService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={SimpleReportTable}
      childProps={{
        title: 'Upcoming Expirations',
        columns,
        initialState,
        state,
      }}
      fetchMethod={getUpcomingExpirations}
      defaultValue={[]}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
