import { useAxios } from './axiosService';

export const useUserProfileService = () => {
  const { axios } = useAxios();

  const updateUserPreferences = async (userPreferences, config = {}) => {
    const res = await axios.patch('auth/user-preferences', userPreferences, { ...config });

    return res?.data;
  };

  return {
    updateUserPreferences,
  };
};
