import { axiosInstance } from './axiosService';

const API_PATH = '/admin/properties';

export const usePropertyService = () => {
  const get = async (company_id, account_manager_id, exclude_archived, config = {}) => {
    const response = await axiosInstance.get(API_PATH, {
      ...config,
      params: { company_id, account_manager_id, exclude_archived },
    });

    return response?.data;
  };

  const getPropertyNames = async (company_ids, account_manager_id) => {
    const response = await axiosInstance.get(`${API_PATH}/names`, { params: { company_ids, account_manager_id } });

    return response?.data;
  };

  const createProperty = async (property) => {
    const response = await axiosInstance.post(API_PATH, property);

    return response?.data;
  };

  const getPropertyDetails = async (id) => {
    const response = await axiosInstance.get(`${API_PATH}/${id}`);

    return response?.data;
  };

  const updateProperty = async (id, property) => {
    const response = await axiosInstance.patch(`${API_PATH}/${id}`, property);

    return response?.data;
  };

  const enableProperty = async (id) => {
    const response = await axiosInstance.patch(`${API_PATH}/enable/${id}`);

    return response?.data;
  };

  const disableProperty = async (id) => {
    const response = await axiosInstance.patch(`${API_PATH}/disable/${id}`);

    return response?.data;
  };

  const retryPropertyConnection = async (id, connection_type) => {
    const response = await axiosInstance.patch(`${API_PATH}/${id}/retry/${connection_type}`);

    return response?.data;
  };

  return {
    get,
    getPropertyNames,
    createProperty,
    getPropertyDetails,
    updateProperty,
    enableProperty,
    disableProperty,
    retryPropertyConnection,
  };
};
