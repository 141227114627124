import React, { useEffect, useState } from 'react';
import { Select } from '@mantine/core';

export const REPORT_DATE_RANGE_OPTIONS = {
  ThisMonthToDate: 'this_month_to_date',
  LastSevenDays: 'last_seven_days',
  PreviousMonth: 'previous_month',
};

export const REPORT_DATE_RANGE_OPTION_LABELS = {
  [REPORT_DATE_RANGE_OPTIONS.ThisMonthToDate]: 'This month to date',
  [REPORT_DATE_RANGE_OPTIONS.LastSevenDays]: 'Last seven days',
  [REPORT_DATE_RANGE_OPTIONS.PreviousMonth]: 'Previous month ',
};

const ReportDateRangeOptionsSelector = ({ value, onChange, readOnly, required }) => {
  const options = Object.values(REPORT_DATE_RANGE_OPTIONS).map((x) => ({
    value: x,
    label: REPORT_DATE_RANGE_OPTION_LABELS[x],
  }));

  const [selectedOption, setSelectedOption] = useState(value);

  const handleClick = (value) => {
    const newValue = options.find((x) => x.value === value)?.value ?? options[0].value;
    setSelectedOption(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    handleClick(value);
  }, [value]);

  return (
    <Select
      required={required}
      readOnly={readOnly}
      value={selectedOption}
      label='Report Date Range'
      data={options}
      onChange={handleClick}
    />
  );
};

export default ReportDateRangeOptionsSelector;
