import React, { useContext, useState } from 'react';
import { Group, Flex, Title, useMantineTheme, Menu, LoadingOverlay, Switch } from '@mantine/core';
import { PropertyFiltersContext } from '../../../../context/PropertyFiltersContext';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar, IconFileDownload, IconFileTypePdf } from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';
import { usePropertyDetails } from '../../../../context/PropertyDetailsContext';
import { loadImage } from '../../../../utils/utils';
import { AuthContext } from '../../../../context/AuthContext';
import { useExportReportService } from '../Services/exportReportService';
import { useFeatureFlagsService } from '../../../../services/featureFlagsService';
import { FEATURE_FLAGS } from '../../../../constants/featureFlags';
import {
  HIDE_DATE_FILTER_LOCATIONS,
  SHOW_MULTI_TOUCH_ATTRIBUTION_LOCATIONS,
} from '../../../../constants/propertyReportPages';

export default function PropertiesHeader({ isExporting, setIsExporting }) {
  const { property, dates, setDates, multiTouchAttribution, toggleMultiTouchAttribution } =
    useContext(PropertyFiltersContext);
  const { currentUser } = useContext(AuthContext);
  const [datePickerValue, setDatePickerValue] = useState(dates);
  const location = useLocation();
  const { exportPDF } = useExportReportService();
  const { isFeatureEnabled } = useFeatureFlagsService();
  const { colors, colorScheme } = useMantineTheme();

  const { details } = usePropertyDetails();

  const hideDatePicker = HIDE_DATE_FILTER_LOCATIONS.includes(location.pathname.split('/').pop());
  const showMultiTouchAttribution = SHOW_MULTI_TOUCH_ATTRIBUTION_LOCATIONS.includes(location.pathname.split('/').pop());

  const locationName = location.pathname
    .split('/')
    .pop()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const onChangeDates = (value) => {
    setDatePickerValue(value);
    if (value.length && value.every((el) => !!el)) {
      setDates(value);
    }
  };

  const toggleExporting = async () => {
    setIsExporting(!isExporting);

    const logo = await loadImage(currentUser?.theme?.logo_url_light ?? currentUser.company_logo);

    setTimeout(
      async () => {
        await exportPDF({
          reportSelector: 'reporting-page-container',
          logo,
          colorScheme,
          drawColor: colors.company_theme_primary_color[6],
          textColor: 'gray',
          propertyName: property.name,
          pageName: locationName,
          from: dates[0],
          to: dates[1],
          hideDatePicker,
        });

        setIsExporting(!!isExporting);
      },

      700
    );
  };

  return (
    <Group
      m='md'
      grow
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 220,
        margin: 0,
        padding: '1.25rem',
        background: colors.gradient_header_color[6],
      }}
    >
      <Group align='baseline'>
        <Title order={2} fw={500} tt='uppercase'>
          {property?.label ?? ''}
        </Title>
        <Title order={5} color='dimmed' size='lg'>
          {details && `${details.city}, ${details.state}`}
        </Title>
        <Title ml='lg' color='dimmed' size='lg' fw={500} style={{ alignSelf: 'center' }}>
          {locationName}
        </Title>
        {showMultiTouchAttribution && isFeatureEnabled(FEATURE_FLAGS.GA4MultiTouchAttribution) && (
          <Switch
            ml='lg'
            style={{ alignSelf: 'center' }}
            checked={multiTouchAttribution}
            onChange={toggleMultiTouchAttribution}
            label='Multi Touch Attribution'
          />
        )}
      </Group>

      <Flex justify='right'>
        {!hideDatePicker && (
          <DatePickerInput
            valueFormat='MM/DD/YYYY'
            type='range'
            placeholder='Pick dates range'
            icon={<IconCalendar size='1.3em' />}
            variant='default'
            value={datePickerValue}
            styles={{
              input: {
                backgroundColor: colors.third_background_color[6],
              },
            }}
            onChange={onChangeDates}
            w={260}
          />
        )}
        {isFeatureEnabled(FEATURE_FLAGS.PdfExport) && (
          <Group ml={27}>
            {/*  <ActionIcon>*/}
            {/*    <IconLink color='black' />*/}
            {/*  </ActionIcon>*/}
            <LoadingOverlay
              id='pdf_export_loading_overlay'
              zIndex={10}
              visible={isExporting}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ type: 'bars' }}
            />
            <Menu shadow='md' width={200} className={'download-file-dropdown'}>
              <Menu.Target>
                <IconFileDownload style={{ cursor: 'pointer' }} />
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Download file format</Menu.Label>
                <Menu.Item icon={<IconFileTypePdf size={14} />} onClick={toggleExporting} id='pdf-export-button'>
                  PDF
                </Menu.Item>
                {/*TODO: CSV Download*/}
                {/*<Menu.Item icon={<IconFileTypeCsv size={14} />} onClick={() => console.log('CSV')}>*/}
                {/*  CSV*/}
                {/*</Menu.Item>*/}
              </Menu.Dropdown>
            </Menu>

            {/*  <ActionIcon>*/}
            {/*    <IconViewportWide color='black' />*/}
            {/*  </ActionIcon>*/}
          </Group>
        )}
      </Flex>
    </Group>
  );
}
