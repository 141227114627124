import React, { useContext, useEffect } from 'react';
import { Card, useMantineTheme } from '@mantine/core';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { PortfolioOverviewFiltersContext } from '../../../../../context/PortfolioOverviewFiltersContext';

export default function PortfolioOverviewTable({ columns, initialState, state, data }) {
  const theme = useMantineTheme();
  const backgroundColor = theme.colors.secondary_background_color[6];

  const table = useMantineReactTable({
    columns,
    initialState,
    state: {
      ...state,
    },
    data,
    enableTopToolbar: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableColumnFilters: true,
    mantineTableContainerProps: { sx: { maxHeight: 'calc(100vh - 250px)' } },
    mantineTableHeadCellProps: {
      style: {
        backgroundColor,
        lineHeight: '.85rem',
        paddingLeft: '1rem',
      },
      align: 'center',
    },
    mantineTableBodyCellProps: {
      style: {
        backgroundColor,
        lineHeight: '.85rem',
        paddingLeft: '1rem',
      },
      align: 'center',
    },
    mantineBottomToolbarProps: {
      style: {
        backgroundColor,
        fontSize: '0.8rem',
      },
    },
    mantineTableProps: {
      style: {
        minHeight: '262px',
      },
      withColumnBorders: true,
    },
    mantinePaperProps: {
      style: {
        height: '100%',
        backgroundColor,
      },
    },
  });

  const { portfolioOverviewColumns, setPortfolioOverviewColumns } = useContext(PortfolioOverviewFiltersContext);

  useEffect(() => {
    if (portfolioOverviewColumns) {
      table.setColumnOrder(() => portfolioOverviewColumns.order);
    }
  }, []);

  useEffect(() => {
    table.setColumnOrder(() => portfolioOverviewColumns?.order);
  }, [portfolioOverviewColumns?.headers]);

  useEffect(() => {
    const updatedOrder = {
      ...portfolioOverviewColumns,
      order: table.getState().columnOrder,
    };

    setPortfolioOverviewColumns(updatedOrder);
  }, [table.getState().columnOrder]);

  return (
    <Card
      p={0}
      sx={{
        height: 'fit-content',
        boxShadow: '#00000021 1px 2px 1px',
      }}
    >
      <MantineReactTable table={table} />
    </Card>
  );
}
