//This function allows to preload an image
export const loadImage = async (src) =>
  new Promise((resolve) => {
    if (!src) return resolve(null);
    try {
      const img = new Image();

      img.onload = () => resolve(img);
      img.onerror = () => resolve(null);
      img.crossOrigin = 'Anonymous';
      img.src = `${src}${src.includes('?') ? '&' : '?'}prevent-cache-load`;
    } catch (error) {
      resolve(null);
    }
  });
