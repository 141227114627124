import { useAxios } from '../../../axiosService';

const API_PATH = '/bi/property/marketing/website-performance';

export const useWebsitePerformanceService = () => {
  const { axios } = useAxios();

  const getSessions = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/sessions`, { ...config, params });

    return response?.data;
  };

  const getActiveUsers = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/active-users`, { ...config, params });

    return response?.data;
  };

  const getNewUsers = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/new-users`, { ...config, params });

    return response?.data;
  };

  const getEngagementRate = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/engagement-rate`, { ...config, params });

    return response?.data;
  };

  const getPhoneCalls = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/phone-calls`, { ...config, params });

    return response?.data;
  };

  const getFormSubmissions = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/form-submissions`, { ...config, params });

    return response?.data;
  };

  const getPerformanceOverview = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/performance-overview`, { ...config, params });

    return response?.data;
  };

  const getTopReferralSources = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/top-referral-sources`, { ...config, params });

    return response?.data;
  };

  const getTopPageViews = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/top-page-views`, { ...config, params });

    return response?.data;
  };

  const getNewUsersBySource = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/new-users-by-sources`, { ...config, params });

    return response?.data;
  };

  const getUsersByDevice = async (property_id, from, to, config = {}) => {
    const params = { property_id, from, to };
    const response = await axios.get(`${API_PATH}/users-by-device`, { ...config, params });

    return response?.data;
  };

  return {
    getSessions,
    getActiveUsers,
    getNewUsers,
    getEngagementRate,
    getPhoneCalls,
    getFormSubmissions,
    getPerformanceOverview,
    getTopReferralSources,
    getTopPageViews,
    getNewUsersBySource,
    getUsersByDevice,
  };
};
