import React, { useContext, useEffect, useState } from 'react';
import { Select } from '@mantine/core';
import { useUserService } from '../../services/userService';
import { AuthContext } from '../../context/AuthContext';

const SDMUsersSelect = ({
  value,
  onChange,
  withAsterisk,
  readOnly,
  disabled,
  required,
  error,
  label = 'Account manager',
  placeholder = 'Select a manager',
  preSelectByDefault = false,
  returnObjectOnChange = false,
  ...props
}) => {
  const { getInternalActiveUsers } = useUserService();
  const { currentUser } = useContext(AuthContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function getDropdownOptions() {
      const users = await getInternalActiveUsers();

      setOptions(
        users
          .map((user) => {
            if (`${user?.full_name} (${user?.email})` === `${currentUser?.full_name} (${currentUser?.email})`) {
              return { value: user?.id, label: `(Me): ${user?.full_name} (${user?.email})` };
            }
            return { value: user?.id, label: `${user?.full_name} (${user?.email})` };
          })
          .sort((a, b) => {
            if (`${currentUser?.full_name} (${currentUser?.email})` === a.label) return -1;
            if (`${currentUser?.full_name} (${currentUser?.email})` === b.label) return 1;

            return a.label?.localeCompare(b.label);
          })
      );
    }

    getDropdownOptions();
  }, []);

  useEffect(() => {
    if (preSelectByDefault && options.length > 0 && !value) {
      handleSelectChange(options[0].value);
    }
  }, [options, preSelectByDefault]);

  const handleSelectChange = (value) => {
    const selectedUser = options.find((option) => option.value === value);
    if (onChange) {
      onChange(returnObjectOnChange ? { ...selectedUser } : value);
    }
  };

  return (
    <Select
      withinPortal
      label={label}
      placeholder={placeholder}
      searchable
      clearable={!preSelectByDefault}
      value={value}
      withAsterisk={withAsterisk}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      error={error}
      onChange={handleSelectChange}
      data={options}
      {...props}
    />
  );
};

export default SDMUsersSelect;
