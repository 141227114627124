export const columns = [
  {
    accessorKey: 'floor_plan',
    header: 'Floor Plan',
    maxSize: 50,
  },
  {
    accessorKey: 'num_vacancies',
    header: 'Vacancies',
    maxSize: 50,
  },
  {
    accessorKey: 'units_available',
    header: 'Available Units',
    maxSize: 50,
  },
  {
    accessorKey: 'unit_count',
    header: 'Total Units',
    maxSize: 50,
  },
  {
    accessorKey: 'baths',
    header: 'Baths',
    maxSize: 50,
  },
  {
    accessorKey: 'beds',
    header: 'Beds',
    maxSize: 50,
  },
];

export const initialState = {
  sorting: [{ id: 'units_available', desc: true }],
  pagination: { pageSize: 5 },
};

export const state = {
  density: 'xs',
};
