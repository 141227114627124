import React, { useContext } from 'react';
import { useBiPropertyOverviewService } from '../../../../../../../services/bi/property/overview/PropertyOverviewService';
import { GROW } from '../../../../../../../constants/metric';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import dayjs from 'dayjs';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';

export default function ApprovedLeasesMetric() {
  const { getApprovedLeases } = useBiPropertyOverviewService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Approved Leases',
        hint: 'The numbers of leases that have been approved in your property management software.',
      }}
      fetchMethod={getApprovedLeases}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
