import React from 'react';
import { Container, Grid, SimpleGrid } from '@mantine/core';
import RemarketingImpressionsMetric from './Metrics/RemarketingImpressionsMetric';
import RemarketingClicksMetric from './Metrics/RemarketingClicksMetric';
import RemarketingAvgCpcMetric from './Metrics/RemarketingAvgCpcMetric';
import RemarketingCTRMetric from './Metrics/RemarketingCTRMetric';
import RemarketingAvgCpmMetric from './Metrics/RemarketingAvgCpmMetric';
import PerformanceOverview from './Charts/PerformanceOverview/PerformanceOverview';
import ClickThroughRate from './Charts/ClickThroughRate/ClickThroughRate';
import ClicksByDevice from './Charts/ClicksByDevice/ClicksByDevice';
import PerformanceByHourOfDay from './Charts/PerformanceByHourOfDay/PerformanceByHourOfDay';

export default function PropertyMarketingRemarketingSection() {
  return (
    <Container fluid m={0} pb='lg'>
      <SimpleGrid
        cols={5}
        breakpoints={[
          { maxWidth: 'xl', cols: 3, spacing: 'md' },
          { maxWidth: 'lg', cols: 2, spacing: 'md' },
          { maxWidth: 'md', cols: 1, spacing: 'sm' },
        ]}
      >
        <RemarketingImpressionsMetric />
        <RemarketingClicksMetric />
        <RemarketingCTRMetric />
        <RemarketingAvgCpcMetric />
        <RemarketingAvgCpmMetric />
      </SimpleGrid>
      <Grid mt='md'>
        <Grid.Col span={8} xl={8} lg={12} md={12} sm={12} xs={12}>
          <PerformanceOverview />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={12} md={12} sm={12} xs={12}>
          <ClickThroughRate />
        </Grid.Col>
        <Grid.Col span={4} xl={4} lg={6} md={12} sm={12} xs={12}>
          <ClicksByDevice />
        </Grid.Col>
        <Grid.Col span={8} xl={8} lg={12} md={12} sm={12} xs={12}>
          <PerformanceByHourOfDay />
        </Grid.Col>
        {/*TODO Uncomment once we have the Age data*/}
        {/*<Grid.Col span={4} xl={4} lg={6} md={12}>*/}
        {/*  <ClicksByAgeGender />*/}
        {/*</Grid.Col>*/}
        {/*<Grid.Col span={4} xl={4} lg={6} md={12}>*/}
        {/*  <ImpressionsByAgeGender />*/}
        {/*</Grid.Col>*/}
      </Grid>
    </Container>
  );
}
