import React, { useContext, useEffect } from 'react';
import './App.css';
import { AuthContext } from './context/AuthContext';
import AppLayout from './layouts/AppLayout';

export default function App() {
  const { fetchUser } = useContext(AuthContext);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return <AppLayout />;
}
