import React, { useEffect, useState } from 'react';
import { Chip, Flex, Group, Text } from '@mantine/core';
import { getDaysOfWeek } from '../../utils/time.util';

const WeekdaySelector = ({ value, onChange, disabled }) => {
  const daysOfWeek = getDaysOfWeek();

  const [selectedDay, setSelectedDay] = useState(value);

  const handleClick = (value) => {
    const newValue = daysOfWeek[value] ? value : '0';
    setSelectedDay(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    handleClick(value);
  }, [value]);

  return (
    <Flex align={'baseline'} gap='sm'>
      <Text size={'sm'}>On: </Text>
      <Chip.Group multiple={false} value={selectedDay} onChange={handleClick}>
        <Group justify='center'>
          {daysOfWeek.map((day, index) => (
            <Chip key={day} value={'' + index} disabled={disabled}>
              {day[0]}
            </Chip>
          ))}
        </Group>
      </Chip.Group>
    </Flex>
  );
};

export default WeekdaySelector;
