import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getToken } from '../services/tokenService';
import { useAuthService } from '../services/authService';
import { ORIGINAL_TOKEN, TOKEN } from '../constants/localStorage';
import { USER_ROLES } from '../constants/user';
import { THEME_MODES } from '../constants/theme_modes';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const role = currentUser?.role;
  const authService = useAuthService();

  const ldClient = useLDClient();

  const fetchUser = useCallback(async () => {
    try {
      if (getToken(TOKEN)) {
        setIsUserLoading(true);
        setIsPreviewMode(!!getToken(ORIGINAL_TOKEN));
        const { data } = await authService.authorize();
        const updatedUser = {
          ...data,
        };
        setCurrentUser(updatedUser);

        setIsUserLoading(false);
        return;
      }
      authService.logout();
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    currentUser && ldClient && ldClient.identify({ key: currentUser.email, name: currentUser.full_name });
  }, [currentUser, ldClient]);

  const setManagedProperties = (manager_properties) => setCurrentUser({ ...(currentUser ?? {}), manager_properties });

  return (
    <AuthContext.Provider
      value={{
        fetchUser,
        currentUser,
        isUserLoading,
        role,
        isPreviewMode,
        setManagedProperties,
        //TODO undo this change once dark/light themes are supported for internal users
        userThemeMode: role === USER_ROLES.PROPERTY_MANAGER ? currentUser?.user_theme_mode : THEME_MODES.lightMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
