import React, { useContext } from 'react';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import { GROW } from '../../../../../../../constants/metric';
import dayjs from 'dayjs';
import { useRentComparisonService } from '../../../../../../../services/bi/property/MarketSurvey/RentComperisonService';
import { Title } from '@mantine/core';

const ExposureMetric = () => {
  const { getExposure } = useRentComparisonService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Exposure',
        suffix: '%',
        showTrend: false,
        valueFormatter: (value) => Math.round(value),
        hint: 'The percentage of rental units that are at risk of becoming vacant or are already unoccupied. This metric not only includes current vacancies but also units that will soon be available because current leases are ending and the tenants have given notice they will not be renewing.',
        useCustomContent: true,
        renderCustomContent: (value, params) => {
          if (params.isLoading || (!value && value !== 0)) {
            return (
              <Title order={2} mt={7} fw={600} ff='Montserrat Semibold, sans-serif'>
                -
              </Title>
            );
          }

          return (
            <Title order={2} mt={7} fw={600} ff='Montserrat Semibold, sans-serif'>
              {params.valueFormatter(value)}
              {params.suffix}
            </Title>
          );
        },
      }}
      fetchMethod={getExposure}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
};

export default ExposureMetric;
