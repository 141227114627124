export const formStyles = {
  ['.form-input']: {
    background: 'none',
    '&:focus': {
      borderColor: 'none',
    },
  },
  ['&:focus .mantine-Input-input']: {
    borderColor: 'none',
  },
  ['& .mantine-PasswordInput-rightSection']: {
    padding: '10px',
    border: '1px solid #ccc',
    borderLeft: 'none',
    color: 'white',
    borderRadius: '0 7px 7px 0',
    backgroundColor: 'rgba(154,154,154,0.75)',
  },
  ['& .mantine-PasswordInput-visibilityToggle']: {
    color: 'white',
    '&:hover': {
      background: 'none',
    },
  },
};
