import dayjs from 'dayjs';
import Jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { LatoRegularFont } from '../../../../assets/pdfFonts/Lato-Regular-normal';

export const useExportReportService = () => {
  const addText = (pdf, text, xPosition, yPosition, fontSize, options) => {
    pdf.setFontSize(fontSize);
    pdf.text(text, xPosition, yPosition, { ...options });
  };

  const exportPDF = async ({
    reportSelector,
    logo,
    propertyName,
    pageName,
    from,
    to,
    drawColor,
    textColor,
    hideDatePicker,
  }) => {
    const input = document.getElementById(reportSelector);

    const reservedTop = 65;
    const reservedRight = 35;
    const reservedBottom = 10;
    const reservedLeft = 10;

    const pdf = new Jspdf({
      orientation: 'landscape',
      unit: 'px',
      format: 'a4',
      compressPdf: true,
    });

    if (logo) {
      const logoAspectRatio = logo.naturalHeight / logo.naturalWidth;
      pdf.addImage(logo, 'PNG', reservedLeft, 13, 30 / logoAspectRatio, 30);
    }

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const canvasWidth = input.clientWidth;
    const canvasHeight = input.clientHeight;

    const contentWidth = pdfWidth - reservedLeft - reservedRight;
    const contentHeight = pdfHeight - reservedTop - reservedBottom;

    const scale = Math.min(contentWidth / canvasWidth, contentHeight / canvasHeight);

    const canvas = await html2canvas(input, { scale: 3 });
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', reservedLeft, reservedTop, canvasWidth * scale, canvasHeight * scale);

    pdf.setDrawColor(drawColor);
    pdf.setLineWidth(1);
    pdf.line(pdfWidth, 55, 0, 55);

    pdf.setTextColor(textColor);

    pdf.addFileToVFS('Lato-Regular', LatoRegularFont);
    pdf.addFont('Lato-Regular', 'Lato-Regular', 'normal');
    pdf.setFont('Lato-Regular');

    const propertyNameWidth = (pdf.getStringUnitWidth(propertyName) * 12) / pdf.internal.scaleFactor;
    addText(pdf, propertyName, pdfWidth - propertyNameWidth - 15, !hideDatePicker ? 20 : 27, 12, {});

    const locationWidth = (pdf.getStringUnitWidth(pageName) * 9) / pdf.internal.scaleFactor;
    addText(pdf, pageName, pdfWidth - locationWidth - 15, !hideDatePicker ? 30 : 37, 9, {});

    const startDate = dayjs(from).format('MM/DD/YYYY');
    const endDate = dayjs(to).format('MM/DD/YYYY');

    const dateRangeLabel = startDate + ' — ' + endDate;
    const dateRangeWidth = (pdf.getStringUnitWidth(dateRangeLabel) * 9) / pdf.internal.scaleFactor;
    !hideDatePicker && addText(pdf, dateRangeLabel, pdfWidth - dateRangeWidth - 15, 40, 9, {});

    const poweredByLabel = 'Powered By \nSTREET DIGITAL MEDIA';
    addText(pdf, poweredByLabel, pdfWidth - 20, pdfHeight - pdf.getLineHeight(), 10, {
      angle: 90,
      lineHeightFactor: 1.5,
    });

    const currentDate = dayjs().format('ddd, MMM D, YYYY h:mm a');
    addText(pdf, 'Generated on ' + currentDate, reservedLeft, pdfHeight - 5, 6, {});

    const filename = `${propertyName.replace(/\s+/g, '-').replace(/[#!%&{}\\<>*?$;'"=:@+`|./[\]\s]/g, '')}_${pageName
      .replaceAll(' ', '_')
      .replace(/_/g, '-')}_${startDate + '-' + endDate}`;

    pdf.save(filename);
  };

  return {
    exportPDF,
  };
};
