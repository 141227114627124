export const CONNECTION_TYPE = {
  Entrata: 'entrata',
  Knock: 'knock',
  Callrail: 'callrail',
  GoogleAds: 'google_ads',
  GoogleAnalytics: 'google_analytics',
  FacebookAds: 'facebook_ads',
  Yardi: 'yardi',
  HelloData: 'hellodata',
};
