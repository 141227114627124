import React, { useCallback, useEffect, useState, createContext, useContext } from 'react';
import { PropertyFiltersContext } from './PropertyFiltersContext';
import { usePropertyDetailsService } from '../services/bi/property/details/PropertyDetailsService';

export const PropertyDetailsContext = createContext(null);

const PropertyDetailsProvider = ({ children }) => {
  const [details, setDetails] = useState(null);
  const [isDetailsLoading, setIsDetailsLoading] = useState(true);

  const { property } = useContext(PropertyFiltersContext);
  const { get } = usePropertyDetailsService();

  const fetchDetails = useCallback(async (property) => {
    setDetails(null);
    try {
      if (property) {
        setIsDetailsLoading(true);
        const data = await get(property.value);
        setDetails(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsDetailsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDetails(property);
  }, [property]);

  return (
    <PropertyDetailsContext.Provider
      value={{
        details,
        isDetailsLoading,
      }}
    >
      {children}
    </PropertyDetailsContext.Provider>
  );
};

export default PropertyDetailsProvider;

export const usePropertyDetails = () => {
  return useContext(PropertyDetailsContext);
};
