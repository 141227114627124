import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ActionIcon, Card, createStyles, Flex, LoadingOverlay, Overlay, Title, useMantineTheme } from '@mantine/core';
import { IconInfoCircle, IconRefresh } from '@tabler/icons-react';
import { transformKeysToWordsAndUppercase } from '../../utils/charts.util';
import Info from '../Info/Info';

const LINE_BAR_COLORS = ['darkcyan', 'rebeccapurple'];

const useStyles = createStyles({
  recharts: {
    '.recharts-cartesian-grid-horizontal line:first-child': {
      'stroke-opacity': 0,
    },
    '.recharts-yAxis.recharts-cartesian-axis .recharts-cartesian-axis-tick:first-child text': {
      'font-size': 0,
    },
  },
});

const createLineBarChart = (transformedKeys, yAxisConfig, colors) => {
  const theme = useMantineTheme();
  return transformedKeys.reverse().map((key, index) => {
    const config = yAxisConfig && yAxisConfig.find((x) => x.key === key);
    if (key === config.key && config.chartType === 'Bar') {
      return (
        <Bar
          key={key}
          radius={4}
          yAxisId={config?.position ?? null}
          dataKey={key}
          strokeWidth={2}
          fill={theme.colors?.chart_colors[index] ?? colors[index] ?? 'grey'}
        />
      );
    } else if (key === config.key && config.chartType === 'Line') {
      return (
        <Line
          type='monotone'
          key={key}
          yAxisId={config?.position ?? null}
          dataKey={key}
          animationDuration={650}
          strokeWidth={2}
          dot={true}
          stroke={theme.colors?.chart_colors[index] ?? LINE_BAR_COLORS[index] ?? 'grey'}
          activeDot={{ r: 5 }}
        />
      );
    }
  });
};

export default function LineBarChartComponent({
  title,
  data,
  index,
  hint,
  isLoading,
  isError,
  onRetry,
  valueFormatter,
  dataFormatter = (x) => x,
  excludeFields = [],
  showLegend = true,
  yAxisConfig = [],
}) {
  const { classes } = useStyles();
  const { colors: themeColors } = useMantineTheme();
  const { transformed, transformedKeys } = transformKeysToWordsAndUppercase(dataFormatter(data), [
    ...excludeFields,
    index,
  ]);

  const yAxis = yAxisConfig?.length ? (
    yAxisConfig.map((config) => {
      return (
        <YAxis
          key={config.key}
          tickFormatter={config.valueFormatter ?? valueFormatter}
          tick={{ fill: themeColors.text_third[0], fontWeight: '500' }}
          type='number'
          fontSize={12}
          strokeWidth={0}
          yAxisId={config.position}
          orientation={config.position}
        />
      );
    })
  ) : (
    <YAxis
      tick={{ fill: themeColors.text_third[0], fontWeight: '500' }}
      tickFormatter={valueFormatter}
      type='number'
      fontSize={12}
      strokeWidth={0}
    />
  );

  const LineBarChart = createLineBarChart(transformedKeys, yAxisConfig, LINE_BAR_COLORS) || null;

  return (
    <Card
      radius='lg'
      p='1.5rem'
      style={{
        border: '1px solid ' + themeColors.border_color[0],
        height: 'fit-content',
        backgroundColor: themeColors.primary_background[0],
      }}
    >
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Title size={20} fw='600'>
          {title}
        </Title>
        {hint && (
          <Info label={hint} w={200}>
            <IconInfoCircle size={20} color={themeColors.simple_icon_colors[0]} />
          </Info>
        )}
      </Flex>
      <ResponsiveContainer width='100%' height={320} style={{ padding: '0 .6rem' }} className={classes.recharts}>
        <ComposedChart
          width={500}
          height={400}
          data={transformed}
          margin={{
            top: 32,
            right: -10,
            bottom: 16,
            left: -25,
          }}
        >
          {showLegend && (
            <Legend
              formatter={(value) => (
                <span style={{ color: themeColors.text_chart_legend[0], fontSize: '14px', fontWeight: '500' }}>
                  {value}
                </span>
              )}
              iconType='plainline'
              layout='horizontal'
              verticalAlign={'bottom'}
              wrapperStyle={{ bottom: 10 }}
            />
          )}
          <CartesianGrid stroke={themeColors.border_weak[0]} strokeDasharray='4 4' vertical={false} />
          <XAxis strokeWidth={0} tick={{ fill: themeColors.text_third[0] }} dataKey={index} fontSize={12} />
          {yAxis}
          <Tooltip />
          {LineBarChart}
        </ComposedChart>
      </ResponsiveContainer>
      <LoadingOverlay
        id={`loading_overlay__${title}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'lg', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
      {isError && (
        <Overlay color={themeColors.retry_error_color[6]} opacity={0.6} center zIndex={10}>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
