import React from 'react';
import { Tooltip, useMantineTheme } from '@mantine/core';

const Info = ({ children, nowrap = false, ...additionalProps }) => {
  const { colors } = useMantineTheme();
  return (
    <Tooltip
      multiline
      radius='sm'
      withArrow
      transitionProps={{ duration: 200 }}
      color={colors.company_theme_primary_color[6]}
      position='bottom'
      arrowSize={10}
      offset={3}
      arrowRadius={3}
      styles={{ tooltip: { padding: '6px 8px', fontWeight: '500', textWrap: nowrap ? 'nowrap' : 'initial' } }}
      {...additionalProps}
    >
      {children}
    </Tooltip>
  );
};

export default Info;
