import React, { useEffect, useRef, useState } from 'react';
import { IconPlayerPauseFilled, IconPlayerPlayFilled, IconPlayerSkipBack } from '@tabler/icons-react';
import { Button } from '@mantine/core';

const AudioPlayer = ({
  recordingUrl,
  currentPlayingUrl,
  setCurrentPlayingUrl,
  playingInstance,
  setPlayingInstance,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (!audioRef.current || !recordingUrl) return;

    if (playingInstance && playingInstance !== audioRef.current) {
      playingInstance.pause();
      playingInstance.audioElement?.setIsPlaying(false);
    }

    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
      setPlayingInstance(audioRef.current);
      setCurrentPlayingUrl(recordingUrl);
      audioRef.current.audioElement = { setIsPlaying };
    } else {
      audioRef.current.pause();
      resetAudioPlayerState();
    }
  };

  const handleRestart = () => {
    if (!audioRef.current || !recordingUrl) return;

    if (playingInstance && playingInstance !== audioRef.current) {
      playingInstance.pause();
      playingInstance.audioElement?.setIsPlaying(false);
    }

    audioRef.current.currentTime = 0;
    audioRef.current.play();
    setIsPlaying(true);
    setPlayingInstance(audioRef.current);
    setCurrentPlayingUrl(recordingUrl);
    audioRef.current.audioElement = { setIsPlaying };
  };

  const handleAudioEnd = () => {
    resetAudioPlayerState();
  };

  const resetAudioPlayerState = () => {
    setIsPlaying(false);
    setPlayingInstance(null);
    setCurrentPlayingUrl(null);
  };

  useEffect(() => {
    setIsPlaying(recordingUrl === currentPlayingUrl);
  }, [recordingUrl, currentPlayingUrl]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleAudioEnd);

      return () => {
        audioRef.current?.removeEventListener('ended', handleAudioEnd);
      };
    }
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <audio ref={audioRef} style={{ display: 'none' }}>
        <source src={recordingUrl} type='audio/mpeg' />
        Your browser does not support the audio element.
      </audio>
      <button onClick={handlePlayPause} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
        {isPlaying ? (
          <IconPlayerPauseFilled style={{ color: 'red' }} />
        ) : (
          <IconPlayerPlayFilled style={{ color: 'green' }} />
        )}
      </button>
      <Button variant='transparent' onClick={handleRestart}>
        <IconPlayerSkipBack />
      </Button>
    </div>
  );
};

export default AudioPlayer;
