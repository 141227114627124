import * as React from 'react';
import { Flex } from '@mantine/core';
import { MRT_GlobalFilterTextInput, MRT_ToggleFullScreenButton } from 'mantine-react-table';
import AccountManagerSelection from '../Components/Header/AccountManagerSelection';
import CompanySelection from '../Components/Header/CompanySelection';
import PortfolioOverviewFilters from '../Components/Header/Filters';

export const renderTableHeader = ({ table }) => {
  return (
    <Flex p='md' justify='space-between'>
      <Flex gap='xs' align={'baseline'}>
        <MRT_GlobalFilterTextInput table={table} />
        <AccountManagerSelection />
        <CompanySelection />
      </Flex>
      <Flex gap={'xs'} align={'center'}>
        <PortfolioOverviewFilters />
        <MRT_ToggleFullScreenButton table={table} />
      </Flex>
    </Flex>
  );
};
