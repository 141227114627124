import * as React from 'react';
import { InternalPortfolioOverviewContext } from '../../../../Context/PortfolioOverviewContext';
import { useContext } from 'react';
import CompaniesDropdown from '../../../../../../../../../components/CompaniesDropdown/CompaniesDropdown';

export default function CompanySelection() {
  const { accountManager, company, setCompany } = useContext(InternalPortfolioOverviewContext);

  return (
    accountManager?.value && (
      <CompaniesDropdown
        label={''}
        value={company?.value}
        onChange={setCompany}
        accountManagerId={accountManager?.value}
        preSelectByDefault={true}
        returnObjectOnChange={true}
        w={250}
      />
    )
  );
}
