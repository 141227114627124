import { Box, Button, Title } from '@mantine/core';
import React, { useLayoutEffect } from 'react';
import CompanyForm from './forms/companyForm';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import { FORM_MODES } from '../../../../../constants/form_modes';
import { COMPANY_STATUS } from '../../../../../constants/companyStatus';
import VerifiedCompanyUsersForm from './forms/verifiedCompanyUsersForm';

export default function AdminEditCompany() {
  const company = useRouteLoaderData('company-details');
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (company?.status === COMPANY_STATUS.DISABLED) {
      navigate(-1);
    }
  }, []);

  const onClickBack = () => navigate(-1);

  return (
    <Box mx='auto' mt='sm' px={30} pb={30}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title order={2} mb={20} align='left'>
          Edit {company?.name}
        </Title>
        <Button color='gray' variant='default' onClick={onClickBack}>
          <IconArrowLeft style={{ marginRight: 3 }} />
          Go Back
        </Button>
      </div>
      <CompanyForm mode={FORM_MODES.Edit} initialValues={company}></CompanyForm>
      <VerifiedCompanyUsersForm mode={FORM_MODES.Create} companyId={company.id} />
    </Box>
  );
}
