import dayjs from 'dayjs';
import Jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { LatoRegularFont } from '../../../../assets/pdfFonts/Lato-Regular-normal';

export const useExportReportService = () => {
  const addText = (pdf, text, xPosition, yPosition, fontSize, options = {}) => {
    pdf.setFontSize(fontSize);
    pdf.text(text, xPosition, yPosition, { ...options });
  };

  const exportPDF = async ({
    reportSelector,
    aiLogo,
    logo,
    propertyName,
    pageName,
    from,
    to,
    sDLogo,
    drawColor,
    textColor,
    hideDatePicker,
  }) => {
    const input = document.getElementById(reportSelector);

    const reservedTop = 45;
    const reservedRight = 8;
    const reservedBottom = 10;
    const padding = 8;
    const reservedLeft = 10;

    const pdf = new Jspdf({
      orientation: 'landscape',
      unit: 'px',
      format: 'a4',
      compressPdf: true,
    });
    pdf.setDrawColor(drawColor);
    pdf.setLineWidth(0.6);

    let logoMargin = padding;

    if (aiLogo) {
      const logoAspectRatio = aiLogo.naturalHeight / aiLogo.naturalWidth;
      pdf.addImage(aiLogo, 'PNG', padding, 13, 10 / logoAspectRatio, 10);

      const x = padding + 10 / logoAspectRatio + padding;
      pdf.line(x, 7, x, 30);

      logoMargin = x + padding;
    }

    if (logo) {
      const logoAspectRatio = logo.naturalHeight / logo.naturalWidth;
      pdf.addImage(logo, 'PNG', logoMargin, 10, 16 / logoAspectRatio, 16);
    }

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const canvasWidth = input.clientWidth;
    const canvasHeight = input.clientHeight;

    const contentWidth = pdfWidth - reservedLeft - reservedRight;
    const contentHeight = pdfHeight - reservedTop - reservedBottom;

    pdf.line(pdfWidth, 37, 0, 37);

    const scale = Math.min(contentWidth / canvasWidth, contentHeight / canvasHeight);

    const canvas = await html2canvas(input, { scale: 3 });
    const imgData = canvas.toDataURL('image/png');

    pdf.addImage(imgData, 'PNG', reservedLeft, reservedTop, canvasWidth * scale, canvasHeight * scale);

    pdf.addFileToVFS('Lato-Regular', LatoRegularFont);
    pdf.addFont('Lato-Regular', 'Lato-Regular', 'normal');
    pdf.setFont('Lato-Regular');

    pdf.setTextColor(textColor[1]);
    const locationWidth = (pdf.getStringUnitWidth(pageName) * 8) / pdf.internal.scaleFactor;
    addText(pdf, pageName, pdfWidth - locationWidth - padding, !hideDatePicker ? 23 : 34, 8, {});

    pdf.setTextColor(textColor[0]);
    const propertyNameWidth = (pdf.getStringUnitWidth(propertyName) * 10) / pdf.internal.scaleFactor;
    addText(pdf, propertyName, pdfWidth - propertyNameWidth - padding, !hideDatePicker ? 15 : 22, 10, {});

    const startDate = dayjs(from).format('MM/DD/YYYY');
    const endDate = dayjs(to).format('MM/DD/YYYY');

    const dateRangeLabel = startDate + ' — ' + endDate;
    const dateRangeWidth = (pdf.getStringUnitWidth(dateRangeLabel) * 9) / pdf.internal.scaleFactor;
    !hideDatePicker && addText(pdf, dateRangeLabel, pdfWidth - dateRangeWidth - padding, 33, 9, {});

    if (sDLogo) {
      const logoAspectRatio = sDLogo.naturalHeight / sDLogo.naturalWidth;
      const x = pdfWidth - 10 / logoAspectRatio - 20;
      pdf.addImage(sDLogo, 'PNG', x, pdfHeight - 12, 10 / logoAspectRatio, 10);
    }

    pdf.setTextColor(textColor[1]);
    const currentDate = dayjs().format('ddd, MMM D, YYYY h:mm a');
    addText(pdf, 'Generated on ' + currentDate, reservedLeft + 10, pdfHeight - 5, 6, {});

    const filename = `${propertyName.replace(/\s+/g, '-').replace(/[#!%&{}\\<>*?$;'"=:@+`|./[\]\s]/g, '')}_${pageName
      .replaceAll(' ', '_')
      .replace(/_/g, '-')}_${startDate + '-' + endDate}`;

    pdf.save(filename);

    //// to open PDF in new tab w/o saving it
    // pdf.output('dataurlnewwindow');
  };

  return {
    exportPDF,
  };
};
