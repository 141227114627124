import React from 'react';
import { Select } from '@mantine/core';

export const FREQUENCIES = {
  Weekly: 'weekly',
  Monthly: 'monthly',
};

const FREQUENCY_LABELS = {
  [FREQUENCIES.Weekly]: 'Weekly',
  [FREQUENCIES.Monthly]: 'Monthly',
};

const ScheduledReportPropertiesDropdown = ({
  value,
  onChange,
  withAsterisk,
  readOnly,
  disabled,
  required,
  error,
  ...props
}) => {
  const options = Object.values(FREQUENCIES).map((x) => ({ value: x, label: FREQUENCY_LABELS[x] }));
  const handleSelectChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      withinPortal
      label={'Frequency'}
      placeholder='Frequency'
      searchable
      defaultValue={value}
      withAsterisk={withAsterisk}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      error={error}
      onChange={handleSelectChange}
      data={options}
      {...props}
    />
  );
};

export default ScheduledReportPropertiesDropdown;
