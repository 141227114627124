import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { useBiPropertyBoxScoreService } from '../../../../../../../services/bi/property/overview/PropertyBoxScoreService';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';

export default function ATRMetric() {
  const { getATR } = useBiPropertyBoxScoreService();

  const { property } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'ATR',
        valueFormatter: (value) => Math.round(value),
        suffix: '%',
        hint: 'The percentage of units at your property that are currently available to rent.',
        showTrend: false,
      }}
      fetchMethod={getATR}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property]}
      params={{ property }}
      paramsValidator={(params) => params.property}
      paramsFormatter={(params) => [params.property.value]}
    />
  );
}
