import { Box, Group, List, ScrollArea, Text } from '@mantine/core';
import React, { useContext, useMemo } from 'react';
import { removeElById } from '../../../../../../../../../../utils/array.utils';
import {
  formatReportName,
  ORDERED_PROPERTY_REPORT_PAGES,
} from '../../../../../../../../../../constants/propertyReportPages';
import { PORTFOLIO_OVERVIEW_METRICS } from '../../../../../../../../../../constants/portfolioOverviewMetrics';
import { PortfolioOverviewFilterModalContext } from '../FilterModal';

export const AvailableFields = () => {
  const { selectedMetricIds, setSelectedMetricIds, searchMetric } = useContext(PortfolioOverviewFilterModalContext);

  const onMetricSelect = (id) => {
    if (selectedMetricIds.includes(id)) {
      setSelectedMetricIds(removeElById(id, selectedMetricIds));
      return;
    }

    setSelectedMetricIds(
      [...selectedMetricIds, id].sort(
        (a, b) =>
          ORDERED_PROPERTY_REPORT_PAGES.indexOf(a.split('__')[0]) -
          ORDERED_PROPERTY_REPORT_PAGES.indexOf(b.split('__')[0])
      )
    );
  };

  const filteredMetrics = useMemo(() => {
    if (!searchMetric) return PORTFOLIO_OVERVIEW_METRICS;

    return Object.entries(PORTFOLIO_OVERVIEW_METRICS).reduce((acc, [section, sectionMetrics]) => {
      const filteredSectionMetrics = sectionMetrics.filter((metric) =>
        metric.name.toLowerCase().includes(searchMetric.toLowerCase())
      );

      if (filteredSectionMetrics.length > 0) {
        acc[section] = filteredSectionMetrics;
      }

      return acc;
    }, {});
  }, [searchMetric]);

  return (
    <>
      <Text sx={{ fontSize: '.9rem', marginTop: '1.5em' }}>Available Fields</Text>
      <ScrollArea w='auto' h='15em' scrollbars='x' pt='xs' pb='md'>
        <Group w='max-content' grow sx={{ alignItems: 'stretch' }}>
          {Object.keys(filteredMetrics).map((key) => {
            return (
              <Box key={key}>
                <Text sx={{ fontSize: '.7rem' }}>{formatReportName(key)}</Text>
                <List
                  listStyleType='none'
                  sx={{
                    border: '1px solid #ced4da',
                    padding: '.5em',
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '2px',
                    height: '100%',
                    width: '9em',
                  }}
                >
                  {filteredMetrics[key]?.map((item, i) => {
                    return (
                      <List.Item
                        key={item.id + i}
                        sx={{
                          backgroundColor: selectedMetricIds.includes(item.id) ? '#e8e8e8' : '',
                          cursor: 'pointer',
                          padding: '2px 0 2px 5px',
                          borderRadius: '.3em',
                          fontSize: '.8rem',
                        }}
                        onClick={() => onMetricSelect(item.id)}
                      >
                        {item.name}
                      </List.Item>
                    );
                  })}
                </List>
              </Box>
            );
          })}
        </Group>
      </ScrollArea>
    </>
  );
};
