import React, { useContext } from 'react';
import { GROW } from '../../../../../../../constants/metric';
import { PropertyFiltersContext } from '../../../../../../../context/PropertyFiltersContext';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import ScoreMetric from '../../../../../../../components/ScoreMetric/ScoreMetric';
import dayjs from 'dayjs';
import { useSearchEngineService } from '../../../../../../../services/bi/property/marketing/SearchEngineService';

export default function AvgCpcMetric() {
  const { getAvgCpc } = useSearchEngineService();

  const { property, dates } = useContext(PropertyFiltersContext);

  return (
    <DataFetcherComponent
      Child={ScoreMetric}
      childProps={{
        title: 'Average CPC',
        hint: 'The average cost per click of an ad. Our benchmark is $2.00 for Google PPC ad campaigns.',
        valueFormatter: (value) => value?.toFixed(2),
        prefix: '$',
      }}
      fetchMethod={getAvgCpc}
      defaultValue={{ grow: GROW.STEADY }}
      deps={[property, dates]}
      params={{ property, dates }}
      paramsValidator={(params) => params.property && params.dates?.length}
      paramsFormatter={(params) => [
        params.property.value,
        dayjs(params.dates[0]).format('YYYY-MM-DD'),
        dayjs(params.dates[1]).format('YYYY-MM-DD'),
      ]}
    />
  );
}
