import React, { useContext } from 'react';
import DataFetcherComponent from '../../../../../../../components/DataFetcher/DataFetcher';
import { useUsersActivityService } from '../../../../../../../services/usersActivityService';
import { UsersActivityContext } from '../../../../../../../context/UsersActivityContext';
import dayjs from 'dayjs';
import VerticalBarChartComponent from '../../../../../../../components/VerticalBarChart/VerticalBarChart';

const UsersCountByActivityName = () => {
  const { getUsersByActivityName } = useUsersActivityService();
  const { dates } = useContext(UsersActivityContext);

  return (
    <DataFetcherComponent
      Child={VerticalBarChartComponent}
      childProps={{
        title: 'Users By Activity Name',
        index: 'activity_name',
        colors: ['emerald', 'gray', 'blue'],
      }}
      fetchMethod={getUsersByActivityName}
      defaultValue={[]}
      deps={[dates]}
      params={{ dates }}
      paramsValidator={(params) => params.dates?.length}
      paramsFormatter={(params) => {
        return [dayjs(params.dates[0]).format('YYYY-MM-DD'), dayjs(params.dates[1]).format('YYYY-MM-DD')];
      }}
    />
  );
};

export default UsersCountByActivityName;
