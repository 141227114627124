import React from 'react';
import {
  ActionIcon,
  LoadingOverlay,
  Overlay,
  Title,
  Text,
  Container,
  Card,
  Flex,
  useMantineTheme,
} from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { transformKeysToWordsAndUppercase } from '../../utils/charts.util';
import IconInfo from '../IconInfo/IconInfo';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

const PIE_COLORS = ['#fd7f6f', '#7eb0d5', '#b2e061', '#bd7ebe', '#ffb55a', '#ffee65', '#beb9db', '#fdcce5', '#8bd3c7'];

export default function PieChartComponent({
  title,
  data,
  index,
  hint,
  isLoading,
  isError,
  onRetry,
  customTooltipValueFormatter,
  dataFormatter = (x) => x,
  excludeFields = [],
}) {
  const theme = useMantineTheme();

  const chartColors = theme.colors?.chart_colors.concat(PIE_COLORS);

  const sortedDataByPercentage = dataFormatter(data).sort((a, b) => b.percentage - a.percentage);

  const { transformed, transformedKeys } = transformKeysToWordsAndUppercase(sortedDataByPercentage, [
    ...excludeFields,
    index,
  ]);

  const CustomTooltip = ({ payload, active }) => {
    if (!active || !payload) return null;
    const categoryPayload = payload?.[0];
    if (!categoryPayload) return null;

    return (
      <Card
        style={{
          boxShadow: '#00000021 1px 2px 1px',
        }}
      >
        <Flex>
          <Text size='sm'>{categoryPayload.payload[index]}</Text>
          <Text size='sm' ml='lg' fw={700}>
            {customTooltipValueFormatter(categoryPayload)}
          </Text>
        </Flex>
      </Card>
    );
  };

  return (
    <Card
      p={0}
      style={{
        border: isError && '2px solid red',
        height: 'fit-content',
        boxShadow: '#00000021 1px 2px 1px',
        backgroundColor: theme.colors.secondary_background_color[6],
      }}
    >
      <Container
        fluid
        sx={{
          padding: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `2px ${theme.colors.company_theme_primary_color[7]} solid`,
        }}
      >
        <Title order={5}>{title}</Title>
        {hint && <IconInfo hint={hint} />}
      </Container>

      <ResponsiveContainer width='100%' height={320}>
        <PieChart>
          <Legend
            wrapperStyle={{ width: '40%', fontSize: '.75rem', padding: '.5rem 0' }}
            layout='vetical'
            verticalAlign='middle'
            align={'right'}
          />
          <Tooltip position={{ x: 0 }} content={<CustomTooltip />} />
          <Pie
            data={transformed}
            cx={'30%'}
            cy='50%'
            animationDuration={650}
            outerRadius={'60%'}
            fill='#8884d8'
            style={{ outline: 'none', minWidth: '150' }}
            dataKey={transformedKeys[0]}
          >
            {transformed.map((entry, i) => (
              <Cell name={entry[index]} key={`cell-${i}`} fill={chartColors[i]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      <LoadingOverlay
        id={`loading_overlay__${title}`}
        zIndex={10}
        visible={isLoading}
        overlayProps={{ radius: 'sm', blur: 2 }}
        loaderProps={{ type: 'bars' }}
      />
      {isError && (
        <Overlay color={theme.colors.retry_error_color[6]} opacity={0.6} center>
          <ActionIcon size='xl' color='red' variant='filled' aria-label='Refresh' radius='xl'>
            <IconRefresh
              id={`retry_button__${title?.replaceAll(/\W/g, '')}`}
              style={{ width: '70%', height: '70%' }}
              stroke={1.5}
              onClick={onRetry}
            />
          </ActionIcon>
        </Overlay>
      )}
    </Card>
  );
}
