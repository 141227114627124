import React from 'react';
import { Center, Container, Skeleton, Tooltip, Text, useMantineTheme } from '@mantine/core';
import { IconAlertTriangle, IconArrowNarrowDown, IconArrowNarrowUp, IconRefresh } from '@tabler/icons-react';
import { usePortfolioOverviewService } from '../../../../../../services/bi/portfolioOverview/PortfolioOverviewService';
import { useHover } from '@mantine/hooks';
import { GROW } from '../../../../../../constants/metric';
import { Link } from 'react-router-dom';

export const useTableColumnData = () => {
  const {
    getImpressions,
    getClicks,
    getCTR,
    getAvgCpc,
    getLeads,
    getTours,
    getLeases,
    getAverageRentPerSqft,
    getOccupancy,
    getThirtyDaysExpirations,
    getSixtyDaysExpirations,
  } = usePortfolioOverviewService();
  const generateColumn = (accessorKey, header, { ...params } = {}) => ({
    accessorKey,
    header,
    id: accessorKey,
    Cell: ({ row, table }) => {
      const { hovered, ref } = useHover();
      const { colors } = useMantineTheme();

      return (
        <div ref={ref} style={{ cursor: table.getState()?.error[accessorKey] ? 'pointer' : 'default' }}>
          {table.getState()?.loading[accessorKey] ? (
            <Skeleton visible={true}>1</Skeleton>
          ) : table.getState()?.error[accessorKey] ? (
            hovered ? (
              <Container sx={{ height: 14 }}>
                <IconRefresh color='red' size={17} onClick={table.getState()?.onRetry[accessorKey]} />
              </Container>
            ) : (
              <Container sx={{ height: 14 }}>
                <IconAlertTriangle color='red' size={17} />
              </Container>
            )
          ) : (
            <Center>
              {accessorKey === 'property' ? (
                <Link
                  onClick={() => {
                    table.getState()?.onPropertyClicked(row.original?.propertyId);
                  }}
                  sx={{ lineHeight: 1 }}
                  fw={600}
                  mr={3}
                  style={{ color: colors.link_color[6] }}
                >
                  {row.original?.[accessorKey] && row.original?.[accessorKey] != '-' ? params.prefix : ''}
                  {row.original?.[accessorKey]}
                  {row.original?.[accessorKey] && row.original?.[accessorKey] != '-' ? params.suffix : ''}
                </Link>
              ) : (
                <Text sx={{ lineHeight: 1 }} fw={600} mr={3}>
                  {row.original?.[accessorKey] && row.original?.[accessorKey] != '-' ? params.prefix : ''}
                  {row.original?.[accessorKey]}
                  {row.original?.[accessorKey] && row.original?.[accessorKey] != '-' ? params.suffix : ''}
                </Text>
              )}

              {row.original?.[`${accessorKey}_percentage_change`]?.grow === GROW.UP && (
                <Tooltip
                  withArrow
                  arrowSize={8}
                  label={`+${
                    row.original?.[`${accessorKey}_percentage_change`]?.percentage_change
                  }% in comparison to previous period of time`}
                >
                  <Container sx={{ cursor: 'pointer' }} m={0} p={0} h={14}>
                    <IconArrowNarrowUp size={17} stroke={1.5} />
                  </Container>
                </Tooltip>
              )}
              {row.original?.[`${accessorKey}_percentage_change`]?.grow === GROW.DOWN && (
                <Tooltip
                  withArrow
                  arrowSize={8}
                  label={`${
                    row.original?.[`${accessorKey}_percentage_change`]?.percentage_change
                  }% in comparison to previous period of time`}
                >
                  <Container sx={{ cursor: 'pointer' }} m={0} p={0} h={14}>
                    <IconArrowNarrowDown size={17} stroke={1.5} />
                  </Container>
                </Tooltip>
              )}
            </Center>
          )}
        </div>
      );
    },
    maxSize: 0,
    columnFilterModeOptions: ['between', 'greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo'],
    sortingFn: (rowA, rowB) => {
      if (rowA.original?.[accessorKey] === '-') return -1;
      if (rowB.original?.[accessorKey] === '-') return 1;
      return +rowA.original?.[accessorKey] - +rowB.original?.[accessorKey];
    },
    ...params,
  });

  const initialColumnsData = [
    {
      columnName: 'property',
      column: generateColumn('property', 'Property', {
        mantineTableHeadCellProps: {
          align: 'left',
        },
        mantineTableBodyCellProps: {
          align: 'left',
        },
        minSize: 250,
        enableColumnFilter: false,
        sortingFn: (rowA, rowB) => rowA.original?.['property'].localeCompare(rowB.original?.['property']),
        enableColumnDragging: false,
        enableColumnOrdering: false,
      }),
      show: true,
    },
    {
      columnName: 'impressions',
      column: generateColumn('impressions', 'Impressions'),
      fetchMethod: getImpressions,
      show: true,
    },
    {
      columnName: 'clicks',
      column: generateColumn('clicks', 'Clicks'),
      fetchMethod: getClicks,
      show: true,
    },
    {
      columnName: 'ctr',
      column: generateColumn('ctr', 'CTR', {
        suffix: '%',
      }),
      fetchMethod: getCTR,
      show: true,
      valueFormatter: (value) => value?.toFixed(2),
    },
    {
      columnName: 'avg_cpc',
      column: generateColumn('avg_cpc', 'Avg. CPC', {
        prefix: '$',
      }),
      fetchMethod: getAvgCpc,
      show: true,
      valueFormatter: (value) => value?.toFixed(2),
    },
    {
      columnName: 'leads',
      column: generateColumn('leads', 'Total Leads'),
      fetchMethod: getLeads,
      show: true,
    },
    {
      columnName: 'tours',
      column: generateColumn('tours', 'Tours'),
      fetchMethod: getTours,
      show: true,
    },
    {
      columnName: 'leases',
      column: generateColumn('leases', 'Leases'),
      fetchMethod: getLeases,
      show: true,
    },
    {
      columnName: 'avg_rent_sqft',
      column: generateColumn('avg_rent_sqft', 'Avg. Rent/Sqft.', {
        prefix: '$',
      }),
      fetchMethod: getAverageRentPerSqft,
      show: true,
      valueFormatter: (value) => value?.toFixed(2),
    },
    {
      columnName: 'occupancy',
      column: generateColumn('occupancy', 'Occupancy', {
        suffix: '%',
      }),
      fetchMethod: getOccupancy,
      show: true,
      valueFormatter: (value) => value?.toFixed(2),
    },
    {
      columnName: '30_day',
      column: generateColumn('30_day', '30-Day'),
      fetchMethod: getThirtyDaysExpirations,
      show: true,
    },
    {
      columnName: '60_day',
      column: generateColumn('60_day', '60-Day'),
      fetchMethod: getSixtyDaysExpirations,
      show: true,
    },
  ];

  const initialState = {
    sorting: [{ id: 'property', asc: true }],
    columnPinning: { left: ['property'] },
    pagination: { pageSize: 15 },
  };

  return {
    initialColumnsData,
    generateColumn,
    initialState,
  };
};
